export const SpStringConstants = {
    sessionsText: 'Sessions',
    SortByText: "Sort by:",
    MyAautiText: "My Aauti >",
    PercentageText: "Percentage",
    AttendenceText: "Attendence",
    AttendedText: "Attended",
    UpcomingText: "upcoming",
    NotAttendedText: "Not Attended",
    endUserText: "endUser",
    SessionText: "Session",
    BatchesText: "Batches",
    messageText: "When you do,their status will appear here",
    extraTextText: "consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    topicNameText: "No Data Found",
    noImage:"https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg",
    SearchOrderText: "Search"
}