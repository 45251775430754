import { chatUrl } from "../service/api-constants.js";
import {
  MaxChatResultsCount,
  SocketEvents,
} from "../components/chat/constants";
import {
  setSocketOpenStatus,
  setActiveRoom,
  setActiveUser,
  updateUnreadCount,
} from "../redux/reducer/chatSlice.js";

const socketMiddleware = (store) => {
  let socket = null;
  let timerId;
  let isConnected = false;
  let queuedEvents = {};
  let reconnectAttempts = 0;
  const maxReconnectAttempts = 5;
  const maxQueuedEvents = 10; 

  function resetQueue() {
    queuedEvents = {};
  }

  function closeSocket(error = true) {
    socket?.close();
    socket = null;
    isConnected = false;
    if (error || ["close", "error"].includes(error.type)) {
      store.dispatch(setSocketOpenStatus(false));
    }
    if ([1006, 1012, 1013].includes(error.code)) {
      reconnectSocket();
    }
    reconnectAttempts = 0
  }

  function onOpenSocket() {
    timerId && clearInterval(timerId);
    getUnreadCount();
    Object.values(queuedEvents).forEach(send);
    reconnectAttempts = 0;
  }

  function onMessage(msgEvent) {
    const { event, ...rest } = JSON.parse(msgEvent.data);
    if (!isConnected && socket.readyState !== WebSocket.CONNECTING) {
      store.dispatch(setSocketOpenStatus(true));
      isConnected = true;
    }

    if (queuedEvents.hasOwnProperty(event)) {
      delete queuedEvents[event];
    }

    switch (event) {
      case SocketEvents.ping:
        send({ event: SocketEvents.pong });
        break;
      case SocketEvents.connected:
        onOpenSocket();
        break;
      case SocketEvents.users.getUnreadCount:
        store.dispatch(updateUnreadCount(rest.unreadCount));
        break;
      case SocketEvents.users.status:
        store.dispatch(setActiveUser(rest.data));
        break;
      case SocketEvents.users.getSupport:
      case SocketEvents.room.getRoom:
        store.dispatch(setActiveRoom({ data: rest.data }));
        break;

      default: {
        const eventHandler = getChatPropFromStore("eventHandler");
        if (typeof eventHandler === "function") {
          eventHandler({ event, ...rest });
        } else {
          handleEventsInMiddleWare({ event, ...rest });
        }
        break;
      }
    }
  }
  function reconnectSocket() {
    if ([WebSocket.CONNECTING, WebSocket.OPEN].includes(socket?.readyState)) return;
    
    const userId = localStorage.getItem("USER_ID");
    if (timerId) clearInterval(timerId);
    
    if (!socket || !userId) return;

    if (reconnectAttempts >= maxReconnectAttempts) return;
    reconnectAttempts++;
    const reconnectDelay = Math.min(1000 * Math.pow(2, reconnectAttempts), 30000); // Exponential backoff (up to 30 seconds)

    timerId = setTimeout(() => connectSocket(userId), reconnectDelay);
  }

  function getChatPropFromStore(prop) {
    const chatState = store.getState().chat;
    return chatState?.[prop];
  }

  function getUnreadCount() {
    send({
      event: SocketEvents.users.getUnreadCount,
    });
  }

  function handleEventsInMiddleWare(props) {
    if (props?.data?.hasOwnProperty("message") && (!props?.batchId || !props?.sessionId)) {
      getUnreadCount()
    }
  }


  function send(data) {
    if (isConnected) {
      const stringifiedData = JSON.stringify(data);
      socket?.send(stringifiedData);
    } else {
      if (Object.keys(queuedEvents).length >= maxQueuedEvents) {
        resetQueue();
      }
      queuedEvents[data.event] = data;
      reconnectSocket();
    }
  }

  const connectSocket = (userId) => {
    if (isConnected || socket?.readyState === WebSocket.CONNECTING || !userId) return;

    socket = new WebSocket(`${chatUrl}${userId}`);
    socket.onopen = onOpenSocket;
    socket.onmessage = onMessage;
    socket.onerror = closeSocket;
    socket.onclose = closeSocket;
  };

  return (next) => (action) => {
    switch (action?.type) {
      case SocketEvents.connect:
        if (!isConnected) {
          connectSocket(action.payload);
        }
        break;

      case SocketEvents.room.getConversations:
        getUnreadCount();
      case SocketEvents.getContacts:
      case SocketEvents.getCourseSubscribers:
      case SocketEvents.room.getRoom:
        send({
          event: action.type,
          ...action.payload,
          limit: MaxChatResultsCount,
        });
        break;

      case SocketEvents.users.getUnreadCount:
        getUnreadCount();
        break;

      case SocketEvents.room.getMessagesByRoom:
        send({
          event: action.type,
          roomId: action.roomId,
          pageNo: action.pageNo,
          limit: MaxChatResultsCount,
        });
        break;

      case SocketEvents.users.status:
        send({ event: action.type, userId: action.payload });
        break;

      case SocketEvents.room.updateInfo:
      case SocketEvents.room.deleteConversation:
      case SocketEvents.room.freeze:
      case SocketEvents.room.unFreeze:
      case SocketEvents.room.updateLastSeen:
      case SocketEvents.room.clearConversation:
      case SocketEvents.room.getMembers:
      case SocketEvents.room.makeAdmin:
      case SocketEvents.room.makeModerator:
      case SocketEvents.room.removeModerator:
      case SocketEvents.room.removeAdminAccess:
      case SocketEvents.room.removeUser:
      case SocketEvents.room.leave:
      case SocketEvents.room.blockUser:
      case SocketEvents.room.unblockUser:
      case SocketEvents.room.updateTimeFreeze:
      case SocketEvents.room.createRoom:
      case SocketEvents.message.send:
      case SocketEvents.message.edit:
      case SocketEvents.message.delete:
      case SocketEvents.message.messageDeleteForMe:
      case SocketEvents.aautiFeed.getFeedsAccess:
      case SocketEvents.aautiFeed.updateFeedsAccess:
      case SocketEvents.aautiFeed.recentFeed:
      case SocketEvents.users.getSupport:
      case SocketEvents.room.addUsers:
      case SocketEvents.call.saveSessionCall:
      case SocketEvents.call.updateSessionCall:
        send({
          event: action.type,
          ...(action?.payload ?? {}),
        });
        break;
      default:
        break;
    }
    next(action)
  };
};

export default socketMiddleware;
