import React, { useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { Drawer, Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AccountTabs from "../globalComponents/AccountTabs";
import { useDispatch, useSelector } from "react-redux";
import {
  endUserAccount,
  endUserSettings,
  spAccountItems,
  spSettigsList,
} from "./constants";
import LockResetIcon from "@mui/icons-material/LockReset";
import {
  setSignupScreenIndex,
  toggleSignInPop,
} from "../../redux/reducer/appReducer";
import { MdOutlineAttachMoney } from "react-icons/md";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { AuthService } from "./Services";
import { showToast } from "../globalComponents/Toast";
import { logoutFunction } from "../CommonFunctions";
import AppContainer from "../AppContainer";
import { BsPersonAdd } from "react-icons/bs";
import { Height } from "@mui/icons-material";
import CourseInPerson from "../course/createCourse/CourseInperson";
import BottomSheet from "../globalComponents/BottomSheet";
import { resetChatSlice } from "../../redux/reducer/chatSlice";
import { FiInfo } from "react-icons/fi";
import { HeaderNotificationsInActive } from "../homeScreen/header/pageHeader/pageHeaderUtils";

const AcccountManagement = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { loggedinUserId, userDetails, UserRole } = useSelector(
    (state) => state.app
  );
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [openAddress, setOpenAddress] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    userDetails?.addressList?.find((item) => item.isDefault === true)
  );
  const activeList = ["/my-account"]?.includes(pathname)
    ? UserRole === "endUser"
      ? userDetails?.parentId
        ? endUserAccount.filter((each) => each.id !== 7)
        : [
            ...endUserAccount,
            {
              id: 85,
              label: "My Orders",
              icon: MdOutlineAttachMoney,
              screenName: "/orders",
            },
            {
              id: 86,
              label: "Notifications",
              icon: HeaderNotificationsInActive,
              screenName: "/notifications",
            },
          ]
      : spAccountItems
    : UserRole === "endUser"
    ? userDetails?.createdByParent && userDetails?.parentId
      ? endUserSettings.filter((each) => each.id !== 220)
      : userDetails?.isEmailVerified && userDetails?.email
      ? [
          ...endUserSettings,
          {
            id: 11,
            label:
              userDetails?.email && userDetails?.isPassword
                ? "Change Password"
                : "Create Password",
            icon: LockResetIcon,
            screenName: "",
          },
        ]
      : endUserSettings
    : userDetails?.isEmailVerified && userDetails?.email
    ? [
        ...spSettigsList,
        {
          id: 11,
          label:
            userDetails?.email && userDetails?.isPassword
              ? "Change Password"
              : "Create Password",
          icon: LockResetIcon,
          screenName: "",
        },
      ]
    : spSettigsList;
  const screenName = ["/my-account"]?.includes(pathname)
    ? "My Account"
    : "Settings";

  const accept = () => {
    AuthService.deleteUserAccount(loggedinUserId, onSuccess, onFailure);
  };
  const onSuccess = (response) => {
    if (response?.status === "Success") {
      showToast("success", response?.message);
      navigate("/");
      logoutFunction();
      dispatch(resetChatSlice());
    } else {
      showToast("success", response?.message);
    }
  };
  const onFailure = (err) => {
    showToast("error", "Something went wrong");
  };

  const reject = () => {
    console.log("Not Deleted");
  };
  const confirm1 = () => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      defaultFocus: "accept",
      accept,
      reject,
    });
  };
  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      {/* <AccountTabs /> */}
      <AautiText
        weight={"bold"}
        title={screenName}
        textStyle={{ marginBottom: 5 }}
      />
      <Grid container sx={{ mt: 1 }} gap={1} id>
        {activeList?.map((each, index) => {
          return (
            <Grid
              id={`${each.label}-myaccount`}
              onClick={() => {
                if (
                  each?.label === "Change Password" ||
                  each?.label === "Create Password"
                ) {
                  // dispatch(setSignupScreenIndex(8));
                  // dispatch(toggleSignInPop(true));
                  navigate("/user/profile/change-password", {
                    state: { createPassword: userDetails?.isPassword },
                  });
                } else if (each?.label === "Delete Account") {
                  navigate("/delete-deactivate");
                } else if (each.screenName === "address") {
                  setOpenAddress(true);
                } else {
                  navigate(each?.screenName, {
                    state: { screenName: each.label },
                  });
                }
              }}
              key={index}
              item
              xs={5.8}
              sm={3.8}
              md={3}
              lg={2}
              xl={1.5}
              borderRadius={1}
              sx={{
                cursor: "pointer",
                border: "1px solid #d2d2d2",
                p: 1,
                display:
                  each?.label == "Add Member" &&
                  userDetails?.parentId &&
                  "none",
              }}
            >
              <each.icon size={18} />
              <AautiText title={each?.label} weight={"bold"} size={"semi"} />
            </Grid>
          );
        })}
        <ConfirmDialog />
      </Grid>
      <BottomSheet
        open={openAddress}
        onClose={() => {
          setOpenAddress(false);
        }}
      >
        <CourseInPerson
          clientLocation={false}
          handleSelectAddress={(each) => {
            setSelectedAddress(each);
          }}
          selectedAddress={selectedAddress}
          handleSetSelectAddress={() => {
            setOpenAddress(false);
          }}
          // CreateCourseObj={availableObj}
          // isClientLocation="clientLocation"
          // {...{ handleSetSelectAddress }}
          // changeCreateObj={changeAvailableObj}
          // {...{  changeCreateObj, setCreateCourseObj }}
        />
      </BottomSheet>
    </AppContainer>
  );
};

export default AcccountManagement;
