import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllStudentsAdhoc,
  getAllStudentsBySPId,
  getAllStudentsBySPIdCount,
  getAllStudentsGigs,
  getEndUserAttendance,
  getEndUserAttendanceForAdhoc,
  getEndUserAttendanceForCourse,
  getServiceProviderAllRatings,
  getServiceProviderAttendance,
  getServiceProviderAttendanceForAdhoc,
  getServiceProviderAttendanceForCourse,
  getServiceProviderRatings,
  getServiceProviderRatingsForAdhoc,
  getServiceProviderRatingsForCourses,
  getServiceProviderRatingsForGig,
  getServiceProviderRevenue,
  getServiceProviderRevenueForAdhocs,
  getServiceProviderRevenueForCourse,
  getServiceProviderRevenueForGigs,
  getEUAttendanceForAdhocId,
  getEUAttendanceForCourseId,
  getRevenueForAdhocId,
  getRevenueForCourseId,
  getRevenueForGigId,
  getSPAttendanceForAdhocId,
  getSPAttendanceForCourseId,
  getStudentsForCourseId,
  getStudentsForGigId,
  getStudentsForAdhocId,
  getRatingsForGigId,
  getRatingsForCourseId,
  getRatingsForAdhocId,
} from "./Services";
import { useLocation } from "react-router-dom";
import { checkNull } from "../../utils/Helpers";

const SpContainer = (props) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { loggedinUserId, UserRole } = useSelector((state) => state.app);
  const location = useLocation();
  const dashBoardTabTitle = location?.state?.dashBoardNavigation;
  const pathnameWithoutSlash = location.pathname.startsWith("/")
    ? location.pathname.slice(1)
    : location.pathname;
  const currentScreen =
    props.type != undefined ? props.type : pathnameWithoutSlash;
  const dashboardNav = location?.state?.dashBoardNavigation;
  const [activeTab, setActiveTab] = useState("Courses");
  const [headerCount, setHeaderCount] = useState([]);
  const [renderData, setRenderData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [displayPopUp, setDisplayPopUp] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [open, setOpen] = useState(false);
  const [firstItem, setFirstItem] = useState(false);
  const [selectedRole, setSelectedRole] = useState("Service Provider");
  const [hasData, setHasData] = useState(true);
  const [offSet, setOffset] = useState(0);
  const count = 10;
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // console.log(searchText, "searchText");

  useEffect(() => {
    apiCall(0, "");
  }, [activeTab, selectedRole, props?.id]);

  const apiCall = (offsetkey = offSet, search = searchText) => {
    const params = { offset: offsetkey, count: count, searchKey: search };
    if (UserRole === "endUser") {
      params.endUserId = props.id != undefined ? props.id : loggedinUserId;
    } else {
      params.serviceProviderId =
        props.id != undefined ? props.id : loggedinUserId;
    }
    if (offSet == 0 && search == "") {
      setLoading(true);
    }

    const onSuccessCount = (response) => {
      setHeaderCount(response?.result);
    };
    if (selectedRole === "End User") {
      params.type = "endUser";
    } else {
      params.type = "serviceProvider";
    }
    switch (currentScreen) {
      case "students":
        getAllStudentsBySPIdCount(params, onSuccessCount, onFailure);
        break;
      case "my-revenue":
        getServiceProviderRevenue(params, onSuccessCount, onFailure);
        break;
      case "attendance-details":
        if (UserRole === "endUser") {
          getEndUserAttendance(params, onSuccessCount, onFailure);
        } else {
          getServiceProviderAttendance(params, onSuccessCount, onFailure);
        }
        break;
      case "reviews-ratings":
        if (activeTab !== "My Reviews") {
          getServiceProviderRatings(params, onSuccessCount, onFailure);
        }
        break;
      default:
        break;
    }

    const expandFirstAccordianItem = (data) => {
      switch (currentScreen) {
        case "students":
          if (activeTab === "Courses") {
            if (data?.length > 0) {
              setExpanded(data[0]?.batches[0]?._id);
            }
          }
          break;
        case "my-revenue":
          if (activeTab === "Courses") {
            if (data?.length > 0) {
              setExpanded(data[0]?.batches[0]?._id);
            }
          }
          break;
        case "attendance-details":
          if (activeTab === "Courses") {
            if (data?.length > 0) {
              setExpanded(data[0]?.batches[0]?._id);
            }
          }
          break;
        default:
          break;
      }
    };
    const onSuccess = (response) => {
      setLoading(false);
      if (offsetkey == 0) {
        if (response?.result) {
          const data =
            response?.result?.courses ||
            response?.result?.gigs ||
            response?.result?.adhocs ||
            response?.result?.reviews;
          if (activeTab === "My Reviews") {
            setHeaderCount(response?.result);
          }
          if (checkNull(data)) {
            setRenderData([]);
          } else if (data.length < count) {
            setRenderData(data);
            {
              !mobileDevices && onClickEachItem(data[0]);
            }

            setSelectedData(data?.[0]);
            setHasData(false);
          } else {
            setRenderData(data);
            {
              !mobileDevices && onClickEachItem(data[0]);
            }
            setSelectedData(data?.[0]);
            setOffset(offsetkey + 1);
            setHasData(true);
          }
        }
      } else {
        if (response?.result) {
          const data =
            response?.result?.courses ||
            response?.result?.gigs ||
            response?.result?.adhocs ||
            response?.result?.reviews;

          if (checkNull(data)) {
            setLoading(false);
          } else if (data?.length < count) {
            setRenderData([...renderData, ...data]);
            setHasData(false);
          } else {
            setRenderData([...renderData, ...data]);
            setOffset(offsetkey + 1);
            setHasData(true);
          }
        }
      }
      // if (response?.result && Object.keys(response.result).length > 0) {
      //   const data =
      //     response?.result?.courses ||
      //     response?.result?.gigs ||
      //     response?.result?.adhocs ||
      //     response?.result?.reviews;
      //   if (activeTab === "My Reviews") {
      //     setHeaderCount(response?.result);
      //   }
      //   setRenderData(data);
      //   {
      //     !mobileDevices && onClickEachItem(data[0]);
      //   }
      //   setSelectedData(data?.[0]);
      //   // expandFirstAccordianItem(data);
      // }
      setLoading(false);
    };
    if (selectedRole === "End User") {
      params.type = "endUser";
    } else {
      params.type = "serviceProvider";
    }
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          getAllStudentsBySPId(params, onSuccess, onFailure);
        } else if (activeTab === "Gigs") {
          getAllStudentsGigs(params, onSuccess, onFailure);
        } else {
          getAllStudentsAdhoc(params, onSuccess, onFailure);
        }
        break;
      case "my-revenue":
        if (activeTab === "Courses") {
          getServiceProviderRevenueForCourse(params, onSuccess, onFailure);
        } else if (activeTab === "Gigs") {
          getServiceProviderRevenueForGigs(params, onSuccess, onFailure);
        } else {
          getServiceProviderRevenueForAdhocs(params, onSuccess, onFailure);
        }
        break;
      case "attendance-details":
        if (UserRole === "endUser") {
          if (activeTab === "Courses") {
            getEndUserAttendanceForCourse(params, onSuccess, onFailure);
          } else {
            getEndUserAttendanceForAdhoc(params, onSuccess, onFailure);
          }
        } else {
          if (activeTab === "Courses") {
            getServiceProviderAttendanceForCourse(params, onSuccess, onFailure);
          } else {
            getServiceProviderAttendanceForAdhoc(params, onSuccess, onFailure);
          }
        }
        break;
      case "reviews-ratings":
        if (activeTab === "Courses") {
          getServiceProviderRatingsForCourses(params, onSuccess, onFailure);
        } else if (activeTab === "Gigs") {
          getServiceProviderRatingsForGig(params, onSuccess, onFailure);
        } else if (activeTab === "Adhocs") {
          getServiceProviderRatingsForAdhoc(params, onSuccess, onFailure);
        } else if (activeTab === "My Reviews") {
          getServiceProviderAllRatings(params, onSuccess, onFailure);
        }
        break;
      default:
        break;
    }
  };
  const titleName = () => {
    if (currentScreen === "students") {
      return "Students";
    } else if (currentScreen === "my-revenue") {
      return "Revenue";
    } else {
      return "Attendance";
    }
  };
  const onFailure = () => {
    console.log("error");
    setLoading(false);
  };
  const onSwitchRoleTab = (title) => {
    setSelectedRole(title);
    setSearchText("");
    setActiveTab(activeTab);
    apiCall(0, "");
    setFirstItem(true);
  };
  const onHandleTitleClick = (title) => {
    setSearchText("");
    setActiveTab(title);
    setSelectedData({});
    setFirstItem(false);
    setRenderData([]);
    setHeaderCount([]);
    setLoading(true);
  };
  const onClickEachItem = (eachItem) => {
    activeTab !== "My Reviews" && setFirstItem(true);
    if (mobileDevices) {
      setDisplayPopUp(true);
      setSelectedData(eachItem);
    }
    const params = {};
    if (activeTab === "Courses") {
      params.courseId = eachItem?.courseId || eachItem?._id;
    } else if (activeTab === "Gigs") {
      params.gigId = eachItem?.gigId || eachItem?._id;
    } else if (activeTab === "Adhocs") {
      params.schedulerId = eachItem?.adhocId || eachItem?._id;
    } else if (activeTab === "My Reviews") {
      setStudentId(eachItem?.endUserId);
      // renderData?.length > 0 &&
      //   offSet == 0 &&
      //   checkNull(searchText) &&
      //   setOpen(true);
    }
    const endUserParams = {
      endUserId: props?.id != undefined ? props?.id : loggedinUserId,
    };
    if (activeTab === "Courses") {
      endUserParams.courseId = eachItem?.courseId || eachItem?._id;
    } else if (activeTab === "Adhocs") {
      endUserParams.schedulerId = eachItem?.adhocId || eachItem?.schedulerId;
    }
    const onSuccess = (response) => {
      setSelectedData(response?.result);
      setExpanded(
        response?.result?.batches?.[0]?._id ||
          response?.result?.batches?.[0]?.batchId
      );
      setFirstItem(false);
    };
    const onFailure = (error) => {
      console.log("error");
    };
    if (selectedRole === "End User") {
      params.type = "endUser";
    } else {
      params.type = "serviceProvider";
    }
    switch (currentScreen) {
      case "students":
        if (activeTab === "Courses") {
          getStudentsForCourseId(params, onSuccess, onFailure);
        } else if (activeTab === "Gigs") {
          getStudentsForGigId(params, onSuccess, onFailure);
        } else if (activeTab === "Adhocs") {
          getStudentsForAdhocId(params, onSuccess, onFailure);
        }
        break;
      case "my-revenue":
        if (activeTab === "Courses") {
          getRevenueForCourseId(params, onSuccess, onFailure);
        } else if (activeTab === "Gigs") {
          getRevenueForGigId(params, onSuccess, onFailure);
        } else if (activeTab === "Adhocs") {
          getRevenueForAdhocId(params, onSuccess, onFailure);
        }
        break;
      case "attendance-details":
        if (UserRole === "serviceprovider") {
          if (activeTab === "Courses") {
            getSPAttendanceForCourseId(params, onSuccess, onFailure);
          } else if (activeTab === "Adhocs") {
            getSPAttendanceForAdhocId(params, onSuccess, onFailure);
          }
        } else if (UserRole === "endUser") {
          if (activeTab === "Courses") {
            getEUAttendanceForCourseId(endUserParams, onSuccess, onFailure);
          } else if (activeTab === "Adhocs") {
            getEUAttendanceForAdhocId(endUserParams, onSuccess, onFailure);
          }
        }
        break;
      case "reviews-ratings":
        if (activeTab === "Courses") {
          getRatingsForCourseId(params, onSuccess, onFailure);
        } else if (activeTab === "Gigs") {
          getRatingsForGigId(params, onSuccess, onFailure);
        } else if (activeTab === "Adhocs") {
          getRatingsForAdhocId(params, onSuccess, onFailure);
        }
        break;

      default:
        break;
    }
    setSelectedData(eachItem);
    // setExpanded(eachItem?.batches?.[0]?._id);
  };
  return {
    onHandleTitleClick,
    activeTab,
    headerCount,
    renderData,
    expanded,
    setExpanded,
    handleChange,
    searchText,
    setSearchText,
    selectedData,
    setSelectedData,
    titleName,
    currentScreen,
    loading,
    setLoading,
    dashboardNav,
    displayPopUp,
    setDisplayPopUp,
    dashBoardTabTitle,
    studentId,
    setStudentId,
    open,
    setOpen,
    onClickEachItem,
    firstItem,
    setOffset,
    onSwitchRoleTab,
    hasData,
    apiCall,
    selectedRole,
  };
};
export default SpContainer;
