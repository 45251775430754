import React, { useEffect, useRef, useState } from "react";
import { loadScript } from "../../CommonFunctions";

const MapContainer = ({ getLocationByLatLan, coordinates, setCoordinates }) => {
  const [google, setGoogle] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const loadGoogleMaps = async () => {
      try {
        await loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyD__NdV6G3azCam2izv7RkCrUXCn16sdMQ&libraries=places`,
          "google-maps-script"
        );
        setGoogle(window.google);
      } catch (error) {
        console.error("Error loading the Google Maps API script:", error);
      }
    };

    loadGoogleMaps();
  }, []);

  useEffect(() => {
    if (google && mapRef.current) {
      const map = new google.maps.Map(mapRef.current, {
        center: coordinates,
        zoom: 14,
      });

      const marker = new google.maps.Marker({
        position: coordinates,
        map: map,
      });

      google.maps.event.addListener(map, "click", (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const obj = { lat, lng };
        console.log(obj, "obj?...");
        setCoordinates(obj);
        getLocationByLatLan(obj);
        marker.setPosition(obj);
      });
    }
  }, [google, coordinates, getLocationByLatLan, setCoordinates]);

  return <div ref={mapRef} style={{ width: "100%", height: "30vh" }} />;
};

export default MapContainer;
