import React, { memo, useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import NavButton from "./NavButton";
import { PriceConversions } from "../../CommonFunctions";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { checkNull } from "../../../utils/Helpers";
import { showToast } from "../../globalComponents/Toast";

const MembersConditions = ({
  adhoc = false,
  handleSaveConditions = () => {},
  courseBatch,
  handleChangePercentageAdhoc = () => {},
}) => {
  const [conditions, setConditions] = useState(courseBatch?.membersDiscount);
  const { convertPriceWithCurrency, convertIndianAmount } = PriceConversions();
  useEffect(() => {
    if (courseBatch?.membersDiscount) {
      setConditions(courseBatch.membersDiscount);
    }
  }, [courseBatch]);
  function convertINRtoUSD(amountInINR) {
    const returnAmount = convertIndianAmount(amountInINR);
    return returnAmount || 0;
  }
  const amount =
    courseBatch.localIndividualDiscountPrice ||
    courseBatch.localIndividualPrice;

  const returnCalAmount = (percentage) => {
    const discountAmount = (amount * percentage) / 100;
    const finalAmount = amount - discountAmount;
    return finalAmount;
  };

  const handlePercentageChange = (value, key) => {
    const numericValue = Number(value);
    if (!isNaN(numericValue)) {
      const updatedDiscounts = conditions?.map((discount) => {
        if (discount.noOfMembers == key) {
          const updatedValue = Math.min(numericValue, discount.maximum);
          const amount = returnCalAmount(updatedValue);
          return {
            ...discount,
            percentage: updatedValue.toString(),
            finalPrice: convertINRtoUSD(amount),
            localFinalPrice: amount,
          };
        }
        return discount;
      });

      setConditions(updatedDiscounts);
      // onChangeAddPrice(updatedDiscounts, "membersDiscount");
    }
  };
  return (
    <div style={{ width: "100%" }}>
      {conditions?.map((item, index) => (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div>
            <AautiText
              textStyle={{ color: "#000" }}
              title={`Per-Member Discount- For ${item?.noOfMembers || 0}`}
            />
            <AautiText
              title={`Final Price:${convertPriceWithCurrency(
                item?.finalPrice
              )}`}
              size={"small"}
              textStyle={{ display: adhoc && "none", fontFamily: "sans-serif" }}
            />
          </div>
          <div style={{ width: "10%" }}>
            <AautiTextInput
              onChange={(e) => {
                handlePercentageChange(e.target.value, item?.noOfMembers);
                handleChangePercentageAdhoc(e.target.value, item?.noOfMembers);
              }}
              style={{ width: "100%" }}
              text={"%"}
              value={item?.percentage}
            />
          </div>
        </div>
      ))}
      {!adhoc && (
        <NavButton
          // disabled={!checkDisabled()}
          text={AautiStrings?.SaveString}
          handleClick={() => {
            const checkPercentage = conditions?.every(
              (item) => !checkNull(item?.percentage)
            );

            // console.log(checkPercentage, "checkPercentage");
            if (checkPercentage) {
              handleSaveConditions(conditions);
            } else {
              showToast("info", "Discount percentage cannot be zero or empty");
            }
            // setDialogOpen("");
          }}
        />
      )}
    </div>
  );
};

export default memo(MembersConditions);
