import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSocketOpen: false,
  unreadCount: 0,
  activeRoom: undefined,
  eventHandler: null,
  contacts: [],
  activeUser: {
    _id: null,
    onlineStatus: false,
  },
  activeChatRoomMembers: [],
};

export const getUserProfileDetails = (state) => ({
  _id: state.app?.loggedinUserId,
  displayName: state.app?.userDetails?.displayName,
  profileImage: state.app?.userDetails?.profileImage,
});

export const getActiveChatRoom = (state) => state.chat.activeRoom;
export const getActiveChatRoomId = (state) => state.chat?.activeRoom?._id;

export const getUnreadCount = (state) => state.chat.unreadCount;
export const getContacts = (state) => state.chat.contacts;
export const getActiveChatRoomMembers = (state) =>
  state.chat.activeChatRoomMembers;
export const getIsSocketOpen = (state) => state.chat.isSocketOpen;
export const getUserRole = (state) => state.app?.UserRole;
// export const getChatsView = state=>state.chat.chatsView;

export const getChatsListProps = createSelector(
  [getUnreadCount, getContacts, getIsSocketOpen,getActiveChatRoomId],
  (unreadCount, contacts, isSocketOpen,activeChatRoomId) => ({
    contacts,
    unreadCount,
    isSocketOpen,
    activeChatRoomId,
  })
);

export const chatRoomSelector = createSelector(
  [getActiveChatRoom, getUserProfileDetails, getIsSocketOpen],
  (activeRoom, sender, isSocketOpen) => ({ activeRoom, sender, isSocketOpen })
);

export const createGroupDialogSelector = createSelector(
  [getUserRole, getContacts],
  (role, contactsList) => ({ role, contactsList })
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setSocketOpenStatus(state, { payload }) {
      state.isSocketOpen = payload
    },
    setActiveRoom(state, { payload: { data, resetContacts = false } }) {
      state.activeRoom = data;
      if(!data) {
        state.activeChatRoomMembers = []
      }
      if (resetContacts) {
        state.contacts = [];
      }
    },
    setSocketHandler(state, { payload }) {
      state.eventHandler = payload;
    },
    updateUnreadCount(state, { payload }) {
      state.unreadCount = payload;
    },
    updateContacts(state, { payload }) {
      state.contacts = payload;
    },
    updateActiveChatRoomMembers(state, { payload }) {
      state.activeChatRoomMembers = payload;
    },
    addAdmin(state, { payload }) {
      state.activeRoom.admins.push(payload);
    },
    removeAdmin(state, { payload }) {
      state.activeRoom.admins = state.activeRoom.admins?.filter(
        (adminId) => adminId !== payload
      );
    },
    addModerator(state, { payload }) {
      state.activeRoom.moderators.push(payload);
    },
    removeModerator(state, { payload }) {
      state.activeRoom.moderators = state.activeRoom.moderators?.filter(
        (adminId) => adminId !== payload
      );
    },
    updateBlockedUsers(state, { payload: { blockedBy, blockedUsers = [] } }) {
      state.activeRoom.user['blockedBy'] = blockedBy;
      if (!state.activeRoom?.blockedUsers) {
        state.activeRoom.blockedUsers = [];
      }
      state.activeRoom.blockedUsers = [...blockedUsers];
    },
    updateAdminFreezeStatus(state, { payload }){
      state.activeRoom.adminFreezed = payload
    },
    updateAdminsInRoom(state, { payload }){
      state.activeRoom.admins = payload
    },
    blockUser(state, { payload: { blockedBy, userId } }) {
      state.activeRoom.user.blockedBy = blockedBy;
      if (!state.activeRoom?.blockedUsers) {
        state.activeRoom.blockedUsers = [];
      }
      state.activeRoom.blockedUsers.push(userId);
    },
    unblockUser(state, { payload }) {
      state.activeRoom.user.blockedBy = null;
      state.activeRoom.blockedUsers = state.activeRoom.blockedUsers?.filter(
        (userId) => userId !== payload
      );
    },
    leaveRoom(state, { payload }) {
      let userId = payload.userId;
      const activeRoom = state.activeRoom;
      if (activeRoom.user?._id === userId) {
        activeRoom.user.exitDate = new Date();
      }
      if (activeRoom.admins.includes(userId)) {
        activeRoom.admins = activeRoom.admins.filter((id) => id !== userId);
      }
      if (activeRoom?.moderators?.includes(userId)) {
        activeRoom.moderators = activeRoom.moderators.filter(
          (id) => id !== userId
        );
      }
      state.activeRoom = activeRoom;
    },
    setActiveUser(state, { payload = { _id: null, onlineStatus: false } }) {
      state.activeUser = payload;
    },
    resetChatSlice(state, {}) {
      state = initialState
    },
  },
});

export const {
  resetChatSlice,
  addAdmin,
  removeAdmin,
  addModerator,
  removeModerator,
  setSocketOpenStatus,
  setSocketHandler,
  startSocketConnection,
  setActiveRoom,
  updateContacts,
  updateUnreadCount,
  updateAdminsInRoom,
  updateBlockedUsers,
  updateAdminFreezeStatus,
  blockUser,
  unblockUser,
  setActiveUser,
  leaveRoom,
  updateActiveChatRoomMembers,
} = chatSlice.actions;

export default chatSlice.reducer;
