import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { MdOutlineModeEditOutline } from "react-icons/md";
import AautiText from "../../globalComponents/AautiText";
import PreviewCard from "./PreviewCard";
import { useSelector } from "react-redux";
import PreviewGigCard from "./PreviewGigCard";

const PreviewImage = ({
  handleClickOnPreviewEdit,
  url = "",
  isGig = false,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  function isVideoUrl(url) {
    return /\.(mp4|mov|avi|mkv|webm|flv|wmv)$/i.test(url);
  }
  const isVideo = isVideoUrl(url);

  return (
    <Box
      width={"100%"}
      display={"flex"}
      flexDirection={mobileDevices ? "column" : "row"}
      gap={4}
    >
      {isVideo ? (
        <Box
          sx={{
            height: mobileDevices ? "280px" : "600px",
            width: "100%",
            borderRadius: 6,
          }}
        >
          <video
            controls={true}
            autoPlay={false}
            src={url}
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      ) : (
        <Box
          width={mobileDevices ? "100%" : "70%"}
          sx={{
            height: mobileDevices ? "280px" : "600px",
            width: "100%",
            backgroundImage: `url(${url})`,
            backgroundSize: "cover",
            position: "relative",
            borderRadius: 6,
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backdropFilter: "blur(10px)",
              borderRadius: 6,
            }}
          ></div>
          <img
            //   className={className}
            alt="related-imgs"
            width="100%"
            src={url}
            style={{
              width: "100%",
              height: "100%",

              objectFit: "contain",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "6px",
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              // borderBottomLeftRadius: "0px",
              // borderBottomRightRadius: "0px",
              backgroundSize: "contain",
            }}
          />
          <Tooltip title="Edit">
            <IconButton
              onClick={() => {
                // console.log(edit)4
                handleClickOnPreviewEdit();
              }}
              sx={{
                display: isVideo && "none",
                background: "#f2f2f2",
                height: "30px",
                width: "30px",
                "&:hover": {
                  background: "#f2f2f2",
                },
                position: "absolute",
                right: 4,
                top: 4,
              }}
            >
              <MdOutlineModeEditOutline
                style={{ color: "#000" }}
                fontSize={"medium"}
              />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box width={mobileDevices ? "100%" : "25%"}>
        <AautiText title={"Preview"} weight={"bold"} />
        {isGig ? (
          <PreviewGigCard {...{ isVideo }} url={url} />
        ) : (
          <PreviewCard visibleImage={url} />
        )}
      </Box>
    </Box>
  );
};

export default PreviewImage;
