import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { levelStrings } from "./Constants";
import { Box, IconButton } from "@mui/material";
import "./Style.scss";

const PreviewCard = ({ visibleImage = "" }) => {
  return (
    <div
      // className="box"
      style={{
        maxWidth: "100%",
        //   float: "left",
        display: "flex",
        flexDirection: "column",
        gap: 5,
        border: "1px solid lightgrey",
        padding: 4,
        borderRadius: 4,
        marginTop: 3,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "220px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        {visibleImage ? (
          <img
            src={visibleImage}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "4px",
            }}
          />
        ) : (
          <div
            className="img-preview"
            style={{
              width: "100%",
              float: "left",
              height: "100%",
              borderRadius: 5,
              // backgroundSize: "contain",
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          width: "100%",
        }}
      >
        <div>
          <AautiText title={"Lorem Ipsum"} weight={"bold"} />
          <AautiText
            size={"small"}
            title={
              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
            }
            textStyle={{
              width: "100%",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          />

          <AautiText
            textStyle={{ marginTop: 5, marginBottom: 5 }}
            title={"Anonymus | Exp not listed"}
            size={"small"}
          />

          <AautiText
            textStyle={{ marginTop: 5, marginBottom: 5 }}
            size={"small"}
            title={levelStrings?.join(", ")}
          />

          {true && <div style={{ height: 20 }} />}
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
