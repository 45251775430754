import moment from "moment";

export const passwordCount = 14;
export const firstNameCount = 30;
export const lastNameCount = 30;
export const displayNameCount = 21;
export const emailCount = 50;
export const userNameCount = 21;

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
export const emailValidateRegex =
  /^[a-zA-Z0-9][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
export const firstNameRegex = /^\s*([a-zA-Z'-]+(?:\s+[a-zA-Z'-]+)*)?\s*$/;
export const displayNameRegex = /^[A-Za-z0-9 ]*$/;
export const show12hr = true;
export const noOfMonths = 6;
export const viewAllCount = 6;
export const maxDate = moment(new Date()).add(noOfMonths, "months");
export const maxRangeInCalendar = new Date(
  new Date().setMonth(new Date().getMonth() + 3)
);
