import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CommonFunctions } from "../../../CommonFunctions";

export const useContainer = (props) => {
  const {
    open = false,
    setOpen = () => null,
    showAllCourses = false,
    setShowAllCourses = () => null,
  } = props;

  const { countriesArray, countryObject, cartsDataBeforeLogin } = useSelector(
    (state) => state.app
  );

  const { fetchUserDetails } = CommonFunctions();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [openCountrypop, setOpenCOuntryPop] = useState(false);
  const [filteredCountries, setFilteredCountries] = useState([]);

  useEffect(() => {
    setFilteredCountries(countriesArray);
  }, [countriesArray]);
  const handleLogoClick = () => navigate("/");
  const handleAllCoursesClick = (value) => {
    setShowAllCourses(value);
  };
  const handleTeachWithAautiClick = () => navigate("/teach-with-Aauti");
  const handleInstitutionsClick = () => navigate("/institutes");
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value.trimStart().replace(/\s+/g, " "));
  };
  const handleSearchButtonClick = () => {
    navigate("/search-result", { state: { searchKey: searchInput } });
    setSearchInput("");
  };

  const handleSignInClick = () => {
    navigate("/login");
  };
  const handleSignUpClick = () => {
    navigate("/register/user-type");
  };
  const closeCountryPop = () => {
    setOpenCOuntryPop(!openCountrypop);
    setFilteredCountries(countriesArray);
  };
  const handleMenuButtonClick = () => setOpen(!open);

  const handleOnChange = (value) => {
    const countries = countriesArray.filter((each) => {
      return each?.name?.toLowerCase()?.includes(value?.toLowerCase());
    });
    setFilteredCountries(countries);
  };
  return {
    handleLogoClick,
    handleAllCoursesClick,
    handleTeachWithAautiClick,
    handleInstitutionsClick,
    handleSearchInputChange,
    handleSearchButtonClick,
    handleSignInClick,
    handleSignUpClick,
    handleMenuButtonClick,
    openCountrypop,
    setOpenCOuntryPop,
    closeCountryPop,
    filteredCountries,
    setFilteredCountries,
    handleOnChange,
  };
};
