import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import React from "react";
import "../home/package/styles.scss";
import { PackageStrings } from "../home/Constants";
import Vector from "../../assets/images/Vector.svg";
import { isEmpty } from "lodash";
import { IoClose } from "react-icons/io5";

const AautiSearch = ({
  inputRef,
  searchValue,
  handleSearchChange,
  onClose,
  addStyle,
}) => {
  return (
    <Paper className="addPackage-paper1" style={{ ...addStyle }}>
      <IconButton
        type="button"
        className="addPackage-inputButton1"
        aria-label="search"
      >
        {isEmpty(searchValue) ? (
          <img src={Vector} height={20} width={23} />
        ) : (
          <IoClose onClick={onClose} />
        )}
      </IconButton>
      <InputBase
        className="addPackage-inputBase1"
        placeholder={PackageStrings?.searchCoupons}
        value={searchValue}
        onChange={handleSearchChange}
        sx={{ ml: 1, flex: 1 }}
        inputProps={{ "aria-label": "search google maps" }}
        size="small"
        inputRef={inputRef}
      />
    </Paper>
  );
};

export default AautiSearch;
