import {throttle} from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { useChannelActionContext, useTranslationContext } from '../../../context';
import { showToast } from '../../../../globalComponents/Toast';

const getDurationForInfinityDurationAudioFile = (
  src,
  callback
) => {
  const audioElement = new Audio();
  audioElement.src = src;

  audioElement.addEventListener('loadedmetadata', () => {
    const { duration } = audioElement;
    if (duration === Infinity) {
      audioElement.currentTime = 1e101;
      return;
    }

    return callback(duration);
  });

  audioElement.addEventListener('durationchange', () => {
    if (audioElement.duration === Infinity) {
      return;
    }
    callback(audioElement.duration);
    audioElement.remove();
  });
};

const isSeekable = (audioElement) =>
  !(audioElement.duration === Infinity || isNaN(audioElement.duration));

export const elementIsPlaying = (audioElement) =>
  audioElement && !(audioElement.paused || audioElement.ended);

const DEFAULT_PLAYBACK_RATES = [1.0, 1.5, 2.0];


export const useAudioController = ({
  durationSeconds,
  mimeType,
  playbackRates = DEFAULT_PLAYBACK_RATES,
  src
}) => {
//   const { addNotification } = useChannelActionContext('useAudioController');
//   const { t } = useTranslationContext('useAudioController');
  const [isPlaying, setIsPlaying] = useState(false);
  const [playbackError, setPlaybackError] = useState();
  const [canPlayRecord, setCanPlayRecord] = useState(true);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [playbackRateIndex, setPlaybackRateIndex] = useState(0);
  const [duration,setDuration] = useState(0)
  const playTimeout = useRef();
  const audioRef = useRef(null);

  const registerError = useCallback(
    (e) => {
      setPlaybackError(e);
      showToast('error',e.message);
    },
  );

  const togglePlay = useCallback(async () => {
    if (!audioRef.current) return;
    clearTimeout(playTimeout.current);
    playTimeout.current = undefined;
    if (mimeType && !audioRef.current.canPlayType(mimeType)) {
      registerError(new Error('Recording format is not supported and cannot be reproduced'));
      setCanPlayRecord(false);
      return;
    }
    if (elementIsPlaying(audioRef.current)) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      playTimeout.current = setTimeout(() => {
        if (!audioRef.current) return;
        try {
          audioRef.current.pause();
          setIsPlaying(false);
        } catch (e) {
          registerError(new Error('Failed to play the recording'));
        }
      }, 2000);

      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (e) {
        registerError(e);
        setIsPlaying(false);
      } finally {
        clearTimeout(playTimeout.current);
        playTimeout.current = undefined;
      }
    }
  }, [mimeType, registerError]);

  const increasePlaybackRate = () => {
    setPlaybackRateIndex((prev) => {
      if (!audioRef.current) return prev;
      const nextIndex = prev === playbackRates.length - 1 ? 0 : prev + 1;
      audioRef.current.playbackRate = playbackRates[nextIndex];
      return nextIndex;
    });
  };

  // const seek = useMemo(
  //   () =>
  //     throttle(({ clientX, currentTarget }) => {
  //       if (!(currentTarget && audioRef.current)) return;
  //       if (!isSeekable(audioRef.current)) {
  //         registerError(new Error('Cannot seek in the recording'));
  //         return;
  //       }

  //       const { width, x } = currentTarget.getBoundingClientRect();

  //       const ratio = (clientX - x) / width;
  //       if (ratio > 1 || ratio < 0) return;
  //       const currentTime = ratio * audioRef.current.duration;
  //       setSecondsElapsed(currentTime);
  //       audioRef.current.currentTime = currentTime;
  //     }, 16),
  //   [registerError],
  // );

  useEffect(() => {
    if (!audioRef.current) return;
    const audioElement = audioRef.current;

    const handleEnded = () => {
      setSecondsElapsed(audioElement?.duration ?? durationSeconds ?? 0);
      setIsPlaying(false);
    };
    audioElement.addEventListener('ended', handleEnded);

    const handleError = () => {
    showToast('error','Error reproducing the recording');
      setIsPlaying(false);
    };
    audioElement.addEventListener('error', handleError);

    const handleTimeupdate = () => {
      setSecondsElapsed(audioElement?.currentTime);
    };
    audioElement.addEventListener('timeupdate', handleTimeupdate);

    const onLoadMetaData = (e)=>{
        const { duration } = e.target;
        if (duration !== Infinity) {
          return setDuration(duration);
        }
        getDurationForInfinityDurationAudioFile(src, setDuration);
    }

    return () => {
      audioElement.pause();
      audioElement.removeEventListener('ended', handleEnded);
      audioElement.removeEventListener('error', handleError);
      audioElement.removeEventListener('timeupdate', handleTimeupdate);
      audioElement.removeEventListener('loadedmetadata', onLoadMetaData);
    };
  }, [durationSeconds,src]);

  return {
    audioRef,
    canPlayRecord,
    increasePlaybackRate,
    isPlaying,
    playbackError,
    playbackRate: playbackRates[playbackRateIndex],
    progress:
      audioRef.current && secondsElapsed ? (secondsElapsed / audioRef.current.duration) * 100 : 0,
    secondsElapsed,
    // seek,
    togglePlay,
    duration: audioRef?.current?.duration,
  };
};