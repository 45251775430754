import React, { useEffect, useRef } from "react";
import moment from "moment";
import AautiText from "../../globalComponents/AautiText";
import {
  Box,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { MdCancel } from "react-icons/md";
import { useState } from "react";
import { AuthService } from "./Service";
import { useDispatch, useSelector } from "react-redux";
import LogoIcon from "../../../assets/images/aautiIcon.png";
import { showToast } from "../../globalComponents/Toast";
import { useNavigate } from "react-router-dom";
import "./Styles.scss";
import {
  ChangeUserRole,
  setBuyNowdata,
  setScreenName,
} from "../../../redux/reducer/appReducer";
import { IoTrashOutline } from "react-icons/io5";
import { setCartCount } from "../../../redux/reducer/appReducer";
import { PackageStrings } from "../Constants";
import {
  CapitalizeFirstLetter,
  setBuyNowDataAfterRefresh,
} from "../../CommonFunctions";
import { escape, isEmpty } from "lodash";
import { capitalizeWordFirstLetter, checkNull } from "../../../utils/Helpers";
import { userRoles } from "../../../storage/constants";
import { HeaderStrings } from "../../header/constants";
import EmptyData from "../../globalComponents/EmptyData";
import { BsChevronUp } from "react-icons/bs";
import { GoDotFill } from "react-icons/go";
import { globalColors } from "../../../themes/GlobalColors";
import JoinButton from "../../globalComponents/JoinButton";
import { Container } from "./Container";
import NotificationButtons from "./NotificationButtons";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
// import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// import React, { useRef } from "react";
import PopupDoc from "./PopUpoptions";

const Notifications = ({
  handleCloseNot,
  setAnchorElNot,
  unreadCount,
  responseType,
  height,
  showMorebtns,
  childId,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { loggedinUserId, isUserLoggedIn, UserRole, userDetails } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    inCart,
    readRef,
    actionRef,
    actionRefs,
    readRefs,
    showCursor,
    notificationMessage,
    formatTo12HourTime,
    unReadMessages,
    setUnReadMessages,
    AllNot,
    setAlNot,
    readNotifications,
    setReadNotifications,
    open,
    setOpen,
    loadingNotifi,
    setLoadingNotifi,
    selected,
    setSelected,
    accepteState,
    setAccepteState,
    clearNotification,
    setClearNotification,
    readOffset,
    setReadOffset,
    actionOffSet,
    setActionOffset,
    readLastItem,
    setReadLastItem,
    actionIndex,
    setActionIndex,
    actionLastItem,
    setActionLastItem,
    readIndex,
    setReadIndex,
    showReadScrollButton,
    setShowReadScrollButton,
    showActionScrollButton,
    setShowActionScrollButton,
    handleSelectEachNotification,
    handleSelectAll,
    isAllSelected,
    isNotificationSelected,
    showSelection,
    setShowSelection,
    setSelectedNotifications,
    selectedNotifications,
    deleteSelectedNotifications,
    getNotificationData,
    cancelSelection,
    childNotifications,
    setChildNotifications,
    childRef,
    childRefs,
    childIndex,
    setChildIndex,
    childOffset,
    setChildOffset,
    childScrollbtn,
    setChildScrollBtn,
    childEndReach,
  } = Container({ responseType, childId });

  const handleClose = () => {
    setOpen(false);
    setClearNotification("");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openoptions = Boolean(anchorEl);

  // const onSuccessNot = (response) => {
  //   if (response?.status === "Success") {
  //     setUnReadMessages(response?.result?.unreadCount || 0);
  //     setLoadingNotifi(false);
  //     if (response?.result?.notifications?.length > 0) {
  //       if (responseType === "Read") {
  //         setReadNotifications([
  //           ...(readNotifications ?? []),
  //           ...response?.result?.notifications,
  //         ]);
  //         if (response?.result?.notifications?.length < 10) {
  //           setReadLastItem(true);
  //           return;
  //         }
  //       } else {
  //         setAlNot([...(AllNot ?? []), ...response?.result?.notifications]);
  //         if (response?.result?.notifications?.length < 10) {
  //           setActionLastItem(true);
  //           return;
  //         }
  //       }
  //     } else {
  //       return null;
  //     }
  //   } else {
  //     setLoadingNotifi(false);
  //   }
  // };

  // let countValue = 10;

  // const params = {
  //   userId: loggedinUserId,
  //   count: countValue,
  // };
  // switch (responseType) {
  //   case "Read":
  //     params.responseType = "read";
  //     params.offSet = readOffset;
  //     break;
  //   case "Action":
  //     params.responseType = "action";
  //     params.offSet = actionOffSet;
  //     break;
  //   case "":
  //     params.offSet = actionOffSet;

  //   default:
  //     break;
  // }

  // let paramload = responseType == "Read" ? readOffset : actionOffSet;
  // const getNotificationData = (paramload) => {
  //   if (paramload == 0) {
  //     setLoadingNotifi(true);
  //   }
  //   AuthService.getAllNotificationsByUserId(params, onSuccessNot, onFailure);
  // };

  useEffect(() => {
    if (!checkNull(loggedinUserId)) {
      if (responseType == "Read") {
        !readLastItem && getNotificationData();
        if (readRefs.current[readIndex]) {
          readRefs.current[readIndex].scrollIntoView({
            behavior: "auto",
            block: "end",
          });
        }
      } else if (responseType === "Child") {
        !childEndReach && getNotificationData();
        if (childRefs?.current[childIndex]) {
          childRefs?.current[childIndex]?.scrollIntoView({
            behavior: "auto",
            block: "end",
          });
        }
      } else {
        !actionLastItem && getNotificationData();
        if (actionRefs.current[actionIndex]) {
          actionRefs.current[actionIndex].scrollIntoView({
            behavior: "auto",
            block: "end",
          });
        }
      }
    }
  }, [readOffset, actionOffSet, responseType, childOffset, childId]);

  useEffect(() => {
    setSelectedNotifications([]);
    setShowSelection(false);
  }, [responseType]);

  useEffect(() => {
    setChildNotifications([]);
    setChildOffset(0);
    // getNotificationData();
  }, [childId]);

  const onClickCrosscon = (id) => {
    AuthService.deleteNotificationById(id, onSuccessDeleted, onFailure);
  };

  const onFailure = (error) => {
    setLoadingNotifi(false);
    setOpen(false);
    showToast(PackageStrings?.error, error?.message);
  };
  const onSuccessDeleted = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(PackageStrings?.successSmall, response.message);
      setOpen(false);
      dispatch(setCartCount(99));
      let filtered = (
        responseType === "Read"
          ? readNotifications
          : responseType === "Child"
          ? childNotifications
          : AllNot
      )?.filter((each, index) => {
        return selected?._id !== each?._id;
      });
      // setAlNot(filtered);
      if (responseType === "Read") {
        setReadNotifications(filtered);
      } else if (responseType === "Child") {
        setChildNotifications(filtered);
      } else {
        setAlNot(filtered);
      }
    }
  };

  const onSuccessDeletAllNot = (response) => {
    if (response.status === PackageStrings?.success) {
      setOpen(false);
      showToast(PackageStrings?.successSmall, response.message);
      dispatch(setCartCount(99));
      // AuthService.getAllNotificationsByUserId(
      //   childId ?? loggedinUserId,
      //   (res) => {
      //     if (res?.status == "Success") {
      if (responseType == "Read") {
        setReadNotifications([]);
      } else if (responseType == "Child") {
        setChildNotifications([]);
      } else {
        setAlNot([]);
      }
      // }
      // },
      //   onFailure
      // );
    }
    setLoadingNotifi(false);
  };
  const handleMarkAsAllRead = () => {
    setLoadingNotifi(true);
    AuthService.clearAllNotificationsByUser(
      loggedinUserId,
      onSuccessClearAll,
      onFailure
    );
  };

  const handleDeleteAllSelected = () => {
    if (selectedNotifications.length > 0) {
      setClearNotification("Selected");
      setOpen(true);
    }
  };

  const onSuccessClearAll = (response) => {
    if (response.status === PackageStrings?.success) {
      showToast(PackageStrings?.successSmall, response.message);
      let mappedNotifi = (
        responseType === "Read" ? readNotifications : AllNot
      )?.map((each, index) => {
        return { ...each, status: "1" };
      });

      setUnReadMessages(0);
      if (responseType === "Read") {
        setReadNotifications(mappedNotifi);
      } else {
        setAlNot(mappedNotifi);
      }

      // setAlNot(mappedNotifi);
    } else {
      showToast(PackageStrings?.error, response.message);
    }
    setLoadingNotifi(false);
  };

  const courseBuyApi = (data) => {
    const onSuccessApi = (response) => {
      let buyNowData = [];
      if (response?.status?.toLowerCase() === "success") {
        const courseData = response?.result[0];
        const { course = {} } = courseData;
        const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
          course?.batchDeatils;
        const selectedBatch = [
          ...individualDaily,
          ...individualWeekly,
          ...groupDaily,
          ...groupWeekly,
        ].find((each) => each._id === data?.batchId);
        const courseObject = {
          type: "course",
          endUserId: loggedinUserId,
          serviceProviderId: courseData?.serviceProviderId,
          courseId: course?._id || course?.id,
          serviceProviderName: courseData?.serviceProviderName,
          selectedBatch: selectedBatch,
          selectedBatchId: selectedBatch?._id,
          transactionCurrencyCode:
            courseData?.transactionCurrencyCode ?? courseData?.currencyCode,
          course: {
            _id: course?._id || course?.id,
            courseName: course?.courseName,
            category: course?.category,
            subCategory: course?.subCategory,
            courseDescription: course?.courseDescription,
            courseImage: course?.courseImage,
            courseLevel: course?.courseLevel,
            averageRating: course?.averageRating,
            ratingCount: course?.ratingCount,
            batchDeatils: {
              individualDaily,
              individualWeekly,
              groupDaily,
              groupWeekly,
            },
            blockbusterDeal: course?.blockbusterDeal,
            adminDiscount: course?.adminDiscount,
            blockbusterEndDate: course?.blockbusterEndDate,
            endUserList: course?.endUserList,
          },
          subscribersCount: courseData?.subscribersCount ?? 0,
          averageRating: courseData?.averageRating,
          ratingCount: courseData?.ratingCount,
          yearsOfExperience: courseData?.yearsOfExperience,
          childDetails: [
            {
              endUserId: loggedinUserId,
              batchId: selectedBatch?._id,
            },
          ],
        };
        buyNowData.push(courseObject);
        dispatch(setBuyNowdata(buyNowData));
        setBuyNowDataAfterRefresh(buyNowData, dispatch, "gig");
        navigate(`/buyNow`, {
          state: "buyNow",
        });
        if (showMorebtns) {
          handleCloseNot();
        }
      } else {
        console.log("buyNow failed");
      }
    };
    if (!inCart) {
      addToCart(data);
    }
    AuthService.getBuyNowData(data, onSuccessApi, onFailure);
  };
  const addToCart = (completeDetails) => {
    let ApiObj = {
      type: "course",
      endUserId: loggedinUserId,
      serviceProviderId: completeDetails?.serviceProviderId,
      courseId: completeDetails?.courseId,
      batchId: completeDetails?.batchId ?? "",
      parentId: userDetails?.parentId ? userDetails?.parentId : loggedinUserId,
      childDetails: [],
    };
    AuthService.saveCart(
      ApiObj,
      (response) => {
        if (response.status === "Success") {
          showToast("info", response?.message);
          dispatch(setCartCount(99));
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error save cart");
      }
    );
  };
  const rejectInvite = (data) => {
    delete data.inviteCourse;
    // delete data.id;
    AuthService.updateRejectData(
      data,
      (res) => {
        if (res?.status == "Success") {
          const newList = (
            responseType === "Read" ? readNotifications : AllNot
          )?.map((each) => {
            if (each?._id == data.id) {
              return {
                ...each,
                status: "1",
                userStatus: "Rejected",
              };
            }
            return each;
          });
          if (responseType === "Read") {
            setReadNotifications(newList);
          } else {
            setAlNot(newList);
          }
        }
      },
      onFailure
    );
  };

  const [batchRSdata, setBatchData] = useState("");

  const formatDate = (isoDate) => {
    const dateObject = new Date(isoDate);
    const formattedDate = dateObject.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    return formattedDate;
  };

  const batchData = [
    {
      leftCont: "Batch Name",
      rightCont: batchRSdata.batchName,
    },
    {
      leftCont: "Date",
      rightCont:
        formatDate(batchRSdata?.startDate) +
        "-" +
        formatDate(batchRSdata?.endDate),
    },
    {
      leftCont: "Existing Timings",
      rightCont:
        (batchRSdata.type === "groupDaily" ||
        batchRSdata.type === "individualDaily"
          ? "Daily - "
          : "Weekly -") +
        "(" +
        formatTo12HourTime(batchRSdata?.startTime) +
        "-" +
        formatTo12HourTime(batchRSdata?.endTime) +
        ")",
    },
    {
      leftCont: "Proposed Timings",
      rightCont:
        (batchRSdata.type === "groupDaily" ||
        batchRSdata.type === "individualDaily"
          ? "Daily - "
          : "Weekly -") +
        "(" +
        formatTo12HourTime(batchRSdata?.reScheduleStartTime) +
        "-" +
        formatTo12HourTime(batchRSdata?.reScheduleStartTime) +
        ")",
    },
  ];
  const handleClearAll = () => {
    AuthService.deleteAllNotificationsByUserId(
      childId ?? loggedinUserId,
      onSuccessDeletAllNot,
      onFailure
    );
  };
  function formatKeys(text) {
    const spacedText = text?.replace(/([A-Z])/g, " $1").trim();
    return spacedText?.charAt(0)?.toUpperCase() + spacedText?.slice(1) || "";
  }
  const onFailureFunc = () => {
    console.log("error Message");
  };

  const memberAccept = (data, actionDetails) => {
    const reqData = {
      _id: data?.id,
      userStatus: actionDetails,
    };
    const memberTransferParams = {
      id: data?.id,
      userStatus: actionDetails,
    };
    delete data.inviteCourse;
    delete data.id;
    switch (data?.type) {
      case "member Transfer":
        AuthService.childTransferUpdateStatus(
          memberTransferParams,
          (response) => {
            if (response?.status == "Success") {
              const newList = (
                responseType === "Read" ? readNotifications : AllNot
              )?.map((each) => {
                if (each?._id == memberTransferParams.id) {
                  return {
                    ...each,
                    status: "1",
                    userStatus: actionDetails,
                  };
                }
                return each;
              });
              if (responseType === "Read") {
                setReadNotifications(newList);
              } else {
                setAlNot(newList);
              }
            } else {
              showToast("info", response?.message);
            }
          }
        );
        break;
      case "invite Member":
        AuthService.updateUserInviteStatus(
          memberTransferParams,
          (response) => {
            if (response?.status?.toLowerCase() === "success") {
              const newList = (
                responseType === "Read" ? readNotifications : AllNot
              )?.map((each) => {
                if (each?._id === memberTransferParams.id) {
                  return {
                    ...each,
                    status: "1",
                    userStatus: memberTransferParams?.userStatus,
                  };
                }
                return each;
              });
              if (responseType === "Read") {
                setReadNotifications(newList);
              } else {
                setAlNot(newList);
              }
            }
          },
          onFailure
        );
        break;
      case "invite moderator":
        AuthService.moderatorUpdateStatus(
          reqData,
          (response) => {
            if (response?.status == "Success") {
              const newList = (
                responseType === "Read" ? readNotifications : AllNot
              )?.map((each) => {
                if (each?._id == reqData?._id) {
                  return {
                    ...each,
                    status: "1",
                    userStatus: actionDetails,
                  };
                } else {
                  return each;
                }
              });
              if (responseType === "Read") {
                setReadNotifications(newList);
              } else {
                setAlNot(newList);
              }
            }
          },
          onFailure
        );
        break;
      default:
        break;
    }
  };

  const CoursePurchaseNavigation = (item) => {
    const params = {
      courseId: item?.courseId,
    };
    if (UserRole === userRoles.endUser) {
      params.userId = loggedinUserId;
    }
    if (item?.type === "Course Adhoc") {
      params.schedulerId = item?.schedulerId;
    } else {
      params.batchId = item?.batchId;
    }
    AuthService.getRecentSessionData(
      params,
      (res) => {
        if (checkNull(res?.result?._id || res?.result?.id)) {
          showToast("Info", "No Sessions Available");
        } else {
          const result = res?.result;
          if (result?.serviceProviderId === loggedinUserId) {
            localStorage.setItem(
              HeaderStrings?.role,
              HeaderStrings?.serviceProvide2
            );
            dispatch(ChangeUserRole(HeaderStrings?.serviceProvide2));
          } else if (result?.serviceProviderId !== loggedinUserId) {
            localStorage.setItem(HeaderStrings?.role, HeaderStrings?.endUser2);
            dispatch(ChangeUserRole(HeaderStrings?.endUser2));
          }
          navigate("/join_class", {
            state: res?.result,
          });
          if (showMorebtns) {
            handleCloseNot();
          }
        }
      },
      onFailure
    );
  };

  const handleClick = (data) => {
    const idx = data?.courseId || data?.gigId;
    const type = data?.notificationType ?? "";
    const clearSuccess = async (response) => {
      if (response?.data?.status == "Success") {
        const updatedNotifications = (
          responseType === "Read" ? readNotifications : AllNot
        )?.map((each) => {
          if (each?._id === data?._id) {
            return {
              ...each,
              status: "1",
            };
          }
          return each;
        });
        if (responseType === "Read") {
          unReadMessages != 0 && setReadNotifications(updatedNotifications);
        } else {
          unReadMessages != 0 && setAlNot(updatedNotifications);
        }
        unReadMessages != 0 && setUnReadMessages(unreadCount - 1);
        // setAllNotification(updatedNotifications);
        // setUnReadCount((prev) => (prev - 1 > 0 ? prev - 1 : 0));
        switch (type) {
          case "Batch Transfer":
          case "batchTransfer":
            CoursePurchaseNavigation(data);
            break;
          case "Course starts in 15 Mins":
          case "Adhoc starts in 15 Mins":
          case "Merge":
          case "session reschedule":
          case "Adhoc reschedule":
          case "session reschedule":
          case "Adhoc purchase":
            AuthService.getSessionDetailsById(
              data?.sessionId,
              (res) => {
                if (checkNull(res?.result?.[0])) {
                  showToast("info", "No Sessions Available");
                } else {
                  const result = res?.result?.[0] ?? {};

                  if (result?.serviceProviderId === loggedinUserId) {
                    localStorage.setItem(
                      HeaderStrings?.role,
                      HeaderStrings?.serviceProvide2
                    );
                    dispatch(ChangeUserRole(HeaderStrings?.serviceProvide2));
                  } else if (result?.serviceProviderId !== loggedinUserId) {
                    localStorage.setItem(
                      HeaderStrings?.role,
                      HeaderStrings?.endUser2
                    );
                    dispatch(ChangeUserRole(HeaderStrings?.endUser2));
                  }
                  navigate("/join_class", {
                    state: res?.result[0] ?? {},
                  });
                  if (showMorebtns) {
                    if (showMorebtns) {
                      handleCloseNot();
                    }
                  }
                }
              },
              (err) => console.log("err", err)
            );
            break;
          case "course":
          case "Course Approval Status":
            navigate(`/course-details/${data?.courseId}`);
            if (showMorebtns) {
              if (showMorebtns) {
                handleCloseNot();
              }
            }
            break;
          case "gig":
          case "gig purchase":
          case "Gig Approval Status":
            navigate(`/gig-preview/${data?.gigId}`);
            if (showMorebtns) {
              if (showMorebtns) {
                handleCloseNot();
              }
            }
            break;

          case "course purchase":
          case "Course Adhoc":
          case "batchTransfer":
            CoursePurchaseNavigation(data);
            return;
          case "courseTransfer":
            return console.log("courseTransfer");
          case "Batch Reschedule":
            showMorebtns && handleCloseNot();
            return navigate(`/reschedule-details`, { state: data });
          case "gig purchase":
            navigate(`/gig-preview/${data?.gigId}`);
            if (showMorebtns) {
              handleCloseNot();
            }
          case "ServiceProvider Approval Status":
          case "serviceProvider Approval":
            navigate(`/calendar`);
            if (showMorebtns) {
              handleCloseNot();
            }

          default:
            break;
        }
      } else {
        showToast("error", "error");
      }
    };

    AuthService.clearNotification(data, clearSuccess, onFailure);
  };

  const onScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    if (responseType == "Read") {
      const { scrollTop, scrollHeight, clientHeight } = readRef.current;
      readRefs.current.forEach((item, index) => {
        if (item) {
          const { top, bottom } = item.getBoundingClientRect();
          if (top < scrollPosition && bottom > 0) {
            setReadIndex(index);
          }
        }
      });
      if ((scrollTop + clientHeight).toFixed(0) >= scrollHeight - 1) {
        setReadOffset(readOffset + 1);
      }
      if (scrollTop === 0) setShowReadScrollButton(false);
      else {
        setShowReadScrollButton(true);
      }
    } else if (responseType == "Child") {
      const { scrollTop, scrollHeight, clientHeight } = childRef?.current;
      childRefs?.current?.forEach((item, index) => {
        if (item) {
          const { top, bottom } = item.getBoundingClientRect();
          if (top < scrollPosition && bottom > 0) {
            setChildIndex(index);
          }
        }
      });

      if ((scrollTop + clientHeight).toFixed(0) >= scrollHeight - 1) {
        setChildOffset(childOffset + 1);
      }
      if (scrollTop === 0) setChildScrollBtn(false);
      else {
        setChildScrollBtn(true);
      }
    } else {
      const { scrollTop, scrollHeight, clientHeight } = actionRef.current;
      actionRefs.current.forEach((item, index) => {
        if (item) {
          const { top, bottom } = item.getBoundingClientRect();
          if (top < scrollPosition && bottom > 0) {
            setActionIndex(index);
          }
        }
      });

      if ((scrollTop + clientHeight).toFixed(0) >= scrollHeight - 1) {
        setActionOffset(actionOffSet + 1);
      }
      if (scrollTop === 0) setShowActionScrollButton(false);
      else {
        setShowActionScrollButton(true);
      }
    }
  };

  const scrollToTop = () => {
    if (responseType === "Read") {
      readRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });
    } else if (responseType == "Child") {
      childRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });
    } else {
      actionRef.current.scrollTo({ top: -Infinity, behavior: "smooth" });
    }
  };

  function renderScrollToTopWrapper() {
    return (
      <button className={"scroll_btn_up"} onClick={scrollToTop}>
        <BsChevronUp fontSize={24} />
      </button>
    );
  }

  // function PopupDoc() {
  //   const menuLeft = useRef(null);
  //   const menuRight = useRef(null);
  //   const toast = useRef(null);
  //   const items = [
  //     {
  //       label: "Options",
  //       items: [
  //         {
  //           label: "Refresh",
  //           icon: "pi pi-refresh",
  //         },
  //         {
  //           label: "Export",
  //           icon: "pi pi-upload",
  //         },
  //       ],
  //     },
  //   ];

  //   return (
  //     <div className="card flex justify-content-center">
  //       <Toast ref={toast}></Toast>
  //       <Menu model={items} popup ref={menuLeft} id="popup_menu_left" />
  //       <Button
  //         label="Show Left"
  //         icon="pi pi-align-left"
  //         className="mr-2"
  //         onClick={(event) => menuLeft.current.toggle(event)}
  //         aria-controls="popup_menu_left"
  //         aria-haspopup
  //       />
  //       <Menu
  //         model={items}
  //         popup
  //         ref={menuRight}
  //         id="popup_menu_right"
  //         popupAlignment="right"
  //       />
  //       <Button
  //         label="Show Right"
  //         icon="pi pi-align-right"
  //         className="mr-2"
  //         onClick={(event) => menuRight.current.toggle(event)}
  //         aria-controls="popup_menu_right"
  //         aria-haspopup
  //       />
  //     </div>
  //   );
  // }

  return (
    <div className="notification-div2">
      {accepteState ? (
        <div className="NFbatchRSdiv">
          <AautiText
            title={"Batch Details"}
            className={"NFcolor"}
            weight={"bold"}
          />
          <div className="NFdiv2">
            <img
              alt="images"
              src={batchRSdata?.courseImage}
              className="NFimagestyle"
            />
            <div className="NFstudents">
              <AautiText
                title={batchRSdata?.reScheduleBatchName}
                className={"NFcolor"}
                weight={"bold"}
              />
              <AautiText
                title={
                  batchRSdata?.acceptedUsers?.length + " Students" ||
                  0 + " Students"
                }
                className={"NFcolor"}
                weight={"medium"}
              />
            </div>
          </div>
          {batchData?.map((each, index) => (
            <div key={index} className="NFmapData">
              <AautiText
                title={each?.leftCont}
                weight={"medium"}
                textStyle={{ marginBottom: 3, color: "#1e1e1e" }}
              />
              <AautiText
                title={each?.rightCont}
                weight={"bold"}
                className={"NFcolor"}
              />
            </div>
          ))}
          <div className="NFbuttonStyle">
            <button
              className="buttonStyleNotif"
              style={{
                border: `1px solid ${globalColors?.AAUTI_THEME_COLOR}`,
                marginRight: "10px",
                backgroundColor: "#ffffff",
              }}
              onClick={() => {
                setAccepteState(false);
              }}
            >
              <AautiText
                title={"Reject"}
                textStyle={{ color: globalColors.AAUTI_THEME_COLOR }}
                size={"semi"}
                className={"notification-text1"}
                weight={"medium"}
              />
            </button>
            <button
              className="buttonStyleNotif"
              onClick={() => {
                navigate("reschedule");
                if (showMorebtns) {
                  handleCloseNot();
                }
              }}
            >
              <AautiText
                title={"Accept"}
                size={"semi"}
                className={"notification-text1"}
                weight={"medium"}
              />
            </button>
          </div>
        </div>
      ) : (
        <>
          {!responseType && (
            <div className="notification-div3">
              <AautiText
                weight={"bold"}
                title={PackageStrings?.notifications}
                className={"notification-text2"}
              />
              <button
                className="notification-button1"
                onClick={() => {
                  if (showMorebtns) {
                    handleCloseNot();
                  }
                }}
              >
                <MdCancel color="grey" size={20} />
              </button>
            </div>
          )}
          <div>
            <div style={{ height: height ?? "75vh" }}>
              <Box sx={{ width: "100%" }}>
                {isUserLoggedIn &&
                  (responseType === "Child"
                    ? childNotifications?.length > 0
                    : responseType == "Read"
                    ? readNotifications?.length > 0
                    : AllNot?.length > 0) && (
                    <div
                      className="notification-div4"
                      style={{
                        justifyContent: showSelection && "space-between",
                      }}
                    >
                      {showSelection && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <AautiText
                            title={`Delete Selected(${selectedNotifications?.length})`}
                            textStyle={{
                              color:
                                selectedNotifications?.length > 0 &&
                                globalColors.AAUTI_THEME_COLOR,
                              cursor: "pointer",
                              marginRight: 15,
                            }}
                            onClick={handleDeleteAllSelected}
                          />
                          <AautiText
                            title={"Cancel"}
                            textStyle={{
                              cursor: "pointer",
                            }}
                            onClick={() => cancelSelection()}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          display: !responseType ? "none" : "flex",
                          alignItems: "center",
                        }}
                      >
                        {responseType !== "Child" && (
                          <AautiText
                            textStyle={{
                              display: unReadMessages === 0 && "none",
                            }}
                            onClick={() => handleMarkAsAllRead()}
                            title={PackageStrings?.markAsRead}
                            size={"semi"}
                            weight={"bold"}
                            className={"notification-text4"}
                          />
                        )}
                        <PopupDoc
                          {...{
                            setOpen,
                            setShowSelection,
                            handleDeleteAllSelected,
                            showSelection,
                          }}
                        />
                      </div>
                    </div>
                  )}

                {(responseType === "Read"
                  ? readNotifications?.length
                  : responseType == "Child"
                  ? childNotifications?.length
                  : AllNot?.length) > 0 && isUserLoggedIn ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                        height: height
                          ? "48vh"
                          : mobileDevices
                          ? "78vh"
                          : "73vh",
                        overflow: "auto",
                      }}
                      onScroll={() => {
                        if (responseType) {
                          onScroll();
                        }
                      }}
                      ref={
                        responseType === "Read"
                          ? readRef
                          : responseType == "Child"
                          ? childRef
                          : actionRef
                      }
                    >
                      {(responseType === "Read"
                        ? readNotifications
                        : responseType === "Child"
                        ? childNotifications
                        : AllNot
                      )?.map((each, index) => {
                        return (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              if (showSelection) {
                                handleSelectEachNotification(each);
                              } else if (responseType !== "Child") {
                                handleClick(each);
                              }
                            }}
                            className="notification-div6"
                            style={{ position: "relative", width: "100%" }}
                            ref={(el) =>
                              responseType === "Read"
                                ? (readRefs.current[index] = el)
                                : responseType == "Child"
                                ? (childRefs.current[index] = el)
                                : (actionRefs.current[index] = el)
                            }
                          >
                            <div
                              style={{
                                cursor: showCursor(each) ? "pointer" : null,
                              }}
                              id={"course-notification"}
                              className={"notCard"}
                              key={each?._id}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                {showSelection && (
                                  <Checkbox
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      handleSelectEachNotification(each);
                                    }}
                                    sx={{ padding: 0, mr: 1 }}
                                    checked={isNotificationSelected(each)}
                                    // onChange={handleChange}
                                    inputProps={{ "aria-label": "controlled" }}
                                  />
                                )}
                              </div>
                              <img
                                alt={PackageStrings?.altText}
                                src={
                                  each?.courseImage ||
                                  each?.gigImage ||
                                  each?.image ||
                                  each?.profileImage ||
                                  LogoIcon
                                }
                                className="notification-img1"
                              />
                              <div className="notification-div7">
                                <div className="notification-div8">
                                  <AautiText
                                    size={
                                      // each?.notificationType === "invite Member"
                                      //   ? "small"
                                      //   :
                                      "semi"
                                    }
                                    title={
                                      // each?.notificationType === "invite Member"
                                      //   ? capitalizeWordFirstLetter(
                                      //       each?.message
                                      //     )
                                      //   :
                                      capitalizeWordFirstLetter(
                                        each?.notificationType
                                      )
                                    }
                                    weight={"bold"}
                                    className={"notification-text5"}
                                    textStyle={{
                                      width: moment(each?.createdAt)
                                        ?.fromNow()
                                        .includes("few")
                                        ? "90%"
                                        : "90%",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 3,
                                      color: globalColors?.HEADING_COLOR,
                                    }}
                                  />
                                  {each?.status == "0" && !childId && (
                                    <GoDotFill color="#DC3545" />
                                  )}
                                </div>
                                {/* {each?.notificationType !== "invite Member" && ( */}
                                <AautiText
                                  size={"small"}
                                  textAlign={"left"}
                                  title={notificationMessage(each)}
                                  className={"notification-text7"}
                                />
                                {/* )} */}
                                <div
                                  className="notification-div9"
                                  style={{
                                    justifyContent:
                                      // each?.notificationType !== "invite Member"
                                      //   ?
                                      "space-between",
                                    // : "flex-end",
                                  }}
                                >
                                  <AautiText
                                    size={"small"}
                                    title={moment(each?.createdAt).fromNow()}
                                    className={"notification-text6"}
                                  />
                                  <button
                                    className="cancelIcon2"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelected(each);
                                      setOpen(true);
                                      setClearNotification("delete");
                                      setSelectedNotifications([]);
                                      setShowSelection(false);
                                    }}
                                  >
                                    <IoTrashOutline size={18} />
                                  </button>
                                </div>
                                <div className="return-button">
                                  {
                                    <NotificationButtons
                                      {...{
                                        item: each,
                                        rejectInvite,
                                        courseBuyApi,
                                        memberAccept,
                                      }}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {showReadScrollButton &&
                        responseType === "Read" &&
                        renderScrollToTopWrapper()}
                      {showActionScrollButton &&
                        responseType === "Action" &&
                        renderScrollToTopWrapper()}
                    </div>
                    {showMorebtns && (
                      <div className="more-btns-notifications">
                        {!childId && (
                          <AautiText
                            title={PackageStrings?.markAsRead}
                            textAlign={"center"}
                            onClick={() => handleMarkAsAllRead()}
                            textStyle={{
                              color: globalColors?.AAUTI_THEME_COLOR,
                              width: "48%",
                              display: unReadMessages == 0 && "none",
                              cursor: "pointer",
                              display: unReadMessages == 0 && "none",
                            }}
                            weight={"bold"}
                          />
                        )}
                        <AautiText
                          title={"See All"}
                          textStyle={{
                            color: globalColors?.HEADING_COLOR,
                            width: unReadMessages == 0 && "96%",
                          }}
                          onClick={() => {
                            if (showMorebtns) {
                              handleCloseNot();
                            }
                            navigate("/notifications");
                          }}
                          weight={"bold"}
                          className={"see-all-btn-notifications"}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  !loadingNotifi && (
                    <div
                      className="notification-div10"
                      style={{
                        height: showMorebtns ? "40vh" : "73vh",
                        justifyContent: "center",
                      }}
                    >
                      <EmptyData
                        showExplore={false}
                        height={"30vh"}
                        title={"No Notifications"}
                      />
                    </div>
                  )
                )}
                {loadingNotifi && (
                  <div className="notification-div5">
                    <CircularProgress />
                  </div>
                )}
              </Box>
            </div>
          </div>
        </>
      )}
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> {"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {clearNotification === "delete"
              ? "Are you sure you want to delete?"
              : clearNotification === "Selected"
              ? `Are you sure you want to delete selected ${selectedNotifications?.length} notifications?`
              : "Are you sure you want to delete all the notifications?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              if (clearNotification === "delete") {
                onClickCrosscon(selected?._id);
              } else if (clearNotification === "Selected") {
                deleteSelectedNotifications();
                setOpen(false);
              } else {
                handleClearAll();
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Notifications;
