import React from "react";
import { useSelector } from "react-redux";

function Chips({ data = [], onClick = () => {}, containerStyle = {} }) {
  const { mobileDevices } = useSelector((state) => state.responsive);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: mobileDevices ? "100%" : "40%",
        gap: 5,
        ...containerStyle,
      }}
    >
      {data?.map((each, idx) => {
        return (
          <div
            style={{
              padding: mobileDevices ? "8px 10px" : "10px 15px",
              width: "50%",
              border: each?.active
                ? "1px solid #ffffff55"
                : "1px solid #575B5CCC",
              borderRadius: 5,
              backgroundColor: each?.active ? "#3083ef" : "white",
              cursor: "pointer",
            }}
            onClick={() => onClick(each?.active, each.title)}
          >
            <p
              style={{
                color: each?.active ? "white" : "black",
                textAlign: "center",
              }}
            >
              {each?.title}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default Chips;
