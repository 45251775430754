import React, { memo, useRef, useState } from "react";
import Coursecard from "../../appCards/Coursecard";
import CauroselList from "../../globalComponents/caurosel/CauroselList";
import { useSelector } from "react-redux";
import GigCard from "../../appCards/GigCard";
import TopProvidersCard from "../../home/topProviders/TopProvidersCard";
import { isEmpty } from "lodash";
import AautiText from "../../globalComponents/AautiText";
import AdhocCard from "../../appCards/AdhocCard";
import { PackageStrings } from "../../home/Constants";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { viewAllCount } from "../../globalComponents/helpers.variables";

const ListHeader = memo(
  ({ type, navigate, title, list, activeType, activeFilters }) => {
    return (
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        gap={1}
        mb={type === "toprated" ? 0 : 1}
      >
        <AautiText
          title={title}
          size={"extraMedium"}
          weight={"bold"}
          textStyle={{}}
        />

        <button
          onClick={() =>
            navigate(`/search-results/${type}`, {
              state: { activeType, type, activeFilters },
            })
          }
          style={{
            background: "none",
            cursor: "pointer",
            border: "none",
            display:
              type == "toprated" && list?.length >= viewAllCount
                ? "flex"
                : "none",
          }}
        >
          <AautiText
            title={PackageStrings?.viewAll}
            size={"semi"}
            weight={"normal"}
            className={"home-text1"}
            textStyle={{ marginTop: "0px" }}
          />
        </button>
      </Box>
    );
  }
);

export const MatchCourses = ({
  list,
  title,
  type,
  navigate,
  activeType,
  activeFilters = {},
}) => {
  const { lapy, miniLapys, ipodDevices, mobileDevices, desktop } = useSelector(
    (state) => state.responsive
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const matchRef = useRef();
  const slidesToShow = mobileDevices
    ? 1.2
    : ipodDevices
    ? 2.5
    : miniLapys
    ? 3
    : lapy || desktop
    ? 4
    : 5;
  const matchSettings = {
    dots: false,
    infinite: false,
    slidesToShow: slidesToShow,
    speed: 500,
    slidesToScroll: mobileDevices ? 1 : miniLapys || lapy ? 2 : 3,
    arrows: false,
    swipable: true,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  const handleSliderDirec = (direc) => {
    if (direc === PackageStrings?.right) {
      matchRef.current.slickNext();
    } else {
      matchRef.current.slickPrev();
    }
  };
  const empty = isEmpty(list);
  return (
    <div style={{ width: "100%", display: empty && "none", marginBottom: 15 }}>
      <ListHeader
        {...{ type, navigate, title, list, activeType, activeFilters }}
      />
      {list?.length > slidesToShow && type != "more" ? (
        <>
          <CauroselList
            settings={matchSettings}
            showButtons={!mobileDevices}
            sliderRef={matchRef}
            style={{ width: "100%" }}
            handleSliderDirec={(direc) => {
              handleSliderDirec(direc, matchRef);
            }}
            disabledLeftButton={currentIndex === 0}
            disabledRight={currentIndex + slidesToShow >= list?.length}
          >
            {list?.map((each, index) => (
              <div key={index}>
                <Coursecard
                  activeFilters={activeFilters}
                  title={"searchResults"}
                  {...{ each }}
                  cardWidth={mobileDevices ? "98%" : "96.5%"}
                />
              </div>
            ))}
          </CauroselList>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap: type != "more" ? "nowrap" : "wrap",
            marginBottom: 15,
            overflow: "auto",
          }}
        >
          {list?.map((each, index) => (
            <div key={index}>
              <Coursecard
                activeFilters={activeFilters}
                title={"searchResults"}
                {...{ each }}
                cardWidth={mobileDevices ? "98%" : 282}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const MatchGigs = ({
  list,
  setRefresh,
  refresh,
  title,
  type,
  cards = [],
  activeType,
  activeFilters = {},
}) => {
  const { lapy, miniLapys, ipodDevices, mobileDevices, desktop } = useSelector(
    (state) => state.responsive
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const empty = isEmpty(list);

  const slidesToShow = mobileDevices
    ? 1.1
    : ipodDevices
    ? 2.5
    : miniLapys
    ? 2.6
    : lapy
    ? 3.5
    : desktop
    ? 4
    : 5;
  const upcommingHomeSettings = {
    dots: false,
    infinite: false,

    slidesToShow: slidesToShow,
    speed: 500,
    slidesToScroll: mobileDevices ? 1 : miniLapys || lapy ? 2 : 3,
    arrows: false,
    swipable: true,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  const navigate = useNavigate();

  const upRef = useRef(null);
  const handleSliderDirec = (direc) => {
    if (direc === PackageStrings?.right) {
      upRef.current.slickNext();
    } else {
      upRef.current.slickPrev();
    }
  };
  return (
    <div style={{ width: "100%", display: empty && "none", marginBottom: 15 }}>
      <ListHeader {...{ type, navigate, title, list, activeType }} />
      {list?.length > slidesToShow && type != "more" ? (
        <CauroselList
          arrowsWidth={mobileDevices ? "96%" : ipodDevices ? "90%" : "92%"}
          settings={upcommingHomeSettings}
          sliderRef={upRef}
          handleSliderDirec={handleSliderDirec}
          showButtons={mobileDevices ? false : true}
          style={{ width: "100%" }}
          disabledLeftButton={currentIndex === 0}
          disabledRight={currentIndex + slidesToShow >= list?.length}
        >
          {list?.map((each, index) => (
            <GigCard
              key={index}
              {...{ each, activeFilters }}
              cardwidth={mobileDevices ? "98%" : "96.5%"}
              getGigsList={() => {
                setRefresh(!refresh);
              }}
            />
          ))}
        </CauroselList>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap: type != "more" ? "nowrap" : "wrap",
            marginBottom: 15,
            overflow: "auto",
          }}
        >
          {list?.map((each, index) => (
            <div key={index}>
              <GigCard
                {...{ each, activeFilters }}
                cardWidth="282px"
                getGigsList={() => {
                  setRefresh(!refresh);
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const MatchSessions = ({
  list,

  title,
  type,
  navigate,
  activeType,
  activeFilters = {},
}) => {
  const empty = isEmpty(list);
  const { lapy, miniLapys, ipodDevices, mobileDevices, desktop } = useSelector(
    (state) => state.responsive
  );
  const [currentIndex, setCurrentIndex] = useState(true);

  const slidesToShow = mobileDevices
    ? 1.2
    : ipodDevices
    ? 2.5
    : miniLapys
    ? 4
    : lapy || desktop
    ? 4
    : 5;

  const adhocRef = useRef();
  const AdhocSettings = {
    dots: false,
    infinite: false,
    slidesToShow: slidesToShow,
    speed: 500,
    slidesToScroll: mobileDevices ? 1 : miniLapys || lapy ? 2 : 3,
    arrows: false,
    swipable: true,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  const handleSliderDirec = (direc) => {
    if (direc === PackageStrings?.right) {
      adhocRef.current.slickNext();
    } else {
      adhocRef.current.slickPrev();
    }
  };

  return (
    <div style={{ width: "100%", display: empty && "none", marginBottom: 15 }}>
      <ListHeader {...{ type, navigate, title, list, activeType }} />
      {list?.length >
        (mobileDevices || ipodDevices ? 2 : miniLapys ? 3 : lapy ? 4 : 5) &&
      type != "more" ? (
        <CauroselList
          settings={AdhocSettings}
          sliderRef={adhocRef}
          handleSliderDirec={(direc) => {
            handleSliderDirec(direc, adhocRef);
          }}
          style={{ width: "100%" }}
          showButtons={!mobileDevices}
          disabledLeftButton={currentIndex === 0}
          disabledRight={currentIndex + slidesToShow >= list?.length}
        >
          {list?.map((each, index) => {
            return (
              <AdhocCard
                cardwidth={mobileDevices ? "98%" : "96.5%"}
                {...{ each, index, activeFilters }}
              />
            );
          })}
        </CauroselList>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap: type != "more" ? "nowrap" : "wrap",
            marginBottom: 15,
            overflow: "auto",
          }}
        >
          {list?.map((each, index) => {
            return <AdhocCard {...{ each, index, activeFilters }} />;
          })}
        </div>
      )}
    </div>
  );
};

export const MatchProviders = ({
  list,
  title,
  type,
  navigate,
  activeType,
  activeFilters = {},
}) => {
  const empty = isEmpty(list);
  const { lapy, miniLapys, ipodDevices, mobileDevices, desktop } = useSelector(
    (state) => state.responsive
  );
  const [currentIndex, setCurrentIndex] = useState(0);
  const slidesToShow = mobileDevices
    ? 1.2
    : ipodDevices
    ? 2.5
    : miniLapys
    ? 4
    : lapy || desktop
    ? 4
    : 5;
  const providerRef = useRef();
  const providerSettings = {
    dots: false,
    infinite: false,
    slidesToShow: slidesToShow,
    speed: 500,
    slidesToScroll: mobileDevices ? 1 : miniLapys || lapy ? 2 : 3,
    arrows: false,
    swipable: true,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };

  const handleSliderDirec = (direc) => {
    if (direc === PackageStrings?.right) {
      // if (providerRef.current && currentIndex + slidesToShow < list) {
      providerRef.current.slickNext();
      // }
    } else {
      // if (providerRef.current && currentIndex > 0) {
      providerRef.current.slickPrev();
      // }
    }
  };

  return (
    <div style={{ width: "100%", display: empty && "none", marginBottom: 15 }}>
      <ListHeader {...{ type, navigate, title, list, activeType }} />
      {list?.length > slidesToShow && type != "more" ? (
        <CauroselList
          settings={providerSettings}
          sliderRef={providerRef}
          style={{ width: "100%" }}
          showButtons={!mobileDevices}
          handleSliderDirec={(direc) => {
            handleSliderDirec(direc, providerRef);
          }}
          disabledLeftButton={currentIndex === 0}
          disabledRight={currentIndex + slidesToShow >= list?.length}
        >
          {list?.map((each, index) => {
            return (
              <TopProvidersCard
                key={index}
                {...{ each, index, activeFilters }}
                cardwidth={mobileDevices ? "98%" : "96.5%"}
              />
            );
          })}
        </CauroselList>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
            flexWrap: type != "more" ? "nowrap" : "wrap",
            marginBottom: 15,
            overflow: "auto",
          }}
        >
          {list?.map((each, index) => {
            return (
              <TopProvidersCard
                key={index}
                {...{ each, index, activeFilters }}
                cardwidth={"278px"}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
