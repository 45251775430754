import React from "react";
import AautiText from "../globalComponents/AautiText";
import "./Style.scss";
import Footer from "../footer/Footer";
import { globalColors } from "../../themes/GlobalColors";
import AautiDialog from "../globalComponents/AautiDialog";
import { useSelector } from "react-redux";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Drawer } from "@mui/material";

const RefundPolicyPoints = [
  {
    heading: "Eligibility for Returns:",
    subHeading: ["You may be eligible for a return of your course purchase under the following circumstances: The course you purchased is not what you expected, and you have not completed more than 20% of the course content.You encounter technical issues that prevent you from accessing the course, and our technical support team is unable to resolve the issue within a reasonable time frame."]
  },
  {
    heading: "Timeframe for Returns:",
    subHeading: ["You must request a return within [number] days from the date of purchase. After this period, we will not accept return requests."]
  },
  {
    heading: "Non-Refundable Situations:",
    subHeading: [
      "You will not be eligible for a return or refund in the following situations:",
      "You have completed more than 20% of the course content.",
      "You have violated our terms of service or code of conduct.",
      "You have shared your course materials with others, in violation of our copyright and intellectual property policies.",
      "You have used a promotional code or discount to purchase the course."
    ]
  },
  {
    heading: "Return Process:",
    subHeading: [
      "To request a return, please contact our customer support team at [contact email or phone number].",
      "Please provide the following information:",
      "Your full name",
      "Course name and purchase date",
      "Reason for the return",
      "Our team will review your request and respond within [number] business days."
    ]
  },
  // {
  //   heading: "Refund Process:",
  //   subHeading:
  //     ["If your return request is approved, we will process your refund using the original payment method you used for the course purchase.",
  //       "Please allow [number] business days for the refund to reflect in your account."]
  // },
  {
    heading: "Course Access:",
    subHeading: [
      // "Please allow [number] business days for the refund to reflect in your account.",
      "Upon approval of your return, you will lose access to the course content immediately.",
      "Changes to the Return Policy:",
      "[Your Company Name] reserves the right to modify this Course Purchase Return Policy at any time. Any changes will be posted on our website, and the effective date will be updated accordingly.",
      "By purchasing a course from [Your Company Name], you acknowledge that you have read, understood, and agreed to this Course Purchase Return Policy. If you have any questions or concerns, please do not hesitate to contact our customer support team.",
      "Thank you for choosing [Your Company Name] for your learning needs.",
      "[Your Company Name]",
      "[Website URL]",
      "[Contact Information]"
    ]
  },
]

const RWDReturnPolicy = ({ mobileDevices, aboveLargeMobileDevices }) => {
  return (
    <div className="RefundmainDivStyle">
      <div className="heading-div">
        {RefundPolicyPoints?.map((each, index) => (
          <div key={index} className="eachrowcontainer">
            <AautiText
              title={each?.heading}
              textStyle={{ color: globalColors.HEADING_COLOR }}
              size={"medium"}
              weight={"medium"}
            />
            <div className="sub-heading-div">
              {each?.subHeading?.map((item, index) => (
                <AautiText key={index}
                  title={item}
                  size={(mobileDevices || aboveLargeMobileDevices) ? 'semi' : "normal"}
                  weight={"normal"}
                  textStyle={{ color: "#626972" }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
const ReturnPolicy = ({ showFooter = false, open, setOpen }) => {
  const { mobileDevices, aboveLargeMobileDevices } = useSelector((state) => state.responsive);
  return (
    <>{(mobileDevices || aboveLargeMobileDevices) ?
      <Drawer
        dialogTitle={""}
        anchor="bottom"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "10px 10px 0px 0px",
            // zIndex: 502,
            height: "96vh",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: "10px 0px 10px 5px",
              alignItems: "center",
              flexDirection: 'row',
              borderBottom: '1px solid #5C5B5733',
              columnGap: 10
            }}
          >  <IoIosArrowRoundBack
              onClick={() => setOpen(false)}
              size={25}
              style={{ cursor: "pointer", color: '#000000' }}
            />
            <AautiText
              weight={'bold'}
              title={"Refund Policy"}
              textStyle={{ color: globalColors.HEADING_COLOR, fontSize: "18px" }}
            />
          </div>
          <RWDReturnPolicy {...{ mobileDevices, aboveLargeMobileDevices }} />
        </div>
      </Drawer> :
      <AautiDialog open={open}
        dialogTitle={"Refund Policy"}
        onClose={() => setOpen(false)}>
        <RWDReturnPolicy />
        {showFooter && <Footer />}
      </AautiDialog>
    }
    </>
  );
};
export default ReturnPolicy;
