import React from "react";
import AppContainer from "../AppContainer";
import CourseFilters from "../home/upcomming/CourseFilters";
import { isEmpty } from "lodash";
import EmptyData from "./EmptyData";
import Coursecard from "../appCards/Coursecard";
import { Divider, Grid } from "@mui/material";
import CardLoader from "./CardLoader";
import { useSelector } from "react-redux";
import "./styles.scss";
import AautiText from "./AautiText";
import HeadCategories from "../header/HeadCategories";
import MatchMyScheduleListView from "../adhoc/matchMySchedule/MatchMyScheduleListView";
import AautiInfiniteScroll from "../features/InfiniteScroll";

const GlobalListComponent = ({
  list,
  type,
  loading,
  headerText,
  searchInput,
  onChangeSearchValue,
  handleClickApply,
  screenName,
  showFilters = true,
  multipleArray = {},
  activeFilters = {},
  setSearchInput,
  actFilters = {},
  activeType,
  setActiveType,
  makeSearchApi,
  apiLoading,
  setRefresh,
  refresh,
  hasData,
  handleLoadMore,
  ...rest
}) => {
  const { lapy, miniLapys, ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );

  const returnListing = () => {
    return (
      <MatchMyScheduleListView
        searchValue={searchInput}
        {...{
          activeType,
          setActiveType,

          makeSearchApi,
          apiLoading,
          moreResults: multipleArray,
          setRefresh,
          refresh,
        }}
      />
    );
  };
  const returnEmpty = () => {
    if (isEmpty(multipleArray)) {
      return [];
    } else {
      return list;
    }
  };

  const returnMainContent = () => {
    if (!isEmpty(list)) {
      return (
        <AautiInfiniteScroll
          testId={"scrollableDiv"}
          {...{ hasData, handleLoadMore }}
          cardsList={list}
        >
          <div className="main-cont-flex">
            {list?.map((each, index) => {
              return (
                <Coursecard
                  activeFilters={activeFilters}
                  cardWidth={
                    mobileDevices ? "99%" : ipodDevices ? 263 : "283px"
                  }
                  key={index}
                  title={headerText}
                  each={each}
                />
              );
            })}
          </div>
        </AautiInfiniteScroll>
      );
    } else {
      return (
        <EmptyData text={searchInput ? "No Search Results" : "No Results"} />
      );
    }
  };

  return (
    <section id="global_list_component">
      <HeadCategories />
      <Divider sx={{ mt: 1 }} />
      <AppContainer className={"main-cont"}>
        <div className="main-filter-cont">
          <CourseFilters
            actFilters={actFilters}
            searchValue={searchInput}
            onChangeSearchValue={onChangeSearchValue}
            onCloseSearch={setSearchInput}
            handleClickApply={handleClickApply}
            screenName={screenName}
          />
        </div>
        <div className="content-main2" id="scrollableDiv">
          <div className="header_text">
            <AautiText
              size={mobileDevices ? "extraMedium" : "large"}
              textStyle={{ color: "#1E1E1E" }}
              title={"Showing results for"}
            />
            <AautiText
              textStyle={{ color: "#1E1E1E" }}
              size={mobileDevices ? "extraMedium" : "large"}
              title={headerText}
              weight={"bold"}
            />
          </div>
          {loading ? (
            <Grid container spacing={1.5}>
              {[1, 2, 3, 4, 5, 6, 7, 8]
                .slice(
                  0,
                  mobileDevices
                    ? 1
                    : ipodDevices
                    ? 2
                    : miniLapys
                    ? 3
                    : lapy
                    ? 3
                    : 4
                )
                .map((each, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={index}>
                      <CardLoader />
                    </Grid>
                  );
                })}
            </Grid>
          ) : !isEmpty(multipleArray) ? (
            returnListing()
          ) : (
            returnMainContent()
          )}
        </div>
      </AppContainer>
      {/* <ExploreMore catgoriesToShow={6} /> */}
    </section>
  );
};

export default GlobalListComponent;
