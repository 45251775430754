// import { ApiMethods, ApiPaths } from "../../service/api-constants";
import moment from "moment";
import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";

/** login authentication
 * @param username, password
 */

const GET_SERVICEPROVIDER_RATING_REVIEWS =
  "ratingsReviews/getServiceProviderIdRatingsReviews";

function getMyGigsForServiceProvider(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_MYGIGBYUSERID_SERVICEPROVIDER,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getServiceProviderRatingsReviewsBy(
  serviceProviderId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: GET_SERVICEPROVIDER_RATING_REVIEWS,
    method: ApiMethods.GET,
    params: {
      serviceProviderId: serviceProviderId,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyCoursesByUserid(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getCoursesByUserId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyOfferedCoursesByUserid(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getOfferedCoursesByUserId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getMyExpCoursesByUserid(data, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getExpiredCourseBySpId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getServiceProviderDetails(id, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id, endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getServiceProviderDetailsBeforeLogin(id, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getServiceProviderDetailsById",
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getRatingsReviewsByServiceProviderId(
  serviceProviderId,
  sortBy,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: "ratingsReviews/getRatingsReviewsByServiceProviderId",
    method: ApiMethods.GET,
    params: {
      serviceProviderId: serviceProviderId,
      sortBy: sortBy,
    },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function GetuserDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GETUSERSBYID,
    method: ApiMethods.GET,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getServiceProviderDashboardDetails(
  loggedInUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SERVICEPROVIDER_DASHBOARD_DETAILS + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getReports(type, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_REPORTS + `?type=${type}`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getStudentsListByServiceProviderID(
  loggedInUserId,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SERVICEPROVIDERS_STUDENTS_LIST + loggedInUserId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRecentViewedServiceProviders(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/recentViewedServiceProviders",
    params: data,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getRecentViewedByEndUsers(data, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMyProfileViewedUsers",
    params: data,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function saveRatingsReviews(ratingsData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.SAVE_RATINGS_REVIEWS,
    method: ApiMethods.POST,
    data: ratingsData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function complaintSave(complaintData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_COMPLAINT_SAVE,
    method: ApiMethods.POST,
    data: complaintData,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteRatingsReviews(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETE_RATING_REVIEWS_BY_REVIEWSID,
    params: { id },
    method: ApiMethods.DELETE,
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function updateRatingsReviews(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.UPDATE_RATING_REVIEWS,
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAttendance(serviceProviderId, noOfDays, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ATTENDANCE +
      "?serviceProviderId=" +
      serviceProviderId +
      "&noOfDays=" +
      noOfDays,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getCourseAttendanceEU(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getEndUserAttendanceForCourse",
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getCourseAttendanceSp(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getServiceProviderAttendanceForCourse",
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getAdhocAttendanceSp(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getServiceProviderAttendanceForAdhoc",
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getAdhocAttendanceEU(data, onSuccess, onFailure) {
  ApiRequest({
    url: "attendance/getEndUserAttendanceForAdhoc",
    method: ApiMethods.GET,
    params: data
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getEndUserAttendance(endUserId, noOfDays, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ENDUSER_ATTENDANCE +
      "?endUserId=" +
      endUserId +
      "&noOfDays=" +
      noOfDays,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getEligibilty(endUserId, serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_ELIGIBILTY +
      "endUserId=" +
      endUserId +
      "&serviceProviderId=" +
      serviceProviderId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function getSessionsMapByStartEndDatesByServiceProvider(
  startDate,
  endDate,
  serviceProviderId,
  type,
  category,
  subCategory,
  country,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: ApiPaths.GET_SESSION_MAP_BY_START_END_DATES_BY_SERVICEPROVIDER,
    method: ApiMethods.GET,
    params: {
      startDate,
      endDate,
      serviceProviderId,
      type,
      category,
      subCategory,
      country,
      timeZone: moment().utcOffset()
    },
  }).then(
    (response) => {
      onSuccess(response === undefined ? [] : response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function wishlistServiceProvider(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_WISHLIST_WISHLIST_SERVICEPROVIDER,
    method: ApiMethods.POST,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteWishlistedServiceProvider(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_WISHLIST_DELETE_WISHLIST_SERVICEPROVIDER,
    method: ApiMethods.DELETE,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getTopSeviceProviders(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOPSERVICEPROVIDERS + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getTopServiceProvidersBeforeLogin(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOPSERVICEPROVIDERS_BEFORE_LOGIN,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function getMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMembersList",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function reportInChatRoom(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.REPORT_CHAT_ROOM,
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  getRecentViewedServiceProviders,
  getRecentViewedByEndUsers,
  getStudentsListByServiceProviderID,
  getSessionsMapByStartEndDatesByServiceProvider,
  getMyGigsForServiceProvider,
  getMyCoursesByUserid,
  getMyOfferedCoursesByUserid,
  getMyExpCoursesByUserid,
  getServiceProviderDetails,
  getRatingsReviewsByServiceProviderId,
  GetuserDetailsById,
  getServiceProviderDashboardDetails,
  getReports,
  saveRatingsReviews,
  complaintSave,
  deleteRatingsReviews,
  updateRatingsReviews,
  getAttendance,
  getCourseAttendanceEU,
  getAdhocAttendanceEU,
  getCourseAttendanceSp,
  getAdhocAttendanceSp,
  getEndUserAttendance,
  getEligibilty,
  getServiceProviderDetailsBeforeLogin,
  wishlistServiceProvider,
  deleteWishlistedServiceProvider,
  getTopSeviceProviders,
  getTopServiceProvidersBeforeLogin,
  getServiceProviderRatingsReviewsBy,
  getMembers,
  reportInChatRoom,
};
