import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import "./Style.scss";
import "../serviceProviderDetails/Style.scss";
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Box,
} from "@mui/material";
import { showToast } from "../globalComponents/Toast";
import { useSelector } from "react-redux";
import NoImage from "../../assets/images/no-image.svg";
import { AuthService } from "./service";
import CourseRatingDialog from "../globalComponents/CourseRatingDialog";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { AiFillStar } from "react-icons/ai";
import EmptyTopic from "../globalComponents/EmptyTopic";
import EmptyRatingsImg from "../../assets/images/EmptyRatings.png";
import { spStrings } from "../serviceProviderDetails/Constants";
import DeletionAlert from "../../assets/images/DeletionAlert.png";
import ReviewsSkeleton from "./ReviewsSkeleton";
import ReviewsContainer from "./ReviewsContainer";
import UserCard from "../course/courseDetails/UserCard";
import { globalColors } from "../../themes/GlobalColors";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";


const filterOptions = [
  { id: 123, sortBy: "topRating", tabName: "Top Ratings" },
  { id: 123, sortBy: "recent", tabName: "Most Recent" },
];

const CourseReview = ({
  reviewData = {},
  setOrderID,
  dialoghandle = () => { },
  screenName,
  setCourseReview,
  totalOrdersAPI,
  setOncloseRefresh,
}) => {
  const [sortState, setSortState] = useState("recent");
  const { loggedinUserId, isUserLoggedIn } = useSelector(
    (state) => state.app
  );
  const [reviewComment, setReviewComment] = useState("");
  const [ratingValue, setRatingValue] = useState(0);
  const [review, setReview] = useState(null);
  const [open, setOpen] = useState(false);
  const [reviewSkeleton, setReviewSkeleton] = useState(true);
  const [visibleReplay, setVisibleReplay] = useState(false);
  const [editRating, setEditRating] = useState(false);
  const [editID, setEditID] = useState("");
  const [replayRating, setReplayRating] = useState(false);
  const [replayComment, setReplayComment] = useState(false);
  const navigate = useNavigate();
  const [courseReviewData, setcourseReviewData] = useState(null);
  const [comfirmDialog, setComfirmDialog] = useState(false);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const suggestionsArray = (arrayOne) => {
    const list = suggestionsList || [];
    if (list.includes(arrayOne)) {
      const filteredList = list.filter((each) => each !== arrayOne);
      setSuggestionsList(filteredList);
    } else {
      setSuggestionsList([...list, arrayOne]);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open2 = Boolean(anchorEl);
  const handleClick = (event, each) => {
    setAnchorEl(event.currentTarget);
    setReview(each);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { mobileDevices } = useSelector((state) => state.responsive);
  useEffect(() => {
    ReviewByType();
  }, [sortState]);

  const ReviewByType = () => {
    return reviewData?.type === "course"
      ? courseReview()
      : reviewData?.type === "gig"
        ? GigReview()
        : AdhocReview();
  };

  const courseReview = () => {
    const courseId = reviewData?.courseId;
    let data = isUserLoggedIn
      ? { courseId, endUserId: loggedinUserId, sortState }
      : { courseId, sortState };
    AuthService.getCourseReviewsById(data, onSuccessCourseReview, onFailure);
  };
  const onSuccessCourseReview = (response) => {
    if (response.status === "Success") {
      setReviewSkeleton(false);
      setcourseReviewData(response.result);
    } else {
      setReviewSkeleton(false);
      showToast("error", response?.message);
    }
  };
  const GigReview = () => {
    const params = {
      gigId: reviewData?.gigId,
      sortBy: sortState
    }
    if (isUserLoggedIn) {
      params.endUserId = loggedinUserId;
    }
    AuthService.getGigReviewsById(
      params,
      onSuccessGigReview,
      onFailure
    );
  };
  const onSuccessGigReview = (response) => {
    if (response.status === "Success") {
      setReviewSkeleton(false);
      setcourseReviewData(response.result);
    } else {
      setReviewSkeleton(false);
      showToast("error", response?.message);
    }
  };
  const AdhocReview = () => {
    const category = reviewData?.category;
    const subCategory = reviewData?.subCategory;
    const serviceProviderId = reviewData?.serviceProviderId;

    AuthService.getAdhocReviewsById(
      category,
      subCategory,
      serviceProviderId,
      sortState,
      onSuccessAdhocReview,
      onFailure
    );
  };
  const onSuccessAdhocReview = (response) => {
    if (response.status === "Success") {
      setReviewSkeleton(false);
      setcourseReviewData(response.result);
    } else {
      setReviewSkeleton(false);
      showToast("error", response?.message);
    }
  };
  const deleteAPI = (eachID, skipAPICall = false) => {
    const reviewID = eachID;
    AuthService.deleteRatingsReviews(
      reviewID,
      (response) => onSuccessDeleteRating(response, skipAPICall),
      onFailure
    );
  };
  const onSuccessDeleteRating = (response, skipAPICall) => {
    if (response?.status === "Success") {
      if (screenName === "Orders") {
        totalOrdersAPI(0, "", {})
      }
      setRatingValue("");
      setReviewComment("");
      ReviewByType();
      setComfirmDialog(false);
    } else {
      showToast("error", response?.message);
    }
  };
  const updateRating = (each = null) => {
    const ratingData = {
      endUserId: each ? each?.endUserId : loggedinUserId,
      type: each ? null : reviewData?.type,
      courseId: each ? null : reviewData?.courseId || null,
      gigId: each ? null : reviewData?.gigId || null,
      serviceProviderId: each ? each?.serviceProviderId : reviewData?.serviceProviderId,
      rating: editID ? null : (each ? null : ratingValue),
      comment: reviewComment,
      _id: each ? each?._id : review?._id,
      suggestions: suggestionsList,
    };
    AuthService.updateRatingsReviews(
      ratingData,
      onSuccessRatingUpdate,
      onFailure
    );
  };

  const onSuccessRatingUpdate = (response) => {
    if (response?.status === "Success") {
      ReviewByType();
    } else {
      showToast("info", response?.message);
    }
  };
  const ratingAPI = (each, isReply = false) => {
    const ratingData = isReply
      ? {
        endUserId: loggedinUserId,
        serviceProviderId: each?.serviceProviderId,
        comment: replayComment,
        parentId: each?._id,
      }
      : {
        endUserId: loggedinUserId,
        type: reviewData?.type,
        courseId: reviewData?.courseId || null,
        gigId: reviewData?.gigId || null,
        serviceProviderId: reviewData?.serviceProviderId,
        rating: editID ? null : ratingValue,
        comment: editID ? false : reviewComment,
        suggestions: suggestionsList,
      };

    AuthService.saveRatingsReviews(ratingData, (response) => onSuccessRatingPost(response, isReply), onFailure);
  };
  const onSuccessRatingPost = (response, isReply = false) => {
    if (!isReply) {
      setReviewSkeleton(true);
    }
    if (response?.data?.status === "Success") {
      setReviewSkeleton(false);
      ReviewByType();
    } else {
      setReviewSkeleton(false);
      showToast("error", response?.data?.message);
    }
  };
  const onFailure = (error) => {
    console.log(error, "error");
    setReviewSkeleton(false);
    showToast("error", "something went wrong");
  };
  const [anchorElSort, setAnchorElSort] = useState(null);
  const openSort = Boolean(anchorElSort);
  const handleClickSort = (event) => {
    setAnchorElSort(event.currentTarget);
  };
  const handleCloseSort = () => {
    setAnchorElSort(null);
  };
  return (
    <div className="reviews-100">
      {reviewSkeleton ? (
        <ReviewsSkeleton />
      ) : courseReviewData?.reviews?.length > 0 ? (
        <div className="RRmaindiv">
          <div className="RRchield1div1">
            <div className="reviews-created-div"   >
              <>
                <AautiText
                  textStyle={{ color: globalColors.HEADING_COLOR }}
                  weight={"bold"}
                  size={"semi"}
                  title={"Created By"}
                />
                <div className="reviews-mobile-res"  >
                  {reviewData?.type === "adhoc" ?
                    <div className="reviews-sp-div"
                      onClick={() => {
                        navigate(
                          `/sp-profile/${courseReviewData?.serviceProviderId}`
                        );
                        dialoghandle();
                      }}
                    >  <div className="reviews-adhoc-div">
                        <Avatar
                          alt="Remy Sharp"
                          sx={{ height: 60, width: 60, mr: 1 }}
                          src={courseReviewData?.profileImage}
                          className="michale-im"
                        />
                        <div className="reviews-91">
                          <div className="reviews-displayName-div"  >
                            <AautiText
                              size={"normal"}
                              weight={"bold"}
                              textStyle={{ color: globalColors.HEADING_COLOR, }}
                              title={courseReviewData?.displayName || "No name"}
                            />
                          </div>
                        </div>
                      </div>
                    </div> :
                    <div className="reviews-sp-div-2">
                      <UserCard {...{
                        completeDetails: courseReviewData,
                        type: reviewData?.type === 'gig' ? "Gig" : reviewData?.type,
                        isRatingScreen: true,
                        isCourseRatingScreen: (screenName === "Orders" || screenName === "ClassDetails") ? false : true,
                        isGigRatingScreen: (screenName === "Orders" || screenName === "ClassDetails") ? false : true,
                        setOncloseRefresh
                      }} />
                    </div>}
                  <div className="reviews-over-all"   >
                    <AautiText
                      title={"Overall Reviews"}
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: globalColors.GREY_TEXT, marginBottom: "5px" }}
                    />
                    <div className="reviews-align-div">
                      <AautiText
                        size={"big"}
                        title={
                          courseReviewData?.averageRating
                            ? courseReviewData?.averageRating?.toFixed(1)
                            : "New"
                        }
                        weight={"bold"}
                        textStyle={{ color: globalColors.HEADING_COLOR, }} />
                      <AiFillStar style={{ color: globalColors.AAUTI_THEME_COLOR, margin: "0px 3px" }} size={22} />
                      <AautiText
                        size={"medium"}
                        title={`${courseReviewData?.totalRatings} ${courseReviewData?.totalRatings === 1
                          ? "Review"
                          : "Reviews"
                          }`}
                        weight={"bold"}
                        textStyle={{ color: globalColors.HEADING_COLOR, }}
                      />
                    </div>
                  </div>
                </div>
              </>
              {reviewData?.type !== "adhoc" && (
                <div className="reviews-course-div"
                  onClick={() => {
                    if (
                      reviewData?.type === "course" ||
                      courseReviewData?.courseId
                    ) {
                      navigate(`/course-details/${courseReviewData?.courseId}`);
                      dialoghandle();
                    } else if (
                      reviewData?.type === "gig" ||
                      courseReviewData?.gigId
                    ) {
                      navigate(`/gig-preview/${courseReviewData?.gigId}`, {});
                      dialoghandle();
                    }
                  }}
                >
                  <Avatar
                    alt="no image"
                    sx={{ height: mobileDevices ? 50 : 80, width: mobileDevices ? 50 : 80 }}
                    src={
                      courseReviewData?.courseImage ||
                      courseReviewData?.gigImage ||
                      NoImage
                    }
                    className="michale-im"
                  />
                  <div className="reviews-courseName "  >
                    <AautiText
                      title={
                        courseReviewData?.courseName ||
                        courseReviewData?.gigName ||
                        "No name"
                      }
                      size={"normal"}
                      weight={'bold'} className={"reviews-course-name-ellips"} />
                    <AautiText
                      textStyle={{ WebkitLineClamp: mobileDevices ? 1 : 2 }}
                      title={
                        courseReviewData?.courseDescription ||
                        courseReviewData?.gigDescription ||
                        "No Description"}
                      size={"semi"}
                      weight={"medium"} className={"reviews-course-name-ellips"}
                    />
                  </div>
                </div>
              )}
              <div className="reviews-allreviews-div1">
                <div className="reviews-allreviews-div2"  >
                  <AautiText
                    title={"All Reviews"}
                    size={"normal"}
                    weight={"bold"}
                    textStyle={{ color: globalColors.HEADING_COLOR }}
                  />
                  <Box
                    id="fade-button"
                    aria-controls={openSort ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSort ? 'true' : undefined}
                    onClick={handleClickSort}
                    className="rating-sort-menu"
                    sx={{ display: 'flex', flexDirection: 'row', cursor: "pointer", alignItems: 'center', columnGap: "5px" }}
                  >
                    <AautiText
                      weight={"medium"}
                      title={sortState === "recent" ? "Most Recent" : "Top Ratings"}
                      size={"semi"}
                      textStyle={{ color: globalColors.GREY_TEXT }}
                    />
                    <ArrowDropDownIcon sx={{ color: globalColors.GREY_TEXT }} />
                  </Box>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElSort}
                    open={openSort}
                    onClose={handleCloseSort}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    onChange={(e) => {
                      setSortState(e.target?.value);
                    }}
                  >
                    {filterOptions?.map((each, index) => (
                      <MenuItem key={index} onClick={() => {
                        setSortState(each?.sortBy);
                        handleCloseSort();
                      }}>{each?.tabName}</MenuItem>
                    ))}
                  </Menu>
                </div>
                <ReviewsContainer {...{
                  reviewData,
                  open,
                  courseReviewData,
                  handleClick,
                  replayComment,
                  replayRating,
                  deleteAPI,
                  setEditID,
                  ratingAPI,
                  setEditRating,
                  updateRating,
                  setReviewComment,
                  setReplayComment,
                  setReplayRating,
                  reviewComment,
                  editID,
                  editRating,
                  screenName
                }} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          < EmptyTopic topicName={"No Reviews Yet"} Image={EmptyRatingsImg} />
          {((reviewData?.isPurchased || screenName === "ClassDetails") && reviewData?.serviceProviderId !== loggedinUserId) && (
            <AautiText
              size={"semi"}
              weight={"bold"}
              title={spStrings?.Addareview}
              className={"detailsLeft-text9"}
              textStyle={{ textAlign: "center" }}
              onClick={() => {
                setOpen(true);
              }
              }
            />
          )}
        </div>
      )}
      <CourseRatingDialog
        {...{
          setOpen,
          open,
          setRatingValue,
          ratingValue,
          courseReviewData,
          setReviewComment,
          reviewComment,
          ratingAPI,
          orderID: reviewData,
          setOrderID,
          updateRating,
          editRating,
          setEditRating,
          suggestionsArray,
          setSuggestionsList,
          suggestionsList,
          type: reviewData?.type,
          setOncloseRefresh,
          screenName
        }}
      />
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open2}
        onClose={handleClose}
      >
        {review?.endUserId === loggedinUserId && (
          <>
            <MenuItem
              onClick={() => {
                setOpen(true);
                setRatingValue(review?.rating);
                setReviewComment(review?.comment);
                setSuggestionsList(review?.suggestions)
                handleClose();
                setEditRating(true);
              }
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClose();
                setComfirmDialog(true);
              }}
            >
              Delete
            </MenuItem>
          </>
        )}

        {review?.endUserId !== loggedinUserId && (
          <MenuItem
            onClick={() => {
              if (!isUserLoggedIn) {
                navigate("/login");
              } else {
                setVisibleReplay(!visibleReplay);
                setReplayRating(!replayRating);
                setReplayComment("");
                setEditID(review?._id);
                handleClose();
              }
            }}
          >
            Reply
          </MenuItem>
        )}
      </Menu>
      <Dialog
        open={comfirmDialog}
        keepMounted
        onClose={() => setComfirmDialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle> {"Confirmation"}</DialogTitle>
        <DialogContent>
          <img
            src={DeletionAlert}
            alt="Confirm"
            style={{
              display: 'flex',
              height: mobileDevices ? "120px" : "200px",
              width: mobileDevices ? "120px" : "200px",
              alignSelf: "center",
            }}
          />
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setComfirmDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              deleteAPI(review?._id);
              setEditID("");
              if (screenName !== "Orders") {
                setOncloseRefresh(true)
              } else if (screenName === "Orders") {
                setCourseReview(false);
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
};
export default CourseReview;