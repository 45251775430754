import React, { useEffect, useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { Box, Grid, Skeleton } from "@mui/material";
import "./style.scss";
import { useSelector } from "react-redux";
import { AuthService } from "./Service";
import { showToast } from "../globalComponents/Toast";
import {
  convertToMillionBillionsPrice,
  PriceConversions,
} from "../CommonFunctions";
import AautiDialog from "../globalComponents/AautiDialog";
import CreateRequest from "./CreateRequest";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
import EmptyData from "../globalComponents/EmptyData";
import moment from "moment";
import { isEmpty } from "lodash";
import { HiPlus } from "react-icons/hi";
import AautiSearch from "../globalComponents/AautiSearch";
import EmptyTopic from "../globalComponents/EmptyTopic";
import { useNavigate } from "react-router-dom";
import { globalColors } from "../../themes/GlobalColors";

const PayoutRequest = () => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { convertPriceWithCurrency, onlyPriceWithCurrency, } =
    PriceConversions();
  const navigation = useNavigate();
  const [count, setCount] = useState(10);
  const [offSet, setOffSet] = useState(0);
  const [banksList, setBanksList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [spCharges, setSpcharges] = useState({});
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("");
  const [payoutList, setPayoutList] = useState([]);
  const verifiedBank = banksList?.find((bank) => bank?.approvalStatus);

  const tax = (selectedAmount * spCharges?.tax) / 100;
  const serviceCharges = (selectedAmount * spCharges?.serviceCharge) / 100;
  const netPay = selectedAmount - (tax + serviceCharges);

  const chargesInfo = [
    { chargeType: "Withdraw Amount", charges: selectedAmount },
    { chargeType: "Service Charge", charges: serviceCharges },
    { chargeType: "Tax", charges: tax },
    { chargeType: "Net Pay", charges: netPay },
  ];
  const payoutData = {
    serviceProviderId: loggedinUserId,
    countryName: selectedBank?.countryName,
    bankName: selectedBank?.bankName,
    accountHolderName: selectedBank?.accountHolderName,
    bankDocument: selectedBank?.accountHolderName,
    accountNumber: selectedBank?.accountNumber,
    ssnNumber: selectedBank?.ssnNumber,
    ifscCode: selectedBank?.ifscCode,
    dateOfBirth: selectedBank?.dateOfBirth,
    payoutStatus: "inProgress",
    withdrawAmount: selectedAmount,
    serviceCharge: serviceCharges,
    tax: tax,
    netPay: netPay,
  };

  const onCloseHandle = () => {
    setOpenDialog(false);
    setSelectedBank([]);
    setSelectedAmount("");
  };

  useEffect(() => {
    setLoading(true);
    getPayoutRequestsApi();
    bankListCall();
    getTotalUserCreditsCall();
  }, [count, loggedinUserId, offSet]);

  const getPayoutRequestsApi = () => {
    AuthService.getPayoutRequests(
      loggedinUserId,
      count,
      offSet,
      onSuccessGetPayouts,
      onFailure
    );
  };
  const bankListCall = () => {
    AuthService.GetBanksList(loggedinUserId, onSuccessBankList, onFailure);
  };
  const getTotalUserCreditsCall = () => {
    AuthService.getTotalUserCredits(
      loggedinUserId,
      onSuccessCharges,
      onFailure
    );
  };
  const onSuccessCharges = (response) => {
    setSpcharges(response.result);
  };
  const onSuccessBankList = (response) => {
    if (response?.status === "Success") {
      setLoading(false);
      setBanksList(response?.result);
    } else {
      setLoading(false);
      showToast("response", response.message);
    }
  };

  const onSuccessGetPayouts = (response) => {
    if (response.status === "Success") {
      setLoading(false);
      setPayoutList(response?.result?.[0]);
    } else {
      setLoading(false);
      showToast("response", response.message);
    }
  };

  const onFailure = (error) => {
    setLoading(false);
    showToast("response", error.message);
  };

  const payoutSaveApi = () => {
    AuthService.payoutSave(payoutData, onSuccessSave, onFailure);
  };
  const onSuccessSave = (response) => {
    if (response.status === "Success") {
      showToast("success", response.message);
      getPayoutRequestsApi();
    }
  };
  const filtered = payoutList?.payOutList?.filter(
    (each) =>
      each?.status?.toLowerCase().includes(search.toLowerCase()) ||
      each?.bankName?.toLowerCase().includes(search.toLowerCase())
  );
  const countryPriceSymbol = userDetails?.transactionCountrySymbol;
  return (
    <AppContainer style={{ width: "100%", height: "90vh" }}>
      <AccountTabs
        {...{
          tabObj: {
            label: "Payout Requests",
            navigation: "/payout-requests",
            active: true,
          },
        }}
      />
      <Grid className="payOut-grid-height ">
        {loading ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: 15,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: mobileDevices ? "column" : "row",
                  justifyContent: "space-between",
                  marginBottom: 20,
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: 15,
                    rowGap: mobileDevices ? 10 : 0,
                    marginBottom: mobileDevices ? 10 : 0,
                    width: "100%",
                  }}
                >
                  <Skeleton
                    variant="rounded"
                    width={mobileDevices ? "48%" : 300}
                    height={mobileDevices ? 50 : 100}
                  />
                  <Skeleton
                    variant="rounded"
                    width={mobileDevices ? "48%" : 300}
                    height={mobileDevices ? 50 : 100}
                  />
                </div>
                <Skeleton
                  variant="rounded"
                  width={mobileDevices ? "100%" : 222}
                  height={mobileDevices ? 25 : 40}
                />
              </div>
              <div className="tabs-flex ">
                {[1, 2, 3, 4, 5, 6]?.map((each, index) => (
                  <Skeleton
                    variant="rounded"
                    width={mobileDevices ? "100%" : 333}
                    height={100}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <Box className={"payOut-main-box"}>
            <div className="payOut-child-div1">
              <div className="payOut-child-div2">
                <div className="payOut-funds-div">
                  <AautiText
                    title={countryPriceSymbol +
                      (payoutList?.availableFunds > 0
                        ? convertToMillionBillionsPrice(
                          payoutList?.availableFunds
                        )
                        : 0)
                    }
                    textStyle={{ color: "#1E1E1E", fontFamily: "sans-serif" }}
                    weight={"bold"}
                    size={"medium"}
                  />
                  <AautiText
                    title={"Available Funds"}
                    textStyle={{ color: "#5C5B57" }}
                    weight={"medium"}
                    size={"semi"}
                  />
                </div>
                <div className="payOut-funds-div">
                  <AautiText
                    title={countryPriceSymbol +
                      (payoutList?.pipelineFunds > 0
                        ? convertToMillionBillionsPrice(
                          payoutList?.pipelineFunds
                        )
                        : 0
                      )}
                    textStyle={{ color: "#1E1E1E", fontFamily: "sans-serif" }}
                    weight={"bold"}
                    size={"medium"}
                  />
                  <AautiText
                    title={"Pipeline Funds"}
                    textStyle={{ color: "#5C5B57" }}
                    weight={"medium"}
                    size={"semi"}
                  />
                </div>
              </div>
              {isEmpty(payoutList?.payOutList) ? null : (
                <div className="payOut-search-div">
                  <AautiSearch
                    addStyle={{ width: mobileDevices && "90%" }}
                    searchValue={search}
                    onClose={() => {
                      setSearch("");
                    }}
                    handleSearchChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <HiPlus
                    id="create-course-button"
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "4px",
                      backgroundColor: "#078dda",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (
                        isEmpty(payoutList) ||
                        payoutList?.availableFunds < 1
                      ) {
                        showToast("info", "No Available Funds");
                      } else if (isEmpty(banksList)) {
                        showToast(
                          "info",
                          "Please add bank details to continue.."
                        );
                      } else {
                        setOpenDialog(true);
                      }
                    }}
                  />
                </div>
              )}
            </div>

            {isEmpty(payoutList?.payOutList) ? (
              <EmptyTopic
                height={"68vh"}
                message={
                  isEmpty(banksList) ? (
                    <div style={{ fontFamily: "Segoe UI", color: globalColors.GREY_TEXT }}>
                      Your bank details are currently empty. Would you like to add them?{" "}
                      <span
                        onClick={() => navigation("/bank-details")}
                        style={{ color: globalColors.AAUTI_THEME_COLOR, cursor: "pointer" }}
                      >
                        Click here
                      </span>
                    </div>
                  ) : !verifiedBank ?
                    <p style={{
                      fontFamily: "Segoe UI", color: globalColors.GREY_TEXT
                    }}>Bank details are under verification.</p>
                    : (isEmpty(payoutList) || payoutList?.availableFunds < 1) ? <p style={{
                      fontFamily: "Segoe UI", color: globalColors.GREY_TEXT
                    }}>No Available Funds.</p> :
                      <span
                        onClick={() => setOpenDialog(true)}
                        style={{ color: globalColors.AAUTI_THEME_COLOR, cursor: "pointer", fontFamily: "Segoe UI" }}
                      >
                        + Payout Request
                      </span>}
              />
            ) : (
              <div className="payOut-map-wrap">
                {filtered.length < 1 ? (
                  <EmptyData
                    showExplore={false}
                    text={"No Search Results"}
                  // height={'70vh'}
                  />
                ) : (
                  <>
                    {filtered?.map((each, index) => (
                      <div key={index} className="payOut-map-div">
                        <div className="price-cont-New">
                          <AautiText
                            weight={"bold"}
                            title={
                              (each?.bankName ? each?.bankName : "N/A") +
                              "-" +
                              (each?.accountNumber
                                ? each.accountNumber.toString().slice(-4)
                                : "N/A")
                            }
                            textStyle={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 1,
                              textOverflow: "ellipsis",
                              maxWidth: "80%",
                            }}
                          />
                          <AautiText
                            weight={"bold"}
                            title={countryPriceSymbol +
                              (convertToMillionBillionsPrice(
                                each?.netPay ? each?.netPay : 0
                              )
                              )}
                            size={"semi"}
                            textStyle={{ fontFamily: "sans-serif" }}
                          />
                        </div>
                        <div className="price-cont-New"  >
                          <div style={{ display: "flex", flexDirection: "row" }}  >
                            <AautiText
                              textStyle={{ color: "#5C5C5C" }}
                              size={"semi"}
                              title={`Ref ID: `}
                              weight={"normal"}
                            />
                            <AautiText
                              textStyle={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                color: "#5C5C5C",
                                marginLeft: 2,
                              }}
                              size={"semi"}
                              weight={"medium"}
                              title={` ${each?._id}`}
                            />
                          </div>

                        </div>
                        <div className="price-cont-New">
                          <AautiText
                            textStyle={{ color: "#5C5C5C" }}
                            size={"semi"}
                            weight={"normal"}
                            title={moment(each?.createdAt).format(
                              "DD MMM YYYY hh:mm A"
                            )}
                          />
                          <AautiText
                            weight={"bold"}
                            textStyle={{
                              color:
                                each?.status === "inProgress"
                                  ? "#E67E43"
                                  : each?.status === "success"
                                    ? "#36AF58"
                                    : "#DC3545",
                            }}
                            size={"semi"}
                            title={
                              each?.status?.charAt(0).toUpperCase() +
                              each.status.slice(1)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </Box>
        )}
      </Grid>
      <AautiDialog
        open={openDialog}
        onClose={() => {
          onCloseHandle();
        }}
        dialogTitle={"Request Payout"}
      >
        <CreateRequest
          {...{
            payoutList,
            convertPriceWithCurrency,
            banksList,
            selectedBank,
            setSelectedBank,
            userDetails,
            setSelectedAmount,
            selectedAmount,
            chargesInfo,
            payoutSaveApi,
            onCloseHandle,
            convertToMillionBillionsPrice,
          }}
        />
      </AautiDialog>
    </AppContainer>
  );
};

export default PayoutRequest;
