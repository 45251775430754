import { Box, Collapse } from "@mui/material";
import "./header.scss";
import { useState } from "react";
import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PageHeader } from "./pageHeader";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { constants } from "./constants";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LayersIcon from "@mui/icons-material/Layers";
import TopicIcon from "@mui/icons-material/Topic";

export default function HeaderBeforeLogin({ ShowHeader }) {
  const [showAllCourses, setShowAllCourses] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [expandData, setExpandData] = useState({
    allCourse: false,
    exploreCourses: false,
    categoryBased: false,
    teachers: false,
    recentSearches: false,
  });

  const navigateTo = (item) => {
    if (item?.navigate) {
      setOpen(false);
      navigate(item.route);
    } else {
      onChangeExpandCollapse(item.expandLabel);
    }
  };

  const onChangeExpandCollapse = (value) => {
    setExpandData((prev) => ({
      ...prev,
      [value]: !prev[value],
    }));
  };

  const DrawerList = (
    <Box
      sx={{ width: 340 }}
      role="presentation"
      // onClick={toggleDrawer(false)}
    >
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        disablePadding
      >
        {constants.sideOptions.map((item, index) => (
          // <NavLink
          //   to={item.route}
          //   onClick={handleClick}
          //   className="sidebar-link"
          // >
          <ListItem
            key={index}
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "unset",
            //   cursor: "pointer",
            //   paddingRight: "unset",
            // }}
            className="side-list-item"
          >
            <ListItemButton
              style={{
                paddingLeft: "unset",
              }}
              onClick={() => navigateTo(item)}
            >
              <ListItemIcon
                style={{
                  minWidth: "46px",
                }}
              >
                <img
                  src={item.inActiveIcon}
                  alt={item.label}
                  className="sidebar-icon"
                />
              </ListItemIcon>
              <ListItemText primary={item.label} />
              {item.needExpand && (
                <>
                  {expandData[item.expandLabel] ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </>
              )}
            </ListItemButton>

            {
              <Collapse
                in={expandData[item.expandLabel]}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                  sx={{
                    ml: 2,
                  }}
                >
                  {item?.children?.length > 0 &&
                    item.children.map((subItem, subIndex) => {
                      return (
                        <>
                          <ListItemButton
                            key={subIndex}
                            onClick={() => navigateTo(subItem)}
                          >
                            <ListItemIcon
                              style={{
                                minWidth: "46px",
                              }}
                            >
                              <LayersIcon />
                            </ListItemIcon>
                            <ListItemText primary={subItem.label} />
                            {subItem.needExpand && (
                              <>
                                {expandData[subItem.expandLabel] ? (
                                  <ExpandLess />
                                ) : (
                                  <ExpandMore />
                                )}
                              </>
                            )}
                          </ListItemButton>
                          {
                            <Collapse
                              in={expandData[subItem.expandLabel]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List
                                component="div"
                                disablePadding
                                sx={{
                                  ml: 2,
                                }}
                              >
                                {subItem?.children?.length > 0 &&
                                  subItem.children.map(
                                    (inSubItem, inSubIndex) => {
                                      return (
                                        <>
                                          <ListItemButton
                                            key={inSubIndex}
                                            // sx={{ pl: 8 }}
                                            onClick={() =>
                                              navigateTo(inSubItem)
                                            }
                                          >
                                            <ListItemIcon
                                              style={{
                                                minWidth: "46px",
                                              }}
                                            >
                                              <TopicIcon />
                                            </ListItemIcon>
                                            <ListItemText
                                              primary={inSubItem.label}
                                            />
                                            {inSubItem.needExpand && (
                                              <>
                                                {expandData[
                                                  inSubItem.expandLabel
                                                ] ? (
                                                  <ExpandLess />
                                                ) : (
                                                  <ExpandMore />
                                                )}
                                              </>
                                            )}
                                          </ListItemButton>
                                          <Divider />
                                        </>
                                      );
                                    }
                                  )}
                              </List>
                            </Collapse>
                          }
                          <Divider />
                        </>
                      );
                    })}
                </List>
              </Collapse>
            }
            <Divider />
          </ListItem>
          // {/* </NavLink> */}
        ))}
      </List>
    </Box>
  );

  return (
    <Box>
      <PageHeader
        open={open}
        setOpen={setOpen}
        showAllCourses={showAllCourses}
        setShowAllCourses={setShowAllCourses}
        toggleDrawer={toggleDrawer}
      />
      {showAllCourses && (
        <section
          id="ALL_COURSES_BANNER"
          onMouseLeave={() => setShowAllCourses(false)}
          style={{ zIndex: 1200 }}
        >
          <Box className="all_courses_banner_frame">
            <Box className="all_courses_banner_explore_courses">
              <ul className="all_courses_banner_explore_courses_ul">
                <li className="all_courses_banner_explore_courses_label">
                  EXPLORE COURSES
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Trending
                </li>
                <li
                  className="all_courses_banner_explore_courses_label_2"
                  onClick={() => {
                    navigate("/trending-courses");
                  }}
                >
                  Trending Courses
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Popular
                </li>
                <li className="all_courses_banner_explore_courses_label_2">
                  Courses Certified By Aauti
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Popular Searches
                </li>
                <li className="all_courses_banner_explore_courses_label_2">
                  Popularly Searched Courses Throughout Globe
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Explore All Courses
                </li>
                <li
                  className="all_courses_banner_explore_courses_label_2"
                  onClick={() => navigate("/search-result")}
                >
                  Search For Courses You Wish For
                </li>
              </ul>
            </Box>
            <Box className="all_courses_banner_explore_courses">
              <ul className="all_courses_banner_explore_courses_ul">
                <li className="all_courses_banner_explore_courses_label">
                  CATEGORY BASED
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Popular Categories
                </li>
                <li className="all_courses_banner_explore_courses_label_2">
                  Trending Courses
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Categories We Picked For You
                </li>
                <li className="all_courses_banner_explore_courses_label_2">
                  Courses Certified By Aauti
                </li>
              </ul>
            </Box>
            <Box className="all_courses_banner_explore_courses">
              <ul className="all_courses_banner_explore_courses_ul">
                <li className="all_courses_banner_explore_courses_label">
                  TEACHERS
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Trending Teachers
                </li>
                <li className="all_courses_banner_explore_courses_label_2">
                  Trending Courses
                </li>
                <li className="all_courses_banner_explore_courses_sub_label">
                  Top Teachers
                </li>
                <li className="all_courses_banner_explore_courses_label_2">
                  Courses Certified By Aauti
                </li>
              </ul>
            </Box>
            <Box className="all_courses_banner_explore_courses">
              <ul className="all_courses_banner_explore_courses_ul">
                <li className="all_courses_banner_explore_courses_label">
                  RECENT SEARCHES
                </li>

                <li className="all_courses_banner_explore_courses_label_2">
                  Events based on your recent search
                </li>
              </ul>
            </Box>
          </Box>
        </section>
      )}
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </Box>
  );
}
