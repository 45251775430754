import React from "react";
import CourseImage from "../../globalComponents/CourseImage";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import { IoPlayCircleOutline } from "react-icons/io5";
import { BsChat, BsVolumeMute } from "react-icons/bs";
import { AiOutlineHeart } from "react-icons/ai";
import AautiText from "../../globalComponents/AautiText";
import JoinButton from "../../globalComponents/JoinButton";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { MdDeleteOutline, MdOutlineModeEditOutline } from "react-icons/md";
import ShareIcon from "../../globalComponents/icons/ShareIcon";

const iconsList = [
  { name: "like", icon: <AiOutlineHeart size={16} color="#000" />, id: 1 },
  {
    name: "comment",
    icon: <BsChat size={16} color="#000" />,
    id: 2,
  },
  {
    name: "share",
    icon: <ShareIcon noShare={true} height="18px" width="18px" />,
    id: 3,
  },

  {
    name: "edit",
    icon: <MdOutlineModeEditOutline color="#000" size={16} />,
    id: 4,
  },
  {
    name: "delete",
    icon: <MdDeleteOutline color="#000" size={16} />,
    id: 5,
  },
];

const PreviewGigCard = ({ url, isVideo }) => {
  return (
    <div
      id="gig-card"
      style={{
        width: 269,
        maxWidth: 269,
        padding: 0,
        height: 420,
        cursor: "pointer",
        border: "1px solid lightgrey",
        borderRadius: "6px",
      }}
    >
      <div style={{ width: "100%", height: "100%", position: "relative" }}>
        {isVideo ? (
          <video
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              borderRadius: 5,
            }}
            autoPlay
            muted={true}
            controls={false}
            preload="metadata"
            loop
            // onLoadStart={() => {
            //   setIsLoading(true);
            // }}
          >
            <source src={url} type="video/mp4"></source>
          </video>
        ) : (
          <CourseImage
            imgUrl={
              url ||
              "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
            }
            height="100%"
          />
        )}

        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "transparent",
            top: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              padding: 10,
              height: "10%",
            }}
          >
            {
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Tooltip title={"play"}>
                  <IconButton
                    disableTouchRipple
                    sx={{
                      bgcolor: "#FBFCFF",
                      p: 0.8,
                      "&:hover": {
                        background: "#FBFCFF",
                      },
                    }}
                  >
                    <IoPlayCircleOutline size={18} color="#000" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Mute"}>
                  <IconButton
                    disableTouchRipple
                    sx={{
                      bgcolor: "#FBFCFF",
                      p: 0.8,
                      "&:hover": {
                        background: "#FBFCFF",
                      },
                    }}
                  >
                    <BsVolumeMute size={18} color="#000" />
                  </IconButton>
                </Tooltip>
              </div>
            }
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "60%",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <div
              style={{
                // width: "100%",
                position: "absolute",
                right: 0,
                top: 0,
                display: "flex",
                // display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 5,
                padding: 10,
                height: "100%",
                justifyContent: "flex-end",
              }}
            >
              {iconsList?.map((item1, index) => {
                return item1?.name === "like" ? (
                  <Tooltip key={index} title={"add to wishlist"}>
                    <IconButton
                      id={"gig-card-wishlist"}
                      key={item1.id}
                      aria-label={item1.name}
                      sx={{
                        backgroundColor: "#FBFCFF",
                        p: 0.8,
                        "&:hover": {
                          backgroundColor: "#FBFCFF",
                        },
                      }}
                    >
                      <AiOutlineHeart size={18} color="#000" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title={item1?.name} key={index}>
                    <IconButton
                      //   id={"gig-card-wishlist"}
                      key={item1.id}
                      aria-label={item1.name}
                      sx={{
                        backgroundColor: "#FBFCFF",
                        p: 0.8,
                        "&:hover": {
                          backgroundColor: "#FBFCFF",
                        },
                      }}
                    >
                      {item1.icon}
                    </IconButton>
                  </Tooltip>
                );
              })}
            </div>
          </div>
          <div
            style={{
              paddingTop: 15,
              padding: 10,
              width: "100%",
              borderBottomLeftRadius: 5,
              borderBottomRightRadius: 5,
              backgroundImage:
                "linear-gradient(-180deg, rgba(201, 201, 201, 0) 3.070175438596502%, rgba(69, 69, 69, 0.65) 12.1885964912281%, #000000 98.24561403508774%)",
            }}
          >
            <Tooltip title={"Sample gigName"} id="gig-card-title">
              <div style={{ width: "100%" }}>
                <AautiText
                  title={"sample name"}
                  id={"gig-title"}
                  size={"normal"}
                  weight={"medium"}
                  textStyle={{
                    color: "#ffffff",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: 1,
                    textOverflow: "ellipsis",
                    maxWidth: "100%",
                    textShadow: "0px 1px 2px 4px",
                  }}
                  textAlign={"left"}
                />
              </div>
            </Tooltip>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 6,
                  alignItems: "center",
                }}
              >
                <Avatar
                  variant="circular"
                  src={""}
                  size="small"
                  sx={{ height: 24, width: 24 }}
                />
                <AautiText
                  id={"Sp-name"}
                  title={"" || "N/A"}
                  size={"small"}
                  textStyle={{ color: "#ffffff" }}
                  textAlign={"left"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 4,
                  alignItems: "center",
                }}
              >
                <VisibilityIcon fontSize="small" sx={{ color: "#fff" }} />
                <AautiText
                  title={`${0}`}
                  size={"small"}
                  textStyle={{ color: "#ffffff" }}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                marginTop: 10,
                gap: 10,
              }}
            >
              <JoinButton
                textStyle={{
                  fontSize: "14px",
                }}
                color="#ffffff"
                width={"55%"}
                height="40px"
                title={"Add to Cart"}
                outlined
                buttonStyle={{ color: "#ffffff", fontSize: 10 }}
              />

              {
                <JoinButton
                  id={"Gig-buyNow"}
                  height="40px"
                  title={"Buy Now"}
                  width="45%"
                  color="#ffffff"
                  outlined
                  textStyle={{ fontSize: 14 }}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewGigCard;
