import { ApiRequest } from "../../../service";
import { ApiMethods, ApiPaths } from "../../../service/api-constants";

/** login authentication
 * @param username, password
 */
function getUpcomingCourses(data, count, userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_UPCOMING_COURSES + "?count=" + count,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getCoursesNextEightHours(
  isUserLoggedIn,
  userId,
  onSuccess,
  onFailure
) {
  const path = isUserLoggedIn
    ? ApiPaths.GET_COURSES_NEXT_EIGHT_HOURS + "?endUserId=" + userId
    : ApiPaths.GET_COURSES_NEXT_EIGHT_HOURS;
  ApiRequest({
    url: path,

    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getFiltersData(onSuccess, onFailure) {
  ApiRequest({
    url: "filters/getAllFilters",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getFiltersByType(type, onSuccess, onFailure) {
  ApiRequest({
    url: "filters/getFiltersByType",
    method: ApiMethods.GET,
    params: { type },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
export const AuthService = {
  getUpcomingCourses,
  getFiltersData,
  getCoursesNextEightHours,
  getFiltersByType,
};
