import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { HiPlus } from "react-icons/hi";
import HelperText from "./HelperText";
import { useSelector } from "react-redux";
import "./index.scss";
import { MdEdit } from "react-icons/md";
import { courseConstant } from "./courseConstant";
import { isEmpty } from "lodash";

const AddressSelection = ({
  handleOpenAddress,
  courseAdhoc = false,
  CreateCourseObj,
}) => {
  const { userDetails } = useSelector((state) => state.app);
  const sortList = userDetails?.addressList
    ?.map((item) => ({ ...item }))
    .sort((a, b) => b.isDefault - a.isDefault);

  return (
    <div
      className="add-list"
      style={{ alignItems: isEmpty(sortList) && "center" }}
    >
      <div className="level-main" style={{ marginTop: 8 }}>
        {sortList?.slice(0, 1)?.map((each, index) => {
          return (
            <button
              key={index}
              className={`address-card ${each?.isDefault && "active-class"} ${
                courseAdhoc && "adress-card-width"
              }`}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <AautiText
                  title={each.addressType}
                  textStyle={{ color: "#2076E5" }}
                />
                <MdEdit
                  color="#2076E5"
                  onClick={() => {
                    handleOpenAddress();
                  }}
                />
              </div>
              <AautiText
                title={each?.address}
                size={"semi"}
                textStyle={{ color: "#000" }}
              />
              <AautiText
                title={`Floor: ${each?.floor}`}
                size={"semi"}
                textAlign={"left"}
                textStyle={{
                  display: !each?.floor && "none",
                  marginTop: 6,
                  color: "#000",
                }}
              />
              <AautiText
                title={`Landmark: ${each?.landMark}`}
                size={"semi"}
                textAlign={"left"}
                textStyle={{
                  display: !each?.landMark && "none",
                  marginTop: 6,
                  color: "#000",
                }}
              />

              {CreateCourseObj?.isClientLocation && (
                <AautiText
                  textStyle={{ marginTop: 2, color: "#000" }}
                  size={"small"}
                  title={`Client Location Radius: ${
                    CreateCourseObj?.radius || 0
                  } ${CreateCourseObj?.radiusType || "Km"}s`}
                />
              )}
            </button>
          );
        })}
        {userDetails?.addressList?.length <= 0 && (
          // <button
          //   className="add-plus"
          //   onClick={() => {
          //     handleOpenAddress();
          //   }}
          // >
          <HiPlus
            style={{
              width: "38px",
              height: "38px",
              borderRadius: "4px",
              backgroundColor: "#078dda",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOpenAddress();
            }}
          />
          // </button>
        )}
      </div>
      {/* <div
        className="flex-container"
        style={{ gap: 10, display: courseAdhoc ? "none" : "flex" }}
      >
        <HelperText title={courseConstant?.selectALocation} />
        <HelperText title={courseConstant?.addALocation} />
        <HelperText title={courseConstant?.pickARadiusFromTheDesiredLocation} />
        <HelperText title={courseConstant?.editLocationAndFixRadius} />
      </div> */}
    </div>
  );
};

export default AddressSelection;
