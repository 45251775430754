import React, { useState } from "react";
import AautiTextInput from "../../../globalComponents/AautiTextInput";
import AautiText from "../../../globalComponents/AautiText";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
// import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { PriceConversions } from "../../../CommonFunctions";
import { AautiStrings } from "../../../globalComponents/AautiStrings";
import { createCourseStrings } from "../Constants";
import STRINGS from "../../../globalComponents/Strings";
import HelperText from "../../courseCreation/HelperText";
import { showToast } from "../../../globalComponents/Toast";
import { checkNull } from "../../../../utils/Helpers";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { courseConstant } from "../../courseCreation/courseConstant";
import { maxDate } from "../../../globalComponents/helpers.variables";

const GigPricing = ({
  handleDate,
  gigObject,
  handleChangePricing,
  convertINRtoUSD,
  editGig,
  returnRelatedPricing,
}) => {
  const { convertPriceWithCurrency, onlyPriceConvert, onlyPriceWithCurrency } =
    PriceConversions();
  const {
    discountStartDate,
    discountEndDate,
    gigPrice,
    discountValue,
    localGigPrice,
    discountType,
    localGigDiscountValue,
  } = gigObject;
  const { pricingRates } = useSelector((state) => state.app);
  const { mobileDevices } = useSelector((state) => state.responsive);

  const convertedPrice = onlyPriceConvert(5).toFixed();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <div style={{ width: "100%" }}>
        <AautiTextInput
          important={true}
          disable={!isEmpty(editGig?.endUserList)}
          text={createCourseStrings?.price}
          value={localGigPrice || ""}
          onChange={(e) => {
            let value = Number(
              e.target.value.trimStart().replace(/\s+/g, " ") || 0
            );
            handleChangePricing(value || 0, "localGigPrice");
            handleChangePricing(convertINRtoUSD(value) || 0, "gigPrice");
          }}
          style={{ width: "100%" }}
          maxLength={STRINGS.maxPriceConstant}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 5,
          }}
        >
          <HelperText
            error={!localGigPrice || localGigPrice < convertedPrice}
            title={`minimum price should be ${onlyPriceWithCurrency(
              convertedPrice
            )}`}
            size={"small"}
            textStyle={{ fontFamily: "sans-serif" }}
          />
          <AautiText
            title={`ASP - ${onlyPriceWithCurrency(
              onlyPriceConvert(
                returnRelatedPricing("individualPrice")
              ).toFixed()
            )}`}
            size={"small"}
            fontFamily={"sans-serif"}
          />
        </div>
      </div>

      <AautiText title={createCourseStrings?.discount} weight={"medium"} />
      <div style={{ marginTop: 10, width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: mobileDevices ? "column" : "row",
            gap: 4,
          }}
        >
          {/* <div className="flex-container">
            <FormControl
              sx={{
                minWidth: mobileDevices ? "100%" : "20%",
                marginTop: 1,
              }}
              size="small"
            >
              <InputLabel id="demo-simple-select-label">
                Discount Type
              </InputLabel>
              <Select
                sx={{ width: "100%" }}
                autoWidth
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select-label"
                value={discountType == "percentage" ? 10 : 20}
                label={"Discount Type"}
                onChange={(event) => {
                  if (event.target.value == 10) {
                    // onChange={() => {
                    handleChangePricing(0, "gigDiscountValue");
                    handleChangePricing(0, "localGigDiscountValue");
                    handleChangePricing("percentage", "discountType");
                    // }}
                    // setCourseBatch((prev) => ({
                    //   ...prev,
                    //   discountType: "Percentage",
                    // }));
                    // onChangeAddPrice(
                    //   "",
                    //   activeBatchType === "Group"
                    //     ? "groupDiscountValue"
                    //     : "individualDiscountValue"
                    // );
                    // onChangeAddPrice(
                    //   "",
                    //   activeBatchType === "Group"
                    //     ? "localGroupDiscountValue"
                    //     : "localIndividualDiscountValue"
                    // );
                  } else if (event.target.value == 20) {
                    handleChangePricing(0, "gigDiscountValue");
                    handleChangePricing(0, "localGigDiscountValue");
                    handleChangePricing("amount", "discountType");
                    // setCourseBatch((prev) => ({
                    //   ...prev,
                    //   discountType: "Amount",
                    // }));
                    // onChangeAddPrice(
                    //   "",
                    //   activeBatchType === "Group"
                    //     ? "groupDiscountValue"
                    //     : "individualDiscountValue"
                    // );
                    // onChangeAddPrice(
                    //   "",
                    //   activeBatchType === "Group"
                    //     ? "localGroupDiscountValue"
                    //     : "localIndividualDiscountValue"
                    // );
                  }
                }}
              >
                <MenuItem value={10}>Percentage</MenuItem>
                <MenuItem value={20}>Amount</MenuItem>
              </Select>
            </FormControl>
          </div> */}
          <div style={{ width: "100%" }}>
            <AautiTextInput
              disable={!localGigPrice || localGigPrice < convertedPrice}
              text={`Discount ${
                discountType == "percentage" ? "Percentage" : "Amount"
              }`}
              maxLength={discountType == "percentage" && 2}
              style={{ width: "100%" }}
              value={localGigDiscountValue || ""}
              onChange={(e) => {
                let value = Number(
                  e.target.value.trimStart().replace(/\s+/g, " ") || 0
                );
                const minValue = localGigPrice;

                const discountedPrice = (minValue * value) / 100;
                const actPriceWithDic = minValue - discountedPrice;

                if (discountType == "percentage") {
                  if (actPriceWithDic >= convertedPrice) {
                    handleChangePricing(value || 0, "gigDiscountValue");
                    handleChangePricing(value || 0, "localGigDiscountValue");
                  } else {
                    showToast(
                      "info",
                      "Discount price cannot be less than the minimum price"
                    );
                  }
                } else {
                  if (minValue - value >= Number(convertedPrice)) {
                    handleChangePricing(
                      convertINRtoUSD(value || 0),
                      "gigDiscountValue"
                    );
                    handleChangePricing(value || 0, "localGigDiscountValue");
                  } else {
                    showToast(
                      "info",
                      "Discount price can't be more than minimum price"
                    );
                  }
                }
              }}
            />
          </div>
        </div>

        <AautiText
          title={courseConstant?.selectDiscountValidDates}
          //   required={true}
          //   weight={"bold"}
          size={"normal"}
          textStyle={{ marginTop: 10 }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            gap: 5,
          }}
        >
          <div style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DemoItem label={AautiStrings?.StartDateString}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    defaultValue={dayjs(discountStartDate)}
                    size="small"
                    value={dayjs(discountStartDate)}
                    onChange={(e) => {
                      handleDate(e, "discountStartDate");
                    }}
                    minDate={dayjs(new Date())}
                    maxDate={dayjs(maxDate)}
                    disabled={checkNull(localGigDiscountValue)}
                    slotProps={{ field: { readOnly: true } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
          <div style={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateField"]}>
                <DemoItem label={AautiStrings?.EndDateString}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    defaultValue={dayjs(discountEndDate)}
                    size="small"
                    value={dayjs(discountEndDate)}
                    onChange={(e) => {
                      handleDate(e, "discountEndDate");
                    }}
                    minDate={dayjs(discountStartDate)}
                    maxDate={dayjs(maxDate)}
                    disabled={checkNull(localGigDiscountValue)}
                    slotProps={{ field: { readOnly: true } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GigPricing;
