export const adhocSlotSelectionStrings = {
  classType: 'Class Type',
  expertLevel: 'Expert Levels',
  virtual: 'Virtual',
  inPerson: 'In-Person',
  courseType: 'Course Type',
  noSlotsAvailable: 'No Slots Available',
  alreadyPurchasedThisSession: 'You have already purchased this slot',
  buyNow: 'Buy Now',
  forMoreDetailsContactSP: 'For more offers connect with tutors directly',
  languages: 'Languages',
  selectDayForSlots: 'Select a date for slots',
  BESTOFFERSFORYOU: "BEST OFFERS FOR YOU",
  CancellationPolicy: "Cancellation Policy",
  DurationBeforeClassStarts: "Duration Before Class Starts",
  ofRefund: "% of Refund",
  Thissessiondoesnothaveanyrefund: "This session does not have any refund",
  zeroKM: "0 KM",
  fiftyKM: "50 KM",
  Classfor: "Class for",
  Apply: "Apply",
  Alreadyboughtfor: "Already bought for :",
  SelectedSlots: "Selected Slots",
  Selectadateforslots: "Select a date for slots",
  Reviews: "Reviews ",
  Remove: "Remove",
  Thispackagedoesnothaveanyrefund: "This package does not have any refund",
  RefundPolicy: "Refund Policy",
}
export const spStrings = {
  NA: "N/A",
  Education: "Education",
  Recentlyviewed: "Recently Viewed",
  Writeyourreview: 'Write your review',
  Addareview: 'Add a Review',
  Update: "Update",
  Submit: "Submit",
  Follow: "Follow",
  Students: "Students",
  Following: "Following",
  Speciality: "Speciality",
  Skillset: "Skillset",
  Courses: "Courses",
  Ratings: "Ratings",
  AllReviews: "All Reviews",
  OverallReviews: "Overall Reviews",
  Reply: "Reply",
  Reviews: "Review",
  NoDataFound: 'No Data Found',
  ReportServiceprovider: "Report Service provider",
  Telluswhatcanbeimproved: "Tell us what can be improved?",
  editReview: "Edit Review"
}
