import { Box } from "@mui/material";

import {
  AautLogoWhite,
  FacebookLogo,
  InstagramLogo,
  TwitterLogo,
} from "../container";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <section id="FOOTER">
        <Box className="footer_container">
          <Box className="footer_logo_container">
            <AautLogoWhite />
            <Box className="footer_logo_text">
              <p className="footer_logo_text_label">
                Aauti is an online platform connecting service providers and end
                users for buying and selling courses. It offers a user-friendly
                experience, featuring live sessions for courses and pre-recorded
                videos called Gigs. Users can purchase and manage their courses,
                orders, and payments
              </p>
            </Box>
          </Box>
          <Box className="footer_about_follow">
            <Box className="footer_about_aauti">
              <ul className="footer_about_aauti_ul">
                <li className="footer_about_aauti_label_head">Company</li>
                <li className="footer_about_aauti_label">About Us</li>
                <li className="footer_about_aauti_label">Contact Us</li>
                <li className="footer_about_aauti_label">History</li>
                <li
                  className="footer_about_aauti_label"
                  onClick={() => navigate("/privacypolicy")}
                >
                  Privacy Policy
                </li>
                <li
                  className="footer_about_aauti_label"
                  onClick={() => navigate("/termsandconditions")}
                >
                  Terms & Conditions
                </li>
              </ul>
            </Box>
            <Box className="footer_follow_aauti">
              <Box className="footer_follow_socials">
                <p className="footer_follow_socials_label">Follow Us :</p>
                <Box className="footer_follow_socials_icons">
                  <Box className="footer_follow_socials_icon">
                    <FacebookLogo />
                  </Box>
                  <Box className="footer_follow_socials_icon">
                    <InstagramLogo />
                  </Box>
                  <Box className="footer_follow_socials_icon">
                    <TwitterLogo />
                  </Box>
                </Box>
              </Box>
              <Box className="footer_follow_socials">
                <p className="footer_follow_socials_label">Contact :</p>
                <Box className="footer_follow_socials_icons">
                  <p
                    className="footer_contact_text"
                  >
                    support@aauti.com
                  </p>
                </Box>
              </Box>
              <Box className="footer_follow_socials">
                <p
                  className="footer_follow_socials_label"
                  style={{ width: 120 }}
                >
                  Address :
                </p>
                <Box className="footer_follow_socials_icons">
                  <p
                    className="footer_contact_text"
                    style={{ marginTop: 25, fontSize: 14, textAlign: "left" }}
                  >
                    7924 PRESTON RD STE 350 PLANO, TX 75024
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="footer_copyright">
          <p className="footer_copyright_label">
            © Aauti, Edu. 2019. We love our users!
          </p>
        </Box>
      </section>
    </>
  );
}
