import { useEffect, useState } from "react";
// import "./ScrollToTop";
import AautiText from "./AautiText";
import JoinButton from "./JoinButton";
import { Drawer, Skeleton } from "@mui/material";
import AautiDialog from "./AautiDialog";
import { useSelector } from "react-redux";
import { AuthService } from "../serviceProviderDetails/service";
import { showToast } from "./Toast";
import AautiTextInput from "./AautiTextInput";
import "./styles.scss";
import { globalColors } from "../../themes/GlobalColors";
import { IoIosArrowRoundBack } from "react-icons/io";
const RWDDialog = ({ RWD, complaintAPI, handleClose, SetReportValue, reportValue, reportID,
  reportSkeleton, reportReason, addValue, screenName, disableBtn, aboveLargeMobileDevices, mobileDevices
}) => {
  return (
    <>
      {reportSkeleton ? (
        <div style={{ padding: 10 }}>
          <Skeleton
            variant="rounded"
            sx={{ width: RWD ? '100%' : "80%", height: 30, margin: RWD ? '0px 0px 10px 0px ' : "20px 0px" }}
          />
          <div className="aautiReport-div">
            {Array.from({ length: 20 }).map((each) => {
              return (
                <Skeleton variant="rounded" sx={{ width: 90, height: 28 }} />
              );
            })}
            <Skeleton
              variant="rounded"
              sx={{ width: "100%", height: 40, margin: "20px 0px" }}
            />
          </div>
        </div>
      ) : (
        <div className="ReportDialogDivRR"
          style={{
            display: 'flex', flexDirection: "column",
            justifyContent: RWD && "space-between",
            padding: RWD ? "0px 10px" : 0
          }}>
          <div style={{
            display: 'flex', flexDirection: 'column',
            height: RWD && "78vh",
            overflow: 'scroll'
          }}>
            <AautiText
              weight={"medium"}
              title={"We won’t let the person know who is reported them"}
              size={"normal"}
              textStyle={{ color: globalColors.HEADING_COLOR }}
              required
            />
            <div className="SPnewreportDiv" style={{ margin: '10px 0px 5px 0px', maxHeight: '40vh', overflow: "scroll" }}>
              {reportReason?.map((each, index) => (
                <div
                  className="SPnewReportSelect"
                  key={index}
                  style={{
                    border: !reportID?.includes(each) ? "1px solid #E0E0E0" : `1px solid ${globalColors.AAUTI_THEME_COLOR}`,
                    backgroundColor: reportID?.includes(each)
                      ? globalColors.AAUTI_THEME_COLOR
                      : globalColors.BackGround_Ligh_Color,
                  }}
                  onClick={() => {
                    addValue(each);
                  }}
                >
                  <AautiText
                    weight={'normal'}
                    title={each?.name}
                    size={"semi"}
                    textStyle={{
                      color: reportID?.includes(each) ? "#ffffff" : globalColors.HEADING_COLOR,
                    }}
                  />
                </div>
              ))}
            </div>
            <AautiTextInput
              style={{ width: "100%", margin: "0px 0px 20px 0px" }}
              text={"Reason"}
              multiline
              rows={3}
              value={reportValue}
              important
              maxLength={300}
              helperText={`${reportValue?.length}/300 (minimum 15 characters)`}
              onChange={(e) => {
                SetReportValue(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end", columnGap: 15
            }}
          >
            <JoinButton outlined
              onClick={() => {
                handleClose();
              }}
              buttonStyle={{ width: 100, height: 40 }}
              title={"Cancel"}
              color={globalColors.AAUTI_THEME_COLOR}
            />
            <JoinButton
              disabled={
                screenName === "Report Chat"
                  ? reportValue?.length < 15
                  : disableBtn()
              }
              onClick={() => {
                handleClose();
                complaintAPI();
              }}
              buttonStyle={{ width: 100, height: 40 }}
              title={"Submit"}
            />
          </div>
        </div>
      )}
    </>
  )
}
const AautiReport = ({
  reportType,
  serviceProviderId,
  endUserId,
  gigId,
  courseId,
  sessionId,
  classId,
  screenName,
  open,
  setOpen, 
  getGigDetails,
  chatRoomId,
}) => {
  const [reportValue, SetReportValue] = useState("");
  const [reportID, selectReportID] = useState([]);
  const [reportSkeleton, setReportSkeleton] = useState("");
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [reportReason, setReportReason] = useState(null);
  const {
    mobileDevices,
    aboveLargeMobileDevices,
    ipodDevices,
    miniLapys,
    lapy,
  } = useSelector((state) => state.responsive);
  useEffect(() => {
    handleReport(screenName);
    setReportSkeleton(true);
  }, []);

  const complaintAPI = () => {
    if (screenName === "Report Chat") {
      const chatReportData = {
        userId: loggedinUserId,
        description: reportValue,
        roomId: chatRoomId,
      };
      return AuthService.reportInChatRoom(
        chatReportData,
        onSuccessReportChat,
        onFailure
      );
    }
    const complaintDataList = {
      description: reportValue,
      userName: userDetails?.displayName
        ? userDetails?.displayName
        : userDetails?.firstName,
      userId: loggedinUserId,
      courseId:
        screenName === "Report Course" || screenName === "Report Issue"
          ? courseId
          : null,
      gigId: screenName === "Report Gig" ? gigId : null,
      sessionId:
        screenName === "Report Issue" || screenName === "Report Issue"
          ? sessionId
          : null,
      serviceProviderId:
        screenName === "Report Service Provider" ||
          screenName === "Report Issue"
          ? serviceProviderId
          : null,
      endUserId: screenName === "Report User" ? endUserId : null,
      status: "reported",
      type: reportID,
    };

    AuthService.complaintSave(
      complaintDataList,
      onSuccessComplaintPost,
      onFailure
    );
  };

  const onSuccessComplaintPost = (response) => {
    if (response?.data?.status === "Success") {
      showToast("success", response?.data?.message);
      if (screenName === "Report Gig") {
        getGigDetails();
      }
    } else {
      showToast("info", response?.message);
    }
  };
  const onSuccessReportChat = (response) => {
    if (response?.data.status === "success") {
      showToast("success", response?.data?.message);
    } else {
      showToast("error", response?.message);
    }
  };
  const addValue = (newValue) => {
    if (reportID?.includes(newValue)) {
      const r = reportID?.filter((each, index) => {
        return each !== newValue;
      });
      selectReportID(r);
    } else {
      selectReportID([...reportID, newValue]);
    }
  };
  const handleClose = () => {
    setOpen(!open);
    selectReportID([]);
    SetReportValue("");
  };
  const getReportTypesAPI = (type) => {
    AuthService.getReports(type, onSuccessReport, onFailure);
  };

  function handleReport(screenName) {
    switch (screenName) {
      case "Report User":
        return getReportTypesAPI("End User");
      case "Report Service Provider":
        return getReportTypesAPI("Service Provider");
      case "Report Course":
        return getReportTypesAPI("Course");
      case "Report Issue":
        return getReportTypesAPI("Class EU");
      case "Report Gig":
        return getReportTypesAPI("Gig");
      case "Report Chat":
        return getReportTypesAPI("Report Chat");
      default:
        return null;
    }
  }
  function handleReportTitle(screenName) {
    switch (screenName) {
      case "Report User":
        return "Report End User";
      case "Report Service Provider":
        return "Report Service Provider";
      case "Report Course":
        return "Report Course";
      case "Report Issue":
        return "Report Issue";
      case "Report Gig":
        return "Report Gig";
      case "Report Chat":
        return "Report Group";
      default:
        // Handle unexpected values of screenName
        return null; // or throw an error, or handle the case differently
    }
  }
  const onSuccessReport = (response) => {
    if (response?.status === "Success") {
      setReportSkeleton(false);
      setReportReason(response?.result);
    } else {
      showToast("error", response?.message);
      setReportSkeleton(false);
    }
  };
  //
  const onFailure = (error) => {
    setReportSkeleton(false);
  };
  const disableBtn = () => {
    return reportValue?.length > 14 && reportID?.length > 0 ? false : true;
  };
  const RWD = mobileDevices || aboveLargeMobileDevices
  return (
    <>{RWD ?
      <Drawer
        anchor="bottom"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            borderRadius: "10px 10px 0px 0px",
            // zIndex: 502,
            height: "96vh",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center", flexDirection: 'column'
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: RWD ? "10px 0px 10px 5px" : 0,
              marginBottom: 10,
              alignItems: "center", flexDirection: 'row', borderBottom: '1px solid #5C5B5733', columnGap: 10
            }}
          >  <IoIosArrowRoundBack
              onClick={() => handleClose()}
              size={25}
              style={{ cursor: "pointer", color: '#000000' }}
            />
            <AautiText
              weight={'bold'}
              title={handleReportTitle(screenName)}
              textStyle={{ color: globalColors.HEADING_COLOR, fontSize: "18px" }}
            />
          </div>
          <RWDDialog {...{
            RWD,
            complaintAPI, handleClose, SetReportValue, reportValue, reportID,
            reportSkeleton, reportReason, addValue, screenName, disableBtn, aboveLargeMobileDevices, mobileDevices
          }} />
        </div>
      </Drawer>
      :
      <AautiDialog
        titlePosition="left"
        dialogTitle={handleReportTitle(screenName)}
        open={open}
        onClose={(

        ) => handleClose()}
      >
        <RWDDialog {...{
          RWD,
          complaintAPI, handleClose, SetReportValue, reportValue, reportID,
          reportSkeleton, reportReason, addValue, screenName, disableBtn, aboveLargeMobileDevices, mobileDevices
        }} />

      </AautiDialog>
    }
    </>
  );
};

export default AautiReport;
