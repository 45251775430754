import AautiText from "../globalComponents/AautiText";
import JoinButton from "../globalComponents/JoinButton";
import CauroselList from "../globalComponents/caurosel/CauroselList";
import GigCard from "../appCards/GigCard";
import { isEmpty } from "lodash";
import AautiReport from "../globalComponents/AautiReport";
import { IoMdStar } from "react-icons/io";
import { IoShareOutline } from "react-icons/io5";
import ShareIcon from "../globalComponents/icons/ShareIcon";
import { IoMdHeartEmpty } from "react-icons/io";
import { FaAnglesRight, FaHeart } from "react-icons/fa6";
import { AiOutlinePlayCircle } from "react-icons/ai";
import UserCard from "../course/courseDetails/UserCard";
import "./index.scss";
import { useEffect, useRef } from "react";
import PreviewContainer from "./PreviewContainer";
import gigStrings from "./gigStrings";
import CourseRatingDialog from "../globalComponents/CourseRatingDialog";
import CourseDeatilsStrings from "../course/courseDetails/CourseDeatilsStrings";
import { useState } from "react";
import AautiDialog from "../globalComponents/AautiDialog";

import PackageDetailsLoader from "../home/package/PackageDetailsLoader";
import { Avatar, Box, Drawer, IconButton, Tooltip } from "@mui/material";
import ReturnPolicy from "../conditions/ReturnPolicy";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveGigObject,
  setSelectedCategory,
  toggleSignInPop,
} from "../../redux/reducer/appReducer";
import moment from "moment";
import { MdForward10, MdReplay10 } from "react-icons/md";

import CourseImage from "../globalComponents/CourseImage";
import GigRatings from "./GigRatings";
import MappedCategories from "../globalComponents/MappedCategories";
import { BiRightArrowAlt } from "react-icons/bi";
import CourseReview from "../ratingReviews/CourseReviews";
import { FaCheck } from "react-icons/fa";
import { globalColors } from "../../themes/GlobalColors";
import WishIcon from "../globalComponents/icons/WishIcon";
import GigRightContainer from "./gigRightContainer";
import ReactPlayer from "react-player";
import GigComments from "./gigComments/GigComments";
import { showToast } from "../globalComponents/Toast";
import { FaAngleRight } from "react-icons/fa6";
import BottomSheet from "../globalComponents/BottomSheet";
import { RxCrossCircled } from "react-icons/rx";
import { convertTimeToSeconds } from "../CommonFunctions";

const GigPreview = () => {
  const {
    completerDetails,
    handleWishlist,
    handleCart,
    handleSimilarArrows,
    gigData,
    handleSimilarArrows2,
    handleSimilarArrows3,
    handleSimilarArrows4,
    otherRef,
    ratingRef,
    catRef,
    subCatSettings,
    buyNow,
    slideSettings,
    open,
    similarGigs,
    showReportForm,
    gigDetails,
    similarRef,
    navigate,
    mobileDevices,
    aboveLargeMobileDevices,
    miniLapys,
    ipodDevices,
    lapy,
    loggedinUserId,
    userGigs,
    setOpen,
    similarSubCat,
    setShowReportForm,
    getGigDetails,
    visible1,
    setVisible1,
    suggestionsArray,
    setSuggestionsList,
    suggestionsList, 
    ratingAPI,
    orderID,
    setOrderID,
    setRatingValue,
    ratingValue,
    setReviewComment,
    reviewComment,
    gigReviews,
    detailsLoading,
    getRating,
    setDetailsLoading,
    childDetails,
    setChildDetails,
    childs,
    selectableUsers,
    getServiceProviderGigs,
    list,
    filteredUserGigs,
    disabledLeftButton,
    disabledRight,
    slideSettings2,
    disabledLeftButton2,
    disabledRight2,
    filteredSimilarGigs,
    slideSettings3,
    getSimilarGigs,
    setRefresh,
    returnTags,
    disabledLeftSubCat,
    disabledRightSubCat,
    formatTime,
    defaultVideo,
    setDefaultVideo,
    checkisInCart,
  } = PreviewContainer();

  const smallDevices = mobileDevices || ipodDevices || aboveLargeMobileDevices;

  // const [gigPlay, setGigPlay] = useState(null);
  const { isUserLoggedIn, isParent, userDetails } = useSelector(
    (state) => state.app
  );
  const dispatch = useDispatch();
  // const [gigUrl, setGigUrl] = useState("");
  const [refundPolicyState, setRefundPolicyState] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [onCloseRefresh, setOncloseRefresh] = useState(false);
  const [showReplayButton, setShowReplayButton] = useState(false);

  const path = window.location.origin;
  const shareUrl = `${path}/gig-preview/${gigDetails?._id}`;

  const filteredList = list?.filter((each) => {
    const adjustedCount =
      each?.subCategoryName == gigDetails?.gig?.subCategory
        ? each?.count - 1
        : each?.count;
    return adjustedCount > 0;
  });
  const list2 = gigDetails?.gig?.subCategory ? filteredList : list;
  const videopopRef = useRef();
  const videoRef = useRef();
  const leftContainerRef = useRef();
  document.querySelector("video")?.setAttribute("controlslist", "nodownload");

  useEffect(() => {
    if (videopopRef.current) {
      document
        .querySelector("video")
        .setAttribute("controlslist", "nodownload");
    }
    if (videoRef.current) {
      videopopRef?.current?.play();
    }
  }, [defaultVideo]);
  const handleVideoEnd = () => {
    videoRef.current.pause();
    setShowReplayButton(true);
  };
  const base = {
    serviceproviderId: gigDetails?.gig?.serviceProviderId,
    gigId: gigDetails?._id,
  };

  const baseCat = {
    category: [gigDetails?.gig?.category],
    id: gigDetails?._id,
    spList: [gigDetails?.gig?.serviceProviderId],
  };

  const handleSelectUser = (item, index) => {
    let tempItem = { _id: item?._id };
    const isSelected = childDetails?.filter((item2) => item2?._id == item?._id);
    let newArray = childDetails;
    if (isSelected?.length > 0) {
      newArray = newArray?.filter((item) => item?._id != tempItem?._id);
    } else {
      newArray = [...newArray, tempItem];
    }
    setChildDetails(newArray);
  };

  const hasChilds = (each) => {
    if (each?.leftContent == "Purchase For") {
      if (each?.rightData?.length > 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleReplay = () => {
    const video = videoRef?.current;
    video.currentTime = 0;
    video.play();
    setShowReplayButton(false);
  };

  const buyNowAndCartVal = () => {
    if (isParent && childDetails?.length == 0 && !gigDetails?.addedToCart) {
      return true;
    } else if (!isParent && gigDetails?.isPurchased) {
      return true;
    } else {
      return false;
    }
  };

  const buyNowVal = () => {
    if (isParent && childDetails?.length == 0) {
      return true;
    } else if (!isParent && gigDetails?.isPurchased) {
      return true;
    } else {
      return false;
    }
  };

  const isChild = userDetails?.parentId;

  return (
    <div className="gig-details">
      {detailsLoading ? (
        <PackageDetailsLoader {...{ ipodDevices, mobileDevices }} />
      ) : (
        <div
          className="gigPreview-div1"
          style={{
            display: mobileDevices || ipodDevices ? "contents" : "row",
          }}
        >
          <div
            ref={leftContainerRef}
            className="gigPreview-div2"
            style={{
              width: mobileDevices || ipodDevices ? "98%" : "65%",
              paddingRight: !(mobileDevices || ipodDevices) && "3%",
            }}
          >
            <div
              style={{ width: "100%", position: "relative" }}
              className="hover-main"
            >
              <video
                className="gigPreview-img1"
                autoPlay={false}
                ref={videoRef}
                zoom
                src={
                  gigDetails?.isPurchased
                    ? gigDetails?.gig?.gigVideos[defaultVideo]?.videoUrl
                    : gigDetails?.gig?.shortVideoUrl
                }
                muted={true}
                controls={true}
                // onended={() => {
                //   alert("video ended");
                //   videoRef
                // }}
                preload="metadata"
                // loop
                style={{
                  width: "100%",
                  // height: "100%",
                  height: mobileDevices ? 200 : 400,
                  objectFit: "contain", //cover
                }}
              />
              <button
                className="hover-me"
                style={{
                  position: "absolute",
                  top: "40%",
                  left: "25%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime -= 10;
                  }
                }}
              >
                <MdReplay10 size={25} />
              </button>

              {showReplayButton && (
                <button
                  onClick={handleReplay}
                  style={{
                    display: "block",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "10px 20px",
                    backgroundColor: "blue",

                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  Replay
                </button>
              )}
              <button
                className="hover-me"
                onClick={() => {
                  if (videoRef.current) {
                    videoRef.current.currentTime += 10;
                  }
                }}
                style={{
                  position: "absolute",
                  top: "40%",
                  right: "25%",
                  cursor: "pointer",
                }}
              >
                <MdForward10 size={25} />
              </button>
            </div>

            <AautiText
              title={gigDetails?.gig?.gigName}
              id={"gig-preview-title"}
              size={"medium"}
              weight={"bold"}
              textStyle={{
                color: globalColors?.HEADING_COLOR,
              }}
            />
            <AautiText
              title={gigDetails?.gig?.gigDescription}
              className={"gigPreview-text1"}
              id={"gig-description"}
              size={"normal"}
            />

            {/* {returnTags()} */}

            <div className="gigPreview-div4">
              <div className="gigPreview-div5">
                <div className="gigPreview-div7" onClick={() => setOpen2(true)}>
                  <IoMdStar className="gigPreview-icon1" />
                  <AautiText
                    size={"semi"}
                    className={"allCard-text2"}
                    title={
                      !gigDetails?.gig?.averageRating
                        ? "New"
                        : `${
                            gigDetails?.gig?.averageRating?.toFixed(1) || 0
                          } (${gigReviews?.totalRatings || 0})`
                    }
                  />
                  {gigReviews?.averageRating && <FaAngleRight color="#fff" />}
                </div>
              </div>
              <div className="gigPreview-div5" style={{ gap: 5 }}>
                <WishIcon
                  active={gigDetails?.isWishlisted}
                  height="25px"
                  width="25px"
                  onClick={() => {
                    if (isUserLoggedIn) {
                      handleWishlist();
                    } else {
                      localStorage.setItem(
                        "prevPath",
                        `/course-details/${gigDetails?._id}`
                      );
                      navigate("/login");
                    }
                  }}
                />
                <ShareIcon height="25px" width="25px" shareUrl={shareUrl} />
              </div>
            </div>

            {(mobileDevices || ipodDevices) && (
              // <h1>Hello</h1>
              <GigRightContainer
                {...{
                  gigData,
                  hasChilds,
                  handleSelectUser,
                  isChild,
                  buyNowAndCartVal,
                  handleCart,
                  gigDetails,
                  completerDetails,
                  isParent,
                  buyNowVal,
                  childDetails,
                  checkisInCart,
                }}
              />
            )}

            <AautiText
              title={gigStrings?.gigContent}
              size={"medium"}
              weight={"bold"}
            />
            {gigDetails?.gig?.gigVideos?.map((each, index) => (
              <div className="gigPreview-div8">
                <div
                  onClick={() => {
                    if (gigDetails?.isPurchased) {
                      // setGigUrl(each?.videoUrl);

                      // setGigPlay(each);
                      if (videoRef.current) {
                        setDefaultVideo(index);
                        videoRef.current.play();
                        leftContainerRef.current.scrollTo({
                          top: -Infinity,
                          behavior: "smooth",
                        });
                      }
                    } else {
                      setDefaultVideo(index);
                      showToast("info", "Subscribe to View Content");
                    }
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    gap: 10,
                    marginBottom: 10,
                    cursor: "pointer",
                  }}
                >
                  <AiOutlinePlayCircle size={30} />
                  <AautiText
                    title={each?.videoTitle}
                    size={"normal"}
                    // className={"gigPreview-text5"}
                    weight={"bold"}
                  />
                </div>
                {index == defaultVideo && (
                  <div
                    style={{ gap: 5, display: "flex", flexDirection: "column" }}
                  >
                    {each?.topics?.map((topic) => {
                      return (
                        <div
                          key={index}
                          className="topic-name-container"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            cursor: "pointer",
                            alignItems: "flex-start",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                          onClick={() => {
                            if (gigDetails?.isPurchased) {
                              const startTimeInSeconds = convertTimeToSeconds(
                                `${topic?.hours}:${topic?.minutes}:${topic?.seconds}`
                              );
                              if (videoRef.current) {
                                videoRef.current.currentTime =
                                  startTimeInSeconds;
                                videoRef.current.play();
                              }
                              leftContainerRef.current.scrollTo({
                                top: -Infinity,
                                behavior: "smooth",
                              });
                            } else {
                              showToast("info", "Subscribe to View Content");
                            }
                          }}
                        >
                          <img
                            src={gigDetails?.gig?.gigImage}
                            alt="topic-img"
                            style={{
                              width: 53,
                              height: 38,
                              borderRadius: "5px",
                            }}
                          />
                          <div style={{ overflow: "hidden" }}>
                            <AautiText
                              title={topic?.topicName}
                              size={"semi"}
                              className={"gigPreview-text6"}
                              textStyle={{
                                width: "100%",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 1,
                                overflow: "hidden",
                              }}
                            />
                            <AautiText
                              title={formatTime({
                                hour: topic?.hours,
                                minutes: topic?.minutes,
                                seconds: topic?.seconds,
                              })}
                              size={"small"}
                              textStyle={{
                                color: globalColors?.AAUTI_THEME_COLOR,
                              }}
                              className={"gigPreview-text6-time"}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ))}
            <div className="gigPreview-div8">
              <AautiText
                title={gigStrings?.whatWillLearn}
                size={"normal"}
                weight={"bold"}
                className={"gigPreview-text7"}
              />
              <AautiText
                title={gigDetails?.gig?.summary}
                size={"semi"}
                className={"gigPreview-text8"}
              />
              <div className="gigPreview-div9">
                {gigDetails?.isPurchased &&
                  loggedinUserId !== gigDetails?.gig?.serviceProviderId && (
                    <AautiText
                      title={
                        gigDetails?.alreadyReviewed
                          ? "View Reviews"
                          : gigStrings?.addReview
                      }
                      weight={"bold"}
                      className={"detailsLeft-text9"}
                      onClick={() => {
                        gigDetails?.alreadyReviewed
                          ? setOpen2(true)
                          : setVisible1(true);
                      }}
                    />
                  )}

                {gigDetails?.isPurchased &&
                  loggedinUserId &&
                  loggedinUserId !== gigDetails?.gig?.serviceProviderId && (
                    <AautiText
                      title={gigStrings?.reportGig}
                      size={"normal"}
                      weight={"bold"}
                      onClick={() => {
                        setShowReportForm(true);
                        setOpen(!open);
                      }}
                      className={"gigPreview-text9"}
                    />
                  )}
              </div>
              {showReportForm && (
                <AautiReport
                  gigId={gigDetails?._id}
                  screenName="Report Gig"
                  open={open}
                  setOpen={setOpen}
                  getGigDetails={getGigDetails}
                />
              )}
            </div>

            <GigComments gigObject={gigDetails?.gig} details={true} />
            <div className="cancellation-polict-container-previc">
              <AautiText
                title={"Cancellation Policy"}
                size={"normal"}
                weight={"bold"}
                textStyle={{
                  marginRight: 15,
                  color: globalColors?.HEADING_COLOR,
                }}
              />
              <AautiText
                size={"semi"}
                className={"gigPreview-text8"}
                title={
                  "Cancel up to within 24 hours before the start time for full refund."
                }
              />
              <AautiText
                title={gigStrings?.refundPolicy}
                onClick={() => {
                  setRefundPolicyState(true);
                }}
                size={"normal"}
                weight={"bold"}
                textAlign={"right"}
                className={"gigPreview-text9"}
              />
            </div>
            <div
              className="detailsLeft-div12"
              style={{
                display:
                  (isEmpty(gigReviews) || gigReviews?.reviews?.length < 1) &&
                  "none",
                width: mobileDevices ? "98%" : "99%",
              }}
            >
              {gigReviews?.reviews?.length > 0 && (
                <div className="overview-container3-details">
                  <GigRatings
                    {...{
                      ratings: gigReviews,
                      slideSettings: slideSettings3,
                      completeDetails: gigDetails,
                      handleSliderDirec: handleSimilarArrows4,
                      reference: ratingRef,
                      open: open2,
                      setOpen: setOpen2,
                      disabledLeftButton,
                      getRating,
                    }}
                  />
                </div>
              )}
            </div>

            <div
              className="gigPreview-div10"
              style={{ display: filteredUserGigs?.length < 1 && "none" }}
            >
              <div className="gigPreview-div11">
                <AautiText
                  title={`${gigStrings?.otherGigs} ${gigDetails?.gig?.serviceProviderDisplayName}`}
                  size={"medium"}
                  weight={"extraBold"}
                  className={"gigPreview-text10"}
                />

                <AautiText
                  onClick={() => {
                    navigate("/similar-gigs", { state: base });
                  }}
                  title={gigStrings?.viewAll}
                  size={"semi"}
                  className={"gigPreview-text11"}
                  textStyle={{
                    display:
                      filteredUserGigs?.length <
                        (mobileDevices
                          ? 2
                          : ipodDevices
                          ? 3
                          : miniLapys || lapy
                          ? 4
                          : 4) && "none",
                  }}
                />
              </div>
              {filteredUserGigs?.length <
              (mobileDevices
                ? 2
                : ipodDevices
                ? 3
                : miniLapys
                ? 3
                : lapy
                ? 4
                : 4) ? (
                <div className="gigPreview-div12">
                  {filteredUserGigs?.map((item, index) => (
                    <GigCard
                      cardWidth={mobileDevices ? "98%" : "269px"}
                      height={460}
                      key={index}
                      {...{
                        each: item,
                      }}
                      getGigsList={() =>
                        getServiceProviderGigs(
                          gigDetails?.gig?.serviceProviderId
                        )
                      }
                    />
                  ))}
                </div>
              ) : isEmpty(filteredUserGigs) ? (
                <div className="gigPreview-div13">
                  <AautiText title={gigStrings?.noGigsAvailable} />
                </div>
              ) : (
                <CauroselList
                  arrowsWidth={"92%"}
                  settings={slideSettings}
                  sliderRef={similarRef}
                  handleSliderDirec={handleSimilarArrows}
                  disabledLeftButton={disabledLeftButton}
                  disabledRight={disabledRight}
                  style={{ width: "100%" }}
                  showButtons={!mobileDevices}
                >
                  {filteredUserGigs?.map((item, index) => (
                    <GigCard
                      cardWidth={"96.5%"}
                      height={460}
                      key={index}
                      getGigsList={() =>
                        getServiceProviderGigs(
                          gigDetails?.gig?.serviceProviderId
                        )
                      }
                      {...{
                        each: item,
                      }}
                    />
                  ))}
                </CauroselList>
              )}
            </div>
            {!isEmpty(similarSubCat) && !isEmpty(list2) && (
              <div className="overview-container-gig-details">
                <div className="title-view-all-cont-gig">
                  <AautiText
                    title={gigStrings?.similarSubCat}
                    size={mobileDevices ? "extraMedium" : "large"}
                    weight={"extraBold"}
                    className={"gigPreview-text12"}
                  />
                  <AautiText
                    onClick={() => {
                      const newArray = {
                        categoryName: [gigDetails?.gig?.category],
                        subCategoryName: [gigDetails?.gig?.subCategory],
                      };
                      dispatch(setSelectedCategory(newArray));
                      navigate(`/CourseCategories`, { state: "gig" });
                    }}
                    title={gigStrings?.viewAll}
                    size={"semi"}
                    textStyle={{
                      display:
                        list?.length <
                          (mobileDevices
                            ? 2
                            : ipodDevices
                            ? 3
                            : miniLapys
                            ? 6
                            : lapy
                            ? 7
                            : 7) && "none",
                    }}
                    className={"gigPreview-text13"}
                  />
                </div>
                <MappedCategories
                  {...{
                    handleSimilarArrows2,
                    catRef,
                    subCatSettings,
                    subCategory: gigDetails?.gig?.subCategory,
                    similarSubCat,
                    disableLeft: disabledLeftButton,
                    disableRight: disabledRightSubCat,
                    gigId: gigDetails?.gig?._id,
                    isGig: true,
                  }}
                />
              </div>
            )}
            {filteredSimilarGigs?.length > 0 && (
              <div className="gigPreview-div10">
                <div className="gigPreview-div14-details">
                  <AautiText
                    title={gigStrings?.similarGigsByOther}
                    size={"medium"}
                    weight={"extraBold"}
                    className={"gigPreview-text10"}
                  />
                  <AautiText
                    title={gigStrings?.viewAll}
                    onClick={() =>
                      navigate("/similar-gigs", { state: baseCat })
                    }
                    size={"semi"}
                    className={"gigPreview-text11"}
                    textStyle={{
                      display:
                        filteredSimilarGigs?.length <
                          (mobileDevices
                            ? 2
                            : ipodDevices
                            ? 3
                            : miniLapys || lapy
                            ? 6
                            : 6) && "none",
                    }}
                  />
                </div>
                {filteredSimilarGigs?.length <
                (mobileDevices
                  ? 2
                  : ipodDevices
                  ? 3
                  : miniLapys
                  ? 3
                  : lapy
                  ? 4
                  : 4) ? (
                  <div className="gigPreview-div12">
                    {filteredSimilarGigs?.map((item, index) => (
                      <GigCard
                        cardWidth={mobileDevices ? "98%" : "269px"}
                        height={460}
                        key={index}
                        {...{
                          each: item,
                        }}
                        getGigsList={() =>
                          getSimilarGigs(
                            gigDetails?.gig?._id,
                            gigDetails?.gig?.serviceProviderId
                          )
                        }
                      />
                    ))}
                  </div>
                ) : isEmpty(filteredSimilarGigs) ? (
                  <div className="gigPreview-div13">
                    <AautiText title={gigStrings?.noGigsAvailable} />
                  </div>
                ) : (
                  <CauroselList
                    arrowsWidth={"92%"}
                    settings={slideSettings2}
                    sliderRef={otherRef}
                    handleSliderDirec={handleSimilarArrows3}
                    style={{ width: "100%" }}
                    showButtons={!mobileDevices}
                    disabledLeftButton={disabledLeftButton2}
                    disabledRight={disabledRight2}
                  >
                    {filteredSimilarGigs?.map((item, index) => (
                      <GigCard
                        cardWidth={"96.5%"}
                        height={460}
                        key={index}
                        {...{
                          each: item,
                        }}
                        getGigsList={() =>
                          getSimilarGigs(
                            gigDetails?.gig?._id,
                            gigDetails?.gig?.category,
                            gigDetails?.gig?.serviceProviderId
                          )
                        }
                      />
                    ))}
                  </CauroselList>
                )}
              </div>
            )}
          </div>
          {!(mobileDevices || ipodDevices) && (
            <GigRightContainer
              {...{
                gigData,
                hasChilds,
                handleSelectUser,
                isChild,
                buyNowAndCartVal,
                handleCart,
                gigDetails,
                completerDetails,
                isParent,
                buyNowVal,
                childDetails,
                checkisInCart,
              }}
            />
          )}
        </div>
      )}
      <CourseRatingDialog
        {...{
          setOpen: setVisible1,
          open: visible1,
          courseReviewData: { gigDetails },
          setRatingValue,
          ratingValue,
          setReviewComment,
          reviewComment,
          ratingAPI,
          orderID,
          setOrderID,
          parentID: null,
          suggestionsArray,
          setSuggestionsList,
          suggestionsList,
          type: 'gig',
          setOncloseRefresh
        }}
      />
      {/* <BottomSheet
        open={gigPlay}
        onClose={() => {
          // setGigPlay(null);
          // setGigUrl("");
        }}
        direction="bottom"
        // dialogTitle={gigDetails?.gig?.gigName}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            height: "100%",
            marginTop: 10,
            flexDirection: smallDevices ? "column" : "row",
          }}
        >
          <video
            style={{
              height: "100%",
              width: smallDevices ? "100%" : "57%",
              objectFit: "contain",
              maxHeight: "fit-content",
              borderRadius: 5,
            }}
            autoPlay={false}
            ref={videopopRef}
            // src={gigPlay?.videoUrl}
            // muted={true}
            controls
            controlsList="nodownload" // Prevent the download button
            preload="metadata"
            loop={false}
          >
            <source src={gigPlay?.videoUrl} type="video/mp4" />
          </video>
          <div
            style={{
              width: smallDevices ? "100%" : "40%",
              gap: 5,
              display: "flex",
              flexDirection: "column",
              marginTop: smallDevices && 10,
            }}
          >
            {gigPlay?.topics?.map((topic, index) => {
              return (
                <div
                  key={index}
                  className="topic-name-container"
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    cursor: "pointer",
                    alignItems: "flex-start",
                    width: "100%",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    const startTimeInSeconds = convertTimeToSeconds(
                      `${topic?.hours}:${topic?.minutes}:${topic?.seconds}`
                    );
                    if (videopopRef.current) {
                      videopopRef.current.currentTime = startTimeInSeconds;
                      videopopRef.current.play();
                    }
                  }}
                >
                  <img
                    src={gigDetails?.gig?.gigImage}
                    alt="topic-img"
                    style={{
                      width: 53,
                      height: 38,
                      borderRadius: "5px",
                    }}
                  />
                  <div>
                    <AautiText
                      title={topic?.topicName}
                      size={"semi"}
                      className={"gigPreview-text6"}
                    />
                    <AautiText
                      title={formatTime({
                        hour: topic?.hours,
                        minutes: topic?.minutes,
                        seconds: topic?.seconds,
                      })}
                      size={"small"}
                      textStyle={{
                        color: globalColors?.AAUTI_THEME_COLOR,
                      }}
                      className={"gigPreview-text6-time"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </BottomSheet> */}

      <ReturnPolicy {...{
        open: refundPolicyState,
        setOpen: setRefundPolicyState,
        showFooter: false
      }} />
      <AautiDialog
        dialogTitle={gigStrings?.gigReviews}
        open={open2}
        onClose={() => {
          setOpen2(false);
          if (onCloseRefresh) {
            setRefresh((prev) => !prev);
          }
          setOncloseRefresh(false);
        }}
      >
        <CourseReview
          reviewData={{
            type: "gig",
            gigId: orderID?._id,
            gigImage: orderID?.gigImage,
            gigName: orderID?.gigName,
            serviceProviderId: orderID?.serviceProviderId,
            isPurchased: gigDetails?.isPurchased,
          }}
          setRefresh={setRefresh}
          setOpen2={setOpen2}
          onCloseRefresh={onCloseRefresh}
          setOncloseRefresh={setOncloseRefresh}
          dialoghandle={() => {
            setOpen2(false);
          }}
        />
      </AautiDialog>
    </div>
  );
};

export default GigPreview;
