import { Box, IconButton, Tooltip } from "@mui/material";
import React from "react";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import { isEmpty } from "lodash";
import { RxCrossCircled } from "react-icons/rx";
import { checkNull, formatStartandEndTime } from "../../../utils/Helpers";
import { MdOutlineEdit } from "react-icons/md";

const CustomBatchDetails = ({
  courseBatch,
  showRanges = true,
  handleRemoveExclude,
  handleRemoveCustom,
  isDisplay = true,
  selectedDate = "",
  handleClickTime = () => {},
}) => {
  const formatedDates = courseBatch?.excludedDates
    ? [...courseBatch.excludedDates]?.sort((a, b) =>
        moment(a.date).diff(moment(b.date))
      )
    : [];

  const returnCustomizedFormats = (schedule) => {
    if (schedule) {
      return Object.keys(schedule).map((date) => {
        return (
          <button
            key={date}
            style={{
              padding: isDisplay ? 2 : "2px 10px",
              border: isDisplay ? "none" : "1px solid lightgrey",
              borderRadius: 4,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 4,
              gap: 4,
            }}
          >
            <AautiText
              key={date}
              size={"semi"}
              title={`${moment(date).format(
                "DD MMM YYYY"
              )} ${formatStartandEndTime({
                start: schedule[date]?.startTime,
                end: schedule[date]?.endTime,
              })}`}
            />
            {!isDisplay && (
              <Tooltip title="remove">
                <IconButton
                  onClick={() => {
                    handleRemoveCustom(date);
                  }}
                >
                  <RxCrossCircled size={18} />
                </IconButton>
              </Tooltip>
            )}
          </button>
        );
      });
    }
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      width={"100%"}
    >
      <Box
        display={showRanges ? "flex" : "none"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={"100%"}
        gap={1}
      >
        <AautiText title={"Date Range:"} textStyle={{ color: "#000" }} />
        <AautiText
          size={"semi"}
          title={`${moment(courseBatch?.startDate).format(
            "DD MMM YYYY"
          )} - ${moment(courseBatch?.endDate).format("DD MMM YYYY")}`}
        />
      </Box>
      <Box
        display={showRanges ? "flex" : "none"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={"100%"}
        gap={1}
      >
        <AautiText title={"Time:"} textStyle={{ color: "#000" }} />
        <AautiText
          size={"semi"}
          title={formatStartandEndTime({
            start: courseBatch?.startTime,
            end: courseBatch?.endTime,
          })}
        />
        <IconButton
          sx={{
            display: (isDisplay || checkNull(selectedDate)) && "none",
            padding: 1,
          }}
          onClick={() => {
            handleClickTime();
          }}
        >
          <MdOutlineEdit size={16} />
        </IconButton>
      </Box>
      <Box
        display={!isEmpty(courseBatch?.excludedDates) ? "flex" : "none"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        width={"100%"}
        gap={1}
        flexWrap={"wrap"}
      >
        <AautiText title={"Excluded Dates:"} textStyle={{ color: "#000" }} />
        {formatedDates?.map((each, index) => {
          const excludeLength = formatedDates?.length;
          return (
            <button
              key={index}
              style={{
                padding: isDisplay ? 4 : "2px 10px",
                border: isDisplay ? "none" : "1px solid lightgrey",
                borderRadius: 4,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
              }}
            >
              <AautiText
                size={"semi"}
                title={`${moment(each).format("DD MMM")}`}
              />
              {!isDisplay && (
                <Tooltip title="remove">
                  <IconButton
                    onClick={() => {
                      handleRemoveExclude(each);
                    }}
                  >
                    <RxCrossCircled size={18} />
                  </IconButton>
                </Tooltip>
              )}
            </button>
          );
        })}
      </Box>
      <Box
        display={!isEmpty(courseBatch?.customDates) ? "flex" : "none"}
        flexDirection={"column"}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        width={"100%"}
        // gap={1}
        flexWrap={"wrap"}
      >
        <AautiText title={"Customized Dates:"} textStyle={{ color: "#000" }} />
        {returnCustomizedFormats(courseBatch?.customDates)}
      </Box>
    </Box>
  );
};

export default CustomBatchDetails;
