import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import AppContainer from "../../AppContainer";
import { getResheduleBatches, saveRescheduleBatches } from "./Services";
import AautiHead from "../../globalComponents/AautiHead";
import AautiText from "../../globalComponents/AautiText";
import BatchesListing from "./BatchesListing";
import AautiDialog from "../../globalComponents/AautiDialog";
import { isEmpty } from "lodash";
import UpdateBatch from "./UpdateBatch";
import AautiFileHeader from "../../globalComponents/AautiFileHeader";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { setAautiLoader } from "../../../redux/reducer/appReducer";
import { useDispatch } from "react-redux";
import AccountTabs from "../../globalComponents/AccountTabs";

const Reschedule = () => {
  // const { courseId } = useParams();
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let courseId = searchParams.get("courseId");
  const [batchesList, setBatchesList] = useState({});
  const [classTypeState, setClassTypeState] = useState(undefined);
  const [classTypes, setClassTypes] = useState([]);
  const [selectedSlotDate, setSelectedSlotData] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAautiLoader(true));
    getResheduleBatches(
      courseId,
      (response) => {
        if (response?.result !== null) {
          dispatch(setAautiLoader(false));
          const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
            response?.result;
          let isIndividual =
            individualDaily?.length > 0 || individualWeekly?.length > 0;
          let isGroup = groupDaily?.length > 0 || groupWeekly?.length > 0;

          if (isIndividual && isGroup) {
            setClassTypes((prev) => ["Individual", "Group"]);
          } else if (isGroup) {
            setClassTypes((prev) => ["Group"]);
          } else if (isIndividual) {
            setClassTypes((prev) => ["Individual"]);
          }
          setBatchesList(response?.result);
        } else {
          dispatch(setAautiLoader(false));
          setBatchesList({});
        }
      },
      (error) => {
        dispatch(setAautiLoader(false));
        console.log(error, "error");
      }
    );
  }, [courseId, refresh]);

  useEffect(() => {
    setClassTypeState(classTypes[0]);
  }, [classTypes]);

  const returnBatchesArray = (each) => {
    const {
      individualDaily = [],
      individualWeekly = [],
      groupDaily = [],
      groupWeekly = [],
    } = batchesList;
    const individual = [
      {
        title: "individualDaily",
        arrayOfBatches: individualDaily,
      },
      {
        title: "individualWeekly",
        arrayOfBatches: individualWeekly,
      },
    ];
    const group = [
      {
        title: "groupWeekly",
        arrayOfBatches: groupWeekly,
      },
      {
        title: "groupDaily",
        arrayOfBatches: groupDaily,
      },
    ];
    if (classTypeState == "Individual") {
      if (individualDaily.length == 0 && individualWeekly.length == 0)
        return [];
    }

    if (classTypeState != "Individual") {
      if (groupDaily.length == 0 && groupWeekly.length == 0) return [];
    }
    return classTypeState == "Individual" ? individual : group;
  };
  const arrayOfBatches = returnBatchesArray();

  const handleSaveRescheduleApi = (obj) => {
    saveRescheduleBatches(
      obj,
      (response) => {
        if (response?.result) {
          setSelectedSlotData({});
          setRefresh(!refresh);
        }
      },
      (error) => {
        console.log(error, "save-error");
      }
    );
  };

  return (
    <AppContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <AautiFileHeader activeTitle={AautiStrings?.BatchesString} /> */}
        <AccountTabs
          tabObj={[
            {
              label: "My Courses",
              navigation: "/my-courses",
              active: false,
            },
            {
              label: "Batches",
              navigation: "/my-courses",
              active: true,
            },
          ]}
        />
        <BatchesListing
          {...{
            classTypes,
            arrayOfBatches,
            classTypeState,
            setClassTypeState,
            selectedSlotDate,
            setSelectedSlotData,
            setIsCreate,
          }}
        />

        <AautiDialog
          open={!isEmpty(selectedSlotDate)}
          onClose={() => {
            setSelectedSlotData({});
            setIsCreate(false);
          }}
        >
          <UpdateBatch
            {...{ setSelectedSlotData, batchesList, handleSaveRescheduleApi }}
            batchObj={selectedSlotDate}
            CreateCourseObj={selectedSlotDate}
            activeBatchType={classTypeState}
            isCreate={isCreate}
          />
        </AautiDialog>
      </div>
    </AppContainer>
  );
};

export default Reschedule;
