import { Avatar } from "@mui/material";
import React from "react";
import AautiText from "./AautiText";
import { AiFillStar } from "react-icons/ai";
import moment from "moment";
import FormattedDate from "../ratingReviews/GetDateTimeDifference";
import Rating from "@mui/material/Rating";
import { globalColors } from "../../themes/GlobalColors";

const RatingCard = ({ each, ratings, index }) => {
  const returntime = (time) => {
    const formattedTime = moment.utc(time).local().startOf("seconds").fromNow();
    return formattedTime || "NA";
  };
  return (
    <div key={index} className="course-review-card">
      <div className="course-review-reviewr">
        <Avatar
          alt="Remy Sharp"
          sx={{ height: 40, width: 40, mr: 1.5 }}
          src={each?.profileImage}
        />
        <div style={{ width: "100%" }}>
          <AautiText
            title={each?.displayName}
            size={"semi"}
            className={"courseRating-text1"}
          />
          <div className="rating-show-time">
            <div style={{ display: "flex", alignItems: 'center', columnGap: 3 }}>
              <AautiText title={`${each?.rating?.toFixed(1)}`} size={"semi"} />
              <Rating
                color={globalColors.AAUTI_THEME_COLOR}
                sx={{ color: globalColors?.AAUTI_THEME_COLOR }}
                name="read-only"
                value={each?.rating}
                size="small"
                readOnly
              />
            </div>
          </div>
        </div>
      </div>

      <AautiText
        title={each?.comment || "NA"}
        size={"semi"}
        textStyle={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          WebkitLineClamp: 2,
          textOverflow: "ellipsis",
        }}
        className={"courseRating-text2"}
      />
      <AautiText
        size={"small"}
        className={"rightCont-div6"}
        textStyle={{ marginTop: 5 }}
        title={<FormattedDate dateTime={each?.createdAt} />}
      />
    </div>
  );
};

export default RatingCard;
