import React, { useState } from "react";
import "./Style.scss";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AautiText from "../../globalComponents/AautiText";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DialogTitle,
  Drawer,
  Menu,
  MenuItem,
} from "@mui/material";
import CourseLessonPlan from "./CourseLessonPlan";
import { useSelector } from "react-redux";
import { RiPencilFill } from "react-icons/ri";
import Sessions from "./Sessions";
import ChooseLessonPlans from "./ChooseLessonPlans";
import { FaTrashAlt } from "react-icons/fa";
import { isEmpty } from "lodash";
import "../courseCreation/index.scss";
import ConfirmationDialogRaw from "../../globalComponents/ConfirmationDialogRaw";
import { PartType, PartTypeView } from "aauti_lessonplan";
import { saveMyLessonPlan } from "../../createLessonPlan/Services";
import { showToast } from "../../globalComponents/Toast";
import { createCourseStrings } from "./Constants";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { pluralOrSingular } from "../../../utils/Helpers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CreateCourse2 = (props) => {
  const {
    CreateCourseObj,
    changeCreateObj,
    planObj,
    changePlanObject,
    sessionObject,
    topicObject,
    dialoTitle,
    setDialogTitle,
  } = props;
  const [openDrawer, setOpenDrawer] = useState(false);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [handleLesson, setHandleLesson] = useState(true);
  const [open, setOpen] = useState(false);
  const [lpMenu, setLPMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const toggleDrawer = () => {
    setOpenDrawer(false);
  };

  const saveLesson = () => {
    let newObj = {
      contentName: CreateCourseObj?.courseName,
      contentDescription: CreateCourseObj?.courseDescription,
      category: CreateCourseObj?.category,
      subCategory: CreateCourseObj?.subCategory,
      level: CreateCourseObj?.courseLevel,
      duration: 0,
      noOfSessions: 1,
      noOfTopics: 1,
      noOfSubTopics: 0,
      serviceProviderId: planObj?.serviceProviderId,
      sessions: planObj?.sessions,
    };
    changeCreateObj(newObj, "lessonPlan");
    toggleDrawer();
  };

  const saveLessonPlan = (data) => {
    changeCreateObj(data, "lessonPlan");
    setOpenDrawer(false);
  };

  const LPDetails = () => {
    return (
      <div>
        {isEmpty(
          CreateCourseObj?.lessonPlan?.sessions?.[0]?.topics?.[0]?.data
        ) ? (
          CreateCourseObj?.lessonPlan?.sessions?.map((each) => (
            <Accordion
              sx={{
                width: "50vw",
                borderColor: "red",
                borderWidth: 10,
              }}
              disableGutters
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <AautiText
                    size={"normal"}
                    title={`${each?.sessionName} - `}
                    weight={"bold"}
                    textStyle={{
                      color: "#6b6b6b",
                    }}
                  />
                  <AautiText
                    size={"small"}
                    title={`${each?.topics?.length} Topics`}
                    weight={"normal"}
                    textStyle={{
                      color: "#6b6b6b",
                      marginLeft: "5px",
                    }}
                  />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {each?.topics?.map((eachTopic) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginBottom: "5px",
                      }}
                    >
                      <AautiText
                        size={"semi"}
                        title={`${eachTopic?.topicName}`}
                        weight={"normal"}
                        textStyle={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          color: "#6b6b6b",
                          marginBottom: "15px",
                        }}
                      />
                    </div>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <div style={{ width: "95vw" }}>
            <PartTypeView PTData={CreateCourseObj?.lessonPlan} />
          </div>
        )}
      </div>
    );
  };

  const SetDropDataToShow = () => {
    switch (dialoTitle) {
      case "Sessions":
        return (
          <Sessions
            {...{
              planObj,
              changePlanObject,
              sessionObject,
              topicObject,
              toggleDrawer,
              saveLesson,
            }}
          />
        );
      case "Edit Lesson Plan":
        return (
          <div style={{ width: "90%" }}>
            <PartType
              fromWeb={false}
              onSubmit={(data) => {
                saveLessonPlan(data);
              }}
              data={CreateCourseObj?.lessonPlan}
            />
          </div>
        );
      case "Create Lesson Plan":
        return (
          // <CourseLessonPlan
          //   {...{
          //     CreateCourseObj,
          //     changeCreateObj,
          //     planObj,
          //     setPlanObj,
          //     changePlanObject,
          //     sessionObject,
          //     activeSession,
          //     activeSubTopic,
          //     activeTopic,
          //     setactiveSession,
          //     setactiveTopic,
          //     setactiveSubTopic,
          //     topicObject,
          //     subTopicObject,
          //     toggleDrawer,
          //   }}
          // />
          <div style={{ width: "90%" }}>
            <PartType
              fromWeb={false}
              onSubmit={(data) => {
                saveLessonPlan(data);
              }}
              name={CreateCourseObj?.courseName}
              description={CreateCourseObj?.courseDescription}
            />
          </div>
        );
      case "Choose Lesson Plan":
        return (
          <ChooseLessonPlans
            {...{ CreateCourseObj, changeCreateObj, toggleDrawer }}
          />
        );
      case "View Lesson Plan":
        return <LPDetails />;

      case "Generate Using AI":
        return null;

      default:
        return null;
    }
  };

  const Card = (each, index) => {
    let topicsCount = 0;
    each?.sessions?.map((obj, index) => {
      topicsCount += obj?.topics?.length;
    });

    return (
      <div
        key={"Course-LessonPlan"}
        className="existing-lesson"
        onClick={() => {
          // setLessonPlanDetails(each);
          // setVisible(true);
          setLPMenu(false);
          setOpenDrawer(true);
          setDialogTitle("View Lesson Plan");
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="existing-lesson-head">
            <AautiText
              title={CapitalizeFirstLetter(each?.contentName || "NA")}
              weight={"bold"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                color: "#3166ba",
              }}
            />
            <MoreVertIcon
              sx={{ width: "auto", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
                setLPMenu(true);
              }}
            />
          </div>
          {/* <div style={{ padding: 10, height: "75px" }}>
            <AautiText
              title={each?.contentDescription || "NA"}
              size={"semi"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 3,

                overflow: "hidden",
                // marginTop: ,
                // padding: 7,s
              }}
            />
          </div> */}
          <div className="existing-lesson-foot">
            <AautiText
              size={"semi"}
              title={`${
                each?.sessions?.length || each?.lessons?.length || 0
              } ${pluralOrSingular(each?.sessions?.length, `Session`)}`}
              textStyle={{
                color: "#000000",
                borderRight: topicsCount > 0 ? "2px solid #000000" : "none",
                paddingRight: 10,
                // minWidth: "160px",
                display: isEmpty(each?.sessions) && "none",
              }}
            />
            <AautiText
              size={"semi"}
              title={`${topicsCount || 0}  ${
                topicsCount == 1 ? "Topic" : "Topics"
              } `}
              textStyle={{
                color: "#000000",
                paddingRight: 10,
                // minWidth: "160px",
                marginLeft: "13px",
                display: topicsCount > 0 ? "flex" : "none",
              }}
            />
          </div>
        </div>
        <div className="EditOptions">
          <Menu
            key={"Lp-Menu"}
            anchorEl={anchorEl}
            open={lpMenu}
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            onClose={(e) => {
              e.stopPropagation();
              setLPMenu(false);
            }}
            // PaperProps={{
            //   elevation: 1, // Removes the shadow by setting elevation to 0
            //   style: {
            //     boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Light box shadow
            //   },
            // }}
          >
            {["Edit", "Delete"].map((option) => (
              <MenuItem
                key={option}
                onClick={(e) => {
                  e.stopPropagation();
                  setLPMenu(false);
                  if (option === "Delete") {
                    setOpen(true);
                  } else if (option === "Edit") {
                    setOpenDrawer(true);
                    setDialogTitle(
                      CreateCourseObj?.lessonPlan?.sessions?.[0]?.topics?.[0]
                        ?.data?.length > 0
                        ? "Edit Lesson Plan"
                        : "Sessions"
                    );
                  }
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    );
  };

  return (
    <div
      className="CreateCourseTab"
      style={{
        width: "100%",
      }}
    >
      {CreateCourseObj?.lessonPlan?.contentName &&
      CreateCourseObj?.lessonPlan?.contentName !== "" ? (
        false ? (
          <div style={{ marginBottom: 10 }}>
            <AautiText
              title={createCourseStrings?.selectedLessonPlan}
              textStyle={{ fontSize: 15, marginTop: 10 }}
            />
            <div
              style={{
                padding: 10,
                border: "1px #3166ba solid",
                borderRadius: 5,
                width: "100%",
                backgroundColor: "#e3f2fd",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                color: "#3166ba",
                flexDirection: "row",
              }}
            >
              <AautiText
                textStyle={{
                  color: "#3166ba",
                  fontSize: 15,
                }}
                title={CreateCourseObj?.lessonPlan?.contentName}
              />
              <div>
                <RiPencilFill
                  style={{
                    color: "black",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenDrawer(true);
                    setDialogTitle(
                      CreateCourseObj?.lessonPlan?.sessions?.[0]?.topics?.[0]
                        ?.data?.length > 0
                        ? "Edit Lesson Plan"
                        : "Sessions"
                    );
                  }}
                  size={20}
                />
                <FaTrashAlt
                  size={20}
                  onClick={() => setOpen(!open)}
                  style={{ color: "black", cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        ) : (
          Card(CreateCourseObj?.lessonPlan)
        )
      ) : (
        <>
          <div className="lessonPlans-dev">
            <div
              className="choose-lsn-plan"
              style={{
                marginBottom: mobileDevices && 10,
              }}
              onClick={() => {
                setOpenDrawer(true);
                setDialogTitle(createCourseStrings?.chooseLessonPlan);
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: "#8687a7" }}
                fontSize="large"
              />
              <AautiText
                title={createCourseStrings?.chooseLessonPlan}
                textStyle={{ color: "#8687a7", marginTop: 10 }}
                weight={"bold"}
              />
            </div>
            <div
              className="choose-lsn-plan"
              style={{
                marginBottom: mobileDevices && "0px",
              }}
              onClick={() => {
                setOpenDrawer(true);
                setDialogTitle(createCourseStrings?.createLessonPlan);
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: "#8687a7" }}
                fontSize="large"
              />
              <AautiText
                title={createCourseStrings?.createLessonPlan}
                textStyle={{ color: "#8687a7", marginTop: 10 }}
                weight={"bold"}
              />
            </div>
          </div>
          <div className="lessonPlans-dev">
            <div
              className="choose-lsn-plan"
              style={{
                marginBottom: mobileDevices && 10,
              }}
              onClick={() => {
                showToast("info", "Coming Soon");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: "#8687a7" }}
                fontSize="large"
              />
              <AautiText
                title={"Generate Using AI"}
                textStyle={{ color: "#8687a7", marginTop: 10 }}
                weight={"bold"}
              />
            </div>
            <div
              className="choose-lsn-plan"
              style={{
                marginBottom: mobileDevices && 10,
              }}
              onClick={() => {
                setOpenDrawer(true);
                setDialogTitle("Sessions");
              }}
            >
              <AddCircleOutlineOutlinedIcon
                sx={{ color: "#8687a7" }}
                fontSize="large"
              />
              <AautiText
                title={createCourseStrings?.enterSessionManually}
                textStyle={{ color: "#8687a7", marginTop: 10 }}
                weight={"bold"}
              />
            </div>
          </div>
        </>
      )}

      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={open}
        onClick={(e) => {
          e.stopPropagation();
          // handleDelete(each?._id);
          changeCreateObj({}, "lessonPlan");
          changePlanObject([sessionObject], "sessions");
          setOpen(false);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />
      <Drawer
        open={openDrawer}
        onClose={toggleDrawer}
        anchor="bottom"
        sx={{
          // zIndex: 99,
          "& .MuiDrawer-paper": {
            borderRadius: "20px 20px 0px 0px",
            minHeight: "calc(100vh - 200px)",
            // from == "updatestatus" || from == "inviteAdmin"
            //   ? "calc(100vh - 150px)"
            //   : from == "manageFunraiserAdmin"
            //   ? "calc(100vh - 200px)"
            //   : "calc(50vh)",
            mt: "0px !important",
            maxHeight: { xs: "calc(100vh - 100px)", sm: "calc(100vh - 150px)" },
          },
          "&.MuiDrawer-root": {
            // zIndex: 1100,
          },
        }}
      >
        <DialogTitle sx={{ alignSelf: "center", fontWeight: "bold" }}>
          {dialoTitle}
        </DialogTitle>
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {SetDropDataToShow()}
        </div>
      </Drawer>
    </div>
  );
};

export default CreateCourse2;
