import { ApiRequest } from "../../service";
import { ApiMethods } from "../../service/api-constants";

const ApiConstants = {
  //Sp Attendance
  getAttendanceDetails: "attendance/getServiceProviderAttendanceForSession?",
  ServiceProviderAttendanceForCourse:
    "attendance/getServiceProviderCourseAttendance",
  ServiceProviderAttendanceForAdhoc:
    "attendance/getServiceProviderAdhocAttendance",
  getSpCourseId: "attendance/getServiceProviderAttendanceByCourseId",
  getSpAdhocId: "attendance/getServiceProviderAttendanceBySchedulerId",

  //EndUSer Attendance
  getEndUserAttendanceDetails: "attendance/getEndUserAttendanceForSession?",
  EndUserAttendanceForCourses: "attendance/getEndUserCourseAttendance",
  EndUserAttendanceForAdhoc: "attendance/getEndUserAdhocAttendance",
  getEndUserCourseId: "attendance/getEndUserAttendanceByCourseId",
  getEndUserAdhocId: "attendance/getEndUserAttendanceBySchedulerId",

  // students api urls
  ServiceProviderStudentCount: "attendance/getServiceproviderStudentsList?",
  ServiceProviderStudentForCourse:
    "attendance/getServiceproviderStudentsListForCourse",
  ServiceProviderStudentForGigs:
    "attendance/getServiceproviderStudentsListForGig",
  ServiceProviderStudentForAdhoc:
    "attendance/getServiceproviderStudentsListForAdhoc",
  getStudentCourseId: "attendance/getStudentsListByCourseId",
  getStudentGigId: "attendance/getStudentsListByGigId",
  getStudentAdhocId: "attendance/getStudentsListByAdhocId",

  // revenue for service provider
  getServiceProviderRevenueDetails: "attendance/getServiceProviderRevnue?",
  ServiceProviderRevenueForCourse:
    "attendance/getServiceProviderRevenueForCourse",
  ServiceProviderRevenueForGig: "attendance/getServiceProviderRevenueForGig",
  ServiceProviderRevenueForAdhoc:
    "attendance/getServiceProviderRevenueForAdhoc",
  getRevenueCourseId: "attendance/getRevenueByCourseId",
  getRevenueGigId: "attendance/getRevenueByGigId",
  getRevenueAdhocId: "attendance/getRevenueByAdhocId",

  // ratings count api
  getServiceProviderRatingCount:
    "ratingsReviews/getServiceProviderIdRatingsReviews?",
  // service provider ratings
  ServiceProviderRatingsForCourse:
    "ratingsReviews/getServiceProviderIdRatingsReviewsforCourse",
  ServiceProviderRatingsForGig:
    "ratingsReviews/getServiceProviderIdRatingsReviewsforGig",
  ServiceProviderRatingsForAdhoc:
    "ratingsReviews/getServiceProviderIdRatingsReviewsforAdhoc",
  // service provider all ratings
  ServiceProviderAllRatings: "ratingsReviews/getByServiceProviderId",
  getRatingsCourseId: "ratingsReviews/getByCourseId",
  getRatingsGigId: "ratingsReviews/getByGigId",
  getRatingAdhocId: "ratingsReviews/getBySchedulerId",
};

// const count =100

// service provider students for count
export const getAllStudentsBySPIdCount = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderStudentCount,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// service provider students for course
export const getAllStudentsBySPId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderStudentForCourse,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// service provider students for gigs
export const getAllStudentsGigs = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderStudentForGigs,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// service provider students for adhoc
export const getAllStudentsAdhoc = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderStudentForAdhoc,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

// revenue
// revenue header count
export const getServiceProviderRevenue = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: ApiConstants.getServiceProviderRevenueDetails,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// revenue api for courses
export const getServiceProviderRevenueForCourse = (
  params,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: ApiConstants.ServiceProviderRevenueForCourse,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// revenue api for gigs
export const getServiceProviderRevenueForGigs = (
  params,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: ApiConstants.ServiceProviderRevenueForGig,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// revenue for Adhocs
export const getServiceProviderRevenueForAdhocs = (
  params,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: ApiConstants.ServiceProviderRevenueForAdhoc,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// service provider attendence count
export const getServiceProviderAttendance = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: ApiConstants.getAttendanceDetails,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// end user attendence count
export const getEndUserAttendance = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: ApiConstants.getEndUserAttendanceDetails,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
//service provider attendence for course
export const getServiceProviderAttendanceForCourse = (
  params,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: ApiConstants.ServiceProviderAttendanceForCourse,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// service provider attendence for adhoc
export const getServiceProviderAttendanceForAdhoc = (
  params,
  onSuccess,
  onFailure
) => {
  ApiRequest({
    url: ApiConstants.ServiceProviderAttendanceForAdhoc,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// enduser attendence for course
export const getEndUserAttendanceForCourse = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: ApiConstants.EndUserAttendanceForCourses,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// enduser attendence for adhoc
export const getEndUserAttendanceForAdhoc = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: ApiConstants.EndUserAttendanceForAdhoc,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// ratings count
export const getServiceProviderRatings = (params, onSuccess, onFailure) => {
  ApiRequest({
    url: ApiConstants.getServiceProviderRatingCount,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
export const getServiceProviderRatingsForCourses = (
  params,
  onSuccess,
  onFailure
) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderRatingsForCourse,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
export const getServiceProviderRatingsForGig = (
  params,
  onSuccess,
  onFailure
) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderRatingsForGig,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
export const getServiceProviderRatingsForAdhoc = (
  params,
  onSuccess,
  onFailure
) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderRatingsForAdhoc,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// sp all ratings
export const getServiceProviderAllRatings = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.ServiceProviderAllRatings,
    method: ApiMethods.GET,
    params: { ...params },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// latest api's
// revenue
export const getRevenueForCourseId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getRevenueCourseId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getRevenueForGigId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getRevenueGigId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getRevenueForAdhocId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getRevenueAdhocId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// students
export const getStudentsForCourseId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getStudentCourseId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getStudentsForGigId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getStudentGigId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getStudentsForAdhocId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getStudentAdhocId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// ratings
export const getRatingsForCourseId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getRatingsCourseId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getRatingsForGigId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getRatingsGigId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getRatingsForAdhocId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getRatingAdhocId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// attendance service provider

export const getSPAttendanceForCourseId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getSpCourseId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getSPAttendanceForAdhocId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getSpAdhocId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
// attendance end user

export const getEUAttendanceForCourseId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getEndUserCourseId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};

export const getEUAttendanceForAdhocId = (params, onSuccess, onFailure) => {
  return ApiRequest({
    url: ApiConstants.getEndUserAdhocId,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
};
