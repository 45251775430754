import React, { useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { useSelector } from "react-redux";
import CauroselList from "../globalComponents/caurosel/CauroselList";
import "../course/courseDetails/index.scss";
import { Avatar } from "@mui/material";
import Rating from "@mui/material/Rating";
import moment from "moment";
import AautiDialog from "../globalComponents/AautiDialog";
import CourseDeatilsStrings from "../course/courseDetails/CourseDeatilsStrings";
import CourseReviewsAfterBuy from "../course/courseDetails/CourseReviewsAfterBuy";
import { FaRegStar } from "react-icons/fa";
import { AautiStrings } from "../globalComponents/AautiStrings";
import gigStrings from "./gigStrings";
import RatingCard from "../globalComponents/RatingCard";
import { FaStar } from "react-icons/fa";
import { globalColors } from "../../themes/GlobalColors";

const GigRatings = (props) => {
  const { miniLapys, lapy } = useSelector((state) => state.responsive);
  const {
    ratings,
    slideSettings,
    handleSliderDirec,
    reference,
    completeDetails,
    open,
    setOpen,
    getRating,
  } = props;

  const returntime = (time) => {
    const formattedTime = moment.utc(time).local().startOf("seconds").fromNow();
    return formattedTime || "NA";
  };

  return (
    <div>
      <div className="courseRating-div1">
        <AautiText
          size={"medium"}
          title={CourseDeatilsStrings?.review}
          weight={"extraBold"}
          className={"courseRating-text3"}
        />
        <div className="courseRating-div2">
          <FaStar
            className="courseRating-icon1"
            color={globalColors.AAUTI_THEME_COLOR}
          />
          {/* <AautiText
            title={`${ratings?.averageRating?.toFixed(1)}`}
            weight={"extraBold"}
          /> */}
          <AautiText
            title={`${ratings?.averageRating?.toFixed(1)}(${
              ratings?.reviews?.length || 0
            })`}
            weight={"extraBold"}
          />
          {ratings?.reviews?.length > 3 && (
            <button
              className="courseRating-button1"
              onClick={() => setOpen(true)}
            >
              <AautiText
                title={CourseDeatilsStrings?.viewAll}
                size={"semi"}
                className={"courseRating-text4"}
              />
            </button>
          )}
        </div>
      </div>

      <div className="courseRating-div3">
        {ratings?.reviews?.length > 3 ? (
          <CauroselList
            showButtons={true}
            settings={slideSettings}
            sliderRef={reference}
            handleSliderDirec={handleSliderDirec}
            style={{
              width: miniLapys ? "90%" : lapy ? "82%" : "100%",
            }}
            arrowsWidth={miniLapys ? "100%" : lapy ? "100%" : "100%"}
          >
            {ratings?.reviews?.map((each, index) => {
              return <RatingCard each={each} index={index} ratings={ratings} />;
            })}
          </CauroselList>
        ) : (
          <div className="courseRating-div4">
            {ratings?.reviews?.map((each, index) => {
              return <RatingCard each={each} index={index} ratings={ratings} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default GigRatings;
