import React, { useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import { PriceConversions } from "../../CommonFunctions";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import JoinButton from "../../globalComponents/JoinButton";
import "../Style.scss";
import { noImage } from "../../calendar/constants";
import VirtualIcon from "../../globalComponents/icons/VirtualIcon";
import InPerson from "../../globalComponents/icons/InPerson";
import moment from "moment";

import GroupIcon from "../../../assets/images/group.svg";
import IndividualIcon from "../../../assets/images/individual.svg";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import { GoInfo } from "react-icons/go";
import { useSelector } from "react-redux";
import { AuthService } from "../service";
import { showToast } from "../../globalComponents/Toast";
import { globalColors } from "../../../themes/GlobalColors";
import AautiDropDown from "../../globalComponents/AautiDropDown";

const RequestRefunds = ({
  handleOptionChange,
  selectedOption,
  refundData,
  completeOrderState,
  setRefundReason,
  refundRequestApi,
  refundReason,
  handleChange,
  details,
}) => {
  const { convertPriceWithCurrency } = PriceConversions();
  const refundTotalData = refundData?.refundList?.[0] || refundData?.[0];
  const { userDetails } = useSelector((state) => state.app);
  const [reportList, setReportList] = useState(null);

  const getAllReportTypesCall = () => {
    const data = {
      type: "Course",
    };
    AuthService.getAllReportTypes(data, onSuccessReort, onFailure);
  };
  const onSuccessReort = (response) => {
    setReportList(response?.result);
  };
  const onFailure = (error) => {
    showToast("error", "Something went wrong");
  };
  useEffect(() => {
    getAllReportTypesCall();
  }, []);
  const returnIcon = () => {
    if (refundTotalData?.classType == "virtual") {
      return (
        <div className="refundscreen-gap1">
          <VirtualIcon />
        </div>
      );
    } else if (refundTotalData?.classType == "inPerson") {
      return <InPerson />;
    }
  };

  const returnCourseType = (type) => {
    const totalLocalPurchasedPriceCal = refundData?.refundList
      ? refundData?.refundList.reduce((total, item) => {
          return total + (item.localPurchasedPrice || 0);
        }, 0)
      : refundData?.[0]?.localPurchasedPrice || 0;
    // const totalLocalPurchasedPriceCal2 = refundData?.reduce((total, item) => {
    //   return total + item.localPurchasedPrice;
    // }, 0);
    return (
      <div className="refundscreen-gap6">
        {type == "individual" ? (
          <img
            src={IndividualIcon}
            alt="individual"
            className="refundscreen-img"
          />
        ) : (
          <img src={GroupIcon} alt="group" className="refundscreen-img" />
        )}

        <AautiText
          size={"semi"}
          weight={"medium"}
          textStyle={{
            color: globalColors.HEADING_COLOR,
            fontFamily: "sans-serif",
          }}
          title={`${
            refundTotalData?.currencySymbol +
            (totalLocalPurchasedPriceCal?.toFixed(2) || "N/A")
          }`}
        />
      </div>
    );
  };
  return (
    <div className="main-div-refund">
      <div className="refund-flex">
        <img
          src={
            refundTotalData?.courseImage || refundTotalData?.image || noImage
          }
          alt="refund-img"
          className="refund-img"
        />
        <div className="refundscreen-auto ">
          <div className="refundscreen-gap2">
            <div className="refundscreen-90">
              <AautiText
                title={
                  refundTotalData?.courseName?.trim() ||
                  refundTotalData?.name?.trim() ||
                  "Name unavailable"
                }
                size={"semi"}
                weight={"medium"}
                textStyle={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  width: "100%",
                  height: 39,
                  lineHeight: "19px",
                  zIndex: 200,
                  color: globalColors.HEADING_COLOR,
                }}
              />
            </div>
            {returnIcon(refundData)}
          </div>
          <div className="flex-name">
            <AautiText
              size={"semi"}
              textStyle={{ color: globalColors.HEADING_COLOR }}
              title={`Order Date: `}
            />
            <AautiText
              size={"semi"}
              title={`${
                moment(refundTotalData?.orderDate).format("DD MMM YYYY") ||
                "N/A"
              }`}
              weight={"medium"}
              textStyle={{ color: globalColors.HEADING_COLOR }}
            />
          </div>
          <div className="flex-name">
            <AautiText
              size={"semi"}
              title={`Order Id:`}
              textStyle={{ color: globalColors.HEADING_COLOR }}
            />
            <AautiText
              title={`${refundTotalData?.receiptId || "N/A"}`}
              size={"semi"}
              weight={"medium"}
              textStyle={{ color: globalColors.HEADING_COLOR }}
            />
          </div>
          <div className="flex-name">
            <AautiText
              size={"semi"}
              title={`Payment Method:`}
              textStyle={{ color: globalColors.HEADING_COLOR }}
            />
            <AautiText
              size={"semi"}
              weight={"medium"}
              textStyle={{ color: globalColors.HEADING_COLOR }}
              title={`${
                refundTotalData?.paymentMethod?.charAt(0).toUpperCase() +
                  refundTotalData?.paymentMethod?.slice(1) || "N/A"
              }`}
            />
          </div>

          {returnCourseType(refundTotalData?.courseType)}
        </div>
      </div>
      <label className="refundscreen-label">
        <input
          type="radio"
          value="credits"
          className="refundscreen-radio"
          checked={selectedOption === "credits"}
          onChange={handleOptionChange}
        />
        <div className="radio-item-cont">
          <div className="refundscreen-credits-div">
            <AautiText
              title={`${
                userDetails.transactionCountrySymbol +
                (refundData?.aautiRefundAmount?.toFixed(2) ||
                  refundTotalData?.aautiRefundAmount?.toFixed(2) ||
                  "0.00")
              }
                   in Aauti Credits`}
              size={"medium"}
              weight={"medium"}
              textStyle={{
                color: globalColors.HEADING_COLOR,
                fontFamily: "sans-serif",
              }}
            />
            <div className="fast-con">
              <AautiText
                title={"Fastest"}
                size={"semi"}
                weight={"medium"}
                textStyle={{ color: globalColors.Aauti_White }}
              />
            </div>
          </div>
          <AautiText
            title={"Aauti Credits will show up in your account immediately"}
            size={"semi"}
            weight={"normal"}
            textStyle={{ color: globalColors.GREY_TEXT }}
          />
        </div>
      </label>
      <label
        className="refundscreen-label2"
        style={{
          display:
            refundTotalData?.isBatchStarted ||
            refundTotalData?.paymentMethod === "credits"
              ? "none"
              : "flex",
        }}
      >
        <input
          type="radio"
          value="bankPayment"
          className="refundscreen-radio"
          checked={selectedOption === "bankPayment"}
          onChange={handleOptionChange}
        />
        <div className="radio-item-cont">
          <AautiText
            title={`${
              refundTotalData?.currencySymbol +
                (refundData?.refundAmount?.toFixed(2) ||
                  refundTotalData?.refundAmount?.toFixed(2)) || "N/A"
            }
                   in ${refundTotalData?.paymentMethod || "N/A"}`}
            size={"medium"}
            weight={"medium"}
            textStyle={{
              color: globalColors.HEADING_COLOR,
              fontFamily: "sans-serif",
            }}
          />
          <AautiText
            title={
              "Most refunds will be posted to your account within 5 to 10 business days though it may take longer, depending on your payment method or location"
            }
            size={"semi"}
            weight={"normal"}
            textStyle={{ color: globalColors.GREY_TEXT, marginTop: 5 }}
          />
        </div>
      </label>
      <div className="flex-info">
        <GoInfo size={30} />
        <AautiText
          title={
            "Aauti will only be able to refund the original payment source that was used to process the payment. Aauti will process the refund in the same currency used in the payment."
          }
          size={"semi"}
        />
      </div>
      <AautiText
        title={"Refund Reason"}
        size={"normal"}
        weight={"medium"}
        textStyle={{ color: globalColors.HEADING_COLOR }}
      />
      <AautiText
        title={"Help us improve: Why are you getting a refund?"}
        size={"semi"}
        weight={"normal"}
        textStyle={{
          color: globalColors.GREY_TEXT,
        }}
      />
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label" sx={{ color: "#000" }}>
          Reason <span style={{ color: globalColors.Aauti_Red }}>*</span>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={refundReason}
          label="Reason *"
          onChange={handleChange}
        >
          {reportList?.map((each, index) => (
            <MenuItem key={index} value={each?.name}>
              {each?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <AautiTextInput
        onChange={(e) => {
          handleChange(e.target.value, "details");
        }}
        value={details}
        multiline={true}
        important={true}
        maxLength={500}
        rows={4}
        text={"Description"}
        style={{ width: "100%", marginTop: "-5px" }}
        fullWidth
        helperText={`${details?.length}/500 (minimum 50 characters)`}
      />
      <div className="refundscreen-submit">
        <JoinButton
          title={"Submit"}
          disabled={
            refundReason === "" ||
            selectedOption === "" ||
            details == "" ||
            details?.length < 50
          }
          onClick={() => {
            refundRequestApi();
          }}
        />
      </div>
    </div>
  );
};

export default RequestRefunds;
