import React, { memo, useEffect, useMemo, useState } from "react";
import {
  chatConstants,
  conversationTypes,
  SocketEvents,
} from "../../constants";
import { userRoles } from "../../../../storage/constants";
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AautiAvatar from "../AautiAvatar";
import { useDispatch, useSelector } from "react-redux";
import {
  getContacts,
  updateContacts,
} from "../../../../redux/reducer/chatSlice";
import { Close } from "@mui/icons-material";
import SearchBar from "../searchBar";
import EmptyData from "../../../globalComponents/EmptyData";
import { BootstrapDialog } from "../styled_components";

const initialState = {
  selectedContacts: [],
  searchInput: undefined,
};

function AddUserDialog({
  id = "",
  open = false,
  onClose = () => {},
  roomId,
  roomType,
  courseId = undefined,
  batchId = undefined,
  instituteId = undefined,
}) {
  const dispatch = useDispatch();

  const contactsList = useSelector(getContacts);
  const [state, setState] = useState(initialState);
  const { searchInput, selectedContacts } = state;
  const mainContactsList = useMemo(() => {
    if (searchInput?.length > 0) {
      return contactsList;
    }
    return selectedContacts;
  }, [contactsList, searchInput, selectedContacts]);

  useEffect(() => {
    if (open) setState({ ...initialState });
  }, [open]);

  const onCloseDialog = () => onClose();

  const onPressCard = (isSelected, item) => {
    let selectedContacts = state.selectedContacts;
    if (isSelected) {
      selectedContacts = selectedContacts.filter(
        (user) => user._id !== item._id
      );
    } else {
      selectedContacts = [...selectedContacts, item];
    }
    setState((prev) => ({
      ...prev,
      selectedContacts,
    }));
  };

  const resetSearchInput = () => {
    setState((prev) => ({
      ...prev,
      searchInput: undefined,
    }));
    dispatch(updateContacts([]));
  };

  const onChangeSearchInput = (event) => {
    let text = event.target.value;
    setState((prev) => ({
      ...prev,
      searchInput: text,
    }));
    if (text?.length) {
      let payload = { name: text?.trim(), roomId };
      if (roomType === conversationTypes.GROUP) {
        payload = { ...payload, role: userRoles.serviceProvider, instituteId };
        dispatch({
          type: SocketEvents.getContacts,
          payload,
        });
      } else {
        payload = { ...payload, courseId, batchId };
        dispatch({
          type: SocketEvents.getCourseSubscribers,
          payload,
        });
      }
    } else {
      resetSearchInput();
    }
  };

  const handleAddUsers = () => {
    const users = state.selectedContacts.map((eachData) => eachData?._id);
    dispatch({
      type: SocketEvents.room.addUsers,
      payload: { roomId, users },
    });
    onClose();
  };

  const renderUser = (value) => {
    const labelId = `checkbox-list-secondary-label-${value}`;
    const isSelected = selectedContacts.some((user) => user._id === value?._id);
    const name = value?.displayName ?? value?.user?.displayName;
    const profileImage = value?.profileImage ?? value?.user?.profileImage;
    const onClick = () => onPressCard(isSelected, value);
    return (
      <ListItem
        key={value._id}
        secondaryAction={
          <Checkbox
            checked={isSelected}
            onChange={onClick}
            inputProps={{ "aria-label": `${name}` }}
          />
        }
        disablePadding
        className={"list_item_add_group"}
      >
        <ListItemButton>
          <ListItemAvatar>
            <AautiAvatar alt={name} src={profileImage} width={40} height={40} />
          </ListItemAvatar>
          <ListItemText id={labelId} primary={name || "N/A"} />
        </ListItemButton>
      </ListItem>
    );
  };

  const disableAdd = selectedContacts.length < 1;

  return (
    <BootstrapDialog
      onClose={onCloseDialog}
      aria-labelledby="chat-room-add-users-dialog"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="chat-room-add-users-dialog-title">
        {chatConstants.addUsers}
      </DialogTitle>
      <Close
        onClick={onCloseDialog}
        style={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "grey",
        }}
      />
      <DialogContent style={{ padding: "0px 10px", margin: 0 }} sx={{ p: 2 }}>
        <SearchBar
          {...{
            value: searchInput,
            onChangeSearchInput,
            onPressClear: resetSearchInput,
            showBackIcon: false,
          }}
        />
        <div id={"list_container_add_group"}>
          {!mainContactsList?.length && searchInput?.length > 0 && (
            <EmptyData
              {...{
                text: chatConstants.noResults,
                showExplore: false,
                height: "100%",
              }}
            />
          )}
          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
            {mainContactsList?.map(renderUser)}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseDialog}>Cancel</Button>
        <Button disabled={disableAdd} autoFocus onClick={handleAddUsers}>
          {"Add"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default memo(AddUserDialog);
