import { Box, IconButton, Tooltip } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { RxCrossCircled } from "react-icons/rx";
import dayjs from "dayjs";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import NavButton from "./NavButton";
import { isEmpty } from "lodash";
import { courseConstant } from "./courseConstant";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { useSelector } from "react-redux";
import AautiText from "../../globalComponents/AautiText";
import { showToast } from "../../globalComponents/Toast";
import CustomBatchDetails from "./CustomBatchDetails";
import { globalColors } from "../../../themes/GlobalColors";
import { maxDate } from "../../globalComponents/helpers.variables";
import { getNextTimeSlot } from "../createCourse/Constants";
import AautiTimePicker from "../../globalComponents/timepickers/AautiTimePicker";
import { addOneHourOrEndOfDay } from "../../CommonFunctions";

const CustomSlotSelection = ({
  customCourseBatch,
  setCustomCourseBatch,
  state,
  dateRanges,
  setDateRanges,
  handleSelection,
  handleSaveCustomSlot,
}) => {
  let today = new Date();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [selectedDate, setSelectedDate] = useState("");
  const nextTimeSlot = getNextTimeSlot();

  const handleRemoveDate = (date) => {
    let excludedDates = customCourseBatch?.excludedDates
      ? [...customCourseBatch?.excludedDates]
      : [];
    const updatedDatesObject = { ...dateRanges };
    delete updatedDatesObject[date];
    const exclude = moment(date).format("YYYY-MM-DDT00:00:00");
    excludedDates.push(exclude);
    const delteCustomDate = { ...customCourseBatch?.customDates };
    delete delteCustomDate[date];
    setDateRanges(updatedDatesObject);
    setCustomCourseBatch((prev) => ({
      ...prev,
      excludedDates: excludedDates,
      customDates: delteCustomDate,
    }));
  };

  const onChangetimeSlotObj = (val, slot) => {
    let timeFromat = moment(val)?.format("YYYY-MM-DDTHH:mm:00");
    if (slot === "startTime") {
      let calculatedEndTime = addOneHourOrEndOfDay(timeFromat);
      setCustomCourseBatch({
        ...customCourseBatch,
        startTime: timeFromat,
        endTime: calculatedEndTime,
      });
    } else if (slot === "endTime") {
      setCustomCourseBatch((prev) => ({
        ...prev,
        [slot]: timeFromat,
      }));
    } else {
      setCustomCourseBatch((prev) => ({
        ...prev,
        [slot]: val,
      }));
    }
  };

  const handleAddCustomTimes = (date, field) => {
    const timeFormat = moment(date).format("YYYY-MM-DDTHH:mm:00");
    let val2 = addOneHourOrEndOfDay(timeFormat);
    const updatedCustomDates = { ...customCourseBatch?.customDates };
    if (!updatedCustomDates[selectedDate]) {
      updatedCustomDates[selectedDate] = {};
    }
    updatedCustomDates[selectedDate][field] = timeFormat;
    updatedCustomDates[selectedDate]["endTime"] = val2;
    setCustomCourseBatch((prev) => ({
      ...prev,
      customDates: updatedCustomDates,
    }));
  };

  const endTimeValue =
    !isEmpty(customCourseBatch?.customDates) && selectedDate
      ? moment(customCourseBatch?.customDates[selectedDate]?.startTime).add(
          1,
          "hours"
        )
      : moment(customCourseBatch?.startTime)?.add(1, "hours");

  const handleRemoveExclude = (date) => {
    let ranges = { ...dateRanges };
    ranges[moment(date).format("YYYY-MM-DD")] = {};
    let excludedDates = [...customCourseBatch?.excludedDates];
    excludedDates = excludedDates?.filter((each) => each !== date);
    setDateRanges(ranges);
    setCustomCourseBatch((prev) => ({
      ...prev,
      excludedDates: excludedDates,
      // customDates: delteCustomDate,
    }));
  };

  const handleRemoveCustom = (date) => {
    const delteCustomDate = { ...customCourseBatch?.customDates };
    delete delteCustomDate[date];

    setCustomCourseBatch((prev) => ({
      ...prev,
      customDates: delteCustomDate,
    }));
  };

  const isStartToday =
    moment(customCourseBatch?.startDate).format("YYYY-MM-DD") ===
    moment(new Date()).format("YYYY-MM-DD");

  const returnEndTimerValue = () => {
    return selectedDate
      ? customCourseBatch?.customDates?.[selectedDate]?.endTime
        ? customCourseBatch?.customDates?.[selectedDate]?.endTime
        : null
      : customCourseBatch?.endTime || endTimeValue;
  };

  const returnMinStartValue = () => {
    const startDate = moment(customCourseBatch?.startDate)?.format(
      "YYYY-MM-DDT00:00:00"
    );
    const isDateExluded = customCourseBatch?.excludedDates?.includes(startDate);
    return !isDateExluded &&
      (isStartToday || selectedDate === moment(new Date()).format("YYYY-MM-DD"))
      ? nextTimeSlot
      : moment().format("YYYY-MM-DDT00:00:00");
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <DateRange
        editableDateInputs={false}
        months={mobileDevices ? 1 : 2}
        onChange={(item) => {
          handleSelection(item.selection, customCourseBatch);
        }}
        moveRangeOnFirstSelection={false}
        ranges={[...state]}
        style={{ alignSelf: "center" }}
        minDate={today}
        showSelectionPreview={true}
        direction="horizontal"
        maxDate={new Date(maxDate)}
        startDatePlaceholder={moment(state[0]?.startDate).format("MMM DD,YYYY")}
        endDatePlaceholder={moment(state[0]?.endDate).format("MMM DD,YYYY")}
      />

      {dateRanges && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 5,
            flexWrap: "wrap",
            overflow: "hidden",
            overflowX: "scroll",
            width: "100%",
            justifyContent: "center",
          }}
        >
          {Object.keys(dateRanges)?.map((each, index) => {
            const hasData =
              customCourseBatch?.customDates &&
              each in customCourseBatch.customDates;

            return (
              <button
                key={index}
                onClick={() => {
                  if (
                    customCourseBatch?.startTime === "" ||
                    customCourseBatch?.endTime == ""
                  ) {
                    alert("please set timings");
                  } else {
                    setSelectedDate(each);
                  }
                }}
                style={{
                  padding: "5px 10px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderRadius: 4,
                  border:
                    selectedDate === each
                      ? `2px solid ${globalColors.AAUTI_THEME_COLOR}`
                      : "1px solid lightgrey",
                  gap: 6,
                }}
              >
                <AautiText
                  textStyle={{
                    color: hasData ? globalColors.AAUTI_THEME_COLOR : "#000",
                  }}
                  title={moment(each).format("DD MMM")}
                  size={"semi"}
                />
                <Tooltip title={courseConstant?.addToExceptionsList}>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (Object.keys(dateRanges)?.length > 1) {
                        handleRemoveDate(each);
                      } else {
                        showToast("info", "All Dates cannot be excluded");
                      }
                    }}
                  >
                    <RxCrossCircled size={20} />
                  </IconButton>
                </Tooltip>
              </button>
            );
          })}
        </div>
      )}

      <div
        className="start-end-time"
        style={{
          width: "100%",
          border: "none",
          alignItems: "flex-end",
          // justifyContent: "",
        }}
      >
        <div style={{}} className="eachDateStart">
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]} fullWidth>
              <TimePicker
                sx={{ width: "100%" }}
                ampm={false}
                label={AautiStrings.StartTimeString}
                minTime={dayjs(customCourseBatch?.startDate)}
                value={dayjs(
                  selectedDate
                    ? customCourseBatch?.customDates?.[selectedDate]?.startTime
                      ? customCourseBatch?.customDates?.[selectedDate]
                          ?.startTime
                      : null
                    : customCourseBatch?.startTime || nextTimeSlot
                )}
                timeSteps={{ minutes: 30 }}
                onChange={(newVal) => {
                  if (selectedDate) {
                    handleAddCustomTimes(newVal, "startTime");
                  } else {
                    onChangetimeSlotObj(newVal, "startTime");
                  }
                }}
                slotProps={{ field: { readOnly: true } }}
              />
            </DemoContainer>
          </LocalizationProvider> */}
          <AautiTimePicker
            label={AautiStrings.StartTimeString}
            minTime={returnMinStartValue()}
            timerValue={
              selectedDate
                ? customCourseBatch?.customDates?.[selectedDate]?.startTime
                  ? customCourseBatch?.customDates?.[selectedDate]?.startTime
                  : null
                : customCourseBatch?.startTime || nextTimeSlot
            }
            onChangeTimePicker={(newVal) => {
              if (selectedDate) {
                handleAddCustomTimes(newVal, "startTime");
              } else {
                onChangetimeSlotObj(newVal, "startTime");
              }
            }}
          />
        </div>

        <div className="eachDateStart">
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                onError={false}
                sx={{ width: "100%" }}
                label={AautiStrings.EndTimeString}
                ampm={false}
                minTime={dayjs(endTimeValue)}
                value={dayjs(
                  selectedDate
                    ? customCourseBatch?.customDates?.[selectedDate]?.endTime
                      ? customCourseBatch?.customDates?.[selectedDate]?.endTime
                      : null
                    : customCourseBatch?.endTime || endTimeValue
                )}
                timeSteps={{ minutes: 30 }}
                onChange={(newVal) => {
                  if (selectedDate) {
                    handleAddCustomTimes(newVal, "endTime");
                  } else {
                    onChangetimeSlotObj(newVal, "endTime");
                  }
                }}
                slotProps={{ field: { readOnly: true } }}
              />
            </DemoContainer>
          </LocalizationProvider> */}
          <AautiTimePicker
            disabled={
              moment(returnEndTimerValue())?.format("hh:mm A") ==
              moment()?.endOf("day")?.format("hh:mm A")
            }
            label={AautiStrings.EndTimeString}
            minTime={endTimeValue}
            timerValue={returnEndTimerValue()}
            onChangeTimePicker={(newVal) => {
              if (selectedDate) {
                handleAddCustomTimes(newVal, "endTime");
              } else {
                onChangetimeSlotObj(newVal, "endTime");
              }
            }}
          />
        </div>
        {/* <IconButton>
          <AddCircleOutlineOutlinedIcon fontSize="large" />
        </IconButton> */}
      </div>
      <CustomBatchDetails
        handleClickTime={() => {
          setSelectedDate("");
        }}
        isDisplay={false}
        courseBatch={customCourseBatch}
        {...{ handleRemoveExclude, handleRemoveCustom, selectedDate }}
      />
      <NavButton
        text={"Save"}
        handleClick={() => {
          if (
            selectedDate &&
            !customCourseBatch?.customDates?.[selectedDate]?.startTime
          ) {
            showToast("info", "Please set timings");
          } else if (isEmpty(customCourseBatch?.excludedDates)) {
            showToast(
              "info",
              "Excluded Dates cannot be empty, select date to be excluded"
            );
          } else {
            setSelectedDate("");
            handleSaveCustomSlot(customCourseBatch);
          }
        }}
      />
    </div>
  );
};

export default CustomSlotSelection;
