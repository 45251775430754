import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import AautiTextInput from "../AautiTextInput";
import { IconButton } from "@mui/material";
import { IoTimeOutline } from "react-icons/io5";

const endOfDay = moment().endOf("day");
const startofDay = moment().startOf("day");

const AautiTimePicker = ({
  label = "",
  minTime = startofDay,
  maxTime = endOfDay,
  timerValue = new Date(),
  dateObj,
  time,
  onChangeTimePicker = () => {},
  timeIntervals = 30,
  readOnly,
  disabled = false,
}) => {
  return (
    <div style={{ width: "100%" }} id="aauti-time-picker">
      <DatePicker
        disabled={disabled}
        readOnly={readOnly}
        popperPlacement="bottom-left"
        onKeyDown={(e) => {
          e.preventDefault();
        }}
        showPopperArrow={false}
        placeholderText="time selection"
        customInput={
          <AautiTextInput
            size={"medium"}
            inputStyle={{
              "&.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
                paddingRight: "0px !important",
              },
            }}
            fullWidth
            style={{ width: "100%", paddingRight: "0px !important" }}
            text={label}
            endAdornment={
              <IconButton sx={{ mr: -2 }}>
                <IoTimeOutline />
              </IconButton>
            }
          />
        }
        minTime={new Date(minTime)}
        maxTime={new Date(maxTime)}
        selected={timerValue ? new Date(timerValue) : null}
        onChange={onChangeTimePicker}
        showTimeSelect
        showTimeSelectOnly
        showTimeCaption={false}
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat="hh:mm aa"
        dropdownMode="select"
      />
    </div>
  );
};

export default AautiTimePicker;
