import React, { useEffect, useState } from "react";
import "../ratingReviews/Style.scss";
import { Rating, Stack } from "@mui/material";
import AautiDialog from "./AautiDialog";
import AautiText from "./AautiText";
import NoImage from "../../assets/images/no-image.svg";
import AautiTextInput from "./AautiTextInput";
import JoinButton from "./JoinButton";
import { useSelector } from "react-redux";
import { globalColors } from "../../themes/GlobalColors";
import { checkNull } from "../../utils/Helpers";
import { isEmpty } from "lodash";
import { spStrings } from "../serviceProviderDetails/Constants";

const CourseRatingDialog = ({
  setOpen,
  open,
  setRatingValue,
  ratingValue,
  setReviewComment,
  reviewComment,
  ratingAPI,
  orderID,
  setOrderID,
  editRating,
  updateRating,
  setEditRating,
  toggleChatDialog,
  setOpen2,
  screenName,
  type,
  suggestionsArray,
  suggestionsList,
  setSuggestionsList,
  setOncloseRefresh
}) => { 
  const { mobileDevices, aboveLargeMobileDevices, ipodDevices, miniLapys, lapy } = useSelector(
    (state) => state.responsive
  );
  const { properties } = useSelector(
    (state) => state.app
  );
  const propertiesType = type === "course" ? "courseReviewSuggestions" :
    type === "gig" ? "gigReviewSuggestions" :
      type === "adhoc" ? "adhocReviewSuggestions" :
        'serviceProviderReviewSuggestions'
  const [originalReviewComment, setOriginalReviewComment] = useState("");
  const [originalRatingValue, setOriginalRatingValue] = useState(null);
  const [suggestionsBul, setSuggestionsBul] = useState(false)
  useEffect(() => {
    if (editRating) {
      setOriginalReviewComment(reviewComment);
      setOriginalRatingValue(ratingValue);
    }
  }, [editRating]);
  const disableSubmit = () => {
    if (editRating) {
      if (suggestionsBul && !checkNull(ratingValue) && reviewComment?.length > 2) {
        return false
      }
      const ratingPresent = !checkNull(ratingValue);
      const ratingChanged = ratingValue !== originalRatingValue;
      const commentChanged = reviewComment !== originalReviewComment;
      const reviewCommentVal = reviewComment?.length > 2;
      return !(ratingPresent && reviewCommentVal && (ratingChanged || commentChanged));
    };
    return !ratingValue || reviewComment?.length < 3
  };

  const handleState = () => {
    setRatingValue("");
    setReviewComment("");
    setOpen(false);
    setSuggestionsList([])
  };
  return (
    <AautiDialog
      open={open}
      onClose={() => {
        handleState();
        if (screenName === "CourseScreen") {
          toggleChatDialog(false);
          setOpen2(false);
        }
      }}
      dialogTitle={editRating ? "Edit Review" : "Add a Review"}
    >
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          width: "100%",
          overflow: "hidden", rowGap: 10
        }}
      >
        {screenName === 'spDetails' ? null :
          <div className="RRchield1div3Course">
            <img
              alt="Course"
              src={
                orderID?.courseImage ||
                orderID?.gigImage ||
                orderID?.adhocImage ||
                NoImage
              }
              style={{
                width: mobileDevices ? 40 : 60,
                height: mobileDevices ? 40 : 60,
                borderRadius: "50%",
                marginRight: mobileDevices ? 4 : 8,
              }}
            />
            <div className="RRchield2commentdivcourse">
              <AautiText
                textStyle={{
                  color: " #333333",
                  // margin: "4px 0px",
                }}
                weight={"extraBold"}
                size={"normal"}
                title={
                  orderID?.courseName ||
                  orderID?.gigName ||
                  orderID?.adhocName ||
                  "Name not Available"
                }
              />
              <AautiText
                textStyle={{ color: "#4b4b4b" }}
                weight={"medium"}
                size={"semi"}
                title={"Your review will be posted publicly"}
              />
            </div>
          </div>}
        <div className="RRaddReviewdivstar" id="stars-container">
          <Stack spacing={1}>
            <Rating
              id="Stars"
              itemID={`star`}
              name="size-large"
              defaultValue={2}
              sx={{ color: globalColors.AAUTI_THEME_COLOR, fontSize: 55 }}
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
            />
          </Stack>
        </div>
        <>
          <AautiText
            title={spStrings.Telluswhatcanbeimproved}
            size={"normal"}
            textStyle={{ color: globalColors.HEADING_COLOR }}
          />
          <div className="SPratingnewarrayDiv">
            {properties?.[propertiesType]?.map((each, index) => (
              <div
                className="SPratingNewArrayDiv2"
                key={index}
                style={{
                  backgroundColor: suggestionsList?.includes(each?._id)
                    ? globalColors.AAUTI_THEME_COLOR
                    : globalColors.Aauti_White,
                }}
                onClick={() => {
                  suggestionsArray(each?._id);
                  if (editRating) {
                    setSuggestionsBul(true)
                  }
                }}
              >
                <AautiText
                  size={"semi"}
                  weight={'normal'}
                  title={each?.name}
                  textStyle={{
                    color: suggestionsList?.includes(each?._id)
                      ? globalColors.Aauti_White
                      : globalColors.HEADING_COLOR,
                    whiteSpace: "nowrap",
                  }}
                />
              </div>
            ))}
          </div>
        </>

        <AautiTextInput
          value={reviewComment}
          style={{
            width: "100%",
            borderRadius: "5px",
            border: "none",
          }}
          onChange={(e) => {
            setReviewComment(e.target.value);
          }}
          text={"Write your review"}
          multiline
          rows={4}
          maxLength={300}
          important
          helperText={`${reviewComment?.length}/300 (minimum 3 characters)`}
        />
        {editRating ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <JoinButton
              onClick={() => {
                setOpen(false);
                setEditRating(false);
              }}
              outlined
              buttonStyle={{
                marginTop: 20,
                marginRight: "10px",
                width: "30%",
                height: (mobileDevices || aboveLargeMobileDevices) ? 40 : 50
              }}
              title={"Cancel"}
            />

            <JoinButton
              disabled={disableSubmit()}
              onClick={() => {
                updateRating();
                handleState();
                setEditRating(false); 
                if (screenName !== "Orders" && ratingValue !== originalRatingValue) { 
                  setOncloseRefresh(true);
                }
              }}
              buttonStyle={{
                marginTop: 20, width: "30%",
                height: (mobileDevices || aboveLargeMobileDevices) ? 40 : 50
              }}
              title={"Update"}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 10,
            }}
          >
            <JoinButton
              outlined
              color={globalColors.AAUTI_THEME_COLOR}
              onClick={() => {
                setOrderID && setOrderID("");
                handleState();
              }}
              buttonStyle={{
                marginTop: 20, marginRight: "10px", width: "30%",
                height: (mobileDevices || aboveLargeMobileDevices) ? 40 : 50
              }}
              title={"Cancel"}
            />
            <JoinButton
              title={"Submit"}
              disabled={disableSubmit()}
              onClick={() => {
                ratingAPI(orderID);
                setOrderID && setOrderID("");
                handleState();
                if (screenName !== "Orders") {
                  setOncloseRefresh(true);
                }
              }}
              buttonStyle={{
                marginTop: 20, width: "30%",
                height: (mobileDevices || aboveLargeMobileDevices) ? 40 : 50
              }}
            />
          </div>
        )}
      </div>
    </AautiDialog>
  );
};

export default CourseRatingDialog;
