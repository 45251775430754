import React from "react";
import Carousel from "react-grid-carousel";
import CourseImage from "../../globalComponents/CourseImage";
import "./index.scss";
import { Box, IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

const CourseImages = ({
  imagesList,
  setActiveField,
  showIcon = true,
  gigObject,
  isGig,
}) => {
  return (
    <div style={{ marginBottom: 20, position: "relative" }} className="tab">
      <Carousel
        cols={1}
        rows={1}
        hideArrow={true}
        showDots={true}
        gap={10}
        autoplay={3000}
        loop={true}
      >
        {imagesList?.map((each, index) => {
          return (
            <Carousel.Item key={index}>
              <CourseImage imgUrl={each} height="400px" showBackdrop={true} />
            </Carousel.Item>
          );
        })}
        {isGig && <Box></Box>}
      </Carousel>
      {showIcon && (
        <IconButton
          onClick={() => {
            setActiveField("Course Image");
          }}
          sx={{ position: "absolute", top: 2, right: 2, background: "#d2d2d2" }}
        >
          <Edit fontSize="small" />
        </IconButton>
      )}
    </div>
  );
};

export default CourseImages;
