import React from "react";
import "./Style.scss";
import AautiText from "../../globalComponents/AautiText";
import ClearIcon from "@mui/icons-material/Clear";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import AautiDropDown from "../../globalComponents/AautiDropDown";
import { useSelector } from "react-redux";
import JoinButton from "../../globalComponents/JoinButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { FaTrashAlt } from "react-icons/fa";
import { topicDurations } from "../../createLessonPlan/Constants";
import { createCourseStrings } from "./Constants";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { showToast } from "../../globalComponents/Toast";

const Sessions = (props) => {
  const { planObj, changePlanObject, sessionObject, topicObject, saveLesson } =
    props;
  const { ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );
  const removeSession = (position) => {
    const a = planObj?.sessions?.filter((each, index) => {
      return index !== position;
    });
    let modify = a.map((each, index) => {
      return { ...each, sessionName: `Session ${index + 1}` };
    });
    changePlanObject(modify, "sessions");
  };

  const addTopicInLesson = (position) => {
    const currentTopics = [...planObj?.sessions[position].topics, topicObject];
    const updateTopics = planObj?.sessions.map((each, index) => {
      if (index === position) {
        return { ...each, topics: currentTopics };
      } else {
        return each;
      }
    });
    changePlanObject(updateTopics, "sessions");
  };

  const onChangeTopicValue = (val, position, feild, topicIndex) => {
    const topics = planObj?.sessions?.[position]?.topics.map((each, index) => {
      if (index === topicIndex) {
        return {
          ...each,
          [feild]: val,
        };
      } else {
        return each;
      }
    });
    const sessions = planObj?.sessions.map((each, index) => {
      if (index === position) {
        return { ...each, topics: topics };
      } else {
        return each;
      }
    });
    changePlanObject(sessions, "sessions");
  };

  const isButtonDisabled = () => {
    let isValid = [];
    planObj?.sessions?.map((each) => {
      each?.topics?.map((item) => {
        const disableCheck = item?.topicName?.length > 4;
        if (disableCheck) {
          isValid.push(true);
        } else {
          isValid.push(false);
        }
      });
    });
    const isIncludes = isValid.includes(false) ? true : false;
    return isIncludes;
  };

  const removeTopic = (topicIndex, sessionInd) => {
    const removeTopicFromLesson = planObj?.sessions[sessionInd].topics.filter(
      (each, index) => {
        return topicIndex !== index;
      }
    );

    const sessionEdited = planObj?.sessions.map((each, index) => {
      if (index === sessionInd) {
        return { ...each, topics: removeTopicFromLesson };
      } else {
        return each;
      }
    });
    changePlanObject(sessionEdited, "sessions");
  };

  return (
    <div className="sessions-tab">
      <div style={{ width: "100%" }} className="enter-sessions-map">
        {planObj?.sessions.map((each, index) => {
          return (
            <div className="each-enter-session" key={index}>
              <div className="session-enter-cross">
                <AautiText title={`Session ${index + 1}`} />
                {planObj?.sessions?.length > 1 && (
                  <ClearIcon
                    onClick={() => {
                      removeSession(index);
                    }}
                    sx={{
                      backgroundColor: "#dddddd",
                      borderRadius: "50%",
                      p: 0.2,
                      color: "grey",
                      cursor: "pointer",
                    }}
                    fontSize="small"
                  />
                )}
              </div>
              {each?.topics?.map((item, ind) => {
                return (
                  <div
                    className="enter-title-duration"
                    style={{ width: each?.topics.length > 1 ? "100%" : "97%" }}
                  >
                    <AautiTextInput
                      text={`Topic ${ind + 1} `}
                      important
                      maxLength={50}
                      style={{ width: "95%" }}
                      value={item?.topicName}
                      onChange={(e) => {
                        onChangeTopicValue(
                          e.target.value,
                          index,
                          "topicName",
                          ind
                        );
                      }}
                      helperText={`${
                        item?.topicName?.length || 0
                      }/50 (minimum 5 characters)`}
                    />
                    {/* <AautiDropDown
                      data={topicDurations}
                      style={{ width: "25%" }}
                      value={item?.duration}
                      onChange={(e) => {
                        onChangeTopicValue(
                          e.target.value,
                          index,
                          "duration",
                          ind
                        );
                      }}
                    /> */}
                    {each?.topics.length > 1 && (
                      <FaTrashAlt
                        onClick={() => {
                          removeTopic(ind, index);
                        }}
                        size={15}
                        style={{ color: "black", cursor: "pointer" }}
                      />
                    )}
                  </div>
                );
              })}

              <AautiText
                title={createCourseStrings?.PlusSymAddTopic}
                size={"small"}
                weight={"bold"}
                textStyle={{
                  color: "#078dda",
                  cursor: "pointer",
                  marginBottom: 10,
                  // width: "13%",
                }}
                onClick={() => {
                  addTopicInLesson(index);
                }}
              />
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "99%",
          alignItems: "center",
          paddingBottom: "3%",
          paddingTop: "3%",
        }}
      >
        <button
          className="previewbutt"
          onClick={() => {
            let length = planObj?.sessions?.length + 1;
            changePlanObject(
              [
                ...planObj?.sessions,
                { ...sessionObject, sessionName: `Session ${length}` },
              ],
              "sessions"
            );
          }}
          style={{
            height: mobileDevices ? "40px" : ipodDevices ? "45px" : "50px",
            width: mobileDevices
              ? "150px"
              : ipodDevices
              ? "155px"
              : "160px" || "fit - content",
            borderRadius: "5px",
            padding: mobileDevices ? "5px" : "10px",
            marginRight: "5%",
            display: planObj?.sessions?.length >= 10 && "none",
          }}
        >
          <AautiText
            size={"semi"}
            title={AautiStrings?.addSessionString}
            weight={"normal"}
            textStyle={{ color: "#000000" }}
          />
        </button>
        <div>
          <JoinButton
            disabled={isButtonDisabled()}
            title={"Save"}
            onClick={() => {
              saveLesson();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Sessions;
