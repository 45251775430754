import moment from "moment";
import { useRef } from "react";
import { show12hr } from "../components/globalComponents/helpers.variables";

export const renderNA = true;

export function getDateTimeDifference(dateTime) {
  if (!moment(dateTime).isValid()) {
    return "";
  }
  const momentDate = moment.utc(dateTime).local();
  const currentDate = moment().local();
  if (momentDate.isValid()) {
    if (momentDate.isSame(currentDate, "date")) {
      if (Math.abs(momentDate.diff(currentDate)) < 6000) {
        return "just now";
      } else {
        const hoursDiff = Math.abs(momentDate.diff(currentDate, "h"));
        if (hoursDiff < 1) {
          const minutesDiff = Math.abs(momentDate.diff(currentDate, "minutes"));
          if (minutesDiff < 1) {
            return "just now";
          }
          return `${minutesDiff} min${minutesDiff > 1 ? "s" : ""} ago`;
        }
        return `${hoursDiff} hr${hoursDiff > 1 ? "s" : ""} ago`;
      }
    }
    if (momentDate.isBefore(currentDate, "date")) {
      const diff = Math.abs(momentDate.diff(moment().local(), "days"));
      if (diff == 1) {
        return "Yesterday";
      }
      return momentDate.format("DD/MM/YYYY");
    }
  }
  return "N/A";
}

export function checkNull(value) {
  return (
    value === undefined ||
    value === null ||
    value === "" ||
    value === "null" ||
    value?.length === 0 ||
    value == 0
  );
}

export function checkNA(data) {
  if (renderNA) {
    return checkNull(data) ? "N/A" : data;
  } else {
    return checkNull(data) ? "" : data;
  }
}
export const removePlusAtStart = (value) => {
  const string = value || "";
  return string.startsWith("+") ? string.slice(1) : string;
};
export const pluralOrSingular = (num, str, es = false) => {
  if (num != 1) {
    return es ? str + "es" : str + "s";
  } else {
    return str;
  }
};

export const convertMultipleSpacesToSingleSpace = (str) => {
  //Preventing the string starting with a space
  if (str?.length == 1 && str == " ") {
    return "";
  }
  // Use a regular expression to match multiple spaces
  const regex = /\s+/g;

  // Replace all occurrences of multiple spaces with a single space
  return str?.replace(regex, " ");
};

export function capitalizeWordFirstLetter(string) {
  var grammar = ["a", "an", "the", "and", "or", "of", "are", "for"];
  if (checkNull(string)) return "";
  var capString = string?.[0]?.toUpperCase() + string?.slice(1)?.toLowerCase();
  capString = capString
    .split(" ")
    .map(function (word) {
      return !grammar.includes(word)
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word;
    })
    .join(" ");
  return capString;
}

export function isValidPassword(password) {
  let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  return reg.test(password);
}

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export function prettifyFileSize(bytes, precision = 3) {
  const units = ["B", "kB", "MB", "GB"];
  const exponent = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    units.length - 1
  );
  const mantissa = bytes / 1024 ** exponent;
  const formattedMantissa =
    precision === 0
      ? Math.round(mantissa).toString()
      : mantissa.toPrecision(precision);
  return `${formattedMantissa} ${units[exponent]}`;
}

const BOUNCE_RATE = 3500;
export const useDebounce = () => {
  const busy = useRef(false);
  const debounce = async (callback, bounceRate = BOUNCE_RATE) => {
    let timerId = setTimeout(() => {
      busy.current = false;
      clearTimeout(timerId);
    }, bounceRate);

    if (!busy.current) {
      busy.current = true;
      callback();
    }
  };

  return { debounce };
};

export const formatPercentage = (value) => {
  return value % 1 === 0 ? value : value?.toFixed(2);
};

export const isAlreadyPurchased = (
  purchasedArray = [],
  presentUserObj = {},
  type = "course"
) => {
  let filter = purchasedArray?.filter((each) => {
    if (each?.userId === presentUserObj?.userId) {
      if (type == "course") {
        if (each?.batchId == presentUserObj?.batchId) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  });

  if (filter?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const formatTime = ({ time, format }) => {
  const timeFormat = format ? format : show12hr ? "hh:mm A" : "HH:mm";
  return moment(time).format(timeFormat);
};
export const formatStartandEndTime = ({ start, end, format }) => {
  return `${formatTime({ time: start, format: format })}-${formatTime({
    time: end,
    format: format,
  })}`;
};
export function formatDate({ date, format = "YYYY-MM-DD" }) {
  return moment(date).local().format(format);
}
