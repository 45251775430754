import { Box, Button, Container } from "@mui/material";
import React, { useState } from "react";
import { chatConstants } from "../../../constants";
import AautiDialog from "../../../../globalComponents/AautiDialog";
import { DownloadRounded } from "@mui/icons-material";
import { BsFileEarmarkText } from "react-icons/bs";
import AautiText from "../../../../globalComponents/AautiText";
import FileInfo from "../../file_info";
import { StyledMsgContainer } from "../../styled_components";

function MessageFile({ currentMessage : { files }, position, reply = false,searchText }) {
  const [selectedFile, setSelectedFile] = useState(undefined);
  return (
    <StyledMsgContainer>
      <BsFileEarmarkText style={{alignSelf:'center'}} size={40} onClick={()=>setSelectedFile(files[0])} /> 
      <FileInfo file={files[0]}/>
      <AautiDialog
        open={Boolean(selectedFile)}
        onClose={() => {
          setSelectedFile(undefined);
        }}
        dialogTitle={chatConstants?.previewFile}
      >
        <Container style={{height:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',border:'1px solid red'}}>
        <BsFileEarmarkText size={30} />
        <AautiText
            title={'Unable to preview file'}
        />
        <div>
          <Button
            color="primary"
            href={selectedFile?.uri}
            target="_blank"
            download={selectedFile?.name}
          >
            <DownloadRounded /> Download
          </Button>
        </div>
        </Container>
      </AautiDialog>
    </StyledMsgContainer>
  );
}

export default MessageFile;
