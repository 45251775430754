import React, { useEffect, useState } from "react";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { getNextTimeSlot, weekName } from "../createCourse/Constants";
import moment from "moment";
import AautiText from "../../globalComponents/AautiText";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import _, { isEmpty } from "lodash";
import { Divider, IconButton, Popover } from "@mui/material";
import AautiCheckbox from "../../globalComponents/AautiCheckbox";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import NavButton from "./NavButton";
import TrashIcon from "../../globalComponents/icons/TrashIcon";
import { BsCheck } from "react-icons/bs";
import { globalColors } from "../../../themes/GlobalColors";
import AautiTimePicker from "../../globalComponents/timepickers/AautiTimePicker";

const WeeklySlotSelection = ({
  courseBatch,
  editItem = {},
  setCourseBatch,
  setDialogOpen,
}) => {
  const [activeWeek, setActiveWeek] = useState("");
  const [recurringObject, setRecurringObject] = useState({});
  const nextTimeSlot = getNextTimeSlot();

  const { mobileDevices, ipodDevices, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const WeekArray =
      courseBatch?.recurringDays &&
      Object.keys(courseBatch?.recurringDays)?.filter(
        (day) => courseBatch?.recurringDays?.[day]?.length > 0
      );

    if (!isEmpty(WeekArray)) {
      // Find the first key with data and update activeWeek
      const firstDayWithData = Object.keys(courseBatch?.recurringDays)?.find(
        (day) => courseBatch?.recurringDays?.[day]?.length > 0
      );
      setActiveWeek(firstDayWithData); // Set the activeWeek state to the first key with data
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (courseBatch?.recurringDays) {
      setRecurringObject(courseBatch?.recurringDays);
    } else {
      const createWeekObject = (days) => {
        const week = {};
        days.forEach((day) => {
          week[day] = [];
        });
        return week;
      };

      const weekObject = createWeekObject(weekName);
      setRecurringObject(weekObject);
    }
  }, [courseBatch]);

  const endTime =
    courseBatch?.recurringDays && courseBatch?.recurringDays?.[activeWeek]
      ? moment(courseBatch?.recurringDays?.[activeWeek]?.[0]?.startTime)
          .add(1, "hour")
          ?.format("YYYY-MM-DDTHH:mm:ss")
      : moment(nextTimeSlot)?.add(1, "hour")?.format("YYYY-MM-DDTHH:mm:ss");
  const editEndTime = moment(
    editItem?.each?.recurringDays?.[activeWeek]?.[0]?.startTime
  )
    ?.add(1, "hour")
    ?.format("YYYY-MM-DDTHH:mm:ss");

  const addWeekSlotEndTime = (val) => {
    const timeFromat = moment(val).format("YYYY-MM-DDTHH:mm:00");
    const slot = {
      startTime: courseBatch.recurringDays[activeWeek]?.startTime,
      endTime: timeFromat,
    };
    let recurringDays = returnRecurringSlots();
    let activeDay = [...(courseBatch?.recurringDays?.[activeWeek] || [])];
    if (isEmpty(activeDay)) {
      activeDay?.push({ endTime: timeFromat });
    } else {
      activeDay[0].endTime = timeFromat;
    }
    recurringDays[activeWeek] = activeDay;
    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: recurringDays,
    }));
  };
  const addWeekSlot = (val) => {
    const timeFromat = moment(val).format("YYYY-MM-DDTHH:mm:00");

    const slot = {
      startTime: timeFromat,
      endTime: "",
    };

    let recurringDays = returnRecurringSlots();

    let activeDay = (courseBatch?.recurringDays?.[activeWeek] || []).map(
      (slot) => ({
        ...slot,
      })
    );

    let formatedEndTime = moment(timeFromat)
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm:ss");
    if (
      moment(formatedEndTime)?.format("YYYY-MM-DD") !==
      moment(timeFromat)?.format("YYYY-MM-DD")
    ) {
      formatedEndTime = moment(timeFromat).endOf("day");
    }

    if (isEmpty(activeDay)) {
      activeDay.push({
        startTime: timeFromat,
        endTime: formatedEndTime,
      });
    } else {
      activeDay[0] = {
        startTime: timeFromat,
        endTime: formatedEndTime,
      };
    }

    // Update recurringDays with the modified activeDay
    recurringDays[activeWeek] = activeDay;
    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: recurringDays,
    }));
  };

  const handleUpdateSlotWeek = (week, bool, obj) => {
    let recurringDays = courseBatch?.recurringDays;
    if (bool) {
      recurringDays[week] = [obj];
      setCourseBatch((prev) => ({
        ...prev,
        recurringDays: recurringDays,
      }));
    } else {
      recurringDays[week] = [];
      setCourseBatch((prev) => ({
        ...prev,
        recurringDays: recurringDays,
      }));
    }
  };

  function compareSlots(slot1, slot2) {
    const bool = _.isEqual(slot1, slot2);
    return bool;
  }

  const checkSelectAll = () => {
    const { recurringDays } = courseBatch;
    if (recurringDays) {
      const referenceArray = JSON.stringify(Object.values(recurringDays)[0]);
      return Object.values(recurringDays)?.every(
        (dayArray) => JSON.stringify(dayArray) === referenceArray
      );
    } else {
      return false;
    }
  };

  const returnRecurringSlots = () => {
    return {
      ...courseBatch?.recurringDays,
      Sunday: [...(courseBatch?.recurringDays?.Sunday || [])],
      Monday: [...(courseBatch?.recurringDays?.Monday || [])],
      Tuesday: [...(courseBatch?.recurringDays?.Tuesday || [])],
      Wednesday: [...(courseBatch?.recurringDays?.Wednesday || [])],
      Thursday: [...(courseBatch?.recurringDays?.Thursday || [])],
      Friday: [...(courseBatch?.recurringDays?.Friday || [])],
      Saturday: [...(courseBatch?.recurringDays?.Saturday || [])],
    };
  };

  const handleAllWeek = (actWeek, bool, obj) => {
    const schedule = returnRecurringSlots();
    let updatedSchedule;
    if (bool) {
      updatedSchedule = Object.keys(schedule).reduce((acc, day) => {
        acc[day] = [obj]; // Add the default time slot
        return acc;
      }, {});
    } else {
      updatedSchedule = Object.keys(schedule).reduce((acc, day) => {
        if (day === actWeek) {
          acc[day] = schedule[day]; // Keep existing time slots for the specified day
        } else {
          acc[day] = []; // Clear other days
        }
        return acc;
      }, {});
    }

    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: updatedSchedule,
    }));
  };
  const hasValues =
    courseBatch?.recurringDays &&
    Object.values(courseBatch?.recurringDays)?.some(
      (timeSlots) => timeSlots.length > 0
    );

  const handleDeleteSlot = () => {
    let recurringDays = returnRecurringSlots();
    recurringDays[activeWeek] = [];
    setCourseBatch((prev) => ({
      ...prev,
      recurringDays: recurringDays,
    }));
  };

  const returnMinTime = () => {
    const { startDate, endDate } = courseBatch;
    const today = moment().startOf("day");

    // Convert startDate and endDate to moment objects and set to start of the day
    const start = moment(startDate).startOf("day");
    const end = moment(endDate).startOf("day");

    // Check if today's date is within the range
    if (today.isBetween(start, end, null, "[]")) {
      // '[]' includes start and end dates
      //compare week and return min time
      const todayDayOfWeek = today.format("dddd");
      if (todayDayOfWeek === activeWeek) {
        return nextTimeSlot;
      } else {
        return today;
      }
    } else {
      return today;
    }
  };

  return (
    <>
      <div className="weeknames-map" style={{ gap: 4 }}>
        {Object.keys(recurringObject)?.map((each) => {
          const hasData = recurringObject[each]?.length;
          return (
            <div
              onClick={() => {
                setActiveWeek(each);
              }}
              className="weekNae"
              style={{
                backgroundColor:
                  activeWeek === each
                    ? globalColors.AAUTI_THEME_COLOR
                    : "transparent",
                borderColor:
                  hasData || activeWeek === each
                    ? globalColors.AAUTI_THEME_COLOR
                    : "#d2d2d2",
                color: activeWeek === each && "white",
                flex: 1,
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
              }}
            >
              {hasData ? <BsCheck size={18} style={{}} /> : null}
              <AautiText
                textAlign={"center"}
                textStyle={{}}
                title={each?.slice(0, mobileDevices ? 1 : 3)}
                size={"semi"}
                weight={"bold"}
              />
            </div>
          );
        })}
      </div>
      {activeWeek !== "" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              // marginTop: 8,
            }}
          >
            <AautiText title={activeWeek} textStyle={{ color: "#000" }} />
            {!isEmpty(recurringObject?.[activeWeek]) && (
              <IconButton
                disabled={isEmpty(recurringObject[activeWeek])}
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                <ContentCopyOutlinedIcon />
              </IconButton>
            )}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{ padding: 10 }}>
                <AautiCheckbox
                  onChange={(e) => {
                    const defaultObj = recurringObject[activeWeek]?.[0];
                    const value = e.target.checked;
                    console.log(defaultObj, "defailt");
                    handleAllWeek(activeWeek, value, defaultObj);
                    // handleUpdateSlotWeek(week, value, defaultObj);
                  }}
                  checked={checkSelectAll()}
                  key={"selectAl"}
                  inputId={"selectAll"}
                  inputLabel={"Select All"}
                />
                <Divider />
                {weekName?.map((week, index) => {
                  const hasData = recurringObject[week]?.length;
                  const activeWeekObj = recurringObject[activeWeek]?.[0];
                  const weekObject = recurringObject[week]?.[0];
                  return (
                    <AautiCheckbox
                      // disable={activeWeek === week}
                      onChange={(e) => {
                        if (activeWeek == week) {
                          handleClose();
                        }
                        const defaultObj = recurringObject[activeWeek]?.[0];
                        const value = e.target.checked;
                        handleUpdateSlotWeek(week, value, defaultObj);
                      }}
                      checked={compareSlots(activeWeekObj, weekObject)}
                      key={index}
                      inputId={week}
                      inputLabel={week}
                    />
                  );
                })}
              </div>
            </Popover>
          </div>
          <div
            className="start-end-time"
            style={{
              width: "100%",
              border: "none",
              gap: 5,
              padding: 0,
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "50%",
              }}
              className="eachDateStart"
            >
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["Time Picker"]}>
                  <DemoItem label={AautiStrings?.StartTimeString}>
                    <TimePicker
                      timeSteps={{ minutes: 30 }}
                      ampm={false}
                      // minTime={
                      //   moment(dailyObj?.startDate).format("YYYY-MM-DD") ===
                      //   moment(new Date()).format("YYYY-MM-DD")
                      //     ? dayjs(nextTimeSlot)
                      //     : null
                      // }
                      // disablePast={
                      //   moment(courseBatch?.startDate).format("YYYY-MM-DD") ===
                      //   moment(new Date()).format("YYYY-MM-DD")
                      // }
                      minTime={returnMinTime()}
                      value={
                        recurringObject?.[activeWeek][0]?.startTime
                          ? dayjs(recurringObject?.[activeWeek][0]?.startTime)
                          : null
                      }
                      onChange={(e) => addWeekSlot(e, "startTime")}
                      slotProps={{ field: { readOnly: true } }}
                      minutesStep={30}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider> */}
              <AautiTimePicker
                minTime={returnMinTime()}
                timerValue={
                  recurringObject?.[activeWeek][0]?.startTime
                    ? recurringObject?.[activeWeek][0]?.startTime
                    : null
                }
                onChangeTimePicker={(e) => addWeekSlot(e, "startTime")}
                label={AautiStrings?.StartTimeString}
              />
            </div>
            <div
              className="eachDateStart"
              style={{
                width: "50%",
              }}
            >
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <DemoItem label={AautiStrings?.EndTimeString}>
                    <TimePicker
                      disabled={
                        courseBatch?.recurringDays &&
                        !courseBatch?.recurringDays?.[activeWeek]?.[0]
                          ?.startTime
                      }
                      ampm={false}
                      timeSteps={{ minutes: 30 }}
                      minTime={editItem ? dayjs(editEndTime) : dayjs(endTime)}
                      value={
                        recurringObject?.[activeWeek][0]?.endTime
                          ? dayjs(recurringObject?.[activeWeek][0]?.endTime)
                          : null
                      }
                      onChange={(e) => {
                        addWeekSlotEndTime(e, "endTime");
                      }}
                      slotProps={{ field: { readOnly: true } }}
                      minutesStep={30}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider> */}
              <AautiTimePicker
                disabled={
                  (courseBatch?.recurringDays &&
                    !courseBatch?.recurringDays?.[activeWeek]?.[0]
                      ?.startTime) ||
                  moment(recurringObject?.[activeWeek][0]?.endTime)?.format(
                    "hh:mm A"
                  ) == moment()?.endOf("day")?.format("hh:mm A")
                }
                minTime={editItem ? editEndTime : endTime}
                timerValue={
                  recurringObject?.[activeWeek][0]?.endTime
                    ? recurringObject?.[activeWeek][0]?.endTime
                    : null
                }
                label={AautiStrings?.EndTimeString}
                onChangeTimePicker={(e) => {
                  addWeekSlotEndTime(e, "endTime");
                }}
              />
            </div>
            {!isEmpty(courseBatch?.recurringDays?.[activeWeek]) && (
              <TrashIcon
                buttonStyle={{ marginTop: 1 }}
                onClick={() => {
                  handleDeleteSlot();
                }}
                height="30px"
                width="30px"
              />
            )}
          </div>
        </div>
      )}
      <NavButton
        text={"Done"}
        testId={"week-done-button"}
        disabled={!hasValues}
        handleClick={() => {
          setDialogOpen("");
        }}
      />
    </>
  );
};

export default WeeklySlotSelection;
