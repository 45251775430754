import React, { useEffect, useRef, useState } from "react";
import { AuthService } from "../../../service";
import { useSelector } from "react-redux";
import AautiText from "../../globalComponents/AautiText";
import { useLocation, useNavigate } from "react-router-dom";
import { showToast } from "../../globalComponents/Toast";
import {
  getGroupBatchesByCourseId,
  saveInternalTransfer,
  saveRequestBatchChange,
} from "./Services";
import EmptyOrders from "../../../assets/images/EmptyOrders.png";
import CoursesLoader from "./CoursesLoader";
import "./Style.scss";
import { isEmpty } from "lodash";
import { Drawer, CircularProgress } from "@mui/material";
import BatchTransfer from "./BatchTransfer";
import Coursecard from "../../appCards/Coursecard";
import AppContainer from "../../AppContainer";
import AccountTabs from "../../globalComponents/AccountTabs";
import EmptyTopic from "../../globalComponents/EmptyTopic";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { checkNull } from "../../../utils/Helpers";
import moment from "moment";
import AautiSearch from "../../globalComponents/AautiSearch";
import AautiInfiniteScroll from "../../features/InfiniteScroll";

const MyLearnings = () => {
  const { loggedinUserId, UserRole, userDetails } = useSelector(
    (state) => state.app
  );

  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const { innerWidth: windowInnerWidth } = window;
  const [filteredEndUserCourses, setFilteredEndUserCourses] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [loading, setLoading] = useState(false);
  const learnRef = useRef(null);
  const navigate = useNavigate();
  const [batchesList, setBatchesList] = useState([]);
  const [previousBatch, setPreviousBatch] = useState(null);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [reason, setReason] = useState("");
  const location = useLocation();

  const buttonsList = [
    { text: "Courses", value: "courses" },
    { text: "Adhoc Classes", value: "adhoc" },
  ];
  const [activeState, setActiveState] = useState(buttonsList[0]?.value);
  const [activeTab, setActiveTab] = useState("regular");
  const [hasData, setHasData] = useState(true);
  const [offSet, setOffset] = useState(0);
  const [isAdhoc, setIsAdhoc] = useState(true);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };
  let count = AautiStrings?.count;
  const fetchData = (
    active = activeState,
    offsetKey = offSet,
    search = searchText
  ) => {
    let data = {
      endUserId: loggedinUserId,
      offset: offsetKey,
      // sortBy: sortBy,
      count: count,
      searchKey: search,
    };
    if (active == "adhoc") {
      data.type = "adhoc";
    }
    if (offsetKey == 0 && search == "") {
      setLoading(true);
    }
    AuthService.getAllSubscriptionsByUserID(
      data,
      (response) => {
        if (offsetKey == 0) {
          if (!isEmpty(response?.result)) {
            setLoading(false);
            const val = response?.result?.map((eachItem) => {
              let cancelled = false;
              if (eachItem?.batchId) {
                cancelled = eachItem?.endUserList?.some(
                  (eachBatch) =>
                    eachBatch?.batchId === eachItem?.batchId &&
                    eachBatch?.userId === loggedinUserId
                )
                  ? false
                  : true;
              } else {
                cancelled = eachItem?.groupMemberList?.some(
                  (eachId) => eachId === loggedinUserId
                )
                  ? false
                  : true;
              }
              return { ...eachItem, cancelled: cancelled };
            });
            if (response?.result?.length < count) {
              setFilteredEndUserCourses([...val]);
              setHasData(false);
            } else {
              setFilteredEndUserCourses([...val]);
              setHasData(true);
              setOffset(offsetKey + 1);
            }
            setIsAdhoc(false);
          } else {
            setFilteredEndUserCourses([]);
            setLoading(false);
            setIsAdhoc(false);
          }
        } else {
          if (!isEmpty(response?.result)) {
            setLoading(false);
            const val = response?.result?.map((eachItem) => {
              let cancelled = false;
              if (eachItem?.batchId) {
                cancelled = eachItem?.endUserList?.some(
                  (eachBatch) =>
                    eachBatch?.batchId === eachItem?.batchId &&
                    eachBatch?.userId === loggedinUserId
                )
                  ? false
                  : true;
              } else {
                cancelled = eachItem?.groupMemberList?.some(
                  (eachId) => eachId === loggedinUserId
                )
                  ? false
                  : true;
              }
              return { ...eachItem, cancelled: cancelled };
            });
            if (response?.result?.length < count) {
              setFilteredEndUserCourses([...filteredEndUserCourses, ...val]);
              setHasData(false);
            } else {
              setFilteredEndUserCourses([...filteredEndUserCourses, ...val]);
              setHasData(true);
              setOffset(offsetKey + 1);
            }
            setIsAdhoc(false);
          } else {
            setLoading(false);
            setIsAdhoc(false);
          }
        }
      },
      (err) => {
        console.log("error");
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (location?.state?.adhoc) {
      setIsAdhoc(true);
      setActiveState(buttonsList[1]?.value);
      fetchData(buttonsList[1]?.value);
    } else {
      setIsAdhoc(false);
    }
  }, [location]);

  useEffect(() => {
    if (!isAdhoc) {
      fetchData();
    }
  }, [activeState, UserRole, sortBy, loggedinUserId, isAdhoc]);

  const separateArraysByBatchId = (batchId, data) => {
    const resultObject = {
      foundObject: null,
      arrayWithoutBatchId: [],
    };

    for (const key in data) {
      if (key === "groupWeekly" || key === "groupDaily") {
        const currentArray = data[key];
        let found = false;
        for (let i = 0; i < currentArray.length; i++) {
          if (currentArray[i]._id === batchId) {
            resultObject.foundObject = currentArray[i];
            if (key === "groupDaily") {
              resultObject.foundObject = {
                ...resultObject.foundObject,
                type: "Daily",
              };
            } else {
              resultObject.foundObject = {
                ...resultObject.foundObject,
                type: "Weekly",
              };
            }
            found = true;
          } else {
            if (!found) {
              if (key === "groupDaily") {
                resultObject.arrayWithoutBatchId.push({
                  ...currentArray[i],
                  type: "Daily",
                });
              } else {
                resultObject.arrayWithoutBatchId.push({
                  ...currentArray[i],
                  type: "Weekly",
                });
              }
              resultObject.arrayWithoutBatchId.push(currentArray[i]);
            }
          }
        }
      }
    }

    return resultObject;
  };

  const handleGetBatchesList = (course) => {
    const { courseId, batchId } = course;
    setSelectedCourse(course);
    getGroupBatchesByCourseId(
      courseId,
      (response) => {
        if (response?.result) {
          const result = response?.result;
          const batchesCount =
            result?.groupDaily.length + result?.groupWeekly?.length;
          if (batchesCount <= 1) {
            showToast(
              "info",
              `No batches Available to Request
              `
            );
          } else {
            const result = response?.result;

            const requiredObj = separateArraysByBatchId(batchId, result);
            if (requiredObj) {
              setPreviousBatch(requiredObj);
              const filteredDailyType = response?.result?.groupDaily?.map(
                (each) => {
                  return { ...each, type: "Daily" };
                }
              );
              const filteredWeeklyType = response?.result?.groupWeekly?.map(
                (each) => {
                  return { ...each, type: "Weekly" };
                }
              );
              setBatchesList({
                ...response?.result,
                groupWeekly: filteredWeeklyType,
                groupDaily: filteredDailyType,
              });
            }
          }
        } else {
          showToast("info", "No batches Available");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleCancel = () => {
    setBatchesList([]);
    setSelectedBatch(null);
    setPreviousBatch(null);
    setSelectedCourse(null);
    setReason("");
  };

  const handleSaveRequest = () => {
    const fromPrice = checkNull(previousBatch?.foundObject?.groupDiscountPrice)
      ? previousBatch?.foundObject?.groupPrice
      : previousBatch?.foundObject?.groupDiscountPrice;
    const toPrice = checkNull(selectedBatch?.groupDiscountPrice)
      ? selectedBatch?.groupPrice
      : selectedBatch?.groupDiscountPrice;
    const isDirectTransfer =
      moment(selectedBatch?.startDateTime).isAfter(new Date()) &&
      fromPrice === toPrice;
    if (isDirectTransfer) {
      const bodyData = {
        endUserList: [loggedinUserId],
        courseId: selectedCourse?.courseId,
        fromBatchId: previousBatch?.foundObject?._id,
        toBatchId: selectedBatch?._id,
      };
      saveInternalTransfer(
        bodyData,
        (response) => {
          handleCancel();
          showToast("success", response?.message);
        },
        (error) => console.log(error)
      );
    } else {
      const obj = {
        serviceProviderId: selectedCourse?.serviceProviderId,
        endUserId: loggedinUserId,
        endUserImage: userDetails?.profileImage,
        displayName: userDetails?.displayName,
        courseId: selectedCourse?.courseId,
        courseImage: selectedCourse?.courseImage,
        fromBatchId: previousBatch?.foundObject?._id,
        toBatchId: selectedBatch?._id,
        fromBatch: previousBatch?.foundObject,
        courseName: selectedCourse?.courseName,
        toBatch: selectedBatch,
        transferStatus: "pending",
        reason: reason,
      };

      saveRequestBatchChange(
        obj,
        (response) => {
          if (response?.result !== null) {
            handleCancel();
          } else {
            showToast("info", response?.message);
          }
        },
        (error) => {
          console.log(error, "error-save-request");
        }
      );
    }
  };

  const returnListWithouBatchId = (list, batchId) => {
    const newList = list?.filter((each) => each?._id !== batchId);
    return newList || [];
  };

  const renderActiveTabData = () => {
    if (activeTab == "regular") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: mobileDevices ? "row" : "row",
            gap: 4,
            width: "100%",
            marginBottom: mobileDevices && 10,
          }}
        >
          {buttonsList?.map((btn, index) => (
            <button
              key={index}
              onClick={() => {
                if (activeState != btn?.value) {
                  setActiveState(btn?.value);
                  setOffset(0);
                  fetchData(btn?.value, 0);
                }
              }}
              style={{
                background: activeState == btn.value ? "#3083ef" : "white",
                padding: "6px 10px",
                border:
                  activeState == btn.value
                    ? "1px solid #ffffff55"
                    : "1px solid #575B5CCC",
                borderRadius: 4,
                width: mobileDevices ? "50%" : "",
                display: "flex",
                flexDirection: "row",
                gap: 5,
                alignItems: "center",
                cursor: "pointer",
                // width: "50%",
                justifyContent: "center",
              }}
            >
              <AautiText
                textStyle={{
                  color: activeState == btn.value ? "white" : "black",
                }}
                title={btn?.text}
                size={"semi"}
              />
            </button>
          ))}
        </div>
      );
    } else if (activeTab == "institute") {
      return (
        <div>
          <AautiText title={AautiStrings?.comingSoonString} size={"semi"} />
        </div>
      );
    }
  };

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: `${AautiStrings?.myLearningsString}`,
            navigation: "/my-learnings",
            active: true,
          },
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          justifyContent: "space-between",
          alignContent: "center",
          width: "100%",
        }}
      ></div>
      <div
        style={{
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          // gap: 5,
          alignItems: "center",
          width: "100%",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: mobileDevices ? "100%" : ipodDevices ? "40%" : "30%",
            marginTop: 10,
          }}
        >
          {renderActiveTabData()}
        </div>
        <AautiSearch
          inputRef={learnRef}
          searchValue={searchText}
          onClose={() => {
            setSearchText("");
            fetchData(activeState, 0, "");
          }}
          addStyle={{
            display:
              filteredEndUserCourses?.length == 0 &&
              isEmpty(searchText) &&
              "none",
            width: mobileDevices ? "100%" : null,
          }}
          handleSearchChange={(e) => {
            setSearchText(e.target.value);
            fetchData(activeState, 0, e?.target?.value);
          }}
        />
      </div>

      <div style={{ width: "100%" }}>
        <div
          style={{
            border: "1px solid lightgrey",
            height: "75vh",
            overflow: "scroll",
            borderRadius: 4,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            padding: "10px",
            gap: 2,
          }}
          id="myLearningSroll"
        >
          {/* <div
            style={{
              flexGrow: 1,
              height: "100%",
              overflow: "scroll",
            }}
          > */}
          {loading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                // justifyContent: "center",
                width: "100%",
                gap: 10,
              }}
            >
              <CoursesLoader {...{ mobileDevices, windowInnerWidth }} />
              <CoursesLoader {...{ mobileDevices, windowInnerWidth }} />
              <CoursesLoader {...{ mobileDevices, windowInnerWidth }} />
              <CoursesLoader {...{ mobileDevices, windowInnerWidth }} />
              <CoursesLoader {...{ mobileDevices, windowInnerWidth }} />
              <CoursesLoader {...{ mobileDevices, windowInnerWidth }} />
            </div>
          ) : filteredEndUserCourses?.length > 0 ? (
            <AautiInfiniteScroll
              testId="myLearningSroll"
              cardsList={filteredEndUserCourses}
              {...{ hasData, handleLoadMore: fetchData }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 10,
                  height: "100%",
                  width: "100%",
                }}
              >
                {filteredEndUserCourses?.map((each, index) => (
                  <Coursecard
                    adhoc={activeState == "adhoc" ? true : false}
                    cardWidth={mobileDevices ? "100%" : "280px"}
                    {...{ each, handleGetBatchesList }}
                    key={index}
                  />
                ))}
              </div>
            </AautiInfiniteScroll>
          ) : (
            <EmptyTopic
              topicName={
                searchText !== ""
                  ? "No search results"
                  : activeState === "adhoc"
                  ? AautiStrings?.noAdhocsPurchasedYetString
                  : AautiStrings?.noCoursesPurchasedYetString
              }
              Image={EmptyOrders}
              showButton={isEmpty(searchText)}
              // message={AautiStrings?.keepExploringToFindACourseString}
              buttonName={AautiStrings?.KeepExploringString}
              onClick={() => navigate("/")}
            />
          )}
        </div>
        {/* </div> */}
      </div>

      <Drawer
        anchor={mobileDevices ? "bottom" : "right"}
        sx={{
          zIndex: 201,
          padding: 20,
          "& .MuiDrawer-paper": {
            borderRadius: mobileDevices
              ? "10px 10px 0px 0px"
              : "10px 0px 0px 10px",
            minHeight: mobileDevices ? "80vh" : "100vh",
            maxHeight: mobileDevices ? "80vh" : "100vh",
            minWidth: !mobileDevices && "35vw !important",
            maxWidth: mobileDevices ? "75vw" : "35vw",
          },
        }}
        open={!isEmpty(batchesList)}
        onClose={() => {
          setBatchesList([]);
          setReason("");
        }}
      >
        <BatchTransfer
          {...{
            setSelectedBatch,
            selectedBatch,
            batchesList,
            reason,
            setReason,
            handleCancel,
            handleSaveRequest,
            previousBatch,
            returnListWithouBatchId,
          }}
        />
      </Drawer>
    </AppContainer>
  );
};

export default MyLearnings;
