import  { useEffect } from "react";
import { connect } from "react-redux";
import { SocketEvents } from "./constants";

const SocketProvider = (props) => {
  const { isSocketOpen, connectSocket, children } = props;
  const userId = localStorage.getItem("USER_ID");
  document.addEventListener("visibilitychange", function () {
    if (document.visibilityState === "visible" && !isSocketOpen && !!userId) {
      connectSocket(userId);
    }
  });

  useEffect(() => {
    if (!isSocketOpen && !!userId) {
      connectSocket(userId);
    }
  }, [isSocketOpen]);

  return children;
};

const mapStateToProps = (state) => ({
  isSocketOpen: state.chat.isSocketOpen,
});

const mapDispatchToProps = (dispatch) => ({
  connectSocket: (userId) =>
    dispatch({
      type: SocketEvents.connect,
      payload:userId
    }),
});
export default connect(mapStateToProps, mapDispatchToProps)(SocketProvider);
