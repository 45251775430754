import React, { createRef, useState } from "react";
import AautiText from "../../../globalComponents/AautiText";
import AautiUpload from "../../../globalComponents/AautiUpload";

import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import AautiDialog from "../../../globalComponents/AautiDialog";
import { Cropper } from "react-cropper";
import GigUploads from "./GigUploads";
import { useSelector } from "react-redux";
import { checkYoutubeVideo, validateUrl } from "../../../CommonFunctions";
import ReactPlayer from "react-player";
import { AautiStrings } from "../../../globalComponents/AautiStrings";
import { createCourseStrings } from "../Constants";
import HelperText from "../../courseCreation/HelperText";
import BottomSheet from "../../../globalComponents/BottomSheet";
import { showToast } from "../../../globalComponents/Toast";
import { MdAddCircleOutline } from "react-icons/md";
import { generateImagesUsingAi } from "../Services";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../../service/api-constants";
import moment from "moment";

const GigImageFields = ({
  handleGigImage,
  gigObject,
  handleImageFields,
  CreateCourseObj,
  uploadGigImage,
  courseImageErrors,
  handleOpen,
  handleClose,
  activeIndex,
  setActiveIndex,
  setGigObject,
  setCourseImageError,
}) => {
  const { relatedImages } = CreateCourseObj;
  const { shortVideoUrl, gigImage, gigVideos } = gigObject;
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [preview, setPreview] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const cropperRef = createRef(null);
  const [image, setImage] = useState("");
  const [gig, setGig] = useState();
  const [cropImage, setCropImage] = useState(false);

  const [aiImageField, setAiImageField] = useState("");

  const checkImageRatios = async (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        // if (field === "gigImage") {
        if (Math.abs(aspectRatio - 4 / 3) < 0.01) {
          handleGigImage(e, field);
        } else {
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
            setUploadedFile(files[0]);
            setCropImage(true);
          };
          reader.readAsDataURL(files[0]);
        }
        // } else if (field === "relatedImages") {
        //   if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
        //     handleGigImage(e, field);
        //   } else {
        //     alert("Only Images with the folloiwng ratio are allowed: 16:9");
        //   }
        // }
      };
    }
  };

  const returnVideoName = (video) => {
    var filename = video.substring(video.lastIndexOf("/") + 1);
    var videoName = filename.split(".").slice(0, -1).join(".");

    return videoName;
  };

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const fileName =
          uploadedFile?.name ||
          (!aiImageField
            ? returnVideoName(image)
            : `AiImages-${new Date().toUTCString()}.jpg`);

        croppedCanvas.toBlob((blob) => {
          const croppedFile = new File([blob], fileName, {
            type: "image/png",
            lastModified: Date.now(),
          });
          //   setCropData(croppedFile);
          uploadGigImage(croppedFile, "gigImage");
          setAiImageField("");
          closeCrop();
        }, "image/png");
      }
    }
  };

  const [uploadedVideos, setUploadedVideos] = useState([
    ...(gigObject?.gigVideos ? gigObject?.gigVideos : []),
  ]);

  const handleRemoveTopic = (videoIndex) => {
    if (!isEmpty(uploadedVideos)) {
      let updatedGigVideos = [...uploadedVideos];
      const newCount = updatedGigVideos[videoIndex]?.topicsCount - 1;
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        topicsCount: newCount,
      };
      updatedGigVideos[videoIndex]?.topics?.pop();
      setUploadedVideos(updatedGigVideos);
    }
  };

  const handleUploadVideoUrl = async (url, field) => {
    const validUrl = validateUrl(url);
    const youTubeVideo = checkYoutubeVideo(url);
    const getVideoID = (url) => {
      const urlObj = new URL(url);
      return urlObj.hostname;
    };
    const generateVideoName = (url) => {
      const videoID = getVideoID(url);

      return `Video - ${videoID || ""}`;
    };
    if (validUrl || youTubeVideo) {
      // const videoName = await generateVideoName(url);
      let obj = {
        gigHours: 0,
        gigMinutes: 0,
        gigSeconds: 0,
        videoUrl: url,
        videoTitle: "",
        topicsCount: 0,
        topics: [],
      };

      setUploadedVideos([...uploadedVideos, obj]);
      // setCourseImageError((prev) => ({
      //   ...prev,
      //   gigVideos: "",
      // }));
    } else {
      showToast("info", "Please upload a valid url");
    }
  };

  const handleAddDuration = (duration, activeIndex, itemInd) => {
    const { getHours, getMinutes, seconds } = duration;
    const updatedGigVideos = [...uploadedVideos];
    const updatedTopics = [...updatedGigVideos[activeIndex].topics];
    updatedTopics[itemInd] = {
      ...updatedTopics[itemInd],
      seconds,
      minutes: getMinutes,
      hours: getHours,
    };

    updatedGigVideos[activeIndex] = {
      ...updatedGigVideos[activeIndex],
      topics: updatedTopics,
    };
    setUploadedVideos(updatedGigVideos);
  };

  const removTopic = (videos, activeIndex) => {
    let videosArray = [...uploadedVideos];
    videosArray = videosArray?.map((each, index) => {
      if (index === activeIndex) {
        return { ...each, topics: videos };
      } else {
        return each;
      }
    });

    setUploadedVideos(videosArray);
  };

  const handleOnChangeTopic = (val, videoIndex, field, topicIndex) => {
    if (field === "videoTitle") {
      const updatedGigVideos = [...uploadedVideos];
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        videoTitle: val,
      };
      setUploadedVideos(updatedGigVideos);
    } else if (field === "topicName") {
      const updatedGigVideos = [...uploadedVideos];
      const updatedTopics = [...updatedGigVideos[videoIndex].topics];
      updatedTopics[topicIndex] = {
        ...updatedTopics[topicIndex],
        topicName: val,
      };
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        topics: updatedTopics,
      };
      setUploadedVideos(updatedGigVideos);
    }
  };

  const handleAddTopic = (videoIndex = 0) => {
    if (!isEmpty(uploadedVideos)) {
      let updatedGigVideos = [...uploadedVideos];
      const newCount = updatedGigVideos[videoIndex]?.topicsCount + 1;
      updatedGigVideos[videoIndex] = {
        ...updatedGigVideos[videoIndex],
        topicsCount: newCount,
      };
      let list = updatedGigVideos?.[videoIndex]
        ? [...updatedGigVideos?.[videoIndex]?.topics]
        : [];
      list?.push({
        topicName: "",
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
      updatedGigVideos[videoIndex].topics = list || [];
      setUploadedVideos(updatedGigVideos);
    }
  };

  // const multipleVideoUpload = async (file, i) => {
  //   handleOpen();
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("platform", UploadBaseUrl);
  //   const url = uploadUrl + ApiPaths.FILE_UPLOAD;
  //   try {
  //     const response = await fetch(url, {
  //       method: "POST",
  //       body: formData,
  //     });
  //     const json = await response.json();
  //     if (json.result) {
  //       // const videoName = await returnVideoName(json?.result);
  //       let obj = {
  //         gigHours: 0,
  //         gigMinutes: 0,
  //         gigSeconds: 0,
  //         videoUrl: json?.result,
  //         videoTitle: "",
  //         topicsCount: 0,
  //         topics: [],
  //       };

  //       let videosArray = [...(uploadedVideos || []), obj];
  //       setActiveIndex(videosArray?.length - 1);
  //       // setGigObject((prev) => ({
  //       //   ...prev,
  //       //   gigVideos: videosArray,
  //       // }));

  //       setUploadedVideos(videosArray);
  //     }
  //     handleClose();
  //   } catch (error) {
  //     console.error("Fetch error:", error);
  //     handleClose();
  //   }

  //   handleClose();
  // };

  const handleUploadVideo = async (e, field) => {
    const { files } = e.target;
    const invalidFile = Array.from(files).some(
      (file) => !file.type.startsWith("video/")
    );

    let videosArray = [...(uploadedVideos || [])];

    if (invalidFile) {
      showToast("info", "Please upload a valid video file!");
      e.target.value = ""; // Clear the input
      return;
    }
    const url = `${uploadUrl}${ApiPaths.FILE_UPLOAD}`;
    handleOpen();
    try {
      const uploadPromises = Array.from(files).map(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("platform", UploadBaseUrl);
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });
        const json = await response.json();
        if (json.result) {
          return {
            gigHours: 0,
            gigMinutes: 0,
            gigSeconds: 0,
            videoUrl: json.result,
            videoTitle: "",
            topicsCount: 0,
            topics: [],
          };
        }
      });
      const uploadedVideosArray = await Promise.all(uploadPromises);
      videosArray.push(...uploadedVideosArray.filter(Boolean)); // Add only successfully uploaded videos
    } catch (error) {
      console.error("Fetch error:", error);
    } finally {
      handleClose();
    }

    setActiveIndex(videosArray?.length - 1);
    //       // setGigObject((prev) => ({
    //       //   ...prev,
    //       //   gigVideos: videosArray,
    //       // }));
    setUploadedVideos(videosArray);
  };

  const renderImages = (url, field, index) => {
    return (
      <Tooltip
        title={AautiStrings?.previewString}
        onClick={() => {
          setPreview(url);
        }}
      >
        <div
          style={{
            border: "1px solid lightgrey",
            borderRadius: 6,
            padding: 5,
            position: "relative",
            height: 120,
            width: 130,
            cursor: "pointer",
          }}
        >
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              handleImageFields(field, index);
              document.getElementById(field).value = "";
            }}
            sx={{
              position: "absolute",
              right: 1,
              background: "#fff",
              "&:hover": {
                background: "#fff",
              },
            }}
          >
            <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
          </IconButton>
          <img
            src={url}
            style={{ height: "100%", width: "100%" }}
            alt="gigImage"
          />
        </div>
      </Tooltip>
    );
  };

  const renderVideo = (url, field, index) => {
    const isYoutubeVideo = checkYoutubeVideo(url);
    return (
      <div
        style={{
          border: "1px solid lightgrey",
          borderRadius: 6,
          padding: 5,
          position: "relative",
          height: 120,
          width: 130,
        }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            if (gig && field == "gigVideo") {
              let videos = [...(uploadedVideos || [])];
              videos = videos?.filter((vid, vidIndex) => {
                return vidIndex !== index;
              });
              setActiveIndex(videos?.length - 1 || 0);
              setUploadedVideos(videos);
              // removeUploadedVideo(index);
            } else {
              handleImageFields(field, index);
              if (field == "shortVideoUrl") {
                document.getElementById(field).value = "";
              }
            }
          }}
          sx={{
            position: "absolute",
            right: 1,
            background: "#fff",
            zIndex: 200,
            cursor: "pointer",
            "&:hover": {
              background: "#fff",
            },
          }}
        >
          <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
        </IconButton>
        {isYoutubeVideo ? (
          <ReactPlayer
            style={{ pointerEvents: "none" }}
            config={{
              file: {
                attributes: {
                  crossOrigin: "true",
                },
              },
              youtube: {
                playerVars: { playsinline: 1 },
              },
            }}
            url={url}
            controls
            width="100%"
            height="100%"
            playsinline={true}
          />
        ) : (
          <video
            src={url}
            muted
            autoPlay={true}
            style={{ height: "100%", width: "100%", pointerEvents: "none" }}
          />
        )}
      </div>
    );
  };

  const closeCrop = () => {
    setCropImage(false);
    setImage("");
    setUploadedFile(null);
  };

  const handleGenerateAiImages = (field) => {
    const data = {
      prompt: `images for the category '${
        CreateCourseObj?.category || ""
      }' and subcategory '${CreateCourseObj?.subCategory || ""}'. 
      The aspect ratio should be 4:3, and the images must resemble natural scenes or real-world contexts, without any artificial or overly stylized design elements.`,
      numberOfImages: 2,
      model: "dall-e-3",
      size: "1024x1024",
    };
    setAiImageField("gigImage");
    handleOpen();
    generateImagesUsingAi(
      data,
      (response) => {
        handleClose();
        if (response?.result) {
          const image = response?.result?.data[0];
          setCropImage(true);
          setImage("data:image/jpeg;base64," + image?.b64_json);
        } else {
          showToast("info", "Something went wrong");
        }
      },
      (error) => {
        console.log(error, "error?....");
        handleClose();
      }
    );
  };

  const returnDisabled =
    !CreateCourseObj?.category ||
    !CreateCourseObj?.subCategory ||
    isEmpty(CreateCourseObj?.courseLevel);

  const headText = (text, bool = true) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          // marginTop: 15,
        }}
      >
        <AautiText size={"normal"} title={text} required={bool} />

        {createCourseStrings?.coverImage == text && (
          <Tooltip
            title={returnDisabled ? "category and levels are required" : null}
          >
            <button
              disabled={returnDisabled}
              onClick={() => {
                handleGenerateAiImages("courseImage");
              }}
              id="cover-image-ai"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: returnDisabled ? "help" : "pointer",
                display: gigImage && "none",
              }}
            >
              <AautiText
                size={"small"}
                title={AautiStrings?.GenerateUsingAIString}
                weight={"bold"}
                textStyle={{
                  color: returnDisabled ? "lightgrey" : "#2076E5",
                }}
              />
            </button>
          </Tooltip>
        )}
      </div>
    );
  };

  const validateVideos = () => {
    for (const video of uploadedVideos) {
      if (!video.videoTitle.trim() || video?.videoTitle?.length < 3) {
        return false;
      }

      // Check if there are topics and validate each topic name
      if (video.topicsCount > 0) {
        for (const topic of video.topics) {
          if (!topic.topicName.trim()) {
            return false; // Return false if any topic name is empty
          }
        }
      }
    }
    return true;
  };

  const returnDisableDone = !validateVideos();

  const returnBool = aiImageField ? false : true;

  const handleUpdateVideoDuration = (seconds, index) => {
    const duration = moment.duration(seconds, "seconds");
    const durationObj = {
      gigHours: duration.hours() || 0,
      gigMinutes: duration.minutes() || 0,
      gigSeconds: duration.seconds() || 0,
    };
    let prevVideos = [...uploadedVideos];
    prevVideos = prevVideos?.map((video, videoIndex) => {
      if (videoIndex === index) {
        return { ...video, ...durationObj };
      } else {
        return video;
      }
    });
    setUploadedVideos(prevVideos);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          flexWrap: "wrap",
          gap: 15,
        }}
      >
        <div style={{ width: mobileDevices ? "100%" : "49%" }}>
          {headText(createCourseStrings?.coverImage)}
          {!isEmpty(gigImage) ? (
            renderImages(gigImage, "gigImage")
          ) : (
            <>
              <AautiUpload
                id="gigImage"
                onChange={(e) => {
                  checkImageRatios(e, "gigImage");
                }}
                accept="image/jpeg, image/png"
                iconName={createCourseStrings?.coverImage}
                important
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadGigCoverImage}
                    />
                  </div>
                }
              />
              <HelperText
                error={courseImageErrors?.gigImage}
                title={
                  createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed4IsTo3
                }
              />
            </>
          )}
        </div>
        <div style={{ width: mobileDevices ? "100%" : "49%" }}>
          {headText("Media Files", false)}
          {!isEmpty(relatedImages) ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                marginTop: 5,
                flexWrap: "wrap",
              }}
            >
              {relatedImages?.map((item, index) => (
                <div key={index} style={{ cursor: "pointer" }}>
                  {item.endsWith(".mp4")
                    ? renderVideo(item, "relatedImages", index)
                    : renderImages(item, "mediaFiles", index)}
                </div>
              ))}
              <input
                type="file"
                multiple={true}
                accept="image/jpeg, image/png, application/pdf, video/mp4"
                onChange={(e) => {
                  handleGigImage(e, "mediaFiles");
                }}
                id="upload-Related-Images"
                style={{ display: "none" }}
              />
              <div style={{ alignItems: "center", display: "flex" }}>
                <label htmlFor="upload-Related-Images">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {relatedImages?.length < 8 && (
                      <MdAddCircleOutline
                        size={45}
                        fill={"#1e98d7"}
                        style={{
                          fontSize: 24,
                          cursor: "pointer",
                          color: "#1e98d7",
                          fontWeight: 500,
                          // marginTop: 18,
                        }}
                        error={
                          CreateCourseObj?.relatedImages.length < 2
                            ? true
                            : false
                        }
                      />
                    )}
                  </div>
                </label>
              </div>
            </div>
          ) : (
            <AautiUpload
              multiple={true}
              id="mediaFiles"
              accept="image/jpeg, image/png, application/pdf, video/mp4"
              onChange={(e) => {
                handleGigImage(e, "mediaFiles");
              }}
              iconName={createCourseStrings?.coverImage}
              customLabel={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <AddPhotoAlternateOutlinedIcon
                    sx={{ color: "#8687a7" }}
                    fontSize="large"
                  />
                  <AautiText
                    size={"semi"}
                    weight={"normal"}
                    textStyle={{ color: "#8687a7" }}
                    title={createCourseStrings?.uploadMediaAndFiles}
                  />
                </div>
              }
            />
          )}

          {/* <AautiText
          size={"small"}
          textAlign={"left"}
          title={"Only images with the following ratio are allowed: 4:3"}
        /> */}
        </div>
        <div style={{ width: mobileDevices ? "100%" : "49%" }}>
          {headText(createCourseStrings?.previewVideo)}
          {!isEmpty(shortVideoUrl) ? (
            <div
              onClick={() => {
                setPreview(shortVideoUrl);
              }}
              style={{
                marginTop: 10,
                cursor: "pointer",
              }}
            >
              {renderVideo(shortVideoUrl, "shortVideoUrl")}
            </div>
          ) : (
            <>
              <AautiUpload
                accept="video/mp4"
                id="shortVideoUrl"
                onChange={(e) => {
                  handleGigImage(e, "shortVideoUrl");
                }}
                iconName={createCourseStrings?.previewVideo}
                important
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadPreviewVideo}
                    />
                  </div>
                }
              />
              {courseImageErrors?.shortVideoUrl && (
                <HelperText
                  title={courseImageErrors?.shortVideoUrl}
                  error={true}
                />
              )}
            </>
          )}
          {/* <AautiText
          size={"small"}
          textAlign={"left"}
          title={"Only images with the following ratio are allowed: 4:3"}
        /> */}
        </div>
        <div style={{ width: mobileDevices ? "100%" : "49%" }}>
          {headText(createCourseStrings?.uploadGig)}

          {!isEmpty(gigVideos) ? (
            <div
              style={{
                alignSelf: "flex-start",
                marginTop: 10,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 5,
                  marginTop: 5,
                  flexWrap: "wrap",
                }}
              >
                {gigVideos?.map((each, index) => (
                  <div
                    key={index}
                    style={{
                      // border: activeIndex == index && "1px solid lightgrey",
                      cursor: "pointer",
                      // borderRadius: 5,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveIndex(index);
                      if (!gig) {
                        setGig(true);
                        setUploadedVideos(gigObject?.gigVideos);
                      }
                    }}
                  >
                    {renderVideo(each?.videoUrl, "gigVideo", index)}
                  </div>
                ))}

                <div
                  onClick={() => {
                    setGig(true);
                    setUploadedVideos(gigObject?.gigVideos);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdAddCircleOutline
                    size={45}
                    fill={"#1e98d7"}
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                      color: "#1e98d7",
                      fontWeight: 500,
                      // marginTop: 18,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{ width: "100%" }}
              onClick={() => {
                setGig(true);
              }}
            >
              <AautiUpload
                disabled
                id="upload media"
                iconName={createCourseStrings?.coverImage}
                important
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadGigImp}
                    />
                  </div>
                }
              />
              {courseImageErrors?.gigVideos && (
                <HelperText error={true} title={courseImageErrors?.gigVideos} />
              )}
            </div>
          )}
        </div>
      </div>

      <BottomSheet
        // dialogWidth={"80vw"}
        direction="bottom"
        open={cropImage}
        onClose={() => {
          closeCrop();
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: mobileDevices ? "column" : "row",
            gap: 25,
          }}
        >
          <div style={{ width: mobileDevices ? "100%" : "70%" }}>
            <Cropper
              ref={cropperRef}
              style={{ height: mobileDevices ? 280 : "70vh", width: "100%" }}
              zoomTo={0.5}
              initialAspectRatio={9 / 16}
              aspectRatio={9 / 16}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={true}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
              checkCrossOrigin={returnBool}
            />
          </div>

          <div
            style={{
              width: mobileDevices ? "100%" : "25%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              height: mobileDevices ? "auto" : "70vh",
            }}
          >
            <div>
              <AautiText
                textAlign={"center"}
                weight={"bold"}
                size={"large"}
                title={AautiStrings?.previewString}
              />
              <div
                style={{
                  width: mobileDevices ? "120px" : "320px",
                  float: "left",
                  height: mobileDevices ? "200px" : "400px",
                  borderRadius: 5,
                  marginTop: 10,
                  marginBottom: 15,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  className="img-preview"
                  style={{
                    width: 320,
                    float: "left",
                    height: "100%",
                    borderRadius: 5,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                marginTop: 30,
                gap: 10,
              }}
            >
              <button
                style={{
                  border: "1px solid lightgrey",
                  color: "#000",
                  fontSize: "13px",
                  fontWeight: "500",
                  borderRadius: 4,
                  padding: "5px 15px 5px 15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  closeCrop();
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "#3399ff",
                  color: "#fff",
                  fontSize: "13px",
                  fontWeight: "500",
                  border: "none",
                  borderRadius: 4,
                  padding: "5px 15px 5px 15px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  getCropData();
                }}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        direction="bottom"
        key={true}
        open={!isEmpty(preview) || gig || false}
        onClose={(e) => {
          e.stopPropagation();
          if (!returnDisableDone || !isEmpty(preview)) {
            setPreview(null);
            setGig(false);
            setUploadedVideos(gigObject?.gigVideos);
          } else {
            showToast("info", "Input fields cannot be empty");
          }
        }}
      >
        <GigUploads
          handleClickDone={() => {
            setGigObject((prev) => ({
              ...prev,
              gigVideos: uploadedVideos,
            }));
            delete courseImageErrors.gigVideos;
            setUploadedVideos([]);
          }}
          {...{
            preview,
            setActiveIndex,
            renderVideo,
            activeIndex,
            gigVideos,
            gig,
            setGig,
            setPreview,
            setUploadedVideos,
            handleUploadVideoUrl,
            returnDisableDone,
            setCropImage,
            setImage,
            removTopic,
            handleAddDuration,
            setGigObject,
            handleUploadVideo,
            handleOnChangeTopic,
            handleAddTopic,
            handleRemoveTopic,
            uploadedVideos,
            handleUpdateVideoDuration,
          }}
        />
      </BottomSheet>
    </>
  );
};

export default GigImageFields;
