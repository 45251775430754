import { Box, Drawer, IconButton, Tooltip } from "@mui/material";
import React from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import AautiText from "./AautiText";

const BottomSheet = ({
  onClose,
  open,
  children,
  dialogTitle = "",
  direction = "right",
}) => {
  const { mobileDevices, aboveLargeMobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  return (
    <Drawer
      anchor={
        mobileDevices || aboveLargeMobileDevices || ipodDevices
          ? "bottom"
          : direction
      }
      sx={{
        zIndex: 502,
        position: "relative",
        "& .MuiDrawer-paper": {
          borderRadius:
            mobileDevices ||
            aboveLargeMobileDevices ||
            ipodDevices ||
            direction == "bottom"
              ? "10px 10px 0px 0px"
              : "10px 0px 0px 10px",
          minHeight:
            mobileDevices ||
            aboveLargeMobileDevices ||
            ipodDevices ||
            direction == "bottom"
              ? "90vh"
              : "100vh",
          maxHeight:
            mobileDevices ||
            aboveLargeMobileDevices ||
            ipodDevices ||
            direction == "bottom"
              ? "90vh"
              : "100vh",
          minWidth:
            mobileDevices ||
            aboveLargeMobileDevices ||
            ipodDevices ||
            direction == "bottom"
              ? "100vw"
              : "40vw",
          maxWidth:
            mobileDevices ||
            aboveLargeMobileDevices ||
            ipodDevices ||
            direction == "bottom"
              ? "100vw"
              : "40vw",
          padding: 2,
          paddingTop: 4,
        },
      }}
      open={open}
      onClose={(e) => {
        // e.stopPropagation();
        onClose(e);
      }}
    >
      <Box
        pl={2}
        sx={{
          position: "absolute",
          top: -2,
          right: 8,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "99%",
          alignItems: "center",
        }}
      >
        <AautiText
          // textStyle={{ position: "absolute", top: -2, left: 0 }}
          title={dialogTitle}
          size={"large"}
          weight={"medium"}
        />
        <Tooltip title={"Close"}>
          <IconButton
            onClick={(e) => {
              onClose(e);
            }}
          >
            <RxCrossCircled />
          </IconButton>
        </Tooltip>
      </Box>

      {children}
    </Drawer>
  );
};

export default BottomSheet;
