import React, { useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import { useNavigate } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import "./styles.scss";
import { PackageStrings } from "../Constants";
import { isEmpty } from "lodash";
import {
  getFirstTwoChars,
  PriceConversions,
  returnColorbasedName,
} from "../../CommonFunctions";
import IndividualIcon from "../../../assets/images/individual-svg.svg";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { FaCheck } from "react-icons/fa";

const GigPackageCard = ({
  selectCourse,
  selectedIdList,
  each,
  childs,
  select,
  open,
  review = false,
  cardWidth = "480px",
  details,
  AddPackage,
  handleSelectUser,
}) => {
  const navigate = useNavigate();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { isParent } = useSelector((state) => state.app);
  const [endUserList, setEndUserList] = useState([]);
  const [bought, setBought] = useState([false]);
  useEffect(() => {
    BoughtForList();
  }, [each]);

  const { convertPriceWithCurrency, displayCountryBasedCurrency } =
    PriceConversions();

  const list = childs?.filter(
    (user, index) => !endUserList.includes(user?._id)
  );

  const BoughtForList = () => {
    let list = [];
    let list2 = [];
    each?.endUserList.map((item) => {
      list.push(item?.userId);
    });
    setEndUserList(list);
    childs?.map((item) => {
      if (list.includes(item?._id)) {
        list2.push(true);
      } else {
        list2?.push(false);
      }
    });

    if (list2.includes(true)) {
      setBought(true);
    } else {
      setBought(false);
    }
  };

  const returnPrice = (each) => {
    const checkDiscount =
      moment(each?.discountStartDate).isBefore(moment(new Date())) &&
      moment(each?.discountEndDate).isAfter(moment(new Date()));

    const discountedPrice =
      checkDiscount && each?.discountPrice
        ? each?.discountPrice
        : each.gigPrice;
    const localPrices =
      checkDiscount && each.localDiscountPrice
        ? each.localDiscountPrice
        : each?.localGigPrice;

    return displayCountryBasedCurrency(
      each?.currencyCode,
      discountedPrice,
      localPrices
    );
  };

  return (
    <div
      className="cartCard2"
      style={{
        width: mobileDevices ? "100%" : cardWidth,
        border: selectedIdList?.includes(each?._id)
          ? "1px solid blue"
          : "1px solid #e0e0e0",
        minHeight:
          bought == true && !isEmpty(list)
            ? "250px"
            : !isParent
            ? bought == true
              ? "220px"
              : "170px"
            : isParent && isEmpty(list)
            ? "220px"
            : mobileDevices
            ? "188px"
            : "198px",
        maxHeight:
          bought == true && !isEmpty(list)
            ? "250px"
            : !isParent
            ? bought == true
              ? "220px"
              : "170px"
            : mobileDevices
            ? "188px"
            : "198px",
      }}
      onClick={() => {
        navigate(`/gig-preview/${each?._id}`, {});
      }}
    >
      <div
        className="gigPackageCard-div1"
        style={{
          minHeight:
            bought == true && !isEmpty(list)
              ? "210px"
              : !isParent
              ? "160px"
              : mobileDevices
              ? "170px"
              : "171px",
        }}
      >
        <img
          src={each?.gigImage || PackageStrings?.altImage}
          alt={PackageStrings?.altText}
          className="gigPackageCard-img1"
        />
      </div>
      <div className="cartCard1" style={{ width: "65%", marginLeft: "5px" }}>
        <div className="content-con-card">
          <AautiText
            size={"normal"}
            weight={"bold"}
            title={each?.gigName}
            className={"gigPackageCard-text1"}
          />
        </div>
        <div className="gigPackageCard-div2">
          <div className="gigPackageCard-div3">
            <AautiText
              title={
                each?.serviceProviderDisplayName || PackageStrings?.notAvailable
              }
              size={"semi"}
              weight={"medium"}
              textStyle={{
                color: "#5C5B57",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                // fontFamily: "Helvetica Neue",
                maxWidth: "50%",
                WebkitLineClamp: 1,
                overflow: "hidden",
              }}
            />
            <AautiText
              title={
                each?.yearsOfExperience
                  ? ` | ${each?.yearsOfExperience} ${PackageStrings?.yearsExp}`
                  : PackageStrings?.expNotListed
              }
              size={"semi"}
              weight={"medium"}
              textStyle={{ color: "#5C5B57" }}
            />
          </div>

          <AautiText
            title={
              each?.courseLevel?.length > 3
                ? "All Levels"
                : each?.courseLevel?.join(", ") || ""
            }
            size={"semi"}
            weight={"medium"}
            textStyle={{ color: "#5C5B57" }}
          />
        </div>
        <div className="gigPackageCard-div4">
          <AiFillStar size={18} color="#1e98d7" />
          <AautiText
            title={
              each?.averageRating
                ? each?.averageRating?.toFixed(1)
                : PackageStrings?.new
            }
            size={"semi"}
            weight={"medium"}
            textStyle={{ color: "#5C5B57" }}
            className={"gigPackageCard-text2"}
          />
          <AautiText
            size={"semi"}
            weight={"medium"}
            textStyle={{
              color: "#5C5B57",
              display: !each?.averageRating && "none",
            }}
            title={`(${each?.ratingCount ?? 0})`}
          />
          <AautiText
            title={`| ${
              each?.endUserList?.length > 0 || each?.subscribersCount > 0
                ? each?.endUserList?.length + " " + PackageStrings?.students ||
                  each?.subscribersCount + " " + PackageStrings?.students
                : PackageStrings?.noneRegistered
            }`}
            size={"small"}
            weight={"medium"}
            textStyle={{ color: "#5C5B57" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 3,
            marginTop: 3,
          }}
        >
          <AautiText
            title={"From"}
            size={"semi"}
            weight={"medium"}
            textStyle={{ color: "#5C5B57" }}
          />
          <img
            src={IndividualIcon}
            alt="individual"
            style={{
              height: "18px",
              width: "18px",
              zIndex: 100,
            }}
          />
          <AautiText
            size={"semi"}
            weight={"bold"}
            textStyle={{ fontFamily: "sans-serif" }}
            title={returnPrice(each)}
          />
        </div>
        <div
          style={{
            display: isEmpty(list) ? "none" : "flex",
            marginTop: "5px",
            flexDirection: "row",
          }}
        >
          {childs && isParent && (
            <AautiText
              size={"semi"}
              textStyle={{ whiteSpace: "nowrap", marginRight: 10 }}
              title={`${PackageStrings?.purchaseFor} : `}
            />
          )}
          {childs && isParent && (
            <div className="gigPackageCard-div5">
              {childs?.map((user, index) => {
                return (
                  endUserList.includes(user?._id) === false && (
                    <Tooltip title={user?.displayName}>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          endUserList.includes(user?._id) === false &&
                            handleSelectUser(user, index, each);
                        }}
                      >
                        <Avatar
                          disabled={true}
                          sx={{
                            bgcolor:
                              !user?.profileImage &&
                              returnColorbasedName(user?.displayName),
                            width: 25,
                            height: 25,
                          }}
                          // style={{
                          //   border: checkUser ? "2px solid" : "none",
                          // }}
                          key={index}
                          src={user?.profileImage}
                          alt={user?.displayName}
                        >
                          {!user?.profileImage &&
                            getFirstTwoChars(user?.displayName)}
                        </Avatar>

                        {each?.users?.includes(user?._id) && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#222222",
                              // backdropFilter: "blur(1px)",
                              opacity: 0.8,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "50%",
                            }}
                          >
                            <FaCheck size={20} color="#598f00" />
                          </div>
                        )}
                      </div>
                    </Tooltip>
                  )
                );
              })}
            </div>
          )}
        </div>
        {bought == true && (
          <div
            style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}
          >
            <AautiText
              size={"semi"}
              textStyle={{ whiteSpace: "nowrap" }}
              title={`${PackageStrings?.BoughtFor} :`}
            />
            <div className="gigPackageCard-div5">
              {childs?.map((user, index) => {
                return (
                  endUserList.includes(user?._id) === true && (
                    <Tooltip title={user?.displayName}>
                      <div key={index} className="gigPackageCard-div6">
                        <Avatar
                          className="gigPackageCard-avatar1"
                          disabled={true}
                          key={index}
                          src={user?.profileImage}
                          alt={user?.displayName}
                        />
                        <AautiText
                          title={user?.displayName}
                          size={"small"}
                          className={"gigPackageCard-text3"}
                        />
                      </div>
                    </Tooltip>
                  )
                );
              })}
            </div>
          </div>
        )}{" "}
        {bought == true && (!isParent || isEmpty(list)) && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <AautiText
              title={"Already purchased"}
              size={"semi"}
              textStyle={{ color: "red", alignSelf: "flex-end" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default GigPackageCard;
