import React from "react";
import { Avatar, IconButton } from "@mui/material";
import { CheckCircleOutlined, CircleOutlined } from "@mui/icons-material";
import { SlPencil } from "react-icons/sl";

import MessageOptionsMenu from "./MessageOptions";
import MessageAudio from "../../components/messages/audio";
import MessageImage from "../../components/messages/image";
import MessageFile from "../../components/messages/file";
import { MessageReply } from "../../components/messages/reply";
import MessageText from "../../components/messages/text";
import MessageVideo from "../../components/messages/video";

import AgendaCard from "../../../calendar/join_class/agendaCard";
import AskCard from "../../../calendar/join_class/askCard";
import AssignmentCard from "../../../calendar/join_class/assignmentCard";
import PollCard from "../../../calendar/join_class/pollCard";
import TaskCard from '../../../calendar/join_class/taskCard'
import QuizCard from "../../../calendar/join_class/quizCard";
import AautiText from "../../../globalComponents/AautiText";
import { Time } from "./Time";

import {
  isTextMessage,
  getMessageOptions,
} from "../../utils";
import { chatConstants, messageTypes } from "../../constants";



class Bubble extends React.Component {
  constructor() {
    super(...arguments);

    this.state = {
      visible: false,
      submitted: false,
    };
    const { currentMessage, position } = this.props;
    const isTextMsg = isTextMessage(currentMessage);
    this.menuOptions = getMessageOptions(position, isTextMsg);
  }

  toggleMessageOptions = (title = undefined) => {
    this.setState((prev) => ({ visible: !prev.visible }));
    if (!!title) this.props?.onPressMenuItem(title, this.props.currentMessage);
  };

  renderMessageText() {
    if (this.props.currentMessage && this.props.currentMessage.text) {
      return (
        <MessageText
          {...{
            ...this.props,
          }}
        />
      );
    }
    return null;
  }

  renderMessageImage() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageImage {...this.props} />;
    }
    return null;
  }
  renderMessageVideo() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageVideo {...this.props} />;
    }
    return null;
  }

  renderMessageAudio() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageAudio {...this.props} />;
    }
    return null;
  }

  renderMessageMedia() {
    if (this.props.currentMessage?.files?.length) {
      return <MessageFile {...this.props} />;
    }
    return null;
  }

  renderMessageReply() {
    return <MessageReply {...this.props} />;
  }

  renderTime() {
    if (this.props.currentMessage && this.props.currentMessage.createdAt) {
      return <Time {...this.props.currentMessage} />;
    }
    return null;
  }

  renderUsername() {
    const { currentMessage, sender } = this.props;
    if (sender && currentMessage.sender._id === sender._id) {
      return null;
    }
    return (
      <AautiText
        size={"small"}
        title={`${currentMessage.sender.displayName}`}
      />
    );
  }

  renderAvatar = () => {
    const { currentMessage, sender, position } = this.props;
    if (sender && currentMessage.sender._id === sender._id) {
      return null;
    }

    return (
      <div
        style={position === "left" ? { marginRight: 10 } : { marginLeft: 10 }}
      >
        <Avatar
          alt={currentMessage?.sender?.displayName ?? ""}
          src={currentMessage?.sender?.profileImage ?? ""}
          sx={{ width: 25, height: 25 }}
        />
      </div>
    );
  };

  renderBubbleContent(message) {
    if (!message?.type) return null;
    switch (message?.type) {
      case messageTypes.IMAGE:
        return this.renderMessageImage();
      case messageTypes.AUDIO:
        return this.renderMessageAudio();
      case messageTypes.VIDEO:
        return this.renderMessageVideo();
      case messageTypes.REPLY:
        return this.renderMessageReply();
      case messageTypes.TEXT:
        return this.renderMessageText();
      case messageTypes.OTHER:
      case messageTypes.DOCUMENT:
        return this.renderMessageMedia();
      default:
        return null;
    }
  }

  renderFeedCards(message) {
    if (!message?.type) return null;
    const { currentMessage, UserRole,onPressAsk } = this.props;
    let Card;
    let cardProps = {
        cardDetails: {
          ...currentMessage,
          displayName: currentMessage?.sender?.displayName,
        },
        UserRole,
        screen: "feed",
    }
    switch (message?.type?.toUpperCase()) {
      case messageTypes.AGENDA:
        Card = AgendaCard;
        break;
      case messageTypes.ASK:
        Card = AskCard;
        cardProps.onPressAsk = onPressAsk
        break;
      case messageTypes.TASK:
        Card = TaskCard;
        break;
      case messageTypes.ASSIGNMENT:
        Card = AssignmentCard;
        break;
      case messageTypes.POLL:
        Card = PollCard;
        break;
      case messageTypes.QUIZ:
        Card = QuizCard;
        break;
      default:
        return null;
    }
    return (
      <Card
        {...cardProps}
      />
    );
  }

  renderMessageOptions(id, position,showSelectIcon) {
    if(showSelectIcon) return;
    return (
      <div className={`message-options ${position}`}>
        {this.state.visible && (
          <MessageOptionsMenu
            id={`${id}-messages-options-menu`}
            {...{
              options: this.menuOptions,
              onPressMenuItem: this.toggleMessageOptions,
            }}
          />
        )}
      </div>
    );
  }

  renderMessageSelectedIcon(isSelected) {
    const onClick = ()=> this.props?.onPressMenuItem(chatConstants.selectMessage,this.props.currentMessage)
    return (
      <IconButton className={'message-options selected-msg'} onClick={onClick}
        sx={{marginRight:'3px'}}
      >
        {isSelected ? (
          <CheckCircleOutlined fontSize="small" color={"primary"} />
      ) : (
        <CircleOutlined fontSize="small" />
      )}
      </IconButton>
    );
  }

  renderEditIcon(position){
    return this.props?.currentMessage?.edited?<SlPencil size={16} className={`message-edit-icon ${position}`} />:null
  }

  render() {
    const { position, currentMessage,showSelectIcon,isSelected } = this.props;
    const feedComponent = this.renderFeedCards(currentMessage);

    return feedComponent ? (
      <div style={{ width: "100%",padding:"0px 16px" }}>{feedComponent}</div>
    ) : (
      <div className="bubble-container" >
        <div 
        className={`bubble-wrapper ${position}`}
        onMouseEnter={this.toggleMessageOptions}
        onMouseLeave={this.toggleMessageOptions}
        >
        {position === "left" &&
          this.renderMessageOptions(currentMessage._id, position)}
        {position === "left" &&
          this.props.renderUsernameOnMessage &&
          this.renderAvatar()}
        <div className="bubble-content">
          <div
            className={`bubble-username-time-wrapper ${position}`}
          >
            {this.props.renderUsernameOnMessage && this.renderUsername()}
            {this.renderTime()}
          </div>
          <div className={`bubble-content-and-edit-icon-wrapper ${position}`}>
          {position === "right" && this.renderEditIcon()}
            <div className={`bubble-content-wrapper ${position}`}>
              {this.renderBubbleContent(this.props.currentMessage)}
            </div>
            {position === "left" && this.renderEditIcon()}
          </div>
        </div>
        {position === "right" &&
          this.renderMessageOptions(currentMessage._id, position,showSelectIcon)}
        </div>
        {showSelectIcon && this.renderMessageSelectedIcon(isSelected)}
      </div>
    );
  }
}

export default Bubble;
