import { IconButton, TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import React, { useRef, useState } from "react";
import AautiText from "../../../globalComponents/AautiText";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import AautiUpload from "../../../globalComponents/AautiUpload";
import JoinButton from "../../../globalComponents/JoinButton";
import { showToast } from "../../../globalComponents/Toast";
import { createCourseStrings } from "../Constants";
import { AautiStrings } from "../../../globalComponents/AautiStrings";
import { checkYoutubeVideo } from "../../../CommonFunctions";
import ReactPlayer from "react-player";
import PreviewImage from "../PreviewImage";
import HelperText from "../../courseCreation/HelperText";
import AautiTextInput from "../../../globalComponents/AautiTextInput";
import { IoTrashOutline } from "react-icons/io5";
import dayjs from "dayjs";
import moment, { duration } from "moment";
import "./styles.scss";
import { ApiPaths, UploadBaseUrl } from "../../../../service/api-constants";
import { checkNull } from "../../../../utils/Helpers";

const GigUploads = ({
  preview,
  setActiveIndex,
  renderVideo,
  activeIndex,
  gig,
  setGig,
  setPreview,
  handleUploadVideoUrl,
  returnDisableDone,
  setCropImage,
  setImage,
  removTopic,
  handleAddDuration,
  handleUploadVideo,
  setUploadedVideos,
  handleOnChangeTopic,
  handleAddTopic,
  handleRemoveTopic,
  uploadedVideos,
  handleClickDone,
  gigVideos,
  handleUpdateVideoDuration,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [uploadUrl, setUploadUrl] = useState("");
  const actVideoRef = useRef();

  const returnRenderingVideo = () => {
    const url = uploadedVideos[activeIndex]?.videoUrl;
    const isYoutubeVideo = checkYoutubeVideo(url);

    const handleLoadedMetadata = () => {
      const video = actVideoRef.current;
      if (!video) return;
      if (video.duration) {
        handleUpdateVideoDuration(video.duration, activeIndex);
      }
    };

    function convertDurationtoSeconds(duration) {
      const [hours, minutes, seconds] = duration.split(":");
      return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);
    }
    const handleDuration = (videoDuration) => {
      const duration = formatDuration(videoDuration);
      const durationInSeconds = convertDurationtoSeconds(duration);

      if (durationInSeconds) {
        handleUpdateVideoDuration(durationInSeconds, activeIndex);
      }
    };

    const formatDuration = (seconds) => {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = Math.floor(seconds % 60);

      return `${hrs ? hrs + ":" : ""}${mins < 10 ? "0" : ""}${mins}:${
        secs < 10 ? "0" : ""
      }${secs}`;
    };

    if (isYoutubeVideo) {
      return (
        <ReactPlayer
          onDuration={handleDuration}
          // onReady={() => {
          //   if (playerRef.current) {
          //     const videoDuration = playerRef.current.getDuration();
          //     // setDuration(videoDuration);
          //     console.log(videoDuration);
          //   }
          // }}
          url={url}
          controls
          width="100%"
          height="100%"
        />
      );
    } else {
      return (
        <video
          ref={actVideoRef}
          controls
          src={url}
          muted
          autoPlay={true}
          onLoadedMetadata={handleLoadedMetadata}
          style={{ height: "100%", width: "100%" }}
        />
      );
    }
  };

  const returnTimeValue = (timeObj) => {
    const { hours, minutes, seconds } = timeObj;
    // Ensure each time value is positive
    const isValidTime = hours >= 0 && minutes >= 0 && seconds >= 0;
    if (isValidTime) {
      const updatedTime = moment().set({
        hour: hours,
        minute: minutes,
        second: seconds,
      });
      return updatedTime;
    } else {
      return null;
    }
  };

  const returnDuration = () => {
    const { gigHours, gigMinutes, gigSeconds } =
      uploadedVideos?.[activeIndex] || {};
    return ` ${gigHours || 0}h : ${gigMinutes || 0}m : ${gigSeconds || 0}s`;
  };

  const calculateTotalSeconds = (hours, minutes, seconds) =>
    parseInt(hours || 0) * 3600 +
    parseInt(minutes || 0) * 60 +
    parseInt(seconds || 0);

  const updateVideoTopicField = (
    videos,
    activeIndex,
    baseIndex,
    field,
    enteredValue
  ) => {
    return videos.map((video, idx) => {
      if (idx === activeIndex) {
        const totalAllowedSeconds = calculateTotalSeconds(
          video.gigHours,
          video.gigMinutes,
          video.gigSeconds
        );
        const currentTopicsTotalSeconds = video.topics.reduce(
          (sum, topic, index) => {
            const topicHours = parseInt(
              index === baseIndex && field === "hours"
                ? enteredValue
                : topic.hours || 0
            );
            const topicMinutes = parseInt(
              index === baseIndex && field === "minutes"
                ? enteredValue
                : topic.minutes || 0
            );
            const topicSeconds = parseInt(
              index === baseIndex && field === "seconds"
                ? enteredValue
                : topic.seconds || 0
            );
            return (
              sum +
              calculateTotalSeconds(topicHours, topicMinutes, topicSeconds)
            );
          },
          0
        );
        if (currentTopicsTotalSeconds > totalAllowedSeconds) {
          showToast("info", "Total time exceeds allowed video duration.");
          return video;
        }

        const updatedTopics = video.topics.map((topic, index) => {
          if (index === baseIndex) {
            return {
              ...topic,
              [field]: enteredValue,
            };
          }
          return topic;
        });

        return { ...video, topics: updatedTopics };
      }
      return video;
    });
  };

  const onChangeDuration = (value, base, baseIndex) => {
    const updatedVideos = updateVideoTopicField(
      uploadedVideos,
      activeIndex,
      baseIndex,
      base,
      parseInt(value) || 0
    );
    setUploadedVideos(updatedVideos);
  };

  const validateTopics = (video) => {
    const { gigHours, gigMinutes, gigSeconds, topics } = video;
    const totalAllowedSeconds = gigHours * 3600 + gigMinutes * 60 + gigSeconds;
    let totalTopicSeconds = 0;
    let isValid = true;

    topics.forEach((topic) => {
      const { hours, minutes, seconds } = topic;
      if (
        (hours === 0 || hours === "") &&
        (minutes === 0 || minutes === "") &&
        (seconds === 0 || seconds === "")
      ) {
        isValid = false;
        return;
      }

      if (
        hours === "" ||
        minutes === "" ||
        seconds === "" ||
        hours === null ||
        minutes === null ||
        seconds === null
      ) {
        isValid = false;
        return;
      }

      const topicSeconds = hours * 3600 + minutes * 60 + seconds;
      totalTopicSeconds += topicSeconds;
    });
    if (totalTopicSeconds > totalAllowedSeconds) {
      isValid = false;
    }

    return isValid;
  };

  return (
    <div>
      {!isEmpty(preview) && (
        <PreviewImage
          url={preview}
          handleClickOnPreviewEdit={() => {
            setCropImage(true);
            setImage(preview);
            setPreview(null);
            setGig(false);
          }}
          isGig={true}
        />
      )}
      {gig && (
        <div
          style={{
            width: "100%",
            display: "flex",
            padding: !mobileDevices && "0% 5%",
            flexDirection:
              mobileDevices || isEmpty(uploadedVideos) ? "column" : "row",
            gap: 20,
            justifyContent:
              mobileDevices || isEmpty(uploadedVideos)
                ? "center"
                : "flex-start",
            alignItems:
              mobileDevices || isEmpty(uploadedVideos)
                ? "center"
                : "flex-start",
          }}
        >
          <div
            style={{
              width: mobileDevices ? "100%" : "50%",
            }}
          >
            <div style={{ width: "100%" }}>
              <AautiUpload
                multiple={true}
                id="upload video"
                accept="video/mp4"
                onChange={(e) => {
                  handleUploadVideo(e, "gigVideo");
                }}
                iconName={"video"}
                important
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadVideoImp}
                    />
                  </div>
                }
              />
            </div>
            <p style={{ margin: "10px 0px 10px 0px", textAlign: "center" }}>
              or
            </p>
            <div
              style={{
                width: "100%",
                // border: "1px solid lightgrey",
                // borderRadius: 4,
                // padding: 6,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextField
                value={uploadUrl}
                onChange={(e) => {
                  const value = e.target.value.trimStart().replace(/\s+/g, " ");
                  setUploadUrl(value);
                }}
                size="small"
                fullWidth
                placeholder={createCourseStrings?.pasteURLHere}
                id="fullWidth"
              />
              <IconButton
                onClick={() => {
                  if (uploadUrl !== "") {
                    handleUploadVideoUrl(uploadUrl, "uploadUrl");
                    setUploadUrl("");
                  } else {
                    showToast("info", "Url field cannot be empty");
                  }
                }}
              >
                <AddCircleIcon sx={{ color: "#078dda" }} />
              </IconButton>
            </div>
            <div
              style={{
                height: "300px",
                width: "100%",
                marginTop: 15,
                border: "1px solid lightgrey",
                borderRadius: 4,
                display: isEmpty(uploadedVideos) && "none",
              }}
            >
              {returnRenderingVideo()}
            </div>

            <div
              style={{
                display: isEmpty(uploadedVideos) ? "none" : "flex",
                flexDirection: "row",
                gap: 5,
                marginTop: 10,
                flexWrap: "wrap",
              }}
            >
              {uploadedVideos?.map((each, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      border:
                        activeIndex == index
                          ? "1px solid blue"
                          : each.videoTitle == ""
                          ? `1px solid red`
                          : "",
                      cursor: "pointer",
                      borderRadius: 5,
                    }}
                    onClick={() => {
                      setActiveIndex(index);
                    }}
                  >
                    {renderVideo(each?.videoUrl, "gigVideo", index)}
                  </div>
                );
              })}
            </div>
          </div>
          {!isEmpty(uploadedVideos) && (
            <div
              style={{
                alignSelf: "flex-start",
                marginTop: mobileDevices ? 10 : 8,
                width: mobileDevices ? "100%" : "50%",
              }}
            >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className="video_title_creation">
                  <AautiTextInput
                    maxLength={100}
                    important={true}
                    text={createCourseStrings?.videoTitle}
                    value={uploadedVideos[activeIndex]?.videoTitle}
                    style={{ width: "100%" }}
                    size="medium"
                    onChange={(e) => {
                      handleOnChangeTopic(
                        e.target.value,
                        activeIndex,
                        "videoTitle"
                      );
                    }}
                    id="fullWidth-video-title"
                  />
                </div>
                <HelperText
                  error={
                    uploadedVideos[activeIndex]?.videoTitle?.length < 3
                      ? true
                      : false
                  }
                  title={`${uploadedVideos[activeIndex]?.videoTitle?.length}/100 (minimum 3 characters)`}
                />
              </div>
              <AautiText
                textStyle={{ marginBotom: 4, marginTop: 4 }}
                title={`Duration:${returnDuration()}`}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  border: "1px solid lightgrey",
                  padding: 9,
                  marginTop: 10,
                  borderRadius: 5,
                }}
              >
                <AautiText
                  title={AautiStrings?.topicsString}
                  weight={"medium"}
                  textStyle={{ color: "#000" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 15,
                    alignItems: "center",
                  }}
                >
                  <button
                    style={{
                      padding: "5px 10px 5px 10px",
                      fontWeight: 600,
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    disabled={uploadedVideos[activeIndex]?.topics?.length == 0}
                    onClick={() => {
                      // setCount(count - 1);
                      handleRemoveTopic(activeIndex);
                    }}
                  >
                    -
                  </button>
                  <AautiText
                    title={uploadedVideos[activeIndex]?.topics?.length}
                    weight={"medium"}
                    textStyle={{ color: "#000" }}
                  />
                  <button
                    disabled={uploadedVideos[activeIndex]?.topics?.length == 10}
                    style={{
                      padding: "5px 10px 5px 10px",
                      fontWeight: 600,
                      fontSize: 13,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // setCount(count + 1);
                      handleAddTopic(activeIndex);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
              <div
                style={{
                  height: mobileDevices ? "100%" : "55vh",
                  overflow: "scroll",
                }}
              >
                {uploadedVideos[activeIndex]?.topics?.map((each, index) => {
                  const { gigHours, gigMinutes, gigSeconds } =
                    uploadedVideos[activeIndex] || {};
                  return (
                    <div
                      className="video_title_creation"
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: 8,
                        gap: 4,
                      }}
                    >
                      <AautiTextInput
                        important={true}
                        text={`Topic ${index + 1}`}
                        value={`${each?.topicName}`}
                        size="medium"
                        fullWidth
                        maxLength={100}
                        placeholder={createCourseStrings?.videoTitle}
                        onChange={(e) => {
                          handleOnChangeTopic(
                            e.target.value,
                            activeIndex,
                            "topicName",
                            index
                          );
                        }}
                        style={{ width: "100%" }}
                        id="Topic-name"
                      />

                      <div
                        style={{
                          width: "35%",
                          marginTop: 10,
                          border: "1px solid lightgrey",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-around",
                          height: "54px",
                          padding: 5,
                          borderRadius: 4,
                        }}
                      >
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={["TimePicker", "TimePicker"]}
                          >
                            <TimePicker
                              value={dayjs(timerValue)}
                              views={["hours", "minutes", "seconds"]}
                              timeSteps={0}
                              minutesStep={1}
                              ampm={false}
                              onChange={(e) => {
                                if (!e) return; // Ensure `e` exists
                                const selectedTime = moment(e?.$d);
                                const durationObj = {
                                  getHours: selectedTime.get("hour"),
                                  getMinutes: selectedTime.get("minute"),
                                  seconds: selectedTime.get("second"),
                                };
                                handleAddDuration(
                                  durationObj,
                                  activeIndex,
                                  index
                                );
                              }}
                            />
                          </DemoContainer>
                        </LocalizationProvider> */}
                        <input
                          value={each?.hours || ""}
                          disabled={checkNull(gigHours)}
                          onChange={(e) => {
                            const value = e.target.value
                              ?.trimStart()
                              .replace(/\s+/g, " ")
                              .replace(/[^0-9]/g, "");
                            onChangeDuration(value, "hours", index);
                          }}
                          maxLength={2}
                          placeholder="hh"
                          type="text"
                          style={{
                            width: "25%",
                            outline: "none",
                            borderRadius: 2,

                            border: "none",
                            padding: 5,
                            fontSize: 20,
                          }}
                        />
                        <AautiText title={":"} />
                        <input
                          // disabled={checkNull(gigMinutes)}
                          value={each?.minutes || ""}
                          onChange={(e) => {
                            const value = e.target.value
                              ?.trimStart()
                              .replace(/\s+/g, " ")
                              .replace(/[^0-9]/g, "");
                            onChangeDuration(value, "minutes", index);
                          }}
                          maxLength={2}
                          placeholder="mm"
                          style={{
                            width: "25%",
                            outline: "none",
                            borderRadius: 2,

                            border: "none",
                            fontSize: 20,
                            padding: 5,
                          }}
                        />
                        <AautiText title={":"} weight={"bold"} />
                        <input
                          value={each?.seconds || ""}
                          onChange={(e) => {
                            const value = e.target.value
                              ?.trimStart()
                              .replace(/\s+/g, " ")
                              .replace(/[^0-9]/g, "");
                            onChangeDuration(value, "seconds", index);
                          }}
                          maxLength={2}
                          placeholder="ss"
                          style={{
                            width: "25%",
                            outline: "none",
                            borderRadius: 2,
                            border: "none",
                            fontSize: 20,
                            padding: 5,
                          }}
                        />
                      </div>
                      <IconButton
                        onClick={() => {
                          let prevVideos = [
                            ...uploadedVideos[activeIndex]?.topics,
                          ];
                          prevVideos = prevVideos?.filter((each, itemIndex) => {
                            return itemIndex !== index;
                          });
                          removTopic(prevVideos, activeIndex);
                        }}
                      >
                        <IoTrashOutline />
                      </IconButton>
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                  justifyContent: "center",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <JoinButton
                  onClick={() => {
                    setPreview(null);
                    setGig(false);
                    setUploadedVideos(gigVideos);
                    setActiveIndex(0);
                  }}
                  outlined={true}
                  title="Cancel"
                  buttonStyle={{
                    width: mobileDevices ? "20%" : "14%",
                    height: "40px",
                    // marginRight: "10px",
                    marginLeft: "10px",
                    marginTop: "8px",
                    alignSelf: "flex-end",
                  }}
                />
                <JoinButton
                  disabled={returnDisableDone}
                  buttonStyle={{
                    width: mobileDevices ? "20%" : "14%",
                    height: "40px",
                    // marginRight: "10px",
                    marginLeft: "10px",
                    marginTop: "8px",
                    alignSelf: "flex-end",
                  }}
                  title={"Save"}
                  onClick={() => {
                    const validateVideos = (videos) => {
                      return videos.every(validateTopics);
                    };

                    const validVideos = validateVideos(uploadedVideos);
                    if (validVideos) {
                      setPreview(null);
                      setGig(false);
                      handleClickDone();
                    } else {
                      showToast("info", "Topics duration cannot be empty");
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GigUploads;
