import React, { useState } from "react";
import { Cropper } from "react-cropper";
import "./Style.scss";
import AautiText from "../../globalComponents/AautiText";
import "cropperjs/dist/cropper.css";
import { isEmpty } from "lodash";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { useSelector } from "react-redux";
import { levelStrings } from "./Constants";
import PreviewCard from "./PreviewCard";

const ImageCropper = ({
  cropperRef,
  image,
  cropObject,
  handleUploadCroppedImage,
  uploadedFile,
  cancelCrop,
  isProfile = false,
  isPackage = false,
  multipleUploads,
  setImage,
  setBackdropOpen,
  handleRemoveMultipleImages,
  aiImages,
  removeWhenUploaded = false,
}) => {
  const { mobileDevices } = useSelector((state) => state.responsive);
  const imageFields = ["courseImage", "bookCoverPage"];
  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
      if (croppedCanvas) {
        const fileName = uploadedFile?.name;
        croppedCanvas?.toBlob((blob) => {
          const croppedFile = new File(
            [blob],
            fileName ?? `AiImages-${new Date().toUTCString()}.jpg`,
            {
              type: "image/png",
              lastModified: Date.now(),
            }
          );
          //   setCropData(croppedFile);
          handleUploadCroppedImage(croppedFile, cropObject?.field || aiImages);
        }, "image/png");
      }
    }
  };
  const returnBool = aiImages ? false : true;
  const [croppedUrl, setCropppedUrl] = useState(image);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: mobileDevices ? "column" : "row",
        gap: 15,
      }}
    >
      <div style={{ width: mobileDevices ? "100%" : "75%" }}>
        <Cropper
          disabled={true}
          ref={cropperRef}
          style={{ height: mobileDevices ? 260 : 500, width: "100%" }}
          zoomTo={0.5}
          initialAspectRatio={
            imageFields?.includes(cropObject?.field) ? 4 / 3 : 16 / 9
          }
          aspectRatio={
            imageFields?.includes(cropObject?.field) ? 4 / 3 : 16 / 9
          }
          // preview={isProfile ? ".img-avatar" : ".img-preview"}
          preview=".img-preview"
          src={image}
          viewMode={1}
          minCropBoxHeight={0}
          minCropBoxWidth={0}
          background={true}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          guides={true}
          checkCrossOrigin={returnBool}
          crop={() => {
            if (isProfile) {
              const cropCanvas =
                cropperRef.current?.cropper?.getCroppedCanvas();
              setCropppedUrl(cropCanvas?.toDataURL());
            }
          }}
        />

        {!isEmpty(multipleUploads) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 6,
              marginTop: 8,
              width: "100%",
              justifyContent: "center",
            }}
          >
            {multipleUploads?.map((each, index) => (
              <div
                onClick={() => {
                  setImage(each);
                }}
                style={{
                  border:
                    image === each
                      ? "2px solid #3399ff"
                      : "1px solid lightgrey",
                  borderRadius: 6,
                  padding: 5,
                  position: "relative",
                  height: 80,
                  width: 80,
                }}
              >
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveMultipleImages(each);
                  }}
                  sx={{
                    position: "absolute",
                    right: 1,
                    background: "#fff",
                    padding: 0.5,
                    "&:hover": {
                      background: "#fff",
                    },
                  }}
                >
                  <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
                </IconButton>
                <img
                  src={each}
                  style={{ height: "100%", width: "100%" }}
                  alt="gigImage"
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <div
        style={{
          width: mobileDevices ? "100%" : "25%",
          display: "flex",
          flexDirection: "column",
          justifyContent: !isProfile && "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            marginTop: mobileDevices ? 15 : 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AautiText
            title={AautiStrings?.previewString}
            textAlign={"center"}
            weight={"bold"}
            size={"large"}
          />
          {isProfile ? (
            <img
              src={croppedUrl}
              style={{ height: 190, width: 190, borderRadius: 100 }}
            />
          ) : (
            <PreviewCard isProfile={isProfile} />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            marginTop: 20,
            gap: 10,
          }}
        >
          <button
            style={{
              border: "1px solid lightgrey",
              color: "#000",
              fontSize: "13px",
              fontWeight: "500",
              borderRadius: 4,
              padding: "5px 15px 5px 15px",
              cursor: "pointer",
            }}
            onClick={() => {
              cancelCrop();
            }}
          >
            Cancel
          </button>
          <button
            style={{
              background: "#3399ff",
              color: "#fff",
              fontSize: "13px",
              fontWeight: "500",
              border: "none",
              borderRadius: 4,
              padding: "5px 15px 5px 15px",
              cursor: "pointer",
            }}
            onClick={() => {
              getCropData();
              if (!isEmpty(multipleUploads) || removeWhenUploaded) {
                handleRemoveMultipleImages(image);
              }
              isPackage && setBackdropOpen && setBackdropOpen(true);
            }}
          >
            {multipleUploads?.length > 1 ? "Next" : "Proceed"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
