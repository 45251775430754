import React, { useState } from "react";
import { useSelector } from "react-redux";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import AautiText from "../../globalComponents/AautiText";
import HelperText from "./HelperText";
import { levelStrings, targetedAgeGroup } from "../createCourse/Constants";
import { ClassTypes, courseTypes } from "./constants";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import CloseIcon from "@mui/icons-material/Close";
import AautiCaps from "../../globalComponents/AautiCaps";
import CatDropdown from "../createCourse/CatDropdown";
import AddressSelection from "./AddressSelection";
import NavButton from "./NavButton";
import { isEmpty } from "lodash";
import { showToast } from "../../globalComponents/Toast";
import { courseConstant } from "./courseConstant";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { IoIosCheckmark, IoIosCloseCircleOutline } from "react-icons/io";
import { Box, IconButton } from "@mui/material";
const CourseOverview = ({
  categories,
  subCategories,
  CreateCourseObj,
  changeCreateObj,
  languages,
  setOpenAddress,
  openAddress,
  planObj,
  sessionObject,
  changePlanObject,
  generateAIResp,
  pathname,
  setOpen2,
  setActiveField,
  isGig,
  editCourse,
  cloneCourse,
  setCreateCourseObj,
  setSubCategories,
  allCategories,
  defaultAddress,
  validateObj,
  courseOverviewErrors,
  setCourseOverviewErrors,
  resetObject,
  editGig,
  handleOnBlurInput,
}) => {
  const [visible2, setVisible2] = useState(false);
  const [tagsInput, SetTagsInput] = useState("");
  const { ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );
  const [selectedCities, setSelectedCities] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];
  const [refresh, setRefresh] = useState(false);
  const { userDetails, properties } = useSelector((state) => state.app);
  const { titleMaximumLength = 70, titleMinimumLength = 15 } = properties;

  // Function to check if required fields are filled

  const onSelectLevels = (each) => {
    let baseLevels = [...CreateCourseObj.courseLevel];
    if (baseLevels?.includes(each)) {
      baseLevels = baseLevels?.filter((item, index) => {
        return each !== item;
      });
    } else {
      baseLevels?.push(each);
    }

    changeCreateObj(baseLevels, "courseLevel");

    // if (isEmpty(baseLevels)) {
    //   setCourseOverviewErrors((prev) => ({
    //     ...prev,
    //     courseLevel: "Course levels required",
    //   }));
    // }
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-checkbox"
    );
    selectAllCheckbox.after("  Select All");
  };

  const checkEnterKey = (event) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      const tagCheck = [...CreateCourseObj.searchTags]?.some(
        (each) => each.toLowerCase() == tagsInput.toLowerCase()
      );

      if (tagsInput.length >= 3) {
        if (tagCheck) {
          showToast("info", `${tagsInput} already added`);
        } else {
          changeCreateObj(
            [...CreateCourseObj.searchTags, tagsInput],
            "searchTags"
          );
          SetTagsInput("");
        }
      }
    }
  };

  const removeTag = (tag) => {
    const a = CreateCourseObj.searchTags.filter((each, index) => {
      return each !== tag;
    });
    changeCreateObj(a, "searchTags");
  };

  const chageSessionsObjec = (val) => {
    let newLesson = [];
    for (var i = 0; i < val; i++) {
      newLesson.push(sessionObject);
    }
    changePlanObject(newLesson, "sessions");
  };
  const removeSession = () => {
    const b = planObj?.sessions?.length - 1;
    const a = planObj?.sessions?.filter((each, index) => {
      return index !== b;
    });
    changePlanObject(a, "sessions");
  };

  const returnClass = (name) => {
    let active;
    if (name == "Virtual") {
      active = CreateCourseObj?.virtual;
    } else if (name === "In Person") {
      active = CreateCourseObj?.inPerson;
    }
    return active;
  };

  const handleOpenAddress = () => {
    setOpenAddress(!openAddress);
  };

  const returnLocation = () => {
    if (CreateCourseObj?.inPerson) {
      return <AddressSelection {...{ handleOpenAddress, CreateCourseObj }} />;
    }
  };

  const lan = CreateCourseObj?.languages?.filter((value) =>
    languages.includes(value)
  );

  const gendersList = ["Male", "Female", "Others"];

  return (
    <div className="tab">
      <IconButton
        sx={{ position: "absolute", right: 2, top: 2 }}
        onClick={() => {
          setActiveField("");
        }}
      >
        <IoIosCloseCircleOutline />
      </IconButton>
      <AautiText
        size={"medium"}
        weight={"bold"}
        title={courseConstant?.courseOverview}
        textStyle={{ marginBottom: mobileDevices ? "6px" : "15px" }}
      />
      <div
        style={{ width: "100%", marginBottom: mobileDevices ? "6px" : "15px" }}
      >
        <AautiTextInput
          onBlurInput={(e) => {
            handleOnBlurInput(e, "courseName");
          }}
          important={true}
          id={"course-creation-title"}
          maxLength={titleMaximumLength}
          text={courseConstant?.courseTitle}
          style={{
            width: "100%",
          }}
          value={CreateCourseObj?.courseName}
          onChange={(e) => {
            let name = e.target.value;
            changeCreateObj(name, "courseName");
          }}
        />
        <HelperText
          error={courseOverviewErrors?.courseName || false}
          title={`${
            CreateCourseObj?.courseName?.length || 0
          }/${titleMaximumLength} (minimum ${titleMinimumLength} characters)`}
        />
      </div>
      <div
        className="level-main"
        style={{ marginBottom: mobileDevices ? "6px" : 15 }}
      >
        <AautiText
          title={courseConstant?.levels}
          required={true}
          size={"normal"}
        />
        <div className="level-cont">
          {levelStrings.map((each, index) => {
            const active = CreateCourseObj?.courseLevel?.includes(each);
            return (
              <div
                id={`${each}-level`}
                key={index}
                className={`level-item ${active && "active-class"}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: mobileDevices ? 1 : 3,
                  alignItems: "center",
                  padding: mobileDevices ? 5 : 10,
                  justifyContent: "center",
                }}
                onClick={() => {
                  onSelectLevels(each);
                }}
              >
                {active && <IoIosCheckmark size={24} />}
                <AautiText title={each} size={"semi"} />
              </div>
            );
          })}
        </div>
        <HelperText
          error={courseOverviewErrors?.courseLevel}
          title={
            courseConstant?.Allowsyoutoteachusersacrossdifferentskilllevels
          }
        />
      </div>
      <div
        className="level-main"
        style={{ marginBottom: mobileDevices ? "6px" : 15 }}
      >
        <button
          id="category-subcategory-selection"
          style={{
            width: "100%",
            border: "0px solid #dddddd",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            backgroundColor: "#ffffff",
            cursor: "pointer",
            padding: 0,
          }}
          onClick={() => {
            if (!isEmpty(CreateCourseObj?.endUserList) && !cloneCourse) {
              showToast("info", "Category & SubCategory cannot be edited");
            } else {
              CreateCourseObj?.category !== "" &&
              CreateCourseObj?.courseDescription !== ""
                ? setOpen2(true)
                : setVisible2(true);
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: "2px",
            }}
          >
            <AautiText
              title={courseConstant?.categoryAndSubcategory}
              required={true}
              size={"normal"}
              textStyle={{ color: "#000" }}
            />
          </div>
          <div
            id="category&subCategory-selection"
            style={{
              border: "1px solid #dddddd",
              width: "100%",
              padding: 10,
              borderRadius: 5,
              display: "flex",
            }}
            onClick={() => {
              if (!isEmpty(CreateCourseObj?.endUserList) && !cloneCourse) {
                // showToast("info", "Category & SubCategory cannot be edited");
              } else {
                setVisible2(true);
              }
            }}
          >
            <AautiText
              textStyle={{
                color: !CreateCourseObj?.category ? "#dddddd" : "#000",
              }}
              title={
                CreateCourseObj?.category
                  ? `${CreateCourseObj?.category} - ${CreateCourseObj?.subCategory}`
                  : "Category and Subcategory"
              }
            />
          </div>
        </button>
        {(courseOverviewErrors?.category ||
          courseOverviewErrors?.subCategory) && (
          <HelperText
            error={true}
            title={courseConstant?.categoryAndSubCategoryRequired}
          />
        )}
      </div>
      <div
        className="level-main"
        style={{ marginBottom: mobileDevices ? "6px" : 15 }}
      >
        <AautiText
          required={true}
          title={courseConstant?.courseType}
          // weight={"bold"}
          size={"normal"}
        />
        <div className="level-cont">
          {courseTypes.map((each, index) => {
            const active = CreateCourseObj?.courseType?.includes(each?.title);
            return (
              <div
                key={index}
                className={`level-item course-types-overview ${
                  active ? "active-class" : ""
                }`}
                onClick={() => {
                  if (!editGig) {
                    if (each?.title !== "Gig") {
                      if (pathname !== "/update-gig") {
                        if (CreateCourseObj?.courseType?.includes(each.title)) {
                          if (each?.title == "Group") {
                            setCreateCourseObj((prev) => ({
                              ...prev,
                              groupDaily: [],
                              groupWeekly: [],
                            }));
                          } else if (each?.title == "Individual") {
                            setCreateCourseObj((prev) => ({
                              ...prev,
                              individualDaily: [],
                              individualWeekly: [],
                            }));
                          }
                          let b = CreateCourseObj?.courseType?.filter(
                            (item) => {
                              return each?.title !== item;
                            }
                          );
                          changeCreateObj(b, "courseType");
                        } else {
                          let newi = CreateCourseObj?.courseType?.filter(
                            (each) => {
                              return each !== "Gig";
                            }
                          );
                          changeCreateObj([...newi, each?.title], "courseType");
                          // setNumberOfSteps(5);
                        }
                      }
                    } else {
                      if (CreateCourseObj?.courseType?.includes("Gig")) {
                        let b = CreateCourseObj?.courseType?.filter((item) => {
                          return "Gig" !== item;
                        });
                        if (!pathname?.includes("/update-gig") && !editCourse) {
                          changeCreateObj(b, "courseType");
                          // navigate("/update-gig");
                        }
                        // setNumberOfSteps(5);
                      } else if (!editCourse) {
                        // setNumberOfSteps(3);
                        if (!editCourse) {
                          changeCreateObj(["Gig"], "courseType");
                        }
                      }
                    }
                  }
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  width={"100%"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  {each.icon}
                  {active && <IoIosCheckmark size={24} />}
                </Box>
                <div>
                  <AautiText title={each.title} size="semi" textStyle={{}} />
                  <AautiText title={each?.text} size="small" />
                </div>
              </div>
            );
          })}
        </div>
        {courseOverviewErrors?.courseType && (
          <HelperText error={true} title={courseConstant?.courseTypeRequired} />
        )}
      </div>
      <div
        className="level-main languages-flex"
        style={{
          display: isEmpty(CreateCourseObj?.courseType) && "none",
          marginBottom: mobileDevices ? "6px" : 15,
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            width: mobileDevices ? "100%" : "50%",
            display:
              CreateCourseObj?.courseType?.includes("Individual") ||
              CreateCourseObj?.courseType?.includes("Gig")
                ? "block"
                : "none",
          }}
        >
          <AautiText
            title={
              CreateCourseObj?.courseType?.includes("Gig")
                ? "Languages"
                : "Individual Languages"
            }
            required
          />
          <MultiSelect
            value={lan}
            onChange={(e) => {
              changeCreateObj(e.target.value, "languages");
            }}
            onShow={onShow}
            options={languages}
            inputId="input-select-language"
            style={{ width: "100%", marginTop: 2 }}
            display="chip"
            placeholder={
              CreateCourseObj?.courseType?.includes("Gig")
                ? "Select Languages"
                : "Select Individual Languages"
            }
            className="w-full md:w-18rem"
          />
          {CreateCourseObj?.languages?.length < 1 && (
            <HelperText
              error={
                // (CreateCourseObj?.virtual || CreateCourseObj?.inPerson) &&
                CreateCourseObj?.languages?.length < 1
              }
              title={
                CreateCourseObj?.courseType?.includes("Gig")
                  ? "Select Languages"
                  : "Select Individual Languages"
              }
            />
          )}
        </div>
        <div
          style={{
            width: mobileDevices ? "100%" : "50%",
            display: CreateCourseObj?.courseType?.includes("Group")
              ? "block"
              : "none",
          }}
        >
          <AautiText title={"Group Language"} required />
          <Dropdown
            inputId="dd-city"
            placeholder="Group Language"
            options={languages}
            important
            style={{ width: "100%" }}
            value={CreateCourseObj?.groupLanguage}
            onChange={(e) => {
              changeCreateObj(e.target.value, "groupLanguage");
            }}
          />
          {courseOverviewErrors?.groupLanguage && (
            <HelperText
              error={true}
              title={courseConstant?.selectGroupLanguage}
            />
          )}
        </div>
      </div>

      <div
        className="level-main"
        style={{
          display: isGig && "none",
          marginBottom: "10px",
        }}
      >
        <AautiText
          required={true}
          title={courseConstant?.classType}
          // weight={"bold"}
          size={"normal"}
          textStyle={{ marginBottom: 5 }}
        />
        <div className="flex-container" style={{ gap: 10 }}>
          {ClassTypes?.map((each, index) => {
            const activeClass = returnClass(each.name);
            return (
              <div
                key={index}
                className={`level-item flex-container class-item ${
                  activeClass && "active-class"
                }`}
                onClick={() => {
                  if (each.name === "Virtual") {
                    changeCreateObj(!CreateCourseObj?.virtual, "virtual");
                  } else {
                    if (CreateCourseObj?.inPerson) {
                      changeCreateObj(!CreateCourseObj?.inPerson, "inPerson");
                      changeCreateObj({}, "address");
                    } else {
                      changeCreateObj(!CreateCourseObj?.inPerson, "inPerson");
                      changeCreateObj(defaultAddress?.[0] || {}, "address");
                    }
                  }
                }}
              >
                {activeClass && <IoIosCheckmark size={24} />}
                {each.icon}

                <AautiText
                  title={each.name}
                  size="semi"
                  //   textStyle={{
                  //     color:
                  //     //   CreateCourseObj?.courseType?.includes(each) && "#ffffff",
                  //   }}
                />
              </div>
            );
          })}
        </div>
        {returnLocation()}
        {courseOverviewErrors?.inPersonOrVirtual && (
          <HelperText
            title={courseOverviewErrors?.inPersonOrVirtual}
            error={true}
          />
        )}
      </div>
      <div
        className="level-main"
        style={{ marginBottom: mobileDevices ? "6px" : 15 }}
      >
        <AautiText
          required={true}
          title={courseConstant?.age}
          // weight={"bold"}
          size={"normal"}
          textStyle={{ marginBottom: 5 }}
        />
        <div className="flex-container" style={{ gap: 10 }}>
          {targetedAgeGroup?.map((each, index) => {
            const active = CreateCourseObj?.targetAgeGroups?.includes(each);
            return (
              <div
                key={index}
                className={`level-item ${active && "active-class"}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: mobileDevices ? 1 : 3,
                  alignItems: "center",
                  padding: mobileDevices ? 5 : 10,
                  justifyContent: "center",
                }}
                onClick={() => {
                  if (CreateCourseObj?.targetAgeGroups?.includes(each)) {
                    let ageGroup = CreateCourseObj?.targetAgeGroups.filter(
                      (item) => {
                        return item !== each;
                      }
                    );
                    changeCreateObj(ageGroup, "targetAgeGroups");
                  } else {
                    changeCreateObj(
                      [...CreateCourseObj?.targetAgeGroups, each],
                      "targetAgeGroups"
                    );
                  }
                }}
              >
                {active && <IoIosCheckmark size={24} />}
                <AautiText title={each} size={"semi"} />
              </div>
            );
          })}
        </div>

        <HelperText
          title={courseConstant?.Picktheagegroupyoudliketoteach}
          error={courseOverviewErrors?.targetAgeGroups}
        />
      </div>
      <div
        className="level-main"
        style={{ marginBottom: mobileDevices ? "6px" : 15 }}
      >
        <AautiText
          required={true}
          title={"Gender"}
          // weight={"bold"}
          size={"normal"}
          textStyle={{ marginBottom: 5 }}
        />

        <MultiSelect
          id="gender-dropdown"
          value={
            CreateCourseObj?.gender?.map((each) =>
              CapitalizeFirstLetter(each)
            ) || []
          }
          onChange={(e) => {
            const value = e.target.value;
            changeCreateObj(value, "gender");
          }}
          onShow={onShow}
          options={gendersList}
          inputId="input-select-gender"
          style={{ width: mobileDevices ? "100%" : "50%" }}
          display="chip"
          placeholder="Select Gender"
          className="w-full md:w-18rem"
        />
      </div>
      {courseOverviewErrors?.gender && (
        <HelperText error={true} title={"Gender is required"} />
      )}
      <div className="level-main" style={{ marginBottom: 20 }}>
        <AautiText
          // required={true}
          title={courseConstant?.courseTags}
          // weight={"bold"}
          size={"normal"}
        />
        <div className="tags-container" style={{ width: "100%" }}>
          {CreateCourseObj?.searchTags?.map((each, index) => {
            return (
              <div className="added-tag" key={index}>
                <AautiText
                  title={AautiCaps(each)}
                  textStyle={{ color: "#ffffff" }}
                  weight={"bold"}
                  size={"semi"}
                />
                <CloseIcon
                  sx={{ color: "#ffffff", cursor: "pointer", marginLeft: 1 }}
                  onClick={() => {
                    removeTag(each);
                  }}
                />
              </div>
            );
          })}
          <input
            id="tags-input"
            value={tagsInput}
            disabled={CreateCourseObj?.searchTags?.length >= 10}
            style={{ width: "100%" }}
            placeholder={
              courseConstant?.addUptTo10KeywordsThatItWillHelpPeopleToDiscoverTheCourse
            }
            className="tags-input"
            maxLength={50}
            onKeyDown={checkEnterKey}
            onChange={(e) => {
              const value = e.target.value.trimStart().replace(/\s+/g, " ");
              SetTagsInput(value);
            }}
          />
        </div>
        <HelperText title={courseConstant?.upTo10Tags} />
      </div>
      <div className="level-main" style={{ marginBottom: 20 }}>
        <AautiTextInput
          onBlurInput={(e) => {
            handleOnBlurInput(e, "courseDescription");
          }}
          value={CreateCourseObj?.courseDescription}
          important
          onChange={(e) => {
            changeCreateObj(e.target.value, "courseDescription");
            const obj = {
              ...CreateCourseObj?.lessonPlan,
              description: e.target.value,
            };
            changeCreateObj(obj, "lessonPlan");
          }}
          text={courseConstant?.shortDescription}
          style={{ width: "100%" }}
          multiline
          length={CreateCourseObj?.courseDescription?.length}
          maxLength={100}
          rows={3}
        />
        <HelperText
          aiGeneration={true}
          error={courseOverviewErrors?.courseDescription}
          handleClickGenerateAI={() => generateAIResp("courseDescription")}
          title={`${
            CreateCourseObj?.courseDescription?.length || 0
          }/100 (minimum 50 Characters)`}
        />
      </div>
      <div
        className="level-main"
        style={{ marginBottom: mobileDevices ? "6px" : 15 }}
      >
        <AautiTextInput
          onBlurInput={(e) => {
            handleOnBlurInput(e, "summary");
          }}
          value={CreateCourseObj?.summary}
          important
          onChange={(e) => {
            changeCreateObj(e.target.value, "summary");
          }}
          text={courseConstant?.courseSummary}
          style={{ width: "100%" }}
          multiline
          rows={4}
          maxLength={200}
        />
        <HelperText
          aiGeneration={true}
          handleClickGenerateAI={() => generateAIResp("summary")}
          error={courseOverviewErrors?.summary}
          title={`${CreateCourseObj?.summary?.length}/200 (minimum 50 Characters)`}
        />
      </div>
      <div
        className="level-main"
        style={{
          display: isGig && "none",
          marginBottom: mobileDevices ? "6px" : 15,
        }}
      >
        <div className="session-cou1" style={{ width: "100%" }}>
          <AautiText
            title={courseConstant?.enterNoOfSessions}
            required
            weight={"bold"}
          />
          <div className="sessions-count">
            <button
              className="buttonplus"
              onClick={() => {
                if (planObj?.sessions?.length > 1) {
                  removeSession();
                }
              }}
            >
              -
            </button>
            <input
              style={{
                outline: "none",
                border: "none",
                fontWeight: "bold",
                fontSize: 16,
                width: 25,
                marginLeft: mobileDevices ? "6px" : 15,
                marginRight: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onChange={(e) => {
                let v = e.target.value;
                if (!isNaN(v) && v < 11) {
                  chageSessionsObjec(v);
                }
              }}
              onBlur={(e) => {
                if (e.target.value == 0) {
                  chageSessionsObjec("01");
                }
              }}
              maxLength={2}
              value={planObj?.sessions?.length}
            />
            <button
              className="buttonplus"
              onClick={() => {
                if (planObj?.sessions?.length < 10) {
                  changePlanObject(
                    [...planObj?.sessions, sessionObject],
                    "sessions"
                  );
                }
              }}
            >
              +
            </button>
          </div>
        </div>
        {/* <HelperText
          title={courseConstant?.addAtHowManySessionsYourCourseIsGoingToEnd}
        /> */}
      </div>
      <NavButton
        // disabled={isButtonEnabled}
        text={"Next"}
        handleClick={() => {
          const errObj = validateObj(CreateCourseObj);
          if (!isEmpty(errObj?.errors)) {
            setActiveField("Title");
          } else {
            setActiveField(isGig ? "Course Image" : "Lesson Plan");
          }
        }}
      />
      <CatDropdown
        {...{
          CreateCourseObj,
          visible2,
          mobileDevices,
          setVisible2,
          categories,
          setRefresh,
          subCategories,
          refresh,
          setCreateCourseObj,
          setSubCategories,
          allCategories,
          courseOverviewErrors,
          resetObject,
          validateObj,
        }}
      />
    </div>
  );
};

export default CourseOverview;
