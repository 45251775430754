import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getBatchDetailsByBatchId } from "./Services";
import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AautiHead from "../../globalComponents/AautiHead";
import { isEmpty } from "lodash";
import BatchCard from "../myCourses/BatchCard";
import RescheduleBatchCard from "./RescheduleBatchCard";

import SearchIcon from "@mui/icons-material/Search";
import BatchUserCard from "./BatchUserCard";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import AautiFileHeader from "../../globalComponents/AautiFileHeader";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { useSelector } from "react-redux";
import EmptyData from "../../globalComponents/EmptyData";
import AccountTabs from "../../globalComponents/AccountTabs";

const RescheduleList = () => {
  const [rescheduleList, setRescheduleList] = useState({});
  const [activeState, setActiveState] = useState("Accepted");
  const [search, setSearch] = useState("");
  const { state } = useLocation();
  const { mobileDevices } = useSelector((state) => state.responsive);

  useEffect(() => {
    const data = {
      courseId: state?.courseId,
      batchId: state?.batchId,
    };

    getBatchDetailsByBatchId(
      data,
      (response) => {
        console.log(response, "response?..?");
        if (response.result) {
          console.log(response?.result, "resulttt?...");
          setRescheduleList(response?.result[0]);
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  }, [state]);

  const filteredActiveList = (list) =>
    list?.filter((user) =>
      user.displayName?.toLowerCase().includes(search.toLowerCase())
    );

  const returnActiveContent = () => {
    const activeList =
      activeState == "Accepted"
        ? rescheduleList?.acceptedUsers
        : activeState == "Rejected"
        ? rescheduleList?.rejectedUsers
        : [];

    const filteredList = filteredActiveList(activeList);

    return (
      <div>
        {isEmpty(filteredList) ? (
          <EmptyData
            // style={{ height: mobileDevices && 250 }}
            title={AautiStrings?.noUsersString}
            showExplore={false}
            style={{ height: mobileDevices && 250 }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 10,
              marginTop: 10,
            }}
          >
            {filteredList?.map((each, index) => (
              <div key={index} style={{ width: "48%" }}>
                <BatchUserCard obj={each} />
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <AppContainer>
      {/* <AautiFileHeader activeTitle={"Reschedule"} /> */}
      <AccountTabs
        tabObj={[
          {
            label: "My Courses",
            navigation: "/my-courses",
            active: false,
          },
          {
            label: "Reschedule",
            navigation: "/my-courses",
            active: true,
          },
        ]}
      />
      {!isEmpty(rescheduleList) && (
        <div
          style={{
            width: "100%",
            display: rescheduleList ? "flex" : "none",
            flexDirection: mobileDevices ? "column" : "row",
            border: "1px solid #E0E0E0",
            borderRadius: 8,
            padding: 10,
            height: "90vh",
            gap: 15,
          }}
        >
          <div
            style={{
              width: mobileDevices ? "100%" : "30%",
              display: isEmpty(rescheduleList) && "none",
            }}
          >
            <RescheduleBatchCard
              each={rescheduleList}
              title={rescheduleList?.type}
              listing={true}
            />
          </div>
          <div
            style={{
              width: mobileDevices ? "100%" : "70%",
              flex: mobileDevices && 1,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: mobileDevices ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: mobileDevices ? "100%" : "50%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: mobileDevices ? "5%" : 0,
                  }}
                >
                  {["Accepted", "Rejected"]?.map((each, index) => (
                    <AautiText
                      onClick={() => {
                        setActiveState(each);
                      }}
                      key={index}
                      title={each}
                      textStyle={{
                        color: each === activeState ? "#3166ba" : "#1e1e1e",
                        borderBottom:
                          each === activeState
                            ? "3px solid #3166ba"
                            : "1px solid #dedede",
                        paddingBottom: 10,
                        width: "50%",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                    />
                  ))}
                </div>
                <FormControl
                  sx={{
                    m: 1,
                    width: mobileDevices ? "100%" : "25ch",
                    margin: 0,
                  }}
                  size="small"
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Search
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-search"
                    type={"text"}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton aria-label="search" edge="end">
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Search"
                  />
                </FormControl>
              </div>
            </div>
            <div>{returnActiveContent()}</div>
          </div>
        </div>
      )}
    </AppContainer>
  );
};

export default RescheduleList;
