import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import timeSvg from "./assets/time.svg";
import createCourseSvg from "./assets/create_course.svg";
import createLessonSvg from "./assets/create_lessonp.svg";
import createAdhocSvg from "./assets/create_adhoc.svg";
import myOrdersSvg from "./assets/my_orders.png";
import profileSvg from "./assets/profile.svg";
import AautiText from "../../globalComponents/AautiText";
import VerificationPng from "./assets/verification_png.svg";
import "./styles.scss";
import moment from "moment";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CloneCourseObj,
  editCourseObject,
  editGigObject,
  setJitsiFullScreenMode,
  setJwtToken,
  setRoomName,
} from "../../../redux/reducer/appReducer";
import { AuthService } from "../../../service";
import { showToast } from "../../globalComponents/Toast";
import { checkNull, pluralOrSingular } from "../../../utils/Helpers";
import { CapitalizeFirstLetter, validateTitle } from "../../CommonFunctions";
import ClassJoinButton from "../../globalComponents/classJoinButton/ClassJoinButton";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    minWidth: 300,
  },
  // "& .MuiDialogActions-root": {
  //   padding: theme.spacing(1),
  // },
}));

const TodoListDialog = ({
  toggleOpenTodoDialog,
  openTodoDialog,
  navigateToTodoOptions,
  todayEvents,
  toDoList,
}) => {
  const {
    myLessonPlansCount = 0,
    gigsCount = 0,
    draftGigsCount = 0,
    coursesCount = 0,
    draftCoursesCount = 0,
    adhocClassesCount = 0,
    coursesPurchased = 0,
    gigsPurchased = 0,
    adhocsPurchased = 0,
    ordersCount = 0,
  } = toDoList ?? {};

  const {
    UserRole,
    profilePercentage,
    loggedinUserId,
    userDetails,
    todoLoading,
  } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mobileDevices } = useSelector((state) => state.responsive);
  const renderEvents = (item, index) => {
    return (
      <div
        className="todo-option-item"
        key={index}
        onClick={() => {
          item?.scheduleType != "offTime" &&
            navigate("/join_class", { state: item });
          toggleOpenTodoDialog();
        }}
      >
        <div className="todo-option-item-first">
          <img src={createCourseSvg} alt="course" className="todo-item-image" />
          <div className="todo-option-item-text-con" style={{ width: "85%" }}>
            <AautiText title={item?.courseName} className="todo-label-text" />
            <AautiText
              title={`Batch : ${CapitalizeFirstLetter(
                item?.batchName || item?.sessionName || ""
              )}`}
              size={"semi"}
              textStyle={{
                display:
                  (item?.scheduleType == "offTime" ||
                    item?.scheduleType == "adhoc") &&
                  "none",
              }}
            />
            <AautiText
              title={
                moment(item?.startTime).format("hh:mm A") +
                " to " +
                moment(item?.endTime).format("hh:mm A")
              }
              className="todo-sublabel-text"
            />
          </div>
        </div>

        {item?.scheduleType == "offTime" ? (
          <AautiText
            title="Offtime"
            size={"semi"}
            textStyle={{ color: "#3083EF" }}
          />
        ) : (
          <>
            <ClassJoinButton event={item} {...{ toggleOpenTodoDialog }} />
          </>
        )}
      </div>
    );
  };
  const userApproved = userDetails?.approvalStatus == "approved";
  return (
    <Dialog
      onClose={toggleOpenTodoDialog}
      aria-labelledby="customized-dialog-title"
      open={openTodoDialog}
      className="todo-dialog"
      maxWidth="sm"
    >
      <AautiText title={" To-Do List"} className={"todo-list-head1"} />
      <IconButton
        aria-label="close"
        onClick={toggleOpenTodoDialog}
        sx={(theme) => ({
          position: "absolute",
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <div style={{ width: "100%", minWidth: mobileDevices ? "95%" : 500 }}>
          {todoLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="todo-option-items-container">
                {UserRole !== "endUser" ? (
                  <>
                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={VerificationPng}
                          alt="time"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"Verification Status"}
                            className="todo-label-text"
                          />
                          {/* <AautiText
                      title={"Your verification is in pending"}
                      className="todo-sublabel-text"
                    /> */}
                        </div>
                      </div>
                      <AautiText
                        title={userApproved ? "Approved" : "Pending"}
                        textStyle={{
                          color: userApproved ? "green" : "#FFA551",
                        }}
                      />
                    </div>
                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={timeSvg}
                          alt="time"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"Set your Availability"}
                            className="todo-label-text"
                          />
                          <AautiText
                            onClick={() => {
                              toggleOpenTodoDialog();
                              navigate("/my-schedule");
                            }}
                            title={"6 AM - 10 PM (Default)"}
                            className="todo-sublabel-text"
                          />
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() => navigateToTodoOptions("/my-schedule")}
                      >
                        Set now
                      </button>
                    </div>

                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={createCourseSvg}
                          alt="course"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"Courses"}
                            className="todo-label-text"
                          />
                          <div className="todo-option-course-style">
                            <AautiText
                              title={`${
                                checkNull(coursesCount?.toString())
                                  ? 0
                                  : coursesCount
                              } ${pluralOrSingular(coursesCount, "Course")} 
                        `}
                              className="todo-sublabel-text"
                              onClick={() => {
                                navigateToTodoOptions("/my-courses");
                              }}
                            />
                            <AautiText
                              title={` ${
                                !checkNull(draftCoursesCount?.toString())
                                  ? `| ${draftCoursesCount} ${pluralOrSingular(
                                      draftCoursesCount,
                                      "Draft"
                                    )}`
                                  : ""
                              }`}
                              className="todo-sublabel-text"
                              onClick={() => {
                                toggleOpenTodoDialog();
                                navigate("/my-courses", {
                                  state: { drafts: true },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() => {
                          navigateToTodoOptions("course-creation");
                        }}
                      >
                        Create
                      </button>
                    </div>

                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={createCourseSvg}
                          alt="course"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"Gigs"}
                            className="todo-label-text"
                          />
                          <div className="todo-option-course-style">
                            <AautiText
                              title={`${
                                checkNull(gigsCount?.toString()) ? 0 : gigsCount
                              } ${pluralOrSingular(gigsCount, "Gig")} 
                        `}
                              className="todo-sublabel-text"
                              onClick={() => {
                                navigateToTodoOptions("view-gigs");
                              }}
                            />
                            <AautiText
                              title={`
                        ${
                          !checkNull(draftGigsCount?.toString())
                            ? `| ${draftGigsCount} ${pluralOrSingular(
                                draftGigsCount,
                                "Draft"
                              )}`
                            : ""
                        }`}
                              className="todo-sublabel-text"
                              onClick={() => {
                                toggleOpenTodoDialog();
                                navigate("/view-gigs", {
                                  state: { drafts: true },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() => {
                          toggleOpenTodoDialog();
                          navigate("/course-creation ", {
                            replace: true,
                            state: "Gig",
                          });
                          dispatch(editCourseObject(null));
                          dispatch(CloneCourseObj(null));
                          dispatch(editGigObject(null));
                        }}
                      >
                        Create
                      </button>
                    </div>

                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={createLessonSvg}
                          alt="lesson"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"Lesson Plan"}
                            className="todo-label-text"
                          />
                          <AautiText
                            title={`${
                              checkNull(myLessonPlansCount?.toString())
                                ? 0
                                : myLessonPlansCount
                            } ${pluralOrSingular(
                              myLessonPlansCount,
                              "Lesson Plan"
                            )}`}
                            className="todo-sublabel-text"
                            onClick={() => {
                              navigateToTodoOptions("myLesson-plans");
                            }}
                          />
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() =>
                          navigateToTodoOptions("/create-lesson-plan")
                        }
                      >
                        Create
                      </button>
                    </div>

                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={createAdhocSvg}
                          alt="adhoc"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"Adhoc Class"}
                            className="todo-label-text"
                          />
                          <AautiText
                            title={`${
                              checkNull(adhocClassesCount?.toString())
                                ? 0
                                : adhocClassesCount
                            } ${pluralOrSingular(
                              adhocClassesCount,
                              "Class",
                              true
                            )}`}
                            className="todo-sublabel-text"
                            onClick={() => {
                              navigateToTodoOptions("adhoc-events");
                            }}
                          />
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() => navigateToTodoOptions("/AdhocEvent")}
                      >
                        Create
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={createCourseSvg}
                          alt="time"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"My Learnings"}
                            className="todo-label-text"
                          />
                          <div className="todo-option-course-style">
                            <AautiText
                              title={`${
                                checkNull(coursesPurchased?.toString())
                                  ? 0
                                  : coursesPurchased
                              } ${pluralOrSingular(coursesPurchased, "Course")} 
                        `}
                              className="todo-sublabel-text"
                              onClick={() => {
                                navigateToTodoOptions("my-learnings");
                              }}
                            />
                            <AautiText
                              title={` ${
                                !checkNull(gigsPurchased?.toString())
                                  ? `| ${gigsPurchased} ${pluralOrSingular(
                                      gigsPurchased,
                                      "Gig"
                                    )}`
                                  : ""
                              }`}
                              className="todo-sublabel-text"
                              onClick={() => {
                                navigateToTodoOptions("view-gigs");
                              }}
                            />
                            <AautiText
                              title={` ${
                                !checkNull(adhocsPurchased?.toString())
                                  ? `| ${adhocsPurchased} ${pluralOrSingular(
                                      adhocsPurchased,
                                      "Adhoc"
                                    )}`
                                  : ""
                              }`}
                              className="todo-sublabel-text"
                              onClick={() => {
                                toggleOpenTodoDialog();
                                navigate("/my-learnings", {
                                  state: { adhoc: true },
                                });
                              }}
                            />
                          </div>
                          {/* <AautiText
                      title={"6 AM - 10 PM (Default)"}
                      className="todo-sublabel-text"
                    /> */}
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() => navigateToTodoOptions("/my-learnings")}
                      >
                        View
                      </button>
                    </div>
                    <div className="todo-option-item">
                      <div className="todo-option-item-first">
                        <img
                          src={myOrdersSvg}
                          alt="time"
                          className="todo-item-image"
                        />
                        <div className="todo-option-item-text-con">
                          <AautiText
                            title={"My Orders"}
                            className="todo-label-text"
                          />
                          <AautiText
                            title={`${
                              checkNull(ordersCount?.toString())
                                ? 0
                                : ordersCount
                            } ${pluralOrSingular(ordersCount, "Order")} 
                        `}
                            className="todo-sublabel-text"
                            onClick={() => navigateToTodoOptions("/orders")}
                          />
                          {/* <AautiText
                      title={"6 AM - 10 PM (Default)"}
                      className="todo-sublabel-text"
                    /> */}
                        </div>
                      </div>
                      <button
                        className="todo-btn"
                        onClick={() => navigateToTodoOptions("/orders")}
                      >
                        View
                      </button>
                    </div>
                  </>
                )}

                <div className="todo-option-item">
                  <div className="todo-option-item-first">
                    <img
                      src={profileSvg}
                      alt="time"
                      className="todo-item-image"
                    />
                    <div
                      className="todo-option-item-text-con"
                      style={{ maxWidth: mobileDevices && "150px" }}
                    >
                      <AautiText
                        title={"Complete your Profile"}
                        style={{
                          maxWidth: mobileDevices && "150px !important",
                        }}
                        className="todo-label-text"
                      />
                      <AautiText
                        onClick={() => {
                          toggleOpenTodoDialog();
                          navigate("/profile");
                        }}
                        title={`${profilePercentage || 0}% Completed`}
                        className="todo-sublabel-text"
                      />
                    </div>
                  </div>
                  <button
                    className="todo-btn"
                    onClick={() => navigateToTodoOptions("/profile")}
                  >
                    Finish
                  </button>
                </div>
              </div>
              {todayEvents?.length > 0 && (
                <div className="todo-courses-container">
                  <AautiText
                    title={"Classes to Join"}
                    className={"todo-list-head2"}
                  />
                  <div className="classes-join-section">
                    {todayEvents?.map((item, index) =>
                      renderEvents(item, index)
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TodoListDialog;
