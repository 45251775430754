import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import gigStrings from "../gig/gigStrings";
import noDataImages from "../../assets/images/no-image.svg";
import CauroselList from "./caurosel/CauroselList";
import AautiText from "./AautiText";

const SubcategoriesCard = ({
  each,
  index,
  isGig,
  gigId,
  courseId,
  subCategory,
  similarSubCat,
}) => {
  const [hoveredInd, setHoveredInd] = useState(null);
  const navigate = useNavigate();
  const { mobileDevices, ipodDevices, isAboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const smallDevices =
    mobileDevices || ipodDevices || isAboveLargeMobileDevices;
  return (
    <div
      key={index + "./"}
      className="category-box-gig-map"
      onMouseEnter={() => !smallDevices && setHoveredInd(index)}
      onMouseLeave={() => !smallDevices && setHoveredInd(-1)}
      onClick={() => {
        const newArray = {
          subCategory: each?.subCategoryName,
          category: similarSubCat[0].categoryName,
          courseId: courseId,
        };
        isGig
          ? navigate(`/${each?.subCategoryName}/gigs`, {
            state: {
              subCategory: each?.subCategoryName,
              gigId: gigId,
            },
          })
          : navigate(`/${each?.subCategoryName}/courses`, {
            state: newArray,
          });
      }}
    >
      <img src={each?.subCategoryImage || noDataImages} alt="" />

      <div className="subcategory-count-gig" style={{ padding: "4px 0px" }}>
        <AautiText
          title={each?.subCategoryName}
          size={"semi"}
          textAlign={"center"}
          weight={"bold"}
          className={"courseRating-text1"}
        />
      </div>
      {(hoveredInd === index || smallDevices) && (
        <AautiText
          title={
            isGig
              ? `(${each?.count == 1 ? "Gig" : "Gigs"} - ${each?.count})`
              : `(${each?.count == 1 ? "Course" : "Courses"} - ${each?.count})`
          }
          textAlign={"center"}
          size={"semi"}
          weight={"normal"}
          textStyle={{
            marginBottom: smallDevices && 5,
          }}
          className={"detailsComp-text1"}
        />
      )}
    </div>
  );
};

const MappedCategories = ({
  handleSimilarArrows2,
  catRef,
  subCatSettings,
  similarSubCat,
  gigId = "",
  courseId = "",
  subCategory = "",
  disableLeft = false,
  disableRight = false,
  isGig = true,
}) => {
  let list = [];
  let filteredList = [];

  similarSubCat?.forEach((item) => {
    let temp = item.subCategory?.filter((each) => each?.count > 0);
    list = [...list, ...temp];
  });

  similarSubCat?.forEach((item) => {
    let temp = item.subCategory?.filter((each) => {
      const adjustedCount =
        each?.subCategoryName == subCategory ? each?.count - 1 : each?.count;
      return adjustedCount > 0;
    });
    filteredList = [...filteredList, ...temp];
  });

  const list2 = subCategory ? list : list;

  const { mobileDevices, miniLapys, ipodDevices, lapy } = useSelector(
    (state) => state.responsive
  );

  return (
    <div className="categories-map-gig">
      {list2?.length <
        (mobileDevices ? 3 : ipodDevices ? 4 : miniLapys ? 6 : lapy ? 7 : 7) ? (
        <div className="detailsComp-div1">
          {list2.map((each, index) => {
            return (
              <SubcategoriesCard
                {...{
                  each,
                  index,
                  isGig,
                  gigId,
                  courseId,
                  subCategory,
                  similarSubCat,
                }}
              />
            );
          })}
        </div>
      ) : isEmpty(list2) ? (
        <div className="detailsComp-div2">
          <AautiText title={gigStrings?.notAvailable} />
        </div>
      ) : (
        <CauroselList
          arrowsWidth={"92%"}
          settings={subCatSettings}
          sliderRef={catRef}
          handleSliderDirec={handleSimilarArrows2}
          style={{ width: "100%" }}
          showButtons={!mobileDevices}
          disabledLeftButton={disableLeft}
          disabledRight={disableRight}
        >
          {list2?.map((each, index) => {
            return (
              <SubcategoriesCard
                {...{
                  each,
                  index,
                  isGig,
                  gigId,
                  courseId,
                  subCategory,
                  similarSubCat,
                }}
              />
            );
          })}
        </CauroselList>
      )}
    </div>
  );
};

export default MappedCategories;
