import React, { useCallback, useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import AautiTextInput from "../../globalComponents/AautiTextInput";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import HelperText from "./HelperText";
import {
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { getNextTimeSlot } from "../createCourse/Constants";
import NavButton from "./NavButton";
import AautiDialog from "../../globalComponents/AautiDialog";
import { isEmpty } from "lodash";
import WeeklySlotSelection from "./WeeklySlotSelection";
import CustomSlotSelection from "./CustomSlotSelection";
import { RxCross2 } from "react-icons/rx";
import { showToast } from "../../globalComponents/Toast";
import { getMembersDiscounts } from "../createCourse/Services";
import MembersConditions from "./MembersConditions";
import {
  addOneHourOrEndOfDay,
  PriceConversions,
  returnisPresentDay,
  sortClassType,
  timeFormatString,
} from "../../CommonFunctions";
import rightConflicts from "../../../assets/images/right-conflict.svg";
import { courseConstant } from "./courseConstant";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import PercentIcon from "@mui/icons-material/Percent";
import { useSelector } from "react-redux";
import { MdOutlineAccessTime, MdOutlineEdit } from "react-icons/md";
import { styled } from "@mui/material/styles";

import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion from "@mui/material/Accordion";
import SlotFormats from "./SlotFormats";
import CustomBatchDetails from "./CustomBatchDetails";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FiChevronRight } from "react-icons/fi";
import { show12hr } from "../../globalComponents/helpers.variables";
import { maxDate } from "../../globalComponents/helpers.variables";
import { handleSelect } from "../../calendar/container";
import AautiTimePicker from "../../globalComponents/timepickers/AautiTimePicker";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,

  borderRadius: "3px !important",
  "&& .css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root": {
    padding: "4px !important",
    borderRadius: "4px !important",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

const CoursePricing = ({
  CreateCourseObj,
  changeCreateObj,
  convertINRtoUSD,
  handleOpen,
  handleClose,
  returnRelatedPricing,
  editCourse,
  serviceProviderConflicts,
  setActiveField,
  miniFields,
  countryObject,
  validateObj,
  courseBatch,
  setCourseBatch,
  activeBatch,
  setActiveBatch,
  activeSlot,
  userPurchased,
  commonBatchObject,
}) => {
  const { convertPriceWithCurrency, onlyPriceConvert, onlyPriceWithCurrency } =
    PriceConversions();
  const nextTimeSlot = getNextTimeSlot();
  const { mobileDevices } = useSelector((state) => state.responsive);

  // const [courseBatch, setWeeklyObj] = useState({});
  let today = new Date();
  const moment = require("moment-timezone");
  const [editItem, setEditItem] = useState(null);
  const [editBatch, setEditBatch] = useState(false);
  const [activeBatchId, setActiveBatchId] = useState();
  const sortedTypes = sortClassType(CreateCourseObj?.courseType);
  const [activeBatchType, setActivBatchType] = useState(sortedTypes?.[0]);
  const [previousBatchObj, setPreviousBatchObj] = useState({});

  const [dialogOpen, setDialogOpen] = useState("");
  const [refreshCustom, setRefreshCustom] = useState(false);
  const [customCourseBatch, setCustomCourseBatch] = useState(courseBatch);
  const startOfDayUTC = new Date(
    Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      0,
      0,
      0,
      0
    )
  );

  const [dateRanges, setDateRanges] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const onChangetimeSlotObj = (val, slot) => {
    let dateFormat = moment(val?.$d)?.format("YYYY-MM-DDT00:00:00");
    let timeFromat = moment(val)?.format("YYYY-MM-DDTHH:mm:00");
    let endDateFormat = moment(val?.$d)?.format("YYYY-MM-DDT23:59:59");
    const newOnHour = moment(timeFromat)
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm:00");

    const isBefore = moment(timeFromat)?.isBefore(courseBatch?.endTime);
    if (slot === "batchName") {
      const capitalizedValue = val
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: capitalizedValue || "",
      }));
    } else if (slot === "startDate") {
      const formatTime = nextTimeSlot;
      const newEndTime = addOneHourOrEndOfDay(formatTime);
      const isPresentDay = returnisPresentDay(dateFormat);
      setCourseBatch((prev) => {
        const newBatch = {
          ...prev,
          [slot]: dateFormat,
          startTime: !isPresentDay ? prev?.startTime : formatTime,
          endTime: !isPresentDay ? prev.endTime : newEndTime,
        };
        // Conditionally update endDate only if dateFormat is before prev.endDate
        if (moment(courseBatch?.endDate).isBefore(moment(dateFormat))) {
          newBatch.endDate = dateFormat;
          newBatch.discountEndDate = endDateFormat;
        }

        return newBatch;
      });

      setCustomCourseBatch((prev) => ({
        ...prev,
        [slot]: dateFormat,
        startTime: nextTimeSlot,
        endTime: moment(nextTimeSlot)?.add(1, "hour")?.format(timeFormatString),
      }));
    } else if (slot === "endDate") {
      setCourseBatch((prev) => ({
        ...prev,
        endDate: dateFormat,
        discountEndDate: endDateFormat,
      }));
      setCustomCourseBatch((prev) => ({
        ...prev,
        endDate: dateFormat,
        discountEndDate: endDateFormat,
      }));
    } else if (slot === "startTime") {
      let calculatedEndTime = addOneHourOrEndOfDay(timeFromat);
      setCourseBatch({
        ...courseBatch,
        startTime: timeFromat,
        endTime: calculatedEndTime,
      });
    } else if (slot === "endTime") {
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: timeFromat,
      }));
    } else {
      setCourseBatch((prev) => ({
        ...prev,
        [slot]: val,
      }));
    }
  };
  const membersDiscount = courseBatch?.membersDiscount;
  const changeEditItem = (val, feild) => {
    const dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    const timeFromat = moment(val).format("YYYY-MM-DDTHH:mm:00");
    const val2 = addOneHourOrEndOfDay(timeFromat);
    let endDateFormat = moment(val)?.format("YYYY-MM-DDT23:59:59");
    let newItem = null;
    if (feild == "startDate") {
      const formatTime = nextTimeSlot;
      const newEndTime = addOneHourOrEndOfDay(formatTime);
      const isPresentDay = returnisPresentDay(dateFormat);
      newItem = {
        ...editItem.each,
        startDate: dateFormat,
        endDate: dateFormat,
        startTime: !isPresentDay ? editItem.each?.startTime : formatTime,
        endTime: !isPresentDay ? editItem.each.endTime : newEndTime,
      };
    } else if (feild == "startTime") {
      newItem = {
        ...editItem.each,
        startTime: timeFromat,
        endTime: val2,
      };
    } else if (feild == "endTime") {
      newItem = {
        ...editItem.each,
        endTime: timeFromat,
      };
    } else if (feild == "endDate") {
      newItem = {
        ...editItem.each,
        endDate: dateFormat,
      };
    } else if (feild == "batchName") {
      const capitalizedValue = val
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      newItem = capitalizedValue;
    } else {
      newItem = { ...editItem.each, [feild]: val };
    }
    setEditItem((prev) => ({
      ...prev,
      each: newItem,
    }));
  };

  const returnMinTime = (startTime) => {
    const minTime = dayjs()
      .set("hour", moment(startTime).hour())
      .add(1, "hours")
      .startOf("minute");
    return minTime;
  };
  const onChangeAddPrice = (val, feild) => {
    if (feild === "discountType") {
      setCourseBatch((prev) => ({
        ...prev,
        [feild]: val || "",
      }));
    } else if (feild === "allowWaitingList") {
      setCourseBatch((prev) => ({
        ...prev,
        [feild]: val,
      }));
    } else {
      if (activeBatch == 10 || activeBatch == 30) {
        setCourseBatch((prev) => ({
          ...prev,
          [feild]: val || "",
        }));
      } else {
        setCourseBatch((prev) => ({
          ...prev,
          [feild]: val || "",
        }));
      }
    }
  };

  const amount =
    courseBatch.localIndividualDiscountPrice ||
    courseBatch.localIndividualPrice;

  const returnCalAmount = (percentage) => {
    const discountAmount = (amount * percentage) / 100;
    const finalAmount = amount - discountAmount;
    return finalAmount;
  };

  useEffect(() => {
    if (courseBatch?.allowMembers) {
      functionHandleFinalPrice(membersDiscount);
    }
  }, [amount]);

  const functionHandleFinalPrice = (conditonsArray) => {
    let newArray = conditonsArray?.map((each) => {
      const localAmount = returnCalAmount(each?.percentage);
      return {
        ...each,
        finalPrice: convertINRtoUSD(localAmount),
        localFinalPrice: localAmount,
      };
    });
    onChangeAddPrice(newArray, "membersDiscount");
  };

  const handleMembersDiscountApi = () => {
    handleOpen();
    getMembersDiscounts(
      (response) => {
        if (response?.result !== null) {
          functionHandleFinalPrice(response?.result);
          setDialogOpen("membersDiscount");
        }
        handleClose();
      },
      (error) => {
        handleClose();
        console.log(error, "error-members discounts");
      }
    );
  };

  const onChangeDate = (val, slot) => {
    const val2 = slot === "startDate" ? "discountStartDate" : "discountEndDate";
    const dateFormat = moment(val?.$d).format("YYYY-MM-DDT00:00:00");
    const dateEndFormat = moment(val?.$d).format("YYYY-MM-DDT23:59:59");

    setCourseBatch((prev) => {
      const newBatch = {
        ...prev,
        [slot]: dateFormat,
      };
      if (
        slot === "discountStartDate" &&
        moment(dateEndFormat).isAfter(moment(courseBatch?.discountEndDate))
      ) {
        newBatch.discountEndDate = dateEndFormat;
      }
      if (moment(courseBatch?.endDate).isBefore(moment(dateEndFormat))) {
        newBatch.discountEndDate = dateEndFormat;
      }
      return newBatch;
    });
  };

  const recurringDays = courseBatch?.recurringDays || {};
  const WeekArray = Object.keys(recurringDays)?.filter(
    (day) => recurringDays[day]?.length > 0
  );

  const returnBatchType = () => {
    let calculatedEndTime = moment(courseBatch?.startTime).add(1, "hours");
    const minTime = moment(courseBatch.startTime)
      .add(1, "hour")
      .format(timeFormatString);
    if (calculatedEndTime.isAfter(courseBatch?.endDate)) {
      calculatedEndTime = moment(courseBatch?.startTime).add(29, "minutes");
    } else {
    }

    const isStartToday =
      moment(courseBatch?.startDate).format("YYYY-MM-DD") ===
      moment(new Date()).format("YYYY-MM-DD");

    switch (activeBatch) {
      case 10:
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            width={"100%"}
            alignContent={"center"}
            gap={1}
            mt={!mobileDevices && -1}
          >
            <Box id="start-time-pricing" width={"100%"}>
              <AautiTimePicker
                disabled={userPurchased}
                label={AautiStrings.StartTimeString}
                timerValue={courseBatch?.startTime}
                onChangeTimePicker={(newVal) => {
                  onChangetimeSlotObj(newVal, "startTime");
                }}
                minTime={
                  isStartToday
                    ? nextTimeSlot
                    : moment(startOfDayUTC).format("YYYY-MM-DDT00:00:00")
                }
              />
            </Box>
            <Box
              width={"100%"}
              // className="eachDateStart"
              id="end-time-pricing"
            >
              <AautiTimePicker
                label={AautiStrings.EndTimeString}
                minTime={
                  editItem ? returnMinTime(editItem?.each?.startTime) : minTime
                }
                maxTime={moment()?.endOf("day")}
                timerValue={courseBatch?.endTime}
                onChangeTimePicker={(newVal) => {
                  editItem
                    ? changeEditItem(newVal, "endTime")
                    : onChangetimeSlotObj(newVal, "endTime");
                }}
                disabled={
                  userPurchased ||
                  moment(courseBatch?.endTime)?.format("hh:mm A") ===
                    moment()?.endOf("day")?.format("hh:mm A")
                }
              />
            </Box>
          </Box>
        );
      case 20:
        return (
          <div
            style={{
              width: mobileDevices ? "100%" : "80%",
              marginTop: !mobileDevices && -8,
            }}
          >
            {isEmpty(WeekArray) ? (
              <button
                className="day-button"
                onClick={() => {
                  setDialogOpen("weekly");
                }}
              >
                <AautiText title={"Select Days"} />
              </button>
            ) : null}
          </div>
        );
      case 30:
        const selectedRange = `${moment(courseBatch?.startDate).format(
          "DD MMM YYYY"
        )}-${moment(courseBatch?.endDate).format("DD MMM YYYY")}`;

        const { startDate, endDate } = courseBatch;
        return (
          <div
            style={{
              width: "100%",
              marginTop: mobileDevices ? 0 : -8,
              cursor: "pointer",
            }}
          >
            {courseBatch?.customDates || courseBatch?.excludedDates ? (
              <>
                <AautiText
                  title={courseConstant?.selectedDates}
                  size={"small"}
                />
                <div
                  onClick={() => {
                    const obj = { startDate, endDate };
                    if (!userPurchased) {
                      handleSelection(obj, courseBatch, true);
                      setDialogOpen("custom");
                    } else {
                      showToast("info", "Users Purchased can't be edited");
                    }
                  }}
                  className="tags-container"
                  style={{ width: "100%", padding: "8px 10px", marginTop: 1 }}
                >
                  <AautiText title={selectedRange} />
                </div>
              </>
            ) : (
              <button
                className="day-button"
                onClick={() => {
                  const obj = { startDate, endDate };
                  handleSelection(obj, courseBatch);
                  setDialogOpen("custom");
                }}
              >
                <AautiText title={AautiStrings.SelectDatesString} />
              </button>
            )}
          </div>
        );
      default:
        break;
    }
  };

  const showBatchType = CreateCourseObj?.inPerson && CreateCourseObj?.virtual;

  const handleSaveBatch = () => {
    setPreviousBatchObj({});
    const localDiscountPrice = checkDiscount();
    let batchObje;
    const handleDeleteItemByKey = (obj, keys) => {
      const newObj = { ...obj };
      keys.forEach((key) => {
        if (key in newObj) {
          delete newObj[key];
        }
      });
      return newObj;
    };
    let batchName;
    if (
      activeBatchType === "Group" &&
      (activeBatch == 10 || activeBatch == 30)
    ) {
      batchName = "groupDaily";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "individualDiscountValue",
        "individualPrice",
      ]);
    } else if (activeBatchType === "Group" && activeBatch == 20) {
      batchName = "groupWeekly";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "individualDiscountValue",
        "individualPrice",
      ]);
    } else if (
      activeBatchType == "Individual" &&
      (activeBatch == 10 || activeBatch == 30)
    ) {
      batchName = "individualDaily";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "groupDiscountValue",
        "groupPrice",
      ]);
    } else if (activeBatchType == "Individual" && activeBatch == 20) {
      batchName = "individualWeekly";
      batchObje = handleDeleteItemByKey(courseBatch, [
        "groupDiscountValue",
        "groupPrice",
      ]);
    }
    if (activeBatchType == "Group") {
      batchObje.groupDiscountPrice = convertINRtoUSD(localDiscountPrice);
      batchObje.localGroupDiscountPrice = localDiscountPrice;
    } else if (activeBatchType == "Individual") {
      batchObje.individualDiscountPrice = convertINRtoUSD(localDiscountPrice);
      batchObje.localIndividualDiscountPrice = localDiscountPrice;
    }
    batchObje.timeZone = moment().utcOffset();
    if (!showBatchType) {
      batchObje.inPerson = CreateCourseObj.inPerson;
      batchObje.virtual = CreateCourseObj.virtual;
    }

    if (activeBatch == 10) {
      delete batchObje.recurringDays;
    } else if (activeBatch == 20) {
      delete batchObje.startTime;
      delete batchObje.endTime;
    }

    if (editBatch) {
      if (batchName != courseBatch?.batchLabel) {
        let newBatches = [...CreateCourseObj[batchName], batchObje];
        let newList = CreateCourseObj[batchName]?.filter(
          (each, index) => index !== activeBatchId
        );
        changeCreateObj(newBatches, batchName);
        changeCreateObj(newList, courseBatch?.batchLabel);
      } else {
        let intitialList = CreateCourseObj[courseBatch?.batchLabel]?.map(
          (each, index) => {
            if (index == activeBatchId) {
              let object = courseBatch;
              if (activeBatch == 10) {
                delete object.recurringDays;
              } else if (activeBatch == 20) {
                delete object.startTime;
                delete object.endTime;
              }
              return { ...object };
            } else {
              return { ...each };
            }
          }
        );
        changeCreateObj(
          intitialList,
          editBatch ? courseBatch?.batchLabel : batchName
        );
      }
    } else {
      let allBatches = [...CreateCourseObj[batchName], batchObje];
      changeCreateObj(
        allBatches,
        editBatch ? courseBatch?.batchLabel : batchName
      );
    }

    const batchObj = {
      ...commonBatchObject,
      startTime: nextTimeSlot,
      endTime: moment(nextTimeSlot)?.add(1, "hour")?.format(timeFormatString),
    };

    setCourseBatch(batchObj);
    setCustomCourseBatch(batchObj);
    setActiveBatch(10);
    setActivBatchType(CreateCourseObj?.courseType[0]);
    setEditBatch(false);
  };

  const returnBatchesArray = (type) => {
    const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
      CreateCourseObj;

    const individual = [
      {
        title: "individualDaily",
        arrayOfBatches: individualDaily,
      },
      {
        title: "individualWeekly",
        arrayOfBatches: individualWeekly,
      },
    ];
    const group = [
      {
        title: "groupWeekly",
        arrayOfBatches: groupWeekly,
      },
      {
        title: "groupDaily",
        arrayOfBatches: groupDaily,
      },
    ];
    return type == "Individual" ? individual : group;
  };

  const handleRemoveBatch = (type, batch, object, ind) => {
    if (isEmpty(object?.participants)) {
      const newlist = [...batch?.arrayOfBatches]?.filter(
        (each, index) => index != ind
      );
      changeCreateObj(newlist, batch?.title);
    } else {
      showToast("info", "Batch Purchased by users cannot be deleted");
    }
  };

  const returnBatches = (type) => {
    return (
      <div
        style={{
          width: "100%",
          marginTop: mobileDevices ? 10 : 0,
        }}
      >
        {returnBatchesArray(type).map((each, index) => {
          return (
            <div
              style={{
                width: "100%",
                display: isEmpty(each?.arrayOfBatches) ? "none" : "flex",
                flexDirection: mobileDevices ? "row" : "column",
                overflow: "scroll",
                gap: 4,
              }}
            >
              {each.arrayOfBatches?.map((batch, index) => (
                <Tooltip title={batch?.batchName} key={index}>
                  <div
                    key={index}
                    onClick={() => {
                      if (each?.title == "individualDaily") {
                        setActivBatchType("Individual");
                        if (
                          batch?.excludedDates?.length > 0 ||
                          !isEmpty(batch?.customDates)
                        ) {
                          setActiveBatch(30);
                        } else {
                          setActiveBatch(10);
                        }
                      } else if (each?.title == "individualWeekly") {
                        setActivBatchType("Individual");
                        if (batch?.recurringDays) {
                          setActiveBatch(20);
                        } else {
                          if (
                            batch?.excludedDates?.length > 0 ||
                            !isEmpty(batch?.customDates)
                          ) {
                            setActiveBatch(30);
                          } else {
                            setActiveBatch(10);
                          }
                        }
                      } else if (each?.title == "groupDaily") {
                        setActivBatchType("Group");
                        if (batch?.excludedDates?.length > 0) {
                          setActiveBatch(30);
                        } else {
                          setActiveBatch(10);
                        }
                      } else if (each?.title == "groupWeekly") {
                        setActivBatchType("Group");
                        if (batch?.recurringDays) {
                          setActiveBatch(20);
                        } else {
                          if (batch?.excludedDates?.length > 0) {
                            setActiveBatch(30);
                          } else {
                            setActiveBatch(10);
                          }
                        }
                      }
                      setCourseBatch({ ...batch, batchLabel: each.title });
                      setEditBatch(true);
                      setActiveBatchId(index);
                    }}
                    className="flex-container"
                    style={{
                      justifyContent: "space-between",
                      marginBottom: 6,
                      background: "#F2F2F6",
                      padding: 4,
                      borderRadius: 4,
                      flexWrap: mobileDevices && "nowrap",
                      width: mobileDevices ? 250 : "100%",
                      cursor: "pointer",
                    }}
                  >
                    <AautiText
                      textStyle={{
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        maxWidth: "80%",
                      }}
                      title={batch?.batchName}
                    />
                    <Tooltip title={AautiStrings.RemoveString}>
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!userPurchased) {
                            handleRemoveBatch(type, each, batch, index);
                          } else {
                            showToast(
                              "info",
                              "Users purchased cannot be removed"
                            );
                          }
                        }}
                      >
                        <RxCross2 size={15} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Tooltip>
              ))}
            </div>
          );
        })}
      </div>
    );
  };

  const convrtedPrice = onlyPriceConvert(5).toFixed();
  const minimumCurrency = onlyPriceWithCurrency(convrtedPrice);

  const addSlotValidations = () => {
    const presentTime = new Date();
    const ipDate = new Date(courseBatch?.startDate);
    const price = courseBatch?.individualPrice || courseBatch?.groupPrice;
    const localPrice =
      courseBatch?.localGroupPrice || courseBatch?.localIndividualPrice;
    const newErrors = {};
    if (!Number(price)) newErrors.price = "Price is required";
    if (courseBatch?.batchName?.length < 5)
      newErrors.username = "title is required";
    if (localPrice < convrtedPrice) {
      newErrors.lowPrice = "check minimum price";
    }
    if (showBatchType && !courseBatch.virtual && !courseBatch.inPerson) {
      newErrors.classType = "choose class type";
    }
    // if (presentTime > ipDate) {
    //   newErrors.startTime = "start time cannot be present time";
    // }
    const maxNoOfStudents = courseBatch?.maxNoOfStudents;

    if (activeBatchType == "Group" && (!maxNoOfStudents || maxNoOfStudents < 2))
      newErrors.maxNoOfStudents = "No of students should be more than 2";

    if (Object.keys(newErrors)?.length == 0) {
      return false;
    } else {
      return true;
    }
  };

  const checkDiscount = () => {
    const {
      discountType,
      localIndividualPrice,
      localIndividualDiscountValue,
      localGroupDiscountValue,
      localGroupPrice,
    } = courseBatch;
    const localMainPrice =
      activeBatchType == "Group" ? localGroupPrice : localIndividualPrice;
    const localDiscount =
      activeBatchType == "Group"
        ? localGroupDiscountValue
        : localIndividualDiscountValue;

    if (localDiscount) {
      if (discountType == "Percentage") {
        const discount = (localMainPrice * localDiscount) / 100;
        return localMainPrice - discount || 0;
      } else {
        return localMainPrice - localDiscount || 0;
      }
    } else {
      return 0;
    }
  };

  const onChangeRadio = (bool, field) => {
    if (activeBatch == 20) {
      setCourseBatch((prev) => ({
        ...prev,
        [field]: bool,
      }));
    } else {
      setCourseBatch((prev) => ({
        ...prev,
        [field]: bool,
      }));
    }
  };

  // const maxDate = moment(courseBatch?.startDate).add(6, "months").toDate();

  const hasWeekKey = Object.values(courseBatch?.recurringDays || {}).some(
    (arr) => arr?.length > 0
  );

  const returnBatchClassTypes = () => {
    if (mobileDevices) {
      return (
        <Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={1}
            width={"100%"}
            mt={1}
          >
            {sortClassType(CreateCourseObj?.courseType)?.map((each, index) => {
              const active = each == activeBatchType;
              return (
                <button
                  onClick={() => {
                    if (!active) {
                      if (CreateCourseObj?.courseType?.length == 2) {
                        if (
                          !previousBatchObj?.hasOwnProperty(activeBatchType)
                        ) {
                          setPreviousBatchObj((prev) => ({
                            ...prev,
                            [activeBatchType]: courseBatch,
                          }));
                        }
                        setActivBatchType(each);
                        if (previousBatchObj?.[each]) {
                          setCourseBatch(previousBatchObj?.[each]);
                        } else {
                          setCourseBatch({
                            ...commonBatchObject,
                            startTime: nextTimeSlot,
                            endTime: moment(nextTimeSlot)
                              ?.add(1, "hour")
                              ?.format(timeFormatString),
                          });
                        }
                        setEditBatch(false);
                      } else {
                        setActivBatchType(each);
                        setCourseBatch({
                          ...commonBatchObject,

                          startTime: nextTimeSlot,
                          endTime: moment(nextTimeSlot)
                            ?.add(1, "hour")
                            ?.format(timeFormatString),
                        });
                        setEditBatch(false);
                      }
                    }
                  }}
                  className={`level-item course-types center-content ${
                    active ? "active-class" : ""
                  }`}
                >
                  <AautiText title={each} size="normal" textStyle={{}} />
                </button>
              );
            })}
          </Box>
          {returnBatches(activeBatchType)}
        </Box>
      );
    }
    return (
      <button
        className={`level-item course-types center-content active-class`}
        style={{ display: !activeBatchType && "none" }}
      >
        <AautiText title={activeBatchType} size="normal" textStyle={{}} />
      </button>
    );
  };

  const returnActiveBatchContent = () => {
    if (activeBatch == 20 && !isEmpty(WeekArray)) {
      return (
        <div
          style={{
            borderRadius: 4,
            margin: "5px 0px",
          }}
        >
          <AautiText title={courseConstant?.selectedDays} />
          <div
            style={{
              width: "100%",
              borderRadius: 4,
              padding: 10,
              border: "1px solid lightgrey",
              position: "relative",
            }}
          >
            <SlotFormats timeSlots={courseBatch?.recurringDays} />
            <IconButton
              disabled={userPurchased}
              sx={{ position: "absolute", right: 0, top: 5 }}
              onClick={() => {
                setDialogOpen("weekly");
              }}
            >
              <MdOutlineEdit />
            </IconButton>
          </div>
        </div>
      );
    } else if (activeBatch == 30) {
      return (
        <Box
          sx={{
            border: "1px solid lightgrey",
            p: 0.5,
            borderRadius: "4px",
            mt: 1,
            mb: 1,
            position: "relative",
          }}
        >
          <CustomBatchDetails {...{ courseBatch }} />
          <IconButton
            disabled={userPurchased}
            sx={{ position: "absolute", right: 0, top: 5 }}
            onClick={() => {
              const obj = {
                startDate: courseBatch?.startDate,
                endDate: courseBatch.endDate,
              };
              if (!userPurchased) {
                handleSelection(obj, courseBatch, true);
                setDialogOpen("custom");
              } else {
                showToast("info", "Users Purchased can't be edited");
              }
            }}
          >
            <MdOutlineEdit />
          </IconButton>
        </Box>
      );
    }
  };

  const disableConflict =
    activeBatch == 10
      ? false
      : activeBatch == 20
      ? isEmpty(WeekArray)
        ? true
        : false
      : activeBatch == 30
      ? courseBatch?.customDates
        ? false
        : true
      : false;

  const returnDisable = () => {
    const isGroup = activeBatchType === "Group";
    const price = isGroup
      ? courseBatch?.localGroupPrice
      : courseBatch?.localIndividualPrice;
    if (price && price >= convrtedPrice) {
      return false;
    } else {
      return true;
    }
  };

  const handleChangeCustomSlot = (val, slot) => {
    let timeFromat = moment(val?.$d)?.format("YYYY-MM-DDTHH:mm:00");
    const newOnHour = moment(timeFromat)
      .add(1, "hour")
      .format("YYYY-MM-DDTHH:mm:00");
    if (slot === "startTime") {
      setCustomCourseBatch((prev) => ({
        ...prev,
        [slot]: timeFromat,
        endTime: newOnHour,
      }));
    } else if (slot === "endTime") {
      setCustomCourseBatch((prev) => ({
        ...prev,
        [slot]: timeFromat,
      }));
    }
  };

  const returnRangeDates = (startDateStr, endDateStr) => {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);
    const dates = {};
    let currentDate = startDate;
    while (currentDate <= endDate) {
      const date = moment(currentDate).format("YYYY-MM-DD");
      dates[date] = {};
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const handleSelection = (obj, batch, edit) => {
    const { startDate, endDate } = obj;
    const { excludedDates } = batch;
    const startDateFormat = moment(startDate).format("YYYY-MM-DDT00:00:00");
    const endDateFormat = moment(endDate).format("YYYY-MM-DDT00:00:00");
    setState([
      {
        startDate: startDate,
        endDate: endDate,
        key: "selection",
      },
    ]);
    const arrayOfDates = returnRangeDates(startDate, endDate);

    excludedDates?.forEach((element) => {
      const newDate = moment(element).format("YYYY-MM-DD");
      delete arrayOfDates[newDate];
    });

    setCustomCourseBatch((prev) => ({
      ...prev,
      ...courseBatch,
      customDates: edit ? courseBatch?.customDates : {},
      startDate: startDateFormat,
      endDate: endDateFormat,
      discountStartDate: startDateFormat,
      discountEndDate: endDateFormat,
      excludedDates: edit ? excludedDates : [],
    }));

    setDateRanges(arrayOfDates);
  };

  const handleSaveCustomSlot = (batch) => {
    setDialogOpen("");
    setCourseBatch(batch);
    setCustomCourseBatch(activeSlot);
  };

  const handleSaveConditions = (conditions) => {
    onChangeAddPrice(conditions, "membersDiscount");
    setDialogOpen("");
  };

  return (
    <div className={`tab ${miniFields && "disable-field"}`}>
      <IconButton
        sx={{
          position: "absolute",
          right: 2,
          top: 2,
        }}
        onClick={() => {
          setActiveField("");
        }}
      >
        <IoIosCloseCircleOutline />
      </IconButton>
      <AautiText
        size={"medium"}
        weight={"bold"}
        title={courseConstant?.coursePricing}
        textStyle={{ marginBottom: "15px" }}
      />
      <div
        className="flex-container"
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <div
          style={{
            width: "20%",
            display: mobileDevices && "none",
          }}
        >
          {sortClassType(CreateCourseObj?.courseType)?.map((each, index) => {
            const active = activeBatchType === each;
            return (
              <div key={index} style={{ width: "100%" }}>
                <button
                  onClick={() => {
                    if (!active) {
                      if (CreateCourseObj?.courseType?.length == 2) {
                        if (
                          !previousBatchObj?.hasOwnProperty(activeBatchType)
                        ) {
                          setPreviousBatchObj((prev) => ({
                            ...prev,
                            [activeBatchType]: courseBatch,
                            ...(each === "Group" && { maxNoOfStudents: 2 }),
                          }));
                        }
                        setActivBatchType(each);
                        if (previousBatchObj?.[each]) {
                          setCourseBatch({
                            ...previousBatchObj?.[each],
                            ...(each === "Group" && { maxNoOfStudents: 2 }),
                          });
                        } else {
                          setCourseBatch({
                            ...commonBatchObject,
                            startTime: nextTimeSlot,

                            endTime: moment(nextTimeSlot)
                              ?.add(1, "hour")
                              ?.format(timeFormatString),
                            ...(each === "Group" && { maxNoOfStudents: 2 }),
                          });
                        }
                        setEditBatch(false);
                      } else {
                        setActivBatchType(each);
                        setCourseBatch({
                          ...commonBatchObject,
                          startTime: nextTimeSlot,
                          endTime: moment(nextTimeSlot)
                            ?.add(1, "hour")
                            ?.format(timeFormatString),
                          ...(each === "Group" && { maxNoOfStudents: 2 }),
                        });
                        setEditBatch(false);
                      }
                    }
                  }}
                  key={index}
                  style={{ width: "100%", marginBottom: 8 }}
                  className={`level-item course-types center-content ${
                    active ? "active-class" : ""
                  }`}
                >
                  <AautiText title={each} size="normal" textStyle={{}} />
                </button>
                <div
                  style={{
                    padding: 4,
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {returnBatches(each)}
                  <button
                    className="Add-btn"
                    onClick={() => {
                      setActivBatchType(each);
                      if (each == "Group") {
                        setCourseBatch({
                          ...commonBatchObject,
                          maxNoOfStudents: 2,
                          startTime: nextTimeSlot,
                          endTime: moment(nextTimeSlot)
                            ?.add(1, "hour")
                            ?.format(timeFormatString),
                        });
                      } else {
                        setCourseBatch({
                          ...commonBatchObject,
                          startTime: nextTimeSlot,
                          endTime: moment(nextTimeSlot)
                            ?.add(1, "hour")
                            ?.format(timeFormatString),
                        });
                      }
                      setEditBatch(false);
                      setActiveBatch(10);
                    }}
                  >
                    <AautiText
                      size={"semi"}
                      title={AautiStrings.AddBatchString}
                    />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        {!mobileDevices && <Divider orientation="vertical" flexItem />}

        <div style={{ width: mobileDevices ? "100%" : "75%" }}>
          <div style={{ marginBottom: 5 }}>
            <AautiText
              title={AautiStrings?.step1BatchString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            {returnBatchClassTypes()}
          </div>
          <div className="level-main" style={{ marginBottom: 5 }}>
            <AautiText
              title={AautiStrings?.step2DetailsString}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  gap: 10,
                  marginBottom: 15,
                }}
              >
                <div style={{ width: "100%" }} id="start-date-pricing">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label={AautiStrings.StartDateString}
                        sx={{
                          width: "100%",
                          minWidth: "100% !important",
                          "&.css-wttlwe": {
                            minWidth: "100% !important",
                          },
                        }}
                        disabled={userPurchased}
                        value={
                          // editItem
                          //   ? editItem?.each?.startDate
                          //     ? dayjs(editItem?.each?.startDate)
                          //     : null
                          //   : courseBatch?.startDate
                          //   ? dayjs(courseBatch?.startDate)
                          //   : null
                          dayjs(courseBatch?.startDate)
                        }
                        onChange={(e) => {
                          if (editItem) {
                            changeEditItem(e, "startDate");
                          } else {
                            onChangetimeSlotObj(e, "startDate");
                          }
                        }}
                        defaultValue={dayjs(today)}
                        format="DD/MM/YYYY"
                        minDate={dayjs(today)}
                        maxDate={dayjs(maxDate)}
                        slotProps={{ field: { readOnly: true } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div style={{ width: "100%" }} id="end-date-pricing">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label={AautiStrings.EndDateString}
                        disabled={userPurchased}
                        sx={{
                          minWidth: "100% !important",
                          width: "100%",
                          "&.css-wttlwe": {
                            minWidth: "100% !important",
                          },
                        }}
                        value={
                          editItem
                            ? editItem?.each?.endDate
                              ? dayjs(editItem?.each?.endDate)
                              : null
                            : courseBatch?.endDate
                            ? dayjs(courseBatch?.endDate)
                            : null
                        }
                        onChange={(newDate) => {
                          editItem
                            ? changeEditItem(newDate, "endDate")
                            : onChangetimeSlotObj(newDate, "endDate");
                        }}
                        defaultValue={dayjs(today)}
                        format="DD/MM/YYYY"
                        minDate={dayjs(courseBatch?.startDate)}
                        maxDate={dayjs(maxDate)}
                        slotProps={{ field: { readOnly: true } }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div style={{ width: "100%", marginBottom: 5 }}>
                <AautiTextInput
                  disable={userPurchased}
                  text={AautiStrings.BatchTitleString}
                  className="capitalize-batch-title"
                  onChange={(e) => {
                    editItem
                      ? changeEditItem(e.target.value, "batchName")
                      : onChangetimeSlotObj(e.target.value, "batchName");
                  }}
                  important
                  maxLength={50}
                  value={
                    editItem
                      ? editItem?.each?.batchName
                      : courseBatch?.batchName
                  }
                  style={{ width: "100%" }}
                />
                <HelperText
                  error={
                    (editItem
                      ? editItem?.each?.batchName?.length
                      : courseBatch?.batchName?.length) < 5
                  }
                  title={`${
                    editItem
                      ? editItem?.each?.batchName?.length
                      : courseBatch?.batchName?.length || 0
                  }/50 (minimum 5 characters)`}
                />
              </div>
            </div>
          </div>

          <div className="level-main" style={{ marginBottom: 10 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <AautiText
                title={""}
                size={"normal"}
                // textStyle={{ marginBottom: 4 }}
              />
              <button
                disabled={disableConflict}
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: disableConflict ? "none" : "pointer",
                  border: "none",
                  background: "none",
                  color: disableConflict ? "grey" : "#3083EF",
                }}
                onClick={() => {
                  let type =
                    activeBatch == 10
                      ? "Daily"
                      : activeBatch == 20
                      ? "Weekly"
                      : "Custom";
                  if (activeBatch == 10) {
                    serviceProviderConflicts(type, {
                      ...courseBatch,
                      dailyTimeSlots: [
                        {
                          startTime: moment(courseBatch?.startTime)
                            .local()
                            .format(),
                          endTime: moment(courseBatch?.endTime)
                            .local()
                            .format(),
                        },
                      ],
                    });
                  } else if (activeBatch == 20) {
                    let weekSlots = [];
                    Object.keys(courseBatch?.recurringDays)?.map((each) => {
                      weekSlots?.push({
                        day: each,
                        slots: courseBatch?.recurringDays[each],
                      });
                    });

                    serviceProviderConflicts(type, {
                      ...courseBatch,
                      weekSlots: weekSlots,
                    });
                  } else if (activeBatch == 30) {
                    serviceProviderConflicts(type, {
                      ...courseBatch,
                      customDates: courseBatch?.customDates,
                    });
                  }
                }}
              >
                <AautiText
                  title={courseConstant?.conflicts}
                  //   required={true}
                  //   weight={"bold"}
                  size={"normal"}
                  textStyle={{}}
                />

                <FiChevronRight size={22} />
                {/* <img
                  src={rightConflicts}
                  alt="right-conflict"
                  style={{ width: 20, height: 12 }}
                /> */}
              </button>
            </div>
            <div className="flex-container" style={{ marginBottom: 10 }}>
              <FormControl
                disabled={userPurchased}
                sx={{
                  minWidth: mobileDevices ? "100%" : "20%",
                  mt: mobileDevices ? 1 : 0,
                }}
                size={mobileDevices ? "small" : "medium"}
              >
                <InputLabel id="demo-simple-select-label">
                  Batch Type
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  autoWidth
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-label"
                  value={activeBatch}
                  label={AautiStrings.BatchTypeString}
                  onChange={(event) => {
                    setActiveBatch(event.target.value);
                    if (event.target.value == 20) {
                      if (
                        moment(courseBatch?.endDate).diff(
                          moment(courseBatch?.startDate),
                          "days"
                        ) < 6
                      ) {
                        const oneWeek = moment(courseBatch?.startDate)
                          .add(6, "days")
                          .format("YYYY-MM-DDT00:00:00");
                        showToast(
                          "info",
                          "End date has been changed based on Start Date"
                        );
                        setCourseBatch({
                          ...courseBatch,
                          endDate: oneWeek,
                          recurringDays: {
                            Sunday: [],
                            Monday: [],
                            Tuesday: [],
                            Wednesday: [],
                            Thursday: [],
                            Friday: [],
                            Saturday: [],
                          },
                          discountEndDate: oneWeek,
                        });
                      } else {
                        setCourseBatch({
                          ...courseBatch,
                          recurringDays: {
                            Sunday: [],
                            Monday: [],
                            Tuesday: [],
                            Wednesday: [],
                            Thursday: [],
                            Friday: [],
                            Saturday: [],
                          },
                        });
                      }
                    }
                  }}
                >
                  <MenuItem
                    value={10}
                    // disabled={activeBatch != 10 && editBatch}
                  >
                    Daily
                  </MenuItem>
                  <MenuItem
                    value={20}
                    // disabled={activeBatch != 20 && editBatch}
                  >
                    Weekly
                  </MenuItem>
                  <MenuItem
                    value={30}
                    // disabled={activeBatch != 30 && editBatch}
                  >
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
              {returnBatchType()}
            </div>
            {returnActiveBatchContent()}
          </div>

          <AautiText
            title={AautiStrings.ClassTypeString}
            required
            textStyle={{ display: !showBatchType && "none" }}
          />
          <div
            className="level-main"
            style={{
              display: !showBatchType ? "none" : "flex",
              flexDirection: "row",
              gap: 6,
              alignItems: "center",
              // marginTop: 5,
              marginBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 4,
                alignItems: "center",
              }}
            >
              <input
                disabled={userPurchased}
                checked={courseBatch?.virtual}
                onChange={(e) => {
                  const check = e.target.checked;
                  onChangeRadio(check, "virtual");
                  onChangeRadio(!check, "inPerson");
                }}
                type="radio"
                id="virtal-radio"
                style={{ height: 16, width: 16, cursor: "pointer" }}
              />
              <label htmlFor="virtal-radio">
                <AautiText title={AautiStrings.VirtualString} />
              </label>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
              }}
            >
              <input
                disabled={userPurchased}
                type="radio"
                id="inperson-radio"
                checked={courseBatch?.inPerson}
                onChange={(e) => {
                  const check = e.target.checked;
                  onChangeRadio(check, "inPerson");
                  onChangeRadio(!check, "virtual");
                }}
                style={{ height: 16, width: 16, cursor: "pointer", margin: 0 }}
              />
              <label htmlFor="inperson-radio">
                <AautiText title={AautiStrings.InPersonString} />
              </label>
            </div>
          </div>

          <div
            className="level-main"
            style={{
              display: activeBatchType != "Group" && "none",
              marginBottom: 10,
            }}
          >
            <AautiTextInput
              disable={userPurchased}
              maxLength={3}
              style={{ width: "100%" }}
              text={AautiStrings.NoOfStudentsString}
              size="small"
              important
              value={
                (courseBatch?.maxNoOfStudents !== undefined &&
                  courseBatch?.maxNoOfStudents) ||
                ""
              }
              onChange={(e) => {
                const number = Number(e.target.value) || 0;
                onChangeAddPrice(number, "maxNoOfStudents");
              }}
            />
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                marginBottom: 10,
              }}
            >
              <input
                disabled={userPurchased}
                checked={courseBatch?.allowWaitingList || false}
                type="checkbox"
                onChange={(e) => {
                  onChangeAddPrice(e.target.checked, "allowWaitingList");
                }}
                id="waiting-list-check"
                style={{ height: "20px", width: "20px" }}
              />
              <label htmlFor="waiting-list-check">Allow Waiting List</label>
            </div>
          </div>
          <div>
            <AautiText
              title={courseConstant?.step3Pricing}
              required={true}
              weight={"bold"}
              size={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <div>
              <AautiTextInput
                disable={userPurchased}
                important
                key={"number"}
                size="small"
                style={{ width: "100%", marginTop: 0.5 }}
                maxLength={6}
                value={
                  activeBatchType === "Group"
                    ? courseBatch?.localGroupPrice || ""
                    : courseBatch?.localIndividualPrice || ""
                }
                text={
                  activeBatchType === "Group"
                    ? "Group Price"
                    : "Individual Price"
                }
                onChange={(e) => {
                  const number = Number(e.target.value);
                  if (!isNaN(number)) {
                    onChangeAddPrice(
                      number,
                      activeBatchType === "Group"
                        ? "localGroupPrice"
                        : "localIndividualPrice"
                    );
                    onChangeAddPrice(
                      convertINRtoUSD(number),
                      activeBatchType === "Group"
                        ? "groupPrice"
                        : "individualPrice"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "groupDiscountPrice"
                        : "individualDiscountPrice"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "groupDiscountValue"
                        : "individualDiscountValue"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "localGroupDiscountPrice"
                        : "localIndividualDiscountPrice"
                    );
                    onChangeAddPrice(
                      "",
                      activeBatchType === "Group"
                        ? "localGroupDiscountValue"
                        : "localIndividualDiscountValue"
                    );
                  }
                }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <HelperText
                  error={returnDisable()}
                  title={`Minimum price should be ${minimumCurrency}`}
                  textStyle={{ fontFamily: "sans-serif" }}
                />
                <AautiText
                  title={`ASP - ${onlyPriceWithCurrency(
                    onlyPriceConvert(
                      returnRelatedPricing(
                        activeBatchType === "Group"
                          ? "groupPrice"
                          : "individualPrice"
                      )
                      // activeBatchType === "Group"
                      //   ? returnRelatedPricing(
                      //       activeBatchType === "Group"
                      //         ? "groupPrice"
                      //         : "individualPrice"
                      //     ).groupPrice
                      //   : returnRelatedPricing(
                      //       activeBatchType === "Group"
                      //         ? "groupPrice"
                      //         : "individualPrice"
                      //     ).individualPrice
                    ).toFixed()
                  )}`}
                  size={"small"}
                  fontFamily={"sans-serif"}
                />
              </div>

              <div
                className="level-main"
                style={{
                  display: activeBatchType === "Group" ? "none" : "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  marginBottom: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <input
                    checked={courseBatch?.allowMembers || false}
                    disabled={
                      !courseBatch?.localIndividualPrice ||
                      userPurchased ||
                      courseBatch?.localIndividualPrice < convrtedPrice
                    }
                    type="checkbox"
                    onChange={(e) => {
                      const checked = e.target.checked;
                      if (checked) {
                        onChangeAddPrice(e.target.checked, "allowMembers");
                        handleMembersDiscountApi();
                      } else {
                        onChangeAddPrice(e.target.checked, "allowMembers");
                        onChangeAddPrice([], "membersDiscount");
                      }
                    }}
                    id="allow-members"
                    style={{ height: "25px", width: "25px" }}
                  />
                  <label htmlFor="allow-members" style={{ cursor: "pointer" }}>
                    <AautiText title={courseConstant?.AddMoreFamilyMembers} />
                    <AautiText
                      title={
                        courseConstant?.Getadiscountforeveryadditionalmember
                      }
                      size={"small"}
                    />
                  </label>
                </div>
                <button
                  onClick={() => {
                    setDialogOpen("membersDiscount");
                  }}
                  style={{
                    background: "none",
                    display: !courseBatch?.allowMembers && "none",
                    border: "none",
                    textDecoration: "underline",
                    cursor: "pointer",
                    color: "#3083EF",
                  }}
                >
                  <AautiText title={"View"} />
                </button>
              </div>
              <div className="level-main" style={{ marginBottom: 10 }}>
                <AautiText
                  title={courseConstant?.discountType}
                  //   required={true}
                  //   weight={"bold"}
                  size={"normal"}
                  textStyle={{}}
                />
                <div className="flex-container">
                  <div style={{ width: mobileDevices ? "100%" : "100%" }}>
                    <AautiTextInput
                      labelStyle={{ zIndex: 201 }}
                      disable={returnDisable() || userPurchased}
                      size="small"
                      maxLength={
                        courseBatch?.discountType == "Percentage"
                          ? 2
                          : activeBatchType === "Group"
                          ? courseBatch?.groupPrice?.length
                          : courseBatch?.individualPrice?.length
                      }
                      style={{ width: "100%" }}
                      text={
                        activeBatchType == "Group"
                          ? "Group Discount Percentage"
                          : "Individual Discount Percentage"
                      }
                      value={
                        activeBatchType === "Group"
                          ? courseBatch?.localGroupDiscountValue || ""
                          : courseBatch?.localIndividualDiscountValue || ""
                      }
                      onChange={(e) => {
                        const number = Number(e.target.value) || 0;
                        if (courseBatch?.discountType === "Amount") {
                          if (
                            activeBatchType === "Group"
                              ? courseBatch?.localGroupPrice - number >=
                                Number(convrtedPrice)
                              : courseBatch?.localIndividualPrice - number >=
                                Number(convrtedPrice)
                          ) {
                            onChangeAddPrice(
                              number,
                              activeBatchType === "Group"
                                ? "localGroupDiscountValue"
                                : "localIndividualDiscountValue"
                            );
                            onChangeAddPrice(
                              parseFloat(convertINRtoUSD(number)) || "",
                              activeBatchType === "Group"
                                ? "groupDiscountValue"
                                : "individualDiscountValue"
                            );
                          } else {
                            showToast(
                              "info",
                              "Discounted price is less than minimum price"
                            );
                          }
                        } else if (courseBatch?.discountType === "Percentage") {
                          const localMainPrice =
                            activeBatchType == "Group"
                              ? courseBatch?.localGroupPrice
                              : courseBatch?.localIndividualPrice;
                          const discountedPrice =
                            (localMainPrice * number) / 100;
                          const actPriceWithDic =
                            localMainPrice - discountedPrice;
                          if (actPriceWithDic >= convrtedPrice) {
                            onChangeAddPrice(
                              number,
                              activeBatchType === "Group"
                                ? "groupDiscountValue"
                                : "individualDiscountValue"
                            );
                            onChangeAddPrice(
                              number,
                              activeBatchType === "Group"
                                ? "localGroupDiscountValue"
                                : "localIndividualDiscountValue"
                            );
                          } else {
                            showToast(
                              "info",
                              "Discount price can't be more than minimum price"
                            );
                          }
                        }
                      }}
                      endAdornment={
                        courseBatch?.discountType === "Percentage" ? (
                          <InputAdornment position="start">
                            {courseBatch?.discountType === "Percentage" ? (
                              <PercentIcon fontSize="18px" />
                            ) : (
                              countryObject?.countryCurrencySymbol || ""
                            )}
                          </InputAdornment>
                        ) : null
                      }
                      startAdornment={
                        courseBatch?.discountType === "Amount" ? (
                          <InputAdornment position="start">
                            {courseBatch?.discountType === "Percentage" ? (
                              <PercentIcon fontSize="18px" />
                            ) : (
                              countryObject?.countryCurrencySymbol || ""
                            )}
                          </InputAdornment>
                        ) : null
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="level-main" style={{ marginBottom: 10 }}>
                <AautiText
                  title={courseConstant?.selectDiscountValidDates}
                  //   required={true}
                  //   weight={"bold"}
                  size={"normal"}
                  textStyle={{}}
                />

                <div
                  className="flex-container"
                  style={{
                    gap: 5,
                    display: "flex",
                    flexDirection: mobileDevices && "row",
                  }}
                >
                  <div style={{ width: mobileDevices ? "49%" : "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem label="Start Date">
                          <DatePicker
                            sx={{
                              "&.css-wttlwe": {
                                minWidth: "100% !important",
                              },
                            }}
                            disabled={
                              (activeBatchType === "Group"
                                ? !courseBatch?.groupDiscountValue
                                : !courseBatch?.individualDiscountValue) ||
                              userPurchased
                            }
                            value={
                              courseBatch !== null &&
                              dayjs(courseBatch?.discountStartDate)
                            }
                            onChange={(e) => {
                              onChangeDate(e, "discountStartDate");
                            }}
                            defaultValue={
                              courseBatch !== null
                                ? dayjs(courseBatch?.discountStartDate)
                                : dayjs(today)
                            }
                            format="DD/MM/YYYY"
                            minDate={dayjs(today)}
                            // maxDate={dayjs(maxDate)}
                            maxDate={dayjs(courseBatch.endDate)}
                            slotProps={{ field: { readOnly: true } }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                  <div style={{ width: mobileDevices ? "49%" : "100%" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DemoItem label={AautiStrings.EndDateString}>
                          <DatePicker
                            sx={{
                              "&.css-wttlwe": {
                                minWidth: "100% !important",
                              },
                            }}
                            disabled={
                              (activeBatchType === "Group"
                                ? !courseBatch?.groupDiscountValue
                                : !courseBatch?.individualDiscountValue) ||
                              userPurchased
                            }
                            value={
                              courseBatch !== null &&
                              dayjs(courseBatch?.discountEndDate)
                            }
                            onChange={(e) => {
                              onChangeDate(e, "discountEndDate");
                            }}
                            format="DD/MM/YYYY"
                            defaultValue={
                              courseBatch !== null
                                ? dayjs(courseBatch?.startDate)
                                : dayjs(today)
                            }
                            minDate={
                              courseBatch !== null
                                ? dayjs(courseBatch?.discountStartDate)
                                : dayjs(today)
                            }
                            maxDate={dayjs(courseBatch.endDate)}
                            slotProps={{ field: { readOnly: true } }}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-container buttons-cont">
            <NavButton
              disabled={addSlotValidations() || userPurchased}
              text={editBatch ? "Update batch" : "Save Batch"}
              handleClick={() => {
                const inTime = moment(courseBatch?.startTime);
                const now = moment();
                const hasPassed =
                  inTime.isBefore(now) &&
                  inTime.isSame(courseBatch?.startDate, "day");
                const startDate = moment(courseBatch?.startDate, "YYYY-MM-DD");
                const endDate = moment(courseBatch?.endDate, "YYYY-MM-DD");
                const isValidStartDate = startDate.isSameOrAfter(now, "day");
                const isValidEndDate = endDate.isSameOrAfter(now, "day");
                const validDates = isValidStartDate && isValidEndDate;

                if (hasPassed && activeBatch == 10) {
                  showToast("info", "Please check start time");
                } else if (!editBatch && !editCourse && !validDates) {
                  showToast("info", "Please check batch start & end dates");
                } else if (activeBatch == 20 && !hasWeekKey) {
                  showToast("info", "Please select days");
                } else {
                  const errObj = validateObj(CreateCourseObj);
                  if (!isEmpty(errObj?.errors)) {
                    setActiveField("Title");
                  } else {
                    handleSaveBatch();
                  }
                }
              }}
            />
          </div>
        </div>
      </div>

      <AautiDialog
        overflow="inherit"
        titlePosition={"left"}
        dialogTitle={
          dialogOpen == "membersDiscount"
            ? "Members Discount"
            : "Select Day & Time"
        }
        open={!isEmpty(dialogOpen)}
        onClose={() => {
          if (dialogOpen == "custom") {
            setCustomCourseBatch((prev) => ({
              ...prev,
              customDates: {},
              excludedDates: [],
            }));
          } else if (dialogOpen == "membersDiscount") {
            onChangeAddPrice([], "membersDiscount");
            onChangeAddPrice(false, "allowMembers");
          }
          setDialogOpen("");
        }}
        sxStyles={{
          padding: "20px 20px 20px 20px",
          "&.MuiDialog-paper": {
            overflow: "inherit !important",
          },
          "&.css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
            overflow: "inherit !important",
          },
        }}
      >
        {dialogOpen == "weekly" && (
          <WeeklySlotSelection
            {...{
              courseBatch,
              editItem,
              nextTimeSlot,
              setCourseBatch,
              setEditItem,
              setDialogOpen,
            }}
          />
        )}
        {dialogOpen == "custom" && (
          <CustomSlotSelection
            closePop={() => {
              setDialogOpen("");
            }}
            {...{
              onChangetimeSlotObj,
              customCourseBatch,
              setCustomCourseBatch,
              state,
              dateRanges,
              setDateRanges,
              handleSelection,
              handleSaveCustomSlot,
            }}
          />
        )}
        {dialogOpen == "membersDiscount" && (
          <MembersConditions
            {...{
              handleSaveConditions,
              courseBatch,
            }}
          />
        )}
      </AautiDialog>
    </div>
  );
};

export default CoursePricing;
