import React from "react";
import AautiText from "../../globalComponents/AautiText";
import "./index.scss";
import { CircularProgress } from "@mui/material";

const NavButton = ({
  disabled = false,
  text,
  handleClick,
  testId,
  loading = false,
  outlined,
  style,
}) => {
  return (
    <div className="flex-container buttons-cont">
      <button
        id={testId || text}
        disabled={disabled || loading}
        className={`save-button ${outlined && "outlined-nav-btn"}`}
        style={{ background: disabled && !loading && "#d2d2d2", ...style }}
        onClick={handleClick}
      >
        {loading ? (
          <CircularProgress size={20} sx={{ color: "#fff" }} />
        ) : (
          <AautiText title={text} weight={"medium"} />
        )}
      </button>
    </div>
  );
};

export default NavButton;
