import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AautiText from "../../globalComponents/AautiText";
import ShareIcon from "../../globalComponents/icons/ShareIcon";
import IndividualIcon from "../../../assets/images/individual-svg.svg";
import GroupIcon from "../../../assets/images/group-svg.svg";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { AuthService } from "../../../service/auth-service";
import { toggleSignInPop } from "../../../redux/reducer/appReducer";
import WishIcon from "../../globalComponents/icons/WishIcon";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Box } from "@mui/material";
import { showToast } from "../../globalComponents/Toast";
import { AiFillStar } from "react-icons/ai";
import { PriceConversions } from "../../CommonFunctions";
import CourseDetailsStrings from "./CourseDeatilsStrings";
import VirtualInPerson from "../../globalComponents/icons/VirtualInPersonIcon";
import VirtualIcon from "../../globalComponents/icons/VirtualIcon";
import InPerson from "../../globalComponents/icons/InPerson";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

const CourseCard = (props) => {
  const { each, review, cardWidth = 480, ratings, spExp } = props;
  const navigate = useNavigate();
  const path = window.location.origin;
  const [eachCourse, setEachCourse] = useState(each);
  const dispatch = useDispatch();
  const { userDetails, loggedinUserId, isUserLoggedIn } = useSelector(
    (state) => state.app
  );

  const { convertPriceWithCurrency } = PriceConversions();
  const RWD = useSelector((state) => state.responsiveReducer);
  const isInstituteRole = userDetails?.role?.includes("instituteAdmin");
  const shareUrl = `${path}/course-details/${each?._id}`;

  const onWishlistCourse = () => {
    const data = {
      type: "course",
      courseId: eachCourse?._id,
      gigId: null,
      serviceProviderId: eachCourse?.serviceProviderId,
      name: eachCourse?.courseName,
      description: eachCourse?.courseDescription,
      endUserId: loggedinUserId,
      schedulerId: null,
      parentId: userDetails?.parentId ?? loggedinUserId,
    };
    AuthService.saveWishList(data, onSuccess, onFailure);
  };
  const deleteCourseFromWish = () => {
    const deleteData = {
      type: "course",
      courseId: eachCourse._id,
      gigId: null,
      serviceProviderId: eachCourse?.serviceProviderId,
      name: eachCourse?.courseName,
      description: eachCourse?.courseDescription,
      endUserId: loggedinUserId,
      schedulerId: null,
      isDeleted: false,
      parentId: userDetails?.parentId ?? loggedinUserId,
    };
    AuthService.deleteWishList(deleteData, onSuccessRemove, onFailureRemove);
  };

  const onSuccess = (res) => {
    showToast("success", CourseDetailsStrings?.addedToWishList);
    setEachCourse((prev) => ({
      ...prev,
      isWishlisted: true,
    }));
  };
  const onFailure = (err) => {
    console.log(err);
  };
  const onSuccessRemove = (res) => {
    showToast("error", CourseDetailsStrings?.removedFromWishlist);
    setEachCourse((prev) => ({
      ...prev,
      isWishlisted: false,
    }));
  };

  const onFailureRemove = (err) => {
    console.log(err);
  };

  // const returnIcon = () => {
  //   if (each?.course?.virtual && each?.course?.inPerson) {
  //     return (
  //       <div className="reviewCard-div1">
  //         <VirtualIcon />|<InPerson />
  //       </div>
  //     );
  //   } else if (each?.course?.virtual) {
  //     return <VirtualIcon />;
  //   } else if (each?.course?.inPerson) {
  //     return <InPerson />;
  //   }
  // };

  const PackageImage = (data) => {
    if (data?.courseImage === "" || data?.courseImage === "String") {
      return "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/no-image-available.jpg";
    } else {
      return data?.courseImage;
    }
  };
  // console.log(each, "each");
  return (
    <div
      className="reviewCard-div2"
      style={{
        width: RWD?.mobileDevices ? 206 : cardWidth,
        minWidth: cardWidth,
      }}
      onClick={() => {
        review
          ? navigate(`/course-details/${each?._id}`)
          : navigate(`/course-details/${each?.course?._id}`);
      }}
    >
      <div className="reviewCard-div3" style={{ width: cardWidth }}>
        <div className="reviewCard-div16">
          <img
            src={review ? PackageImage(each) : PackageImage(each?.course)}
            alt=""
            className="reviewCard-div4"
            style={{
              height: RWD?.mobileDevices
                ? "140px"
                : RWD?.miniLapys
                ? "140px"
                : "140px",
            }}
          />
        </div>
        <div
          className="reviewCard-div5"
          onClick={() => {
            navigate(`/course-details/${each?._id}`);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              width: "100%",
            }}
          >
            <AautiText
              title={each?.courseName || each?.gigName || "No name"}
              size={"semi"}
              weight={"bold"}
              textStyle={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 2, // Set the number of lines
                overflow: "hidden",
                width:
                  each?.inPerson && each?.virtual
                    ? "90%"
                    : each?.inPerson || each?.virtual
                    ? "95%"
                    : "100%",
                textAlign: "left",
                height: 42,
                color: "#1e1e1e",
              }}
            />
            {each?.inPerson && each?.virtual ? (
              <VirtualInPerson />
            ) : (
              <>
                {each?.inPerson && <InPerson />}
                {each?.virtual && <VirtualIcon />}
              </>
            )}
          </div>
          <div className="reviewCard-div7">
            <div className="reviewCard-div8">
              <AautiText
                title={
                  each?.displayName + " |" ||
                  each?.course?.serviceProvider?.displayName + " |" ||
                  "No name |"
                }
                size={"semi"}
                weight={"medium"}
                textStyle={{ color: "#5C5B57", marginRight: 3 }}
              />
              {spExp || each?.yearsOfExperience ? (
                <AautiText
                  title={
                    spExp?.yearsOfExperience == 1 ||
                    each?.yearsOfExperience == 1
                      ? (spExp?.yearsOfExperience || each?.yearsOfExperience) +
                        " year"
                      : (spExp?.yearsOfExperience || each?.yearsOfExperience) +
                        " years"
                  }
                  size={"semi"}
                  weight={"medium"}
                  textStyle={{ color: "#5C5B57" }}
                />
              ) : (
                <AautiText
                  title={CourseDetailsStrings?.expNotListed}
                  size={"semi"}
                  weight={"medium"}
                  textStyle={{ color: "#5C5B57" }}
                />
              )}
            </div>
            <AautiText
              title={
                review
                  ? each?.courseLevel?.length > 3
                    ? "All Levels"
                    : each?.courseLevel?.join(", ") || ""
                  : each?.course?.courseLevel?.length > 3
                  ? "All Levels"
                  : each?.course?.courseLevel?.join(", ") || ""
              }
              size={"semi"}
              weight={"medium"}
              textStyle={{ color: "#5C5B57", marginTop: 5 }}
            />
          </div>

          <div className="reviewCard-div9">
            <AautiText
              title={
                ratings?.averageRating?.toFixed(1) ||
                review?.rating ||
                each?.course?.averageRating?.toFixed(1) ||
                CourseDetailsStrings?.new
              }
              size={"medium"}
              weight={"bold"}
              className={"reviewCard-text2"}
            />
            <AiFillStar size={18} color="#1e98d7" />
            <AautiText
              size={"semi"}
              weight={"medium"}
              textStyle={{ color: "#5C5B57" }}
              title={`(${ratings?.totalRatings || review?.totalRatings || 0})`}
            />
            <AautiText
              title={
                each?.subscribersCount + " Students" ||
                each?.course?.subscribersCount + " Students" ||
                CourseDetailsStrings?.notRegistered
              }
              size={"semi"}
              weight={"medium"}
              textStyle={{ color: "#5C5B57" }}
            />
          </div>
          {isInstituteRole && (
            <>
              <Box sx={{ flexGrow: 1 }}>
                <BorderLinearProgress
                  variant="determinate"
                  value={60}
                  valueBuffer={60}
                />
              </Box>
              <div className="reviewCard-div10">
                <AautiText title={"10 AM"} size={"small"} />
                <AautiText title={"30 min left"} size={"small"} />
                <AautiText title={"11 AM"} size={"small"} />
              </div>
            </>
          )}
          {!isInstituteRole && (
            <div className="reviewCard-div11">
              <AautiText title={CourseDetailsStrings?.from} size={"semi"} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                  width: "100%",
                  gap: 10,
                }}
              >
                {(each?.individualDaily?.length > 0 ||
                  each?.individualWeekly?.length > 0) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <img
                      src={IndividualIcon}
                      alt="individual"
                      style={{
                        height: "18px",
                        width: "18px",
                        zIndex: 100,
                      }}
                    />
                    <AautiText
                      size={"semi"}
                      weight={"bold"}
                      title={`${convertPriceWithCurrency(
                        each?.individualDaily?.[0]?.individualDiscountPrice ||
                          each?.individualWeekly?.[0]
                            ?.individualDiscountPrice ||
                          each?.individualDaily?.[0]?.individualPrice ||
                          each?.individualWeekly?.[0]?.individualPrice
                      )}`}
                    />
                  </div>
                )}
                {(each?.groupDaily?.length > 0 ||
                  each?.groupWeekly?.length > 0) && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <img
                      src={GroupIcon}
                      alt="individual"
                      style={{
                        height: "18px",
                        width: "18px",
                        zIndex: 100,
                      }}
                    />
                    <AautiText
                      size={"semi"}
                      weight={"bold"}
                      title={`${convertPriceWithCurrency(
                        each?.groupDaily?.[0]?.groupDiscountPrice ||
                          each?.groupWeekly?.[0]?.groupDiscountPrice ||
                          each?.groupDaily?.[0]?.groupPrice ||
                          each?.groupWeekly?.[0]?.groupPrice
                      )}`}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="reviewCard-div14">
            {!review ? (
              <div
                className="reviewCard-div15"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ShareIcon shareUrl={shareUrl} />

                {eachCourse?.isWishlisted ? (
                  <WishIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteCourseFromWish();
                    }}
                    active={eachCourse?.isWishlisted}
                  />
                ) : (
                  <WishIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isUserLoggedIn) {
                        onWishlistCourse();
                      } else {
                        navigate("/login");
                      }
                    }}
                  />
                )}
              </div>
            ) : (
              <div className="reviewCard-div13"></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
