import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

/** login authentication
 * @param username, password
 */
function getAllCategories(onSuccess, onFailure) {
  ApiRequest({ url: ApiPaths.GET_ALL_CATEGORIES, method: ApiMethods.GET }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllCourses(onSuccess, onFailure) {
  ApiRequest({ url: ApiPaths.GET_ALL_COURSES, method: ApiMethods.GET }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getTopSeviceProviders(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOPSERVICEPROVIDERS,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

// function getNewServiceProviders(count, onSuccess, onFailure) {
//   ApiRequest({
//     url: ApiPaths.GET_NEWSERVICEPROVIDERS + `?count=${count}`,
//     method: ApiMethods.GET,
//   }).then(
//     (response) => {
//       onSuccess(response?.data);
//     },
//     (error) => {
//       onFailure();
//     }
//   );
// }
function getUpcomingCourses(count, userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_UPCOMING_COURSES + "?count=" + count,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getAllCartsByUser(userId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_CARTS_BY_USER + userId,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
function saveWishList(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_SAVE_WISHLIST,
    method: ApiMethods.POST,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteCart(cartData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.DELETECART,
    method: ApiMethods.DELETE,
    data: cartData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getCoursesNextEightHours(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_COURSES_NEXT_EIGHT_HOURS,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getTestimonials(data, onSuccess, onFailure) {
  ApiRequest({
    url: "testimonials/getAllTestimonials",
    method: ApiMethods?.GET,
    params: data,
  }).then(
    (responce) => {
      onSuccess(responce?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export const AuthService = {
  getAllCategories,
  getCoursesNextEightHours,
  getAllCourses,
  getTopSeviceProviders,
  // getNewServiceProviders,
  getUpcomingCourses,
  getAllCartsByUser,
  deleteCart,
  saveWishList,
  getTestimonials,
};
