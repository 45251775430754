import React from "react";
import moment from "moment";

const FormattedDate = ({ dateTime }) => {
  if (!moment(dateTime).isValid()) {
    return "";
  }

  const momentDate = moment.utc(dateTime).local();
  const currentDate = moment().local();

  if (momentDate.isValid()) {
    if (momentDate.isSame(currentDate, "date")) {
      if (Math.abs(momentDate.diff(currentDate)) < 60000) {
        return "just now";
      } else {
        const hoursDiff = Math.abs(momentDate.diff(currentDate, "hours"));
        if (hoursDiff < 1) {
          const minutesDiff = Math.abs(momentDate.diff(currentDate, "minutes"));
          if (minutesDiff < 1) {
            return "just now";
          }
          return `${minutesDiff} min${minutesDiff > 1 ? "s" : ""} ago`;
        }
        return `${hoursDiff} hr${hoursDiff > 1 ? "s" : ""} ago`;
      }
    }

    if (momentDate.isBefore(currentDate, "date")) {
      const daysDiff = Math.abs(momentDate.diff(currentDate, "days"));
      if (daysDiff === 1) {
        return "Yesterday";
      }
      return momentDate.format("DD MMM YYYY");
    }
  }

  return "N/A";
};

export default FormattedDate;
