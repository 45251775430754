import React, { useRef } from "react";
import { useSelector } from "react-redux";
import AautiText from "../globalComponents/AautiText";
import gigStrings from "./gigStrings";
import { globalColors } from "../../themes/GlobalColors";
import { AiOutlinePlayCircle } from "react-icons/ai";
import ShareIcon from "../globalComponents/icons/ShareIcon";
import WishIcon from "../globalComponents/icons/WishIcon";
import { FaAngleRight } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
import { MdForward10, MdReplay10 } from "react-icons/md";
import GigRightContainer from "./gigRightContainer";
import { showToast } from "../globalComponents/Toast";
import { PriceConversions } from "../CommonFunctions";
import moment from "moment";

const PreviewGigSubmit = ({ gigObject }) => {
  const { convertPriceWithCurrency, convertToMillionBillions } =
    PriceConversions();
  const videoRef = useRef();
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );

  const getTimeDifference = (date) => {
    const now = moment();
    const targetDate = moment(date);

    // Calculate the difference in milliseconds
    const difference = targetDate.diff(now);

    // Check if the difference is less than 24 hours
    if (difference <= moment.duration(1, "days")) {
      const hoursLeft = targetDate.diff(now, "hours");
      return `${hoursLeft} hrs left`;
    }

    // Check if the difference is less than 1 month
    const daysLeft = targetDate.diff(now, "days");
    if (daysLeft < 30) {
      return `${daysLeft} days left`;
    }

    // Calculate the difference in months
    const monthsLeft = targetDate.diff(now, "months");
    return `${monthsLeft} months left`;
  };

  const returnGigPrice = () => {
    let today = moment().startOf("day");
    let isBetween =
      moment(gigObject?.discountStartDate).isBefore(moment(new Date())) &&
      moment(gigObject?.discountEndDate).isAfter(moment(new Date())) &&
      gigObject?.discountPrice;
    // null,
    // "[]"

    // if (isBetween) {
    //   return [
    //     convertPriceWithCurrency(gigObject?.discountPrice),
    //     isBetween,
    //   ];
    // } else {

    return [
      convertToMillionBillions(gigObject?.localGigPrice, 2),
      convertToMillionBillions(gigObject?.localDiscountPrice, 2),
      isBetween,
      getTimeDifference(gigObject?.discountEndDate),
    ];
    // }
  };

  const gigData = [
    { leftContent: "Levels", rightData: gigObject?.courseLevel },
    {
      leftContent: "Age Group",
      rightData: gigObject?.targetAgeGroups,
    },
    {
      leftContent: "Language",
      rightData: gigObject?.languages,
    },
    {
      leftContent: "Price",
      rightData: returnGigPrice(),
    },
  ];
  return (
    <div className="gig-details">
      <div
        className="gigPreview-div1"
        style={{
          display: mobileDevices || ipodDevices ? "contents" : "row",
        }}
      >
        <div
          className="gigPreview-div2"
          style={{
            width: mobileDevices || ipodDevices ? "98%" : "65%",
            paddingRight: !(mobileDevices || ipodDevices) && "3%",
          }}
        >
          <div
            style={{ width: "100%", position: "relative" }}
            className="hover-main"
          >
            <video
              className="gigPreview-img1"
              autoPlay={false}
              ref={videoRef}
              zoom
              src={gigObject?.shortVideoUrl}
              muted={true}
              controls={true}
              // onended={() => {
              //   alert("video ended");
              //   videoRef
              // }}
              preload="metadata"
              // loop
              style={{
                width: "100%",
                // height: "100%",
                height: mobileDevices ? 200 : 400,
                objectFit: "contain", //cover
              }}
            />
            <button
              className="hover-me"
              style={{
                position: "absolute",
                top: "40%",
                left: "25%",
                cursor: "pointer",
              }}
              onClick={() => {
                if (videoRef.current) {
                  videoRef.current.currentTime -= 10;
                }
              }}
            >
              <MdReplay10 size={25} />
            </button>
            {/* 
            {showReplayButton && (
              <button
                onClick={handleReplay}
                style={{
                  display: "block",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  padding: "10px 20px",
                  backgroundColor: "blue",

                  border: "none",
                  cursor: "pointer",
                }}
              >
                Replay
              </button>
            )} */}
            <button
              className="hover-me"
              onClick={() => {
                if (videoRef.current) {
                  videoRef.current.currentTime += 10;
                }
              }}
              style={{
                position: "absolute",
                top: "40%",
                right: "25%",
                cursor: "pointer",
              }}
            >
              <MdForward10 size={25} />
            </button>
          </div>

          {/* <ReactPlayer
              url={gigObject?.shortVideoUrl}
              light={gigObject?.gigImage}
              // playIcon={true}
              width={"100%"}
              height={600}
              controls
              loop
              playing
              style={{ borderRadius: 6 }}
            /> */}

          <AautiText
            title={gigObject?.gigName}
            id={"gig-title"}
            size={"medium"}
            weight={"bold"}
            textStyle={{
              color: globalColors?.HEADING_COLOR,
              // display: "-webkit-box",
              // WebkitBoxOrient: "vertical",
              // WebkitLineClamp: 2,
              // overflow: "hidden",
              // width: "100%",
            }}
          />
          <AautiText
            title={gigObject?.gigDescription}
            className={"gigPreview-text1"}
            id={"gig-description"}
            size={"normal"}
          />

          {/* {returnTags()} */}

          <div className="gigPreview-div4">
            <div className="gigPreview-div5">
              {/* <div className="tag-gigPreview">
                  <AautiText
                    title={`Upto ${gigObject?.adminDiscount}% Off`}
                    size={"small"}
                    textStyle={{ color: globalColors?.HEADING_COLOR }}
                  />
                </div> */}
              <div className="gigPreview-div7">
                <IoMdStar className="gigPreview-icon1" />
                <AautiText
                  size={"semi"}
                  className={"allCard-text2"}
                  title={
                    !gigObject?.averageRating
                      ? "New"
                      : `${gigObject?.averageRating?.toFixed(1) || 0} (${0})`
                  }
                />
                {/* {gigReviews?.averageRating && <FaAngleRight color="#fff" />} */}
              </div>
            </div>
            <div className="gigPreview-div5" style={{ gap: 6 }}>
              <WishIcon
                active={false}
                height="25px"
                width="25px"
                onClick={() => {
                  // if (isUserLoggedIn) {
                  //   handleWishlist();
                  // } else {
                  //   localStorage.setItem(
                  //     "prevPath",
                  //     `/course-details/${gigDetails?._id}`
                  //   );
                  //   navigate("/login");
                  // }
                }}
              />
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ShareIcon
                  height="25px"
                  width="25px"
                  shareUrl={""}
                  noShare={true}
                />
              </div>
            </div>
          </div>

          {(mobileDevices || ipodDevices) && (
            // <h1>Hello</h1>
            <GigRightContainer
              {...{
                gigData: gigData,
                hasChilds: () => {},
                handleSelectUser: () => {},
                isChild: false,
                buyNowAndCartVal: () => {},
                handleCart: () => {},
                gigDetails: gigObject,
                completerDetails: gigObject,
                isParent: false,
                buyNowVal: () => {},
                childDetails: {},
                showUser: false,
              }}
            />
          )}

          <AautiText
            title={gigStrings?.gigContent}
            size={"medium"}
            weight={"bold"}
          />
          {gigObject?.gigVideos?.map((each, index) => (
            <div className="gigPreview-div8">
              <AautiText
                title={`${index + 1} Topic : 1 Video `}
                size={"normal"}
                className={"gigPreview-text5"}
                weight={"bold"}
              />
              <div
                key={index}
                className="gigPreview-div5"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  //   if (gigDetails?.isPurchased) {
                  // setGigUrl(each?.videoUrl);
                  // setGigPlay(true);
                }}
              >
                <AiOutlinePlayCircle size={18} />
                <AautiText
                  title={each?.videoTitle}
                  size={"semi"}
                  textStyle={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 1,
                    width: "95%",
                  }}
                  className={"gigPreview-text6"}
                />
              </div>
            </div>
          ))}
          <div className="gigPreview-div8">
            <AautiText
              title={gigStrings?.whatWillLearn}
              size={"normal"}
              weight={"bold"}
              className={"gigPreview-text7"}
            />
            <AautiText
              title={gigObject?.summary}
              size={"semi"}
              className={"gigPreview-text8"}
            />
            {/* <div className="gigPreview-div9">
                {gigDetails?.isPurchased && (
                  <AautiText
                    title={
                      gigDetails?.alreadyReviewed
                        ? "View Reviews"
                        : gigStrings?.addReview
                    }
                    weight={"bold"}
                    className={"detailsLeft-text9"}
                    onClick={() => {
                      gigDetails?.alreadyReviewed
                        ? setOpen2(true)
                        : setVisible1(true);
                    }}
                  />
                )}

                {gigDetails?.isPurchased &&
                  loggedinUserId &&
                  loggedinUserId !== gigObject?.serviceProviderId && (
                    <AautiText
                      title={gigStrings?.reportGig}
                      size={"normal"}
                      weight={"bold"}
                      onClick={() => {
                        setShowReportForm(true);
                        setOpen(!open);
                      }}
                      className={"gigPreview-text9"}
                    />
                  )}
              </div> */}
            {/* {showReportForm && (
              <AautiReport
                gigId={gigDetails?._id}
                screenName="Report Gig"
                open={open}
                setOpen={setOpen}
                getGigDetails={getGigDetails}
              />
            )} */}
          </div>

          {/* <GigComments gigObject={gigObject} details={true} /> */}
          <div className="cancellation-polict-container-previc">
            <AautiText
              title={"Cancellation Policy"}
              size={"normal"}
              weight={"bold"}
              textStyle={{
                marginRight: 15,
                color: globalColors?.HEADING_COLOR,
              }}
            />
            <AautiText
              size={"semi"}
              className={"gigPreview-text8"}
              title={
                "Cancel up to within 24 hours before the start time for full refund."
              }
            />
            {/* <AautiText
              title={gigStrings?.refundPolicy}
              onClick={() => {
                //   setRefundPolicyState(true);
              }}
              size={"normal"}
              weight={"bold"}
              textAlign={"right"}
              className={"gigPreview-text9"}
            /> */}
          </div>

          {/* <div
            className="gigPreview-div10"
            style={{ display: filteredUserGigs?.length < 1 && "none" }}
          >
            <div className="gigPreview-div11">
              <AautiText
                title={`${gigStrings?.otherGigs} ${gigObject?.serviceProviderDisplayName}`}
                size={"medium"}
                weight={"extraBold"}
                className={"gigPreview-text10"}
              />

              <AautiText
                onClick={() => {
                  navigate("/similar-gigs", { state: base });
                }}
                title={gigStrings?.viewAll}
                size={"semi"}
                className={"gigPreview-text11"}
                textStyle={{
                  display:
                    filteredUserGigs?.length <
                      (mobileDevices
                        ? 2
                        : ipodDevices
                        ? 3
                        : miniLapys || lapy
                        ? 4
                        : 4) && "none",
                }}
              />
            </div>
            {filteredUserGigs?.length <
            (mobileDevices
              ? 2
              : ipodDevices
              ? 3
              : miniLapys
              ? 3
              : lapy
              ? 4
              : 4) ? (
              <div className="gigPreview-div12">
                {filteredUserGigs?.map((item, index) => (
                  <GigCard
                    cardWidth={mobileDevices ? "98%" : "269px"}
                    height={460}
                    key={index}
                    {...{
                      each: item,
                    }}
                    getGigsList={() =>
                      getServiceProviderGigs(gigObject?.serviceProviderId)
                    }
                  />
                ))}
              </div>
            ) : isEmpty(filteredUserGigs) ? (
              <div className="gigPreview-div13">
                <AautiText title={gigStrings?.noGigsAvailable} />
              </div>
            ) : (
              <CauroselList
                arrowsWidth={"92%"}
                settings={slideSettings}
                sliderRef={similarRef}
                handleSliderDirec={handleSimilarArrows}
                disabledLeftButton={disabledLeftButton}
                disabledRight={disabledRight}
                style={{ width: "100%" }}
                showButtons={!mobileDevices}
              >
                {filteredUserGigs?.map((item, index) => (
                  <GigCard
                    cardWidth={"96.5%"}
                    height={460}
                    key={index}
                    getGigsList={() =>
                      getServiceProviderGigs(gigObject?.serviceProviderId)
                    }
                    {...{
                      each: item,
                    }}
                  />
                ))}
              </CauroselList>
            )}
          </div> */}
        </div>
        {!(mobileDevices || ipodDevices) && (
          <GigRightContainer
            {...{
              showUser: false,
              gigData: gigData,
              hasChilds: () => {},
              handleSelectUser: () => {},
              isChild: false,
              buyNowAndCartVal: () => {},
              handleCart: () => {},
              gigDetails: gigObject,
              completerDetails: gigObject,
              isParent: false,
              buyNowVal: () => {},
              childDetails: {},
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PreviewGigSubmit;
