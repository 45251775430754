import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FiPlus } from "react-icons/fi";
import Lottie from "react-lottie-player";

import ChatListItem from "./components/chat_list_item";
import AautiText from "../globalComponents/AautiText";
import ChatRoom from "./chat_room";
import CreateChatGroupDialog from "./components/chat_dialogs/CreateChatGroupDialog";
import SearchBar from "./components/searchBar";
import ChatContact from "./components/chat_list_item/ChatContact";
import SkeletonChatsList from "./components/skeletons/ChatsList";

import {
  getChatsListProps,
  setActiveRoom,
  setSocketHandler,
  updateContacts,
  updateUnreadCount,
} from "../../redux/reducer/chatSlice";

import { emptyChatsList } from "./animations";
import { userRoles } from "../../storage/constants";
import {
  chatConstants,
  conversationTypes,
  lottiePlayerStyles,
  SocketEvents,
} from "./constants";
import ChatHeader from "./components/chat_header";

export default function Chat({
  isSpApproved = false,
  isInstituteAdmin,
  role,
  instituteId,
}) {
  const myUserId = localStorage.getItem("USER_ID");
  const { mobileDevices, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const { unreadCount, contacts, isSocketOpen, activeChatRoomId } =
    useSelector(getChatsListProps);
  const navigate = useNavigate();

  const [state, setState] = useState({
    searchInput: "",
    chats: [],
    pageNo: 0,
    createGroupBsOpen: false,
    showLoader: true,
  });

  const dispatch = useDispatch();

  const showSearchedContacts =
    state.searchInput?.length > 0 && !state.createGroupBsOpen;

  const toggleLoader = (status = false) =>
    setState((prev) => ({ ...prev, showLoader: status }));

  const fetchConversations = (currentPageNo = state.pageNo) => {
    if(currentPageNo === 0)toggleLoader(true);
    dispatch({
      type: SocketEvents.room.getConversations,
      payload: {
        pageNo: currentPageNo,
      },
    });
  };

  useLayoutEffect(() => {
    dispatch(setSocketHandler(handleSocketEvents));
    return () => {
      dispatch(setSocketHandler(undefined));
    };
  }, []);

  useEffect(() => {
    if (isSocketOpen) fetchConversations(state.pageNo);
  }, [isSocketOpen, state.pageNo]);

  const getUnreadCount = () =>
    dispatch({ type: SocketEvents.users.getUnreadCount });

  function handleSocketEvents({ event, ...rest }) {
    if (!!rest?.batchId || !!rest.sessionId) return;
    switch (event) {
      case SocketEvents.room.getMessagesByRoom:
        break;
      case SocketEvents.updateUnreadCount:
      case SocketEvents.room.clearConversation:
        clearMessageAndUnreadCount({
          roomId: rest?._id ?? rest.roomId,
          removeMessage: event === SocketEvents.room.clearConversation,
        });
        break;
      case SocketEvents.room.deleteConversation:
        setState((prev) => ({
          ...prev,
          chats: prev.chats.filter((chat) => chat._id !== rest.data._id),
        }));
        getUnreadCount();
        break;
      case SocketEvents.room.getConversations:
        onGetConversations(rest.data);
        break;
      case SocketEvents.getContacts:
        onReceiveContacts(rest?.data?.contacts ?? []);
        break;
      case SocketEvents.room.freeze:
      case SocketEvents.room.unFreeze:
        updateFreezeStatusInChats(rest.data);
        break;
      case SocketEvents.room.created:
        onCreateChatRoom(rest.data);
        break;
      case SocketEvents.message.delete:
      case SocketEvents.message.messageDeleteForMe:
        fetchConversations();
        break;
      case SocketEvents.room.updateTimeFreeze:
      case SocketEvents.room.removeUser:
        break;
      default:
        if (rest?.errMsg) return;
        updateAndSortChats({ event, ...rest });
        break;
    }
  }

  const onReceiveContacts = (contacts) => {
    dispatch(updateContacts(contacts));
  };

  const onCreateChatRoom = (chatRoom) => {
    resetSearchInput();
    setState((prev) => ({
      ...prev,
      searchInput: "",
      createGroupBsOpen: false,
      chats: [chatRoom, ...prev.chats],
    }));
    onPressRoom(chatRoom);
  };

  const clearMessageAndUnreadCount = ({ roomId, removeMessage = true }) => {
    setState((prev) => {
      let { chats } = prev;
      chats = chats.map((chat) => {
        if (chat._id === roomId) {
          if (removeMessage) delete chat.message;
          if (chat.unreadCount) delete chat.unreadCount;
        }
        return chat;
      });
      return {
        ...prev,
        chats,
      };
    });
    getUnreadCount();
  };

  const onGetConversations = (chats) => {
    if (!chats) return;
    setState((prev) => ({
      ...prev,
      chats: prev.pageNo > 0 ? [...prev.chats, ...chats] : chats,
      showLoader: false,
    }));
  };

  const updateFreezeStatusInChats = ({ _id: roomId, adminFreezed = false }) => {
    if (!roomId) return;
    setState((prev) => {
      let chats = prev.chats;
      chats = chats.map((chat) => {
        if (chat._id === roomId) {
          chat.adminFreezed = adminFreezed;
        }
        return chat;
      });
      return { ...prev, chats };
    });
  };

  const getIsUserInActiveChatRoom = (data) => {
    let roomId;
    let amITheSender = false;
    if (data?._id) roomId = data._id;
    if (data?.roomId) roomId = data.roomId;
    if (data?.message) {
      roomId = data.message.roomId;
      amITheSender = data.message.sender._id === myUserId;
    }
    return (
      amITheSender ||
      (!!roomId && !!activeChatRoomId && roomId === activeChatRoomId)
    );
  };

  const updateAndSortChats = ({ event, ...rest }) => {
    let conversationExists = false;
    let count = unreadCount;
    let isLoginLogoutEvent =
      event === SocketEvents.login || event === SocketEvents.logout;
    let isInActiveRoom,
      countIncrementBy = 0;
    if (!isLoginLogoutEvent) {
      isInActiveRoom = getIsUserInActiveChatRoom(rest.data);
      countIncrementBy = isInActiveRoom ? 0 : 1;
    }

    setState((prev) => {
      let { chats } = prev;
      chats = chats
        .map((conversation) => {
          const isOneToOne = conversation.type === conversationTypes.ONE_TO_ONE;
          if (
            isLoginLogoutEvent &&
            isOneToOne &&
            conversation.hasOwnProperty("user") &&
            conversation?.user?._id === rest?.userId
          ) {
            conversation.user = {
              ...conversation.user,
              onlineStatus: event === SocketEvents.login,
            };
          } else if (conversation?._id === rest?.data?._id) {
            conversationExists = true;
            conversation = {
              ...conversation,
              ...rest.data,
              unreadCount: (conversation?.unreadCount ?? 0) + countIncrementBy,
              user:
                isOneToOne && isInActiveRoom
                  ? conversation.user
                  : rest.data?.message?.sender ?? {},
            };
            
            if (
              [SocketEvents.room.leave, SocketEvents.room.removeUser].includes(
                event
              ) &&
              rest.data.userId === conversation?.user?._id
            ) {
              conversation.user = {
                ...conversation.user,
                exitDate: new Date(),
              };
            }
            if(event === SocketEvents.room.updateLastSeen){
              conversation.unreadCount = 0;
            }
            count = count + countIncrementBy;
          }
          return conversation;
        })
        .sort(
          (c1, c2) =>
            moment(c2?.message?.createdAt ?? 0) -
            moment(c1?.message?.createdAt ?? 0)
        );
      if (!conversationExists && !isLoginLogoutEvent) {
        count = count + countIncrementBy;
        chats = [
          {
            ...rest.data,
            unreadCount: 1,
            user: rest?.data?.message?.sender ?? rest.data?.user ?? {},
          },
          ...chats,
        ];
      }
      return { ...prev, chats };
    });

    if (count !== unreadCount) {
      getUnreadCount();
    }
  };

  const onPressAddIcon = () => {
    setState((prev) => ({
      ...prev,
      createGroupBsOpen: true,
    }));
  };

  const onCloseCreateGroupBS = () => {
    resetSearchInput();
    setState((prev) => ({
      ...prev,
      createGroupBsOpen: false,
    }));
  };

  const onChangeSearchInput = (event) => {
    let text = event.target.value;
    setState((prev) => ({ ...prev, searchInput: text }));
    if (text && text?.trim() && text?.length) {
      const payload = {
        name: text?.trim(),
        role,
        withMessage: true,
      };
      if (isInstituteAdmin) {
        payload.instituteId = instituteId;
      }
      dispatch({
        type: SocketEvents.getContacts,
        payload,
      });
    } else resetSearchInput();
  };

  const resetSearchInput = () => {
    setState((prev) => ({
      ...prev,
      searchInput: "",
      showLoader: false,
    }));
    dispatch(updateContacts([]));
  };

  const onScrollChats = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    if (clientHeight + scrollTop >= scrollHeight)
      setState((prev) => ({ ...prev, pageNo: prev.pageNo + 1 }));
  };

  const prepareForActiveRoom = (room) => {
    let { message = {}, ...rest } = room;
    let data = rest;
    if (!room?.type) {
      let temp = rest;
      data = { _id: rest._id };
      data.user = temp;
      data.type = conversationTypes.ONE_TO_ONE;
    }
    return data;
  };

  const onPressRoom = (room) => {
    const data = prepareForActiveRoom(room);
    dispatch(setActiveRoom({ data, resetContacts: true }));
    if (
      [
        conversationTypes.COURSE,
        conversationTypes.COURSE_ADHOC,
        conversationTypes.ADHOC,
      ].includes(data.type)
    ) {
      dispatch({
        type: SocketEvents.room.updateTimeFreeze,
        payload: {
          batchId: data?.batch?._id,
          sessionId: data?.session?._id,
          timezoneOffset: new Date().getTimezoneOffset(),
        },
      });
    }
  };

  const getContactProps = (data) => {
    let bottomText, alt, src;
    if (
      data?.hasOwnProperty("type") &&
      !(data?.type === conversationTypes.ONE_TO_ONE)
    ) {
      alt = data.name;
      src = data.avatar;
      bottomText = data?.description ?? "";
    } else {
      alt = data?.displayName ?? data?.user?.displayName ?? "";
      src = data?.profileImage ?? data?.user?.profileImage ?? "";
    }
    if (!bottomText && data?.instituteId?.length > 1 && instituteId) {
      if (role.includes(userRoles.instituteAdmin)) {
        bottomText = "Institute Admin";
      }
      bottomText = role?.includes(userRoles.serviceProvider)
        ? "Instructor"
        : "Participant";
    }
    if (!bottomText && !data?.instituteId) {
      bottomText = role?.includes(userRoles.serviceProvider)
        ? "Service Provider"
        : "End User";
    }
    if (data?.message?.hasOwnProperty("text")) {
      bottomText = data.message.text;
    }

    return {
      bottomText,
      onlineStatus: data?.onlineStatus ?? data?.user?.onlineStatus,
      onPress: () => {
        resetSearchInput();
        onPressRoom(data);
      },
      alt,
      src,
      id: data._id,
      searchText: state.searchInput,
    };
  };

  const renderContact = (contact) => {
    const contactProps = getContactProps(contact);
    return <ChatContact key={contact._id} {...contactProps} />;
  };

  const renderEmptyView = () => (
    <div className="empty-container">
      <Lottie
        loop
        animationData={emptyChatsList}
        play
        style={lottiePlayerStyles}
      />
      <AautiText
        title={chatConstants?.noContactsFound}
        size={"medium"}
        textStyle={{
          color: "#404040",
          marginTop: "5px",
        }}
      />
    </div>
  );

  const renderContactsList = () => {
    if (!contacts?.length) return renderEmptyView();
    return (
      <ul
        key={"chat-contacts-list-container"}
        className="overflow-container chats-list-container"
      >
        {contacts.map(renderContact)}
      </ul>
    );
  };

  const renderChatListItem = (item) => (
    <ChatListItem
      key={item?._id}
      conversation={item}
      onClickChat={() => onPressRoom(item)}
      selected={item?._id === activeChatRoomId}
    />
  );

  const renderChats = () => {
    if (state.showLoader) return <SkeletonChatsList size={16} />;
    if (showSearchedContacts) return renderContactsList();
    if (!state.chats?.length) return renderEmptyView();
    return (
      <ul
        key={`chats-list-container`}
        className="overflow-container chats-list-container"
        onScroll={onScrollChats}
      >
        {state.chats.map(renderChatListItem)}
      </ul>
    );
  };

  const onCloseChatRoom = () => {
    dispatch(setActiveRoom({ data: undefined }));
    dispatch(setSocketHandler(handleSocketEvents));
  };

  const renderChatsList = () => (
    <div className="search-box-and-chats-container">
      <div className={"chats-list-header"}>
        <ChatHeader
          {...{
            title:'Chat',
            isMobile:isMobileAndMediumDevice,
            onClickBackIcon:() => navigate[-1]
          }}
        />
        <div className="search-box-container">
          <SearchBar
            {...{
              value: state.searchInput,
              showBackIcon: false,
              onChangeSearchInput,
              onPressClear: resetSearchInput,
            }}
          />
          {isSpApproved && (
            <button
              className={"create-group-btn"}
              onClick={onPressAddIcon}
            >
              <FiPlus size={20} />
            </button>
          )}
        </div>
      </div>
      {renderChats()}
      {state.createGroupBsOpen && (
        <CreateChatGroupDialog
          {...{
            open: state.createGroupBsOpen,
            role,
            isInstituteAdmin,
            instituteId,
            onClose: onCloseCreateGroupBS,
          }}
        />
      )}
    </div>
  );

  const isMobileAndMediumDevice = !!mobileDevices || !!aboveLargeMobileDevices;
  return (
    <div className="chat-main-container">
      <div className="chat-wrapper">
      {isMobileAndMediumDevice && !activeChatRoomId && renderChatsList()}
      {!isMobileAndMediumDevice && renderChatsList()}
      <ChatRoom
        key={activeChatRoomId}
        {...{
          showBackIcon: isMobileAndMediumDevice,
          onCloseChat: onCloseChatRoom,
          extraEventHandler: handleSocketEvents,
          isInMobileView: isMobileAndMediumDevice,
        }}
      />
      </div>
    </div>
  );
}
