import React from "react";
import { Box, Grid, Skeleton } from "@mui/material";

const PackageDetailsLoader = () => {
  return (
    <Box sx={{ width: "92%" }}>
      <Grid container className="packageLoader-div2">
        <Grid item xs={12}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height={400}
            width={"100%"}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={9} spacing={1}>
          <Skeleton animation="wave" variant="text" height={40} width={"30%"} />
          <Skeleton animation="wave" variant="text" height={40} width={"80%"} />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
        </Grid>
        <Grid item xs={3}>
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
          <Skeleton
            animation="wave"
            variant="text"
            height={40}
            width={"100%"}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
export default PackageDetailsLoader;
