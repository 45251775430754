// import { courseConstant } from "../course/courseCreation/courseConstant";

export const AautiStrings = {
  AddBatchString: "Add Batch",
  BatchTitleString: "Batch Title",
  count: 20,
  viewAllCount: 6,
  NoDataFoundString: "No Data Found",
  NoCourseFoundString: "No Courses Found",

  NoGigsFoundString: "No Gigs Found",

  NoOrdersFoundString: "No Orders Found",

  NoClassesFoundString: "No Classes Found",

  NoSessionFoundString: "No Session Found",

  KeepExploringString: "Keep Exploring",

  NoOrdersString: "You haven't placed any orders yet",

  AboutServiceProviderString: "About  Service Provider",

  AchivementsandAwardsString: "Achivements and Awards ",
  WorkDetailsString: "Work Details",

  LocationString: "Location",
  SkillsetString: "Skill Set",
  BuyNowString: "Buy Now",
  PricingString: "Pricing",
  InPersonString: "In-Person",
  VirtualString: "Virtual",
  GroupString: "Group ",
  CourseTypeString: "Course Type *",
  AdvancedString: "Advanced",
  ExpertString: "Expert",
  IntermediateString: "Intermediate ",
  BeginnerString: "Beginner",
  ExpertLevelsString: "Expert Levels *",
  NoslotsareavailableString: "Change Date for available slots",
  PleaseselectavailableslotString: "Please select available slot",
  ClassTypeString: "Class Type",
  IndividualString: "Individual",
  ReportServiceproviderString: "Report Service provider",
  RemoveString: "Remove",
  AddReviewString: "Add a Review",
  StartDateString: "Start Date",
  StartTimeString: "Start Time",
  SelectDatesString: "Select Dates",
  EndTimeString: "End Time",
  EndDateString: "End Date",
  BatchTypeString: "Batch Type",
  NoOfStudentsString: "No. of Students",
  DoneString: "Done",
  GenerateUsingAIString: "Generate Using AI",
  LessonPlanString: "Lesson Plan",
  LpNotMandatory: "lessonplan not mandatory",
  NextString: "Next",
  GigPricingString: "Gig Pricing",
  RefundAvailableString: "Refund Available",
  AddImagesAndVideosString: "Add Images and Videos",
  CourseCreationString: "Course Creation",
  GigCreation: "Gig Creation",
  UpdateCourseCreationString: "Update Course",
  UpdateGigCreation: "Update Gig",
  CourseImagesString: "Course Files",
  GigImage: "Gig Files",
  CoverImageString: "Cover Image",
  UploadImagesOrFilesString: "Upload Images or Files",
  SubmitString: "Submit",
  SubmitForReviewString: "Submit For Review",
  RefundsString: "Refunds",
  YouAreResponsibleForTheContentYouHaveUploadedString:
    "You are responsible for the content you have uploaded",
  OkString: "Ok",
  SaveString: "Save",
  ViewLessonPlanString: "View Lesson Plan",
  BatchesString: "Batches",
  DailyString: "Daily",
  GroupSlotsString: "Group slots",
  ExpNotListedString: "Exp not listed",
  noSlotsAvailableString: "No Slots Available",
  followingString: "Following",
  followString: "Follow",
  previewString: "Preview",
  topicsString: "Topics",
  cancelString: "Cancel",
  chooseYourLocationString: "Choose Your Location",
  useCurrentLocationString: "Use current location",
  backString: "Back",
  continueString: "Continue",
  topicNameString: "Topic Name",
  uploadMediaString: "Upload Media",
  uploadDocumentsString: "Upload Documents",
  addSubtopicString: "Add Subtopic",
  subTopicNameString: "SubTopic Name",
  //  "Selected image must have a 4:3 aspect ratio."
  mediaAndFilesString: "Media & Files",
  courseNameString: "Course Name",
  addSessionString: "Add Session",
  addString: "Add",
  slotsString: "Slots",
  fromString: "From",
  availableTimingsString: "Available Timings",
  conflictsString: "Conflicts",
  titleString: "Title",
  selectLanguageString: "Select Language",
  priceString: "Price",
  whenYouDoTheirStatusWillBeAppearHereString:
    "When You do, their status will be appear here",
  draftsString: "Drafts",
  searchCoursesString: "Search",
  noDraftCoursesFoundString: `No Draft Courses Found`,
  addACourseString: "Add a Course",
  weeklyString: "Weekly",
  comingSoonString: "Coming Soon!",
  myLearningsString: "My Learnings",
  searchString: "Search",
  regularString: "Regular",
  instituteString: "Institute",
  noCoursesPurchasedYetString: "No courses purchased yet",
  noAdhocsPurchasedYetString: "No adhocs purchased yet",
  keepExploringToFindACourseString: "Keep Exploring to find a Course!",
  selectAllString: "Select All",
  seeMoreString: "See More",
  requestedTimingsString: "Requested Timings",
  createNewBatchString: "Create New Batch",
  monString: "Mon",
  acceptedString: "Accepted",
  theBatchHasBeenRescheduledToBelowTimingsString: `The batch has been rescheduled to below timings`,
  noUsersString: "No Users",
  step1BatchString: "Step 1 : Batch",
  step2DetailsString: "Step 2 : Details",
  updateBatchString: "Update batch",
  selectDayAndTimeString: "Select Day & Time",
  similarSubcategoriesYouMayLikeString: "Similar Subcategories you may like",
  topicDurationString: "Topic Duration",
  topicContentString: "Topic Content ",
  subtopicDescriptionString: "Subtopic Description",
  subTopicContentString: "SubTopic  Content",
  mediaFilesString: "Media Files",
  relatedDocumentsString: "Related Documents",
  levelsString: "Levels",
  categoriesAndSubcategoriesString: "Categories & Subcategories",
  lessonDescriptionString: "Lesson Description",
  sessionsString: "Sessions",
  selectCategoryAndSubcategoryString: "Select Category & Subcategory",
  passwordVerificationString: "Password Verification",
  forYourSecurityPleaseReEnterYourPasswordOrOTPToContinueString:
    "For your security, please enter your password for",
  passwordString: "Password",
  OTPVerificationString: "OTP Verification",
  pleaseEnterTheOTPSentToYourMobileNumberString:
    "Please enter the OTP sent to your mobile number.",
  OTPString: "OTP",
  resendOTPString: "Resend OTP",
  membersString: "Members",
  deactivationOrDeletionYourAautiAccountString:
    "Deactivate or delete your Aauti account",
  ifYouWantToTemporarilyCloseYourAccountYouCanDeactivateItIfYouWantToPermanentlyRemoveYourDataFromAautiYouCanDeleteYourAccountString:
    "If you want to temporarily close your account, you can deactivate it. If you want to permanently remove your data from Aauti, you can delete your account.",
  deleteAccountString: "Delete Account",
  deletingYourAccountIsPermanentAndIrreversibleOnceDeletedYouWontBeAbleToRetrieveYourAccountString:
    "Deleting your account is permanent and irreversible. Once deleted, you won't be able to retrieve your Account",
  feedbackString: "Feedback",
  addFeedbackString: "Add Feedback",
  quickLinksString: "Quick links",
  overviewString: "Overview",
  featuresString: "Features",
  solutionsString: "Solutions",
  helpAndSupportString: "Help & Support",
  teachWithAautiString: "Teach with Aauti",
  aboutUSString: "About US",
  careersString: "Careers",
  aautiForInstitutionsString: "Aauti For Institutions",
  blogString: "Blog",
  newsletterString: "Newsletter",
  getTheAppString: "Get the app",
  googlePlayString: "Google Play",
  appStoreString: "App Store",
  twentyTwentyThreeAautiAllRightsReservedString:
    "2023 Aauti, All rights reserved",
  privacyPolicyString: "Privacy Policy",
  termsAndConditionsString: "Terms & Conditions",
  replyString: "Reply",
  sortByString: "Sort by:",
  noCommentsString: "No Comments Yet! start the conversation ...",
  startCoversation: "start the conversation ...",
  availableSlots: "Available Slots",
  noResults: "No Results",
};
