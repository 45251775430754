import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { isEmpty } from "lodash";
import { Divider, IconButton, Tooltip } from "@mui/material";
import { MdOutlineEdit } from "react-icons/md";
import CourseDetailsStrings from "./CourseDeatilsStrings";
import "./index.scss";
import { globalColors } from "../../../themes/GlobalColors";
import { color } from "framer-motion";

const RefundSectionView = ({
  completeDetails,
  creation = false,
  isPackage = false,
  setRefundPolicyState,
  fromDetails = false,
  onClickEdit,
  onClickRefund,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {!fromDetails && (
        <div className="cancelationPolicy-con">
          <AautiText
            title={CourseDetailsStrings?.cancelPolicy}
            weight={"bold"}
          />
          <AautiText
            title={CourseDetailsStrings?.refundPolicy}
            size={"semi"}
            weight={"bold"}
            onClick={(e) => {
              e.stopPropagation();
              onClickRefund(setRefundPolicyState, completeDetails);
            }}
            className={"detailsLeft-text9"}
          />
        </div>
      )}

      {completeDetails?.isRefundAvailable &&
      !isEmpty(completeDetails?.refundConditions) ? (
        <div
          style={{
            display:
              completeDetails?.isRefundAvailable &&
              !isEmpty(completeDetails?.refundConditions)
                ? "block"
                : "none",
            border: "2px solid #f2f2f2",
            // height: "200px",
            width: "100%",
            marginBottom: 10,
            padding: 15,
            borderRadius: 6,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              paddingTop: "5px",
              paddingBottom: "5px",
              alignItems: "center",
              width: "100%",
            }}
          >
            <AautiText
              title={
                isPackage
                  ? "After Purchase of Package"
                  : "After Purchase of Course"
              }
              textAlign={"left"}
              textStyle={{
                marginBottom: 5,
                color: globalColors.HEADING_COLOR,
                width: creation ? "93%" : "75%",
              }}
            />
            <AautiText
              title={"% of Refund"}
              textAlign={"left"}
              textStyle={{
                // display: creation && "none",
                color: globalColors.HEADING_COLOR,
                width: "38%",
              }}
            />
            <Tooltip title="Edit" sx={{ display: !creation && "none" }}>
              <IconButton onClick={onClickEdit}>
                <MdOutlineEdit size={18} />
              </IconButton>
            </Tooltip>
          </div>
          {/* <Divider /> */}
          <div
            style={{
              gap: 2,
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {completeDetails?.refundConditions?.map((conditions) => {
              return (
                <div
                  style={{
                    display: conditions?.isSelected ? "flex" : "none",
                    flexDirection: "row",
                    width: "100%",
                    // justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 4,
                  }}
                >
                  <AautiText
                    title={CapitalizeFirstLetter(conditions?.name)}
                    textStyle={{
                      color: globalColors.HEADING_COLOR,
                      width: conditions?.key == -2 ? "100%" : "70%",
                    }}
                    size={"semi"}
                    textAlign={"left"}
                  />
                  <AautiText
                    textAlign={"left"}
                    textStyle={{
                      display: conditions?.key == -2 && "none",
                      color: globalColors.HEADING_COLOR,
                      width: "30%",
                    }}
                    title={`${conditions?.percentage || 0}%`}
                    size={"semi"}
                  />
                </div>
              );
            })}
          </div>
          {/* <AautiText
            textStyle={{ color: globalColors.HEADING_COLOR }}
            title={
              "Can cancel anytime after 1st class and amount will be refunded for all unused classes"
            }
          /> */}
        </div>
      ) : (
        <div
          className="doesnot-have-refund"
          style={{ display: isPackage && "none" }}
        >
          <AautiText
            textAlign={"center"}
            title={"This course does not have any refund"}
            // size={"semi"}
            textStyle={{
              padding: 10,
              width: "100%",
              color: globalColors.HEADING_COLOR,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RefundSectionView;
