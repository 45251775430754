import React from "react";
import AautiText from "../globalComponents/AautiText";
import { Avatar, Divider, Tooltip } from "@mui/material";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { isEmpty } from "lodash";
import { setCountryObject } from "../../redux/reducer/appReducer";
import { useDispatch, useSelector } from "react-redux";
import "./Style.scss";
import { globalColors } from "../../themes/GlobalColors";
import { FaCheck } from "react-icons/fa6";

const CountrySelect = ({
  countryObject,
  handleOnChange,
  filteredCountries,
  closeCountryPop,
}) => {
  const dispatch = useDispatch();
  const { mobileDevices } = useSelector((state) => state.responsive);

  return (
    <>
      <div className="CountrySelect-div1">
        <div className="CountrySelect-div2">
          <AautiText
            weight={"bold"}
            title={"Currency"}
            className={"CountrySelect-text1"}
          />
          <AautiText
            className={"CountrySelect-text1"}
            size={"small"}
            title={`${countryObject?.name} - ${
              countryObject?.countryCurrency ||
              countryObject?.countryCurrencySymbol ||
              "NA"
            }`}
          />
        </div>
        <div>
          <AautiTextInput
            maxLength={50}
            text={"Search"}
            onChange={(event) => {
              const value = event.target.value.trimStart().replace(/\s+/g, " ");
              handleOnChange(value);
            }}
          />
        </div>
      </div>
      <Divider />
      <div
        className="CountrySelect-div3"
        style={{ justifyContent: isEmpty(filteredCountries) && "center" }}
      >
        {filteredCountries?.map((each, index) => {
          return (
            <div
              onClick={() => {
                localStorage.setItem("country", each?.name);
                dispatch(setCountryObject(each));
                closeCountryPop();
              }}
              key={index}
              className="CountrySelect-div4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
                alignItems: "center",
                borderWidth: countryObject?.name === each?.name ? "2px" : "1px",
                borderColor:
                  countryObject?.name === each?.name
                    ? globalColors?.AAUTI_THEME_COLOR
                    : "lightgrey",
              }}
            >
              <div style={{ display: "flex", gap: 4 }}>
                <Avatar
                  variant="rounded"
                  alt={each?.name}
                  src={each?.flag}
                  className="Country-image"
                />

                <div>
                  <Tooltip title={each?.name} placement="bottom">
                    <div>
                      <AautiText
                        title={each?.name}
                        textAlign={"left"}
                        textStyle={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 1,
                          textOverflow: "ellipsis",
                          maxWidth: "100%",
                        }}
                        size={"semi"}
                        weight={"bold"}
                      />
                    </div>
                  </Tooltip>
                  <div style={{display:"flex",alignItems:"center"}}>
                  <AautiText
                    textAlign={"left"}
                    size={"small"}
                    title={`${each?.countryCurrency || each?.name} -`}
                  />
                  <AautiText
                    textAlign={"left"}
                    size={"small"}
                    fontFamily={"Sans-serief"}
                    title={` ${
                      each?.countryCurrencySymbol ||
                      each?.countryCurrency ||
                      "NA"
                    }`}
                  />
                  </div>
                </div>
              </div>
              {countryObject?.name === each?.name ? (
                <FaCheck color={globalColors?.AAUTI_THEME_COLOR}  style={{ marginRight:"10px" }}/>
              ) : (
                ""
              )}
            </div>
          );
        })}
        {isEmpty(filteredCountries) && (
          <div className="CountrySelect-div5" style={{height:!mobileDevices && 550 }}>
            <AautiText title={"No Countries available"} />
          </div>
        )}
      </div>
    </>
  );
};

export default CountrySelect;
