import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AautiText from "../globalComponents/AautiText";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Tooltip } from "@mui/material";
import ShareIcon from "../globalComponents/icons/ShareIcon";
import CourseImage from "../globalComponents/CourseImage";
import { returnIcon } from "../CommonFunctions";

const AdhocCard = ({ each, cardWidth = "269px" }) => {
  const path = window.location.origin;
  const [id, setId] = useState("");
  const { mobileDevices } = useSelector((state) => state.responsive);

  const navigate = useNavigate();
  const { innerWidth: windowInnerWidth } = window;

  const url = `${path}/adhoc-details/${each?._id}`;
  return (
    <Grid
      onMouseEnter={() => {
        setId(each?._id);
      }}
      onMouseLeave={() => {
        setId("");
      }}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/adhoc-details/${each?._id}`);
      }}
      style={{
        width: mobileDevices ? windowInnerWidth - 40 : cardWidth,
        // border: "1px solid lightgrey",
        borderRadius: 8,
        minWidth: cardWidth,
        maxWidth: cardWidth,
        height: "fit-content",
        // boxShadow:
        //   "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
        position: "relative",
        cursor: "pointer",
      }}
    >
      <div style={{ position: "relative", height: "160px" }}>
        <CourseImage
          imgUrl={
            each?.adhocImage ||
            "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
          }
          height="160px"
        />
      </div>
      <div style={{ marginTop: 6 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={each?.title}>
            <div style={{ width: "90%" }}>
              <AautiText
                title={each?.title?.trim() || "Name unavailable"}
                size={"semi"}
                weight={"bold"}
                textStyle={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  overflow: "hidden",
                  width: "100%",
                  height: 40,
                  lineHeight: "19px",
                  zIndex: 200,
                }}
              />
            </div>
          </Tooltip>

          {returnIcon(each)}
        </div>
        <div
          style={{
            width: "100%",
            marginTop: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <AautiText
              title={each?.displayName || "N/A"}
              size={"small"}
              weight={"normal"}
            />
          </div>
          <AautiText
            title={
              each?.courseLevel?.length > 3
                ? "All Levels"
                : each?.courseLevel?.join(", ") || ""
            }
            size={"small"}
            weight={"normal"}
            textStyle={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              width: "100%",
            }}
          />
          <AautiText
            title={
              each?.level?.length > 3
                ? "All Levels"
                : each?.level?.join(", ") || "N/A"
            }
            size={"small"}
            weight={"normal"}
            textStyle={{ marginBottom: 4 }}
          />

          <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
            <AautiText
              title={`Starts from:`}
              size={"small"}
              weight={"normal"}
              textStyle={{ marginBottom: 4 }}
            />
            <AautiText
              title={`${moment(each?.startDate).format("DD MMM YYYY")}`}
              size={"small"}
              weight={"bold"}
              textStyle={{ marginBottom: 4 }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              overflow: "hidden",
              overflowX: "scroll",
              gap: 4,
              marginTop: 5,
              cursor: "pointer",
              width: "90%",
            }}
          >
            {each?.categories?.map((cat, index) => (
              <div
                key={index}
                style={{
                  padding: "4px 8px",
                  borderRadius: 4,
                  backgroundColor: "#F9EF99",
                  width: "max-content",
                  minWidth: "max-content",
                }}
              >
                <AautiText
                  size={"small"}
                  title={cat?.subCategoryName}
                  textStyle={{ color: "#000" }}
                />
              </div>
            ))}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ShareIcon shareUrl={url} />
          </div>
        </div>
      </div>
    </Grid>
  );
};

export default AdhocCard;
