import React from "react";
import UserCard from "../../course/courseDetails/UserCard";
import JoinButton from "../../globalComponents/JoinButton";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";
import { Avatar, Tooltip } from "@mui/material";
import { getFirstTwoChars, returnColorbasedName } from "../../CommonFunctions";
import { FaCheck } from "react-icons/fa";
import { globalColors } from "../../../themes/GlobalColors";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import moment from "moment";
import gigStrings from "../gigStrings";

function GigRightContainer({
  gigData,
  hasChilds,
  handleSelectUser,
  isChild,
  buyNowAndCartVal,
  handleCart,
  gigDetails,
  completerDetails,
  isParent,
  buyNowVal,
  childDetails,
  showUser = true,
  checkisInCart,
}) {
  const { mobileDevices, miniLapys, ipodDevices, lapy } = useSelector(
    (state) => state.responsive
  );

  const { isUserLoggedIn } = useSelector((state) => state.app);
  const navigate = useNavigate();

  const price = (obj) => {
    if (obj[2]) {
      return (
        <div>
          <AautiText
            textStyle={{
              color: globalColors.AAUTI_THEME_COLOR,
              fontFamily: "sans-serif",
            }}
            weight={"bold"}
            title={
              <p>
                <span
                  style={{
                    textDecoration: "line-through",
                    marginRight: 10,
                    color: globalColors.HEADING_COLOR,
                    fontWeight: 400,
                    display: obj[0] == obj[1] && "none",
                  }}
                >
                  {obj[0]}
                </span>
                {obj[1]}
              </p>
            }
          />
          {obj[0] !== obj[1] && (
            <AautiText
              title={`${obj[3]} at this price!`}
              size={"semi"}
              textStyle={{ color: "#DC3545" }}
            />
          )}
        </div>
      );
    } else {
      return (
        <AautiText
          textStyle={{
            color: globalColors.AAUTI_THEME_COLOR,
            fontFamily: "sans-serif",
          }}
          weight={"bold"}
          title={obj[0]}
        />
      );
    }
  };
  return (
    <div
      // className="gigPreview-div15"
      style={{
        width: mobileDevices || ipodDevices ? "98%" : "35%",
        height:
          mobileDevices || ipodDevices ? "auto" : showUser ? "86vh" : "80vh",
        marginBottom: 10,
        display: "flex",
        flexDirection: "column",
        overflow: !(mobileDevices || ipodDevices) && "scroll",
      }}
      // style={{
      //   width: (mobileDevices || ipodDevices) && "98%",
      //   height: (mobileDevices || ipodDevices) && "auto",
      //   marginBottom: (mobileDevices || ipodDevices) && "10px",
      // }}
    >
      {/* <h1>ghjghj</h1> */}
      <div className="gigPreview-div16">
        <div className="gigPreview-div17">
          {gigData.map((each, index) => (
            <div
              key={index}
              className="gigPreview-div18"
              style={{
                display:
                  (each?.leftContent == "Purchase For" &&
                    isParent &&
                    each?.rightData?.length == 0) ||
                  hasChilds(each)
                    ? "none"
                    : ((each?.leftContent == "Bought For" && !isParent) ||
                        each?.rightData?.length == 0) &&
                      "none",
              }}
            >
              <AautiText
                title={
                  each?.leftContent == "Age Group" ? (
                    <p>
                      {each.leftContent}{" "}
                      {/* <span
                        style={{
                          color: "#5C5B57",
                          fontSize: 13,
                          fontWeight: 400,
                        }}
                      >
                        (in Years)
                      </span> */}
                    </p>
                  ) : (
                    each?.leftContent
                  )
                }
                size={"normal"}
                weight={"bold"}
                className={"gigPreview-text14"}
              />
              {each.leftContent === "Purchase For" ||
              each.leftContent === "Bought For" ? (
                <div
                  key={index}
                  className="gigPreview-div19"
                  style={{
                    flexWrap: "wrap",
                    width: "50%",
                    gap: 5,
                  }}
                >
                  {each?.rightData?.map((item, index) => {
                    const isSelected = childDetails?.filter(
                      (item2) => item2?._id == item?._id
                    );
                    return (
                      <div
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          each.leftContent != "Bought For" &&
                            handleSelectUser(item, index);
                        }}
                        style={{
                          cursor:
                            each?.leftContent !== "Bought For" && "pointer",
                        }}
                      >
                        <Tooltip title={item?.displayName}>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Avatar
                              disabled={true}
                              sx={{
                                bgcolor:
                                  !item?.profileImage &&
                                  each?.leftContent !== "Bought For" &&
                                  returnColorbasedName(item?.displayName),
                                height: 32,
                                width: 32,
                              }}
                              key={index}
                              src={item?.profileImage}
                              alt={item?.displayName}
                            >
                              {!item?.profileImage &&
                                getFirstTwoChars(item?.displayName)}
                            </Avatar>

                            {isSelected?.length > 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "#222222",
                                  // backdropFilter: "blur(1px)",
                                  opacity: 0.7,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <FaCheck size={20} color="#598f00" />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              ) : each?.leftContent === "Levels" ||
                each?.leftContent.includes("Age Group") ? (
                <div className="gigLevels-text1">
                  {each?.rightData?.map((item, index) => (
                    <AautiText
                      size={"semi"}
                      weight={"medium"}
                      key={index}
                      textStyle={{
                        color: globalColors.AAUTI_THEME_COLOR,
                        cursor: "pointer",
                        display: "block",
                        textDecoration: "underline",
                      }}
                      title={`${item}${
                        index < each?.rightData?.length - 1 ? "," : ""
                      }`}
                      onClick={() => {
                        if (showUser) {
                          navigate("/search-result", {
                            state: {
                              [each.leftContent]: item,
                              activeType: "Gigs",
                            },
                          });
                        }
                      }}
                    />
                  ))}
                </div>
              ) : each?.leftContent === "Price" ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                >
                  {price(each?.rightData)}
                </div>
              ) : (
                <AautiText
                  title={each?.rightData?.join(", ")}
                  size={"semi"}
                  className={"gigPreview-text14"}
                />
              )}
            </div>
          ))}
        </div>
        {gigDetails?.isPurchased == true && (
          <AautiText
            size={"small"}
            textStyle={{ color: globalColors.AAUTI_THEME_COLOR }}
            title={`Last Purchased on : ${moment(
              gigDetails?.lastPurchasedDate
            ).format("DD MMM YYYY")}`}
          />
        )}
        <div
          className="gigPreview-div20"
          style={{ display: !showUser && "none" }}
        >
          <JoinButton
            id={
              gigDetails?.addedToCart
                ? "gig-details-remove-from-cart"
                : "gig-details-add-to-cart"
            }
            title={
              isUserLoggedIn
                ? gigDetails?.addedToCart
                  ? gigStrings?.removeFromCart
                  : gigStrings?.addToCart
                : checkisInCart()
                ? gigStrings?.removeFromCart
                : gigStrings?.addToCart
            }
            disabled={buyNowAndCartVal()}
            width={isChild ? "100%" : "49%"}
            outlined
            onClick={() => {
              handleCart(gigDetails);
            }}
          />
          {!isChild && (
            <JoinButton
              id={"Gig-details-buyNow"}
              title={gigStrings?.buyNow}
              disabled={buyNowVal()}
              width={"49%"}
              onClick={() => {
                if (showUser) {
                  if (isUserLoggedIn) {
                    handleCart(gigDetails, "buynowitm");
                    // buyNow();
                  } else {
                    localStorage.setItem(
                      "prevPath",
                      `/gig-preview/${gigDetails?._id}`
                    );
                    navigate("/login");
                  }
                }
              }}
            />
          )}
        </div>
      </div>
      {showUser && (
        <UserCard {...{ completeDetails: completerDetails, type: "Gig" }} />
      )}
      {/* <div className="advertisement-container-gigPreview">
        <AautiText
          title={"Advertisement Loading"}
          textStyle={{ color: globalColors.HEADING_COLOR }}
        />
      </div> */}
    </div>
  );
}

export default GigRightContainer;
