import { useEffect, useState } from "react";
import ConfirmationDialogRaw from "../../globalComponents/ConfirmationDialogRaw";
import { CapitalizeFirstLetter, PriceConversions } from "../../CommonFunctions";
import AautiText from "../../globalComponents/AautiText";
import { IconButton, Tooltip } from "@mui/material";

import { Icon } from "../../appCards/AdhocEventsCard";
import ScheduleIcon from "../../../assets/images/schedule.png";
import ClockIcon from "../../../assets/images/Clock-Icon.png";
import Virtualicon from "../../../assets/images/monitor.png";
import InPersonIcon from "../../../assets/images/drop-inperson.png";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setAdhocCourseData } from "./courseAdhocSlice";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import { formatStartandEndTime } from "../../../utils/Helpers";

const CourseAdhocCard = ({ each, onPressDelete }) => {
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { convertPriceWithCurrency } = PriceConversions();

  const calculateMotion = (width = 1) => {
    return parseInt(width / 100) * 1300;
  };

  useEffect(() => {
    const box = document.getElementById(each?._id + "textBoxCourseAdhoc");
    const keyFrames = [
      { transform: "translateX(0%) " }, //0%
      { transform: "translateX(-100%)" }, // 100%
    ];

    if (box) {
      const box1Width = box?.getClientRects();
      const firstChild = box?.children[0];
      if (firstChild) {
        if (firstChild.scrollWidth > box1Width[0].width) {
          const KeyFrameConstructure = new KeyframeEffect(
            firstChild,
            keyFrames,
            {
              duration: calculateMotion(firstChild.scrollWidth),
              iterations: Infinity,
            }
          );
          const a = new Animation(KeyFrameConstructure);
          firstChild.addEventListener("mouseover", () => {
            a.play();
          });

          firstChild.addEventListener("mouseleave", () => {
            a.cancel();
          });
        }
      }
    }
  }, []);

  return (
    <div className="events-card">
      <Tooltip title={CapitalizeFirstLetter(each?.title)}>
        <div className="card-head-dots">
          <AautiText
            title={CapitalizeFirstLetter(each?.title) || "Title"}
            weight={"bold"}
            textStyle={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
            }}
          />
        </div>
      </Tooltip>
      <div className="events-card-data">
        <AautiText
          title={`${each?.courseName} ${each?.courseName}`}
          weight={"normal"}
          textStyle={{
            marginBottom: 8,
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            overflow: "hidden",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 12,
            alignItems: "center",
            marginBottom: 8,
          }}
        >
          <Icon icon={ScheduleIcon} />
          <AautiText
            textStyle={{ color: "#5C5B57" }}
            title={
              moment(each?.startDate).format("DD MMM YYYY") +
              " - " +
              moment(each?.endDate).format("DD MMM YYYY")
            }
          />
        </div>
        <div
          style={{
            gap: 12,
            marginBottom: 8,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Icon icon={ClockIcon} />
          <AautiText
            textStyle={{
              color: "#5C5B57",
              maxWidth: "350px",
              textWrap: "nowrap",
              overflow: "scroll",
              maxWidth: "400px",
            }}
            title={formatStartandEndTime({
              start: each?.startTime,
              end: each?.endTime,
            })}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 12,
          }}
        >
          {each?.virtual && (
            <Tooltip title="Virtual">
              <IconButton
                sx={{
                  p: 0,
                  "&:hover": { background: "#fff" },
                }}
              >
                <img
                  src={Virtualicon}
                  alt="virtual"
                  style={{
                    height: "18px",
                    width: "18px",
                    zIndex: 100,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {each?.inPerson && (
            <Tooltip title={"In Person"}>
              <IconButton sx={{ p: 0 }}>
                <img
                  src={InPersonIcon}
                  alt="inPerson"
                  style={{
                    height: "18px",
                    width: "18px",
                    zIndex: 100,
                  }}
                />
              </IconButton>
            </Tooltip>
          )}

          <AautiText
            title={convertPriceWithCurrency(each?.price || 0, 2, true)}
            textStyle={{ fontFamily: "sans-serif" }}
          />
        </div>
        <div className="hover-scroll" id={each?._id + "textBoxCourseAdhoc"}>
          <AautiText
            title={`${each?.batches
              ?.map((batch, index) => batch.batchName)
              .join(", ")}`}
            className={"scrolltext"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            marginTop: "auto",
          }}
        >
          <button
            onClick={(e) => {
              e.stopPropagation();
              setOpenAlert(true);
            }}
            style={{
              background: "none",
              border: "none",
              padding: "8px 10px",
              cursor: "pointer",
            }}
            disabled={each?.isPurchased}
          >
            <AautiText
              title={"Delete"}
              weight={"bold"}
              textStyle={{
                color: each?.isPurchased ? "lightgrey" : "#3083EF",
              }}
            />
          </button>
          <button
            onClick={(e) => {
              dispatch(setAdhocCourseData(each));
              navigation("/course-adhoc-creation?isEdit=true");
            }}
            style={{
              background: "none",
              border: "none",
              padding: "8px 10px",
              cursor: "pointer",
            }}
            disabled={each?.isPurchased}
          >
            <AautiText
              title={"Edit"}
              weight={"bold"}
              textStyle={{
                color: each?.isPurchased ? "lightgrey" : "#3083EF",
              }}
            />
          </button>
        </div>
      </div>
      <ConfirmationDialogRaw
        id="ringtone-menu"
        keepMounted
        open={openAlert}
        onClick={(e) => {
          e.stopPropagation();
          onPressDelete(each?._id);
          setOpenAlert(false);
        }}
        onClose={() => {
          setOpenAlert(false);
        }}
      />
    </div>
  );
};

export default CourseAdhocCard;
