import React, { useEffect, useState } from "react";
import { EnUserFeedback, ServiceProviderFeedback } from "./Services";
import { useSelector } from "react-redux";
import AautiText from "../globalComponents/AautiText";
import "./styles.scss";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { isEmpty } from "lodash";
import EmptyTopic from "../globalComponents/EmptyTopic";
import EmptyFeedback from "../../assets/images/EmptyFeedback.png";
import { useNavigate } from "react-router-dom";
import AppContainer from "../AppContainer";
import AccountTabs from "../globalComponents/AccountTabs";
import { AautiStrings } from "../globalComponents/AautiStrings";
import { Skeleton } from "@mui/material";

const Feedback = (props) => {
  const { UserRole, loggedinUserId } = useSelector((state) => state.app);
  const [feedbackList, setFeedbackList] = useState([]);
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate();
  const onSuccess = (res) => {
    if (res?.status == "Success") {
      setLoading(false)
      setFeedbackList(res?.result);
    }
  };
  const onFailure = (err) => {
    console.log(err);
    setLoading(false)
  };
  useEffect(() => {
    setLoading(true)
    if (UserRole == "serviceprovider") {
      ServiceProviderFeedback(loggedinUserId, onSuccess, onFailure);
    } else {
      EnUserFeedback(loggedinUserId, onSuccess, onFailure);
    }
  }, [UserRole]);
  const returntime = (time) => {
    const formattedTime = moment.utc(time).local().startOf("seconds").fromNow();
    return formattedTime || "NA";
  };
  const { mobileDevices } = useSelector((state) => state.responsive);

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: `${AautiStrings?.feedbackString}`,
            navigation: "/feedback",
            active: true,
          },
        }}
      />
      <div className="mainContainer-feedback">
        {loading ?
          <div className="tabs-flex ">
            {[1, 2, 3, 4, 5, 6]?.map((each, index) => (
              <Skeleton variant="rounded"
                width={mobileDevices ? "100%" : 300}
                height={mobileDevices ? 80 : 140} />
            ))}
          </div> :
          <div className="feedbacks-list-container">
            {isEmpty(feedbackList) ? (
              <EmptyTopic
                topicName={`${UserRole === "serviceprovider"
                  ? "You haven't added any Feedback yet."
                  : "You haven't received  any Feedback yet."
                  }`}
                Image={EmptyFeedback}
                showButton={false}
                buttonName={AautiStrings?.addFeedbackString}
                onClick={() => navigate("/feedback")}
              />
            ) : (
              <>
                {feedbackList?.map((each, index) => (
                  <div key={index} className="feedback-item">
                    <div className="feedback-main-div2">
                      <Avatar
                        sx={{
                          width: mobileDevices ? 45 : 60,
                          height: mobileDevices ? 45 : 60,
                        }}
                        alt={each?.serviceProviderName}
                        src={UserRole !== "serviceprovider" ?
                          each?.serviceProviderImage : each?.endUserImage}
                      />
                      <div className="feedback-child-div">
                        <div className="feedback-child-div2 ">
                          <AautiText
                            size={"medium"}
                            weight={"bold"}
                            title={UserRole !== "serviceprovider" ?
                              each?.serviceProviderName :
                              each?.endUserName}
                          />
                          <AautiText
                            size={"semi"}
                            title={returntime(each?.createdAt)}
                          />
                        </div>
                        <AautiText
                          weight={"semi"}
                          title={each?.name}
                          className={"feedback-name-text"}
                        />
                      </div>
                    </div>
                    <AautiText
                      size={"semi"}
                      title={each?.comment}
                      className={"feedback-comment-text"}
                    />
                  </div>
                ))}
              </>
            )}
          </div>}
      </div>
    </AppContainer>
  );
};

export default Feedback;
