import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import Badge from "@mui/material/Badge";

export const StyledMsgContainer = styled.div`
    display: flex;
    flex-direction:column;
    row-gap: 4px;
    column-gap: 4px;
    width:100%;
`

export const BootstrapDialog = muiStyled(Dialog)(({ theme }) => ({
  "& .css-bdhsul-MuiTypography-root-MuiDialogTitle-root":{
    padding:0,
  },
  "& .MuiPaper-root.MuiDialog-paper.css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
      width:'100%',
      [theme.breakpoints.up('sm')]:{
        width:450,
      }
  },
  "& MuiDialogContent-root":{
    padding:16,
  },
  "& .css-ypiqx9-MuiDialogContent-root":{
    paddingTop:'16px !important',
  },
  "& .MuiDialogActions-root": {
    padding: 16,
  },
}));

export const StyledBadge = muiStyled(Badge)(({ onlineStatus }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: onlineStatus ? "#44b700" : "#ff0000",
    color: onlineStatus ? "#44b700" : "#ff0000",
    boxShadow: `0 0 0 2px #ffffff`,
    "&::after": {
      position: "relative",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: onlineStatus? "ripple 1.2s infinite ease-in-out":'none',
      border: onlineStatus? "1px solid currentColor":'none',
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));