import moment from "moment";

export const currentDate = new Date();
export const firstDayOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth(),
  1
);
export const lastDayOfMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  0
);
export const formattedFirstDay = `${firstDayOfMonth.getFullYear()}-${(
  firstDayOfMonth.getMonth() + 1
)
  .toString()
  .padStart(2, "0")}-${firstDayOfMonth?.getDate().toString().padStart(2, "0")}`;
export const formattedLastDay = `${lastDayOfMonth.getFullYear()}-${(
  lastDayOfMonth.getMonth() + 1
)
  .toString()
  .padStart(2, "0")}-${lastDayOfMonth?.getDate().toString().padStart(2, "0")}`;

export function filterEventsByDateRange(events, startDate, endDate) {
  return events.filter(
    (event) =>
      moment(event.start || event.startTime) >= moment(startDate) &&
      moment(event.end || event.endTime) <= moment(endDate)
  );
}

export function handleSelect(event, args) {
  const { eventsData, setSelectedEvents, selectedDate, setSelctedDate } = args;
  document.querySelectorAll(".selected-cell").forEach((cell) => {
    cell.classList.remove("selected-cell");
  });

  // Add the 'selected-cell' class to the selected cell
  let selectedDate2 = event.start;
  let selectedCell = document.querySelector(`[data-date='${selectedDate2}']`);
  selectedCell?.classList.add("selected-cell");

  const formattedDate = moment(event?.start).format("DD-MM-YYYY");
  setSelctedDate(formattedDate);
  const filteredEvents = eventsData.filter(
    (data) =>
      moment(data?.start)?.format("DD-MM-YYYY") ===
      moment(event?.start).format("DD-MM-YYYY")
  );
  setSelectedEvents(filteredEvents);
}

function formatTime(time, hours) {
  return new Date(new Date(time).setHours(new Date(time).getHours() + hours));
}

function isNextDay(startTime, endTime) {
  return moment(startTime).format("DD") !== moment(endTime).format("DD");
}

function adjustEndTime(endTime, isNextDay) {
  if (isNextDay) {
    const extraMinutes = moment(endTime).format("mm");
    return moment(endTime)
      .subtract(extraMinutes, "minutes")
      .subtract(1, "seconds")
      .local()
      .format();
  }
  return endTime;
}

export function getFormattedSessionObject(event) {
  const { startTime, endTime, courseName, description } = event;
  const formattedStartTime = formatTime(startTime, -3);
  const formattedEndTime = formatTime(endTime, 3);
  const isNextDay_ = isNextDay(formattedStartTime, formattedEndTime);
  const adjustedEndTime = adjustEndTime(formattedEndTime, isNextDay_);

  return {
    // start: formattedStartTime,
    // end: adjustedEndTime,
    // start: new Date(event?.date),
    // end: new Date(event?.date),
    start: new Date(event.startTime),
    end: new Date(event.endTime),
    title: courseName || "N/A",
    color: "#eff0fe",
    ...event,
  };
}

export const formattedDates = (date) => {
  const originalDate = new Date(date);

  originalDate.setDate(1);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formattedLastDate = (date) => {
  const presentDate = new Date(date);
  const lastOfMonth = new Date(
    presentDate.getFullYear(),
    presentDate.getMonth() + 1,
    0
  );
  const formattedLastDate = `${lastOfMonth.getFullYear()}-${(
    lastOfMonth.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}-${lastOfMonth?.getDate().toString().padStart(2, "0")}`;

  return formattedLastDate;
};

export const formattedLastWeekDate = (date) => {
  const originalDate = new Date(date);
  originalDate.setDate(originalDate.getDate() + 7);
  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};
