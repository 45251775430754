export const courseConstant = {
  levels: "Levels",
  category: "Category",
  courseType: "Course Type",
  coursePricing: "Course Pricing",
  location: "Location",
  age: "Age",
  searchTags: "Search Tags",
  selectALocation: "Select a location",
  addALocation: "Add a location",
  pickARadiusFromTheDesiredLocation: "Pick a radius from the desired location",
  editLocationAndFixRadius: "Edit Location & fix Radius",
  courseOverview: "Overview",
  courseTitle: "Title",
  Allowsyoutoteachusersacrossdifferentskilllevels:
  "Allows you to teach users across different skill levels.",
  categoryAndSubcategory: "Category and Subcategory",
  categoryAndSubCategoryRequired: "Category & subcategory required",
  courseTypeRequired: "Course Type required",
  selectGroupLanguage: "Select Group language",
  selectLanguages: "Select Languages",
  classType: "Class Type",
  Picktheagegroupyoudliketoteach:
    "Pick the age group you'd like to teach.",
  courseTags: "Tags",
  addUptTo10KeywordsThatItWillHelpPeopleToDiscoverTheCourse:
    "Add up to 10 keywords to enhance the visibility and searchability of your course.",
  upTo10Tags: "Up to 10 tags - (minimum 3 characters)",
  shortDescription: "Short Description",
  courseSummary: "Course Summary",
  enterNoOfSessions: "No of Sessions",
  addAtHowManySessionsYourCourseIsGoingToEnd:
    "add at how many sessions your course is going to end",
  selectedDays: "Selected Days",
  selectedDates: "Selected Dates",
  step3Pricing: "Step:3 Pricing",
  selectBatchType: "Select Batch Type",
  conflicts: "Conflicts",
  AddMoreFamilyMembers : "Add More Family Members",
  Getadiscountforeveryadditionalmember:
    "Get a discount for every additional member. ",
  discountType: "Discount Type",
  selectDiscountValidDates: "Select Discount Dates",
  addToExceptionsList: "Add to Excluded Dates",
  Createacoursewithclearobjectivesandengaginginteractivecontenttoenhanceskills:
    "Create a course with clear objectives and engaging, interactive content to enhance skills.",
  Createagigwithclearobjectivesanddesignedcontentaimedtoenhanceskill:
  "Create a gig with clear objectives and designed content aimed to enhance skill.",
  previewCourse: "Preview",
  discard: "Discard",
  onceSubmittedItWillBeUnderReview: "Once Submitted it will be under review",
  Oncethecourseispurchased : "Once the course is purchased",
  allTheOngoingAndFutureSessionsWillBeCancelledDoYouWantToProceedString:
    "All ongoing and upcoming sessions will be canceled. Are you sure you want to continue?",
  deactivationOrDeletion: "Deactivate or Delete",
  createYourOwnLessonPlan: "Create Your Own Lesson Plan",
};
