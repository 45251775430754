import React, { useEffect, useState } from "react";
import "./styles.scss";
import CourseFilters from "../home/upcomming/CourseFilters";
import { useSelector } from "react-redux";
import noDataImages from "../../assets/images/no-image.svg";
import AautiText from "../globalComponents/AautiText";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCoursesCountByCategoryAndSubcategory,
  getGigsCountByCategoryAndSubcategory,
} from "./Service";
import gigStrings from "../gig/gigStrings";
import { AautiStrings } from "../globalComponents/AautiStrings";
import { AuthService } from "../ordersScreen/service";
import moment from "moment";
import { transformedObject } from "../CommonFunctions";

const CourseCategories = () => {
  const { selectedCategory, categories } = useSelector((state) => state.app);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { state } = useLocation();
  const type = state?.type || state;
  const [hoveredInd, setHoveredInd] = useState();
  const [similarSubCat, setSimilarSubCat] = useState([]);
  const [searchInput, setSearchInput] = useState(state.searchInput || "");
  const [apiFilters, setApiFilters] = useState(state.filterKeys || {});

  const navigate = useNavigate();
  const getSubCategories = (value = searchInput, filter = apiFilters) => {
    let newFilter = transformedObject(filter || {});
    if (state?.isOrder) {
      if (type == "course") {
        AuthService?.getCoursesCountByCategories(
          {
            categoryName: selectedCategory,
            subCategoryName: [],
            timeZone: moment().utcOffset(),
            searchKey: value,
            count: 100,
            offset: 0,
            ...newFilter,
          },
          (response) => {
            setSimilarSubCat(response?.result);
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        AuthService.getGigsCountByCategoryAndSubcategory(
          {
            categoryName: selectedCategory?.category,
            subCategoryName: selectedCategory?.subCategory,
            searchKey: value,
            count: 100,
            offset: 0,
            ...newFilter,
          },
          (response) => {
            setSimilarSubCat(response?.result);
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else if (type == "course") {
      getCoursesCountByCategoryAndSubcategory(
        {
          categoryName: selectedCategory?.categoryName,
          subCategoryName: selectedCategory?.subCategoryName,
          searchKey: value,
          count: 100,
          offset: 0,
          ...newFilter,
        },
        (res) => {
          if (res?.status === gigStrings?.success) {
            setSimilarSubCat(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      getGigsCountByCategoryAndSubcategory(
        {
          categoryName: selectedCategory?.categoryName,
          subCategoryName: selectedCategory?.subCategoryName,
          searchKey: value,
          count: 100,
          offset: 0,
          ...newFilter,
        },
        (res) => {
          if (res?.status === gigStrings?.success) {
            setSimilarSubCat(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };
  const onChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
    navigate(".", {
      replace: true,
      state: { ...state, searchInput: e.target.value },
    });
  };

  useEffect(() => {
    getSubCategories();
  }, [searchInput]);

  const handleClickApply = (filterKeys) => {
    setApiFilters(filterKeys);
    getSubCategories("", filterKeys);
    navigate(".", { replace: true, state: { ...state, filterKeys } });
  };
  const filteredItems = categories?.filter(
    (each) => each.categoryName === selectedCategory
  );
  return (
    <div className="ccMainCont" style={{ marginTop: mobileDevices && "10px" }}>
      <div
        className="ccMainSub"
        style={{
          flexDirection: mobileDevices ? "column" : "row",
          // justifyContent: "center",
        }}
      >
        <div
          className="filtersCCCOnt"
          style={{
            width: mobileDevices ? "99%" : "25%",
            border: "1px solid lightgrey",
            borderRadius: "4px",
          }}
        >
          <CourseFilters
            actFilters={apiFilters}
            screenName={
              type == "gig" ? "gigsBySubcategory" : "courseBySubcategory"
            }
            searchValue={searchInput}
            onChangeSearchValue={onChangeSearchInput}
            onCloseSearch={() => {
              setSearchInput("");
            }}
            handleClickApply={handleClickApply}
          />
        </div>
        <div
          className="CCSubCateCont"
          style={{ width: mobileDevices && "100%" }}
        >
          <AautiText
            title={AautiStrings?.similarSubcategoriesYouMayLikeString}
            size={mobileDevices ? "extraMedium" : "large"}
            weight={"extraBold"}
            textStyle={{ color: "#002058", marginBottom: 15 }}
          />
          <div className="categories-map-gig">
            {!isEmpty(similarSubCat) ? (
              similarSubCat[0]?.subCategory.map((each, index) => (
                <>
                  {each?.count > 0 && (
                    <div
                      key={index + "./"}
                      className="category-box-gig"
                      onMouseEnter={() => setHoveredInd(index)}
                      onMouseLeave={() => setHoveredInd(-1)}
                      onClick={() => {
                        const newArray = {
                          subCategory: each?.subCategoryName,
                          category: selectedCategory.categoryName,
                        };
                        type == "gig"
                          ? navigate(`/${each?.subCategoryName}/gigs`, {
                              state: [each?.subCategoryName],
                            })
                          : navigate(`/${each?.subCategoryName}/courses`, {
                              state: newArray,
                            });
                      }}
                    >
                      <img
                        src={each?.subCategoryImage || noDataImages}
                        alt=""
                      />

                      <div className="subcategory-count-gig">
                        <AautiText
                          title={each?.subCategoryName}
                          size={"semi"}
                          textAlign={"center"}
                          weight={"bold"}
                          className={"courseRating-text1"}
                        />
                      </div>
                      {hoveredInd === index && (
                        <AautiText
                          title={
                            type == "gig"
                              ? `(Gigs - ${each?.count})`
                              : `(Courses - ${each?.count})`
                          }
                          textAlign={"center"}
                          size={"semi"}
                          weight={"normal"}
                          className={"detailsComp-text1"}
                        />
                      )}
                    </div>
                  )}
                </>
              ))
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "60vh",
                }}
              >
                <AautiText
                  title={"No Categories Found"}
                  size={"normal"}
                  weight={"extraBold"}
                  textAlign={"center"}
                  textStyle={{ color: "#1E1E1E" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCategories;
