import ProfileMenu from "./ProfileMenu";
// import Popover from "@mui/material/Popover";

import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Popover,
  Tooltip,
  styled,
  tooltipClasses,
} from "@mui/material";
import "./Style.scss";
import Notifications from "../home/notifications/index";
import { useSelector } from "react-redux";
import {
  setHasEvents,
  setReloadCart,
  setTodayEvents,
  setToDoList,
  setTodosLoading,
} from "../../redux/reducer/appReducer";
import {
  HeaderCartIcon,
  HeaderChatIcon,
  HeaderChatInActive,
  HeaderNotificationsIcon,
  HeaderNotificationsInActive,
  HeaderSettingsIcon,
} from "../homeScreen/header/pageHeader/pageHeaderUtils";
import { getUnreadCount, setActiveRoom } from "../../redux/reducer/chatSlice";
import { useLocation } from "react-router-dom";
import todoSvg from "../home/main/assets/todo.svg";
import TodoListDialog from "../home/dialogs/TodoListDialog";
import { useCallback, useEffect, useState } from "react";
import { AuthService } from "../home/Service";
import moment from "moment";
import AautiText from "../globalComponents/AautiText";
import { globalColors } from "../../themes/GlobalColors";

const HeaderTabsLogin = ({
  handleMenuClose,
  menuOpen,
  userDetails,
  FirstName,
  userRole,
  handleUserRole,
  UserRole,
  navigation,
  logoutFunction,
  mobileDevices,
  handleCloseNot,
  anchorElNot,
  id,
  openNot,
  handleClickNot,
  unreadCount,
  loadingCountNot,
  handleMenuClick,
  countryObject,
  setToggleCurrency,
  setAnchorElNot,
  cartCount,
  handleOpenConfirmation,
  profilePercentage,
  dispatch,
  relaodCart,
  pathname,
  eventsResponsiveHandle,
}) => {
  const chatCount = useSelector(getUnreadCount);
  const { hasEvents, todayEvents, loggedinUserId, toDoList } = useSelector(
    (state) => state.app
  );
  const [openTodayEvent, setOpenTodatEvents] = useState(false);
  const endDate = moment().endOf("day").utc().format();
  const startDate = moment().startOf("day").utc().format();

  const toggleOpenTodoDialog = useCallback(() => {
    setOpenTodatEvents((value) => {
      const newValue = !value;
      if (newValue) {
        dispatch(setTodosLoading(true));

        AuthService.getSessionApi(
          UserRole,
          loggedinUserId,
          endDate,
          startDate,
          (res) => {
            dispatch(setTodosLoading(false));
            if (
              res?.status == "Success" &&
              res?.result &&
              res?.message == "Session(s) found"
            ) {
              let hasEvents =
                res?.result?.filter(
                  (eachItem) =>
                    new Date() < moment(eachItem?.endTime).utc() &&
                    eachItem?.scheduleType != "offTime" &&
                    eachItem?.usersList?.length > 0
                ) || [];
              dispatch(setHasEvents(hasEvents.length > 0));
              dispatch(setTodayEvents(hasEvents));
              dispatch(setTodosLoading(false));
            } else {
              dispatch(setTodayEvents(null));
              dispatch(setTodosLoading(false));
            }
          },
          (err) => {
            dispatch(setTodosLoading(false));
            console.log(err);
          }
        );
        AuthService.getToDoList(
          loggedinUserId,
          (res) => {
            if (res?.status == "Success") {
              dispatch(setToDoList(res?.result));
              dispatch(setTodosLoading(false));
            } else {
              dispatch(setTodosLoading(false));
            }
          },
          (err) => {
            dispatch(setTodosLoading(false));
            console.log(err);
          }
        );
      }
      return newValue;
    });
  }, [loggedinUserId, dispatch, UserRole]);

  const location = useLocation();

  const onClickChat = () => {
    if (!(location.pathname === "/chat")) {
      navigation("/chat");
      dispatch(setActiveRoom({ data: undefined }));
    }
  };

  const navigateToTodoOptions = (route) => {
    navigation(route);
  };

  return (
    <div className="HeaderTabLogin-div1">
      <Tooltip title="To-Do list">
        <IconButton onClick={toggleOpenTodoDialog}>
          <img
            src={todoSvg}
            alt="Left-arrow"
            style={{ height: 30, width: 30, cursor: "pointer" }}
          />
        </IconButton>
      </Tooltip>
      <div
        className="HeaderTabLogin-div1"
        style={{
          gap: mobileDevices ? 4 : 6,
        }}
      >
        <Tooltip title="Cart">
          <IconButton
            onClick={() => {
              // navigation("/cart");
              if (pathname !== "/cart") {
                navigation("/cart");
              } else {
                dispatch(setReloadCart(!relaodCart));
              }
            }}
            style={{ marginLeft: mobileDevices ? 0 : -4 }}
          >
            {cartCount > 0 ? (
              <Badge
                color="primary"
                overlap="circular"
                showZero
                badgeContent={
                  loadingCountNot ? (
                    <CircularProgress size={10} color="inherit" />
                  ) : (
                    <span
                      style={{
                        backgroundColor: globalColors?.AAUTI_THEME_COLOR,
                      }}
                    >
                      {cartCount || 0}
                    </span>
                  )
                }
                aria-describedby={id}
                variant="contained"
                sx={{
                  cursor: "pointer",
                  "& .css-114bzxb-MuiBadge-badge": {
                    backgroundColor: globalColors?.AAUTI_THEME_COLOR,
                  },
                }}
                max={99}
              >
                <HeaderCartIcon />
              </Badge>
            ) : (
              <HeaderCartIcon />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Chat">
          <IconButton
            sx={{ display: mobileDevices && "none" }}
            onClick={onClickChat}
            style={{ marginLeft: 2 }}
          >
            {chatCount ? (
              <Badge
                color="primary"
                overlap="circular"
                showZero
                badgeContent={chatCount}
                aria-describedby={"unread-chats-count"}
                variant="contained"
                sx={{
                  cursor: "pointer",
                  "& .css-114bzxb-MuiBadge-badge": {
                    backgroundColor: globalColors?.AAUTI_THEME_COLOR,
                  },
                }}
                max={99}
              >
                <HeaderChatInActive />
              </Badge>
            ) : (
              <HeaderChatInActive />
            )}
          </IconButton>
        </Tooltip>
        {/* <Badge
          color="warning"
          overlap="circular"
          showZero={unreadCount > 0}
          badgeContent={
            loadingCountNot ? (
              <CircularProgress size={10} color="inherit" />
            ) : (
              unreadCount
            )
          }
          aria-describedby={id}
          variant="contained"
          onClick={handleClickNot}
          sx={{ cursor: "pointer" }}
          max={99}
        >
          <NotificationsNone />
        </Badge> */}
        <Tooltip title="Notifications">
          <IconButton onClick={handleClickNot} style={{ marginLeft: -2 }}>
            {unreadCount ? (
              <HeaderNotificationsIcon />
            ) : (
              <HeaderNotificationsInActive size={24} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip title="Settings">
          <IconButton
            sx={{ display: mobileDevices && "none" }}
            onClick={() => {
              navigation("/account-settings");
            }}
          >
            <HeaderSettingsIcon />
          </IconButton>
        </Tooltip>
        {!mobileDevices && (
          <Popover
            sx={{
              p: 1,
              "& .MuiPopover-paper": {
                p: 2,
                width: "420px",
                maxWidth: "600px",
              },
            }}
            id={id}
            open={openNot}
            anchorEl={anchorElNot}
            onClose={handleCloseNot}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Notifications
              height={"55vh"}
              handleCloseNot={handleCloseNot}
              setAnchorElNot={setAnchorElNot}
              anchorElNot={anchorElNot}
              unreadCount={unreadCount}
              showMorebtns={true}
            />
          </Popover>
        )}
        {mobileDevices && (
          <Drawer
            anchor={"right"}
            open={openNot}
            onClose={handleCloseNot}
            sx={{ width: "100%" }}
            id="NotDrawer"
          >
            <Notifications
              showMorebtns={true}
              handleCloseNot={handleCloseNot}
              setAnchorElNot={setAnchorElNot}
              anchorElNot={anchorElNot}
              unreadCount={unreadCount}
            />
          </Drawer>
        )}
      </div>
      <div className="HeaderTabLogin-div2">
        <Tooltip title="Menu" id="Profile-name">
          <IconButton
            disableRipple
            disableTouchRipple
            onClick={handleMenuClick}
            size="small"
            aria-controls={menuOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ position: "relative" }}>
                <Avatar
                  sx={{ width: 30, height: 30, marginRight: 0.5 }}
                  src={userDetails?.profileImage}
                />
                <CircularProgress
                  variant="determinate"
                  value={profilePercentage}
                  size={42}
                  sx={{
                    color: globalColors?.AAUTI_THEME_COLOR,
                    position: "absolute",
                    top: -6,
                    left: -6,
                    zIndex: 1,
                  }}
                />
              </Box>
            </Box>
          </IconButton>
        </Tooltip>
      </div>

      <ProfileMenu
        {...{
          handleMenuClose,
          menuOpen,
          userDetails,
          FirstName,
          userRole,
          handleUserRole,
          UserRole,
          navigation,
          logoutFunction,
          handleOpenConfirmation,
          dispatch,
          mobileDevices,
          countryObject,
          setToggleCurrency,
          chatCount,
          onClickChat,
        }}
      />
      <TodoListDialog
        toggleOpenTodoDialog={() => {
          setOpenTodatEvents((prev) => !prev);
        }}
        openTodoDialog={openTodayEvent}
        navigateToTodoOptions={(route) => {
          setOpenTodatEvents((prev) => !prev);
          navigateToTodoOptions(route);
        }}
        todayEvents={todayEvents}
        toDoList={toDoList}
      />
    </div>
  );
};

export default HeaderTabsLogin;
