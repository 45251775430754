import AppContainer from "../../AppContainer";
import AautiText from "../../globalComponents/AautiText";
import AccountTabs from "../../globalComponents/AccountTabs";
import { useDispatch } from "react-redux";
import { useCourseAdhocCont } from "./container";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import CourseAdhocCard from "./CourseAdhocCard";
import { Skeleton } from "@mui/material";
import EmptyTopic from "../../globalComponents/EmptyTopic";
import AautiInfiniteScroll from "../../features/InfiniteScroll";

const CourseAdhocEvent = () => {
  const { courseList, onPressDelete, loadingData, hasData, getAdhocs } =
    useCourseAdhocCont();

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Course Adhoc Events",
            navigation: "/course-adhoc-events",
            active: true,
          },
        }}
      />
      {/* <AautiText
        title={"Course Adhoc Events"}
        weight={"bold"}
        textStyle={{ marginBottom: "15px" }}
        size={"medium"}
      /> */}
      <div
        id="courseAdhocScroll"
        style={{
          height: "85vh",
          overflow: "scroll",
          // border: "1px solid #E0E0E0",
          borderRadius: 4,
          padding: 10,
          width: "100%",
          // height: "100%",
        }}
      >
        {loadingData ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {[1, 2, 3, 4, 5].map((each) => (
              <div>
                <Skeleton
                  variant="rectangular"
                  width={350}
                  height={200}
                  sx={{ borderRadius: 5, margin: 2 }}
                />
              </div>
            ))}
          </div>
        ) : courseList.length > 0 ? (
          <AautiInfiniteScroll
            cardsList={courseList}
            {...{ hasData, handleLoadMore: getAdhocs }}
            testId="courseAdhocScroll"
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                gap: 12,
                flexWrap: "wrap",
                overflow: "scroll",
                borderRadius: 4,
                // height: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                maxHeight: "100%",
              }}
            >
              {courseList.map((each, index) => (
                <CourseAdhocCard
                  each={each}
                  onPressDelete={onPressDelete}
                  key={"courseAdhocCard" + index}
                />
              ))}
            </div>
          </AautiInfiniteScroll>
        ) : (
          <EmptyTopic topicName={"No Course Adhocs Yet"} />
        )}
      </div>
    </AppContainer>
  );
};

export default CourseAdhocEvent;
