import React from "react";
import { useSelector } from "react-redux";
import { getUserRole } from "../../redux/reducer/chatSlice";
import { userRoles } from "../../storage/constants";
import AautiText from "../globalComponents/AautiText";
import Chat from "./Chat";
import { chatConstants } from "./constants";
import "./styles.scss";

function AautiChat() {
  const instituteId = localStorage.getItem("instituteId");
  const role = useSelector(getUserRole);
  const approvalStatus = useSelector(
    (state) => state.app.userDetails?.approvalStatus ?? false
  );
  const isInstituteAdmin = role === "instituteAdmin";
  const isSpApproved =
    approvalStatus === "approved" && role === userRoles.serviceProvider;
  const showContacts =
    !!instituteId || isSpApproved || role === userRoles.endUser;

  return !showContacts ? (
        <div className="chat-main-container">
          <div className="empty-container">
            <AautiText
              title={chatConstants.adminApproval}
              size={"big"}
              textAlign={"center"}
            />
          </div>
        </div>
      ) : (
        <Chat {...{ isSpApproved, isInstituteAdmin, role, instituteId }} />
      );
}
export default AautiChat;