import React, { useState } from "react";
import { Box, Dialog } from "@mui/material";
import CategoriesDropdown from "../../globalComponents/CategoriesDropdown";
import AautiText from "../../globalComponents/AautiText";
import NavButton from "../courseCreation/NavButton";
import AautiDialog from "../../globalComponents/AautiDialog";

const CatDropdown = (props) => {
  const {
    CreateCourseObj,
    visible2,
    mobileDevices,
    setVisible2,
    categories,
    setRefresh,
    refresh,
    setCreateCourseObj,
    allCategories,
    courseOverviewErrors,
    resetObject,
    validateObj,
  } = props;
  const [categorySubCat, setCategorySubCat] = useState({
    category: CreateCourseObj?.category,
    subCategory: CreateCourseObj?.subCategory,
  });
  const [subCategories, setSubCategories] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSetObject = (obj) => {
    setCreateCourseObj((prev) => ({
      ...prev,
      ...obj,
    }));
  };
  const handleEmptyError = (obj) => {
    delete courseOverviewErrors?.category;
    delete courseOverviewErrors?.subCategory;
  };

  return (
    <AautiDialog
      open={visible2}
      showCloseIcons={!showConfirmation}
      onClose={() => {
        setVisible2(false);
        setCategorySubCat({
          category: CreateCourseObj?.category,
          subCategory: CreateCourseObj?.subCategory,
        });
        setShowConfirmation(false);
      }}
      draggable={false}
      fullWidth
      position="bottom"
      PaperProps={{
        sx: {
          mt: mobileDevices ? "0px" : "100px",
          verticalAlign: "bottom",
        },
      }}
    >
      {showConfirmation ? (
        <Box p={2}>
          <AautiText
            title={
              "You are changing category and sub-category, Do you wish to reset the fields?"
            }
          />
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <NavButton
              text={"No"}
              outlined={true}
              handleClick={() => {
                handleSetObject(categorySubCat);
                setVisible2(false);
                setShowConfirmation(false);
              }}
            />

            <NavButton
              text={"Yes"}
              handleClick={() => {
                handleSetObject({
                  ...resetObject,
                  category: categorySubCat?.category,
                  subCategory: categorySubCat.subCategory,
                  searchTags: [
                    categorySubCat?.category,
                    categorySubCat.subCategory,
                  ],
                });
                setVisible2(false);
                setShowConfirmation(false);
                validateObj(CreateCourseObj);
              }}
            />
          </Box>
        </Box>
      ) : (
        <CategoriesDropdown
          {...{
            categories,
            categorySubCat,
            setCategorySubCat,
            allCategories,
            setSubCategories,
            subCategories,
            refresh,
            setRefresh,
            setVisible2,
            CreateCourseObj,
            resetObject,
            handleSetObject,
            handleEmptyError,
            setShowConfirmation,
          }}
        />
      )}
    </AautiDialog>
  );
};

export default CatDropdown;
