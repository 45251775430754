import React, { useState } from "react";
import AautiText from "../globalComponents/AautiText";
import { FiMonitor } from "react-icons/fi";
import moment from "moment";
import { MdOutlineAccessTime, MdOutlineCalendarMonth } from "react-icons/md";
import bookIcon from "../../../src/assets/images/normal_u432.svg";
import "./Style.scss";
import { useSelector } from "react-redux";
import {
  capitalizeEntireStr,
  CapitalizeFirstLetter,
  groupDaysByTimings,
  getFirstTwoChars,
  PriceConversions,
  returnColorbasedName,
  returnTime,
  returnWeek,
  virtualInPersonIcon,
  sortClassType,
} from "../CommonFunctions";
import { Avatar, Tooltip } from "@mui/material";
import { isEmpty } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { BiSolidDiscount } from "react-icons/bi";
import { AuthService } from "../../service";
import { CartStrings } from "./Constants";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { PackageStrings } from "../home/Constants";
import ConfirmBacthDialog from "../globalComponents/ConfirmBacthDialog";
import { showToast } from "../globalComponents/Toast";
import { globalColors } from "../../themes/GlobalColors";
import { FaCheck } from "react-icons/fa";
import GroupIcon from "../../assets/images/group-svg.svg";
import IndividualIcon from "../../assets/images/individual-svg.svg";
import { formatStartandEndTime } from "../../utils/Helpers";

export const isBefore15mins = (time) => {
  return moment(time).diff(moment(), "minutes") > 15;
};

export const retrunSlotStatus = (batch, isGroup, isParent, isCancelled) => {
  const isBefore = moment(batch?.endDate).isBefore(new Date());

  const batchExpired = (endDateTime) => {
    const timeZone = moment().utcOffset();
    return moment(endDateTime)
      .add(timeZone, "m")
      .isBefore(moment().add(timeZone, "m"));
  };
  let text;
  let color = "red";
  if (isCancelled) return { text: "CANCELLED", color };
  if (isGroup) {
    if (batch?.isAvailable) {
      if (batch?.isPurchased) {
        if (isParent) {
          text = "";
        } else {
          text = "Already purchased";
        }
      } else if (batch?.availableSlots > 0) {
        text = `Available ${batch?.availableSlots}`;
        color = "#0EA30B";
        // 598f00
      } else if (
        batch?.allowWaitingList &&
        isBefore15mins(batch?.startDateTime)
      ) {
        text = `Waiting List ${(batch?.waitingListCount || 0) + 1}`;
        color = "#FBB63B";
      } else {
        text = `FULL`;
        color = "red";
      }
    } else {
      if (batch?.isPurchased) {
        text = isParent ? "" : "Already purchased";
      } else {
        text = batchExpired(batch?.endDateTime) ? "CLOSED" : "FULL";
        color = "red";
      }
    }
  } else {
    if (batch?.isAvailable) {
      if (batch?.isPurchased) {
        if (isParent) {
          text = "";
        } else {
          text = "Already purchased";
        }
      } else {
        text = "";
      }
    } else {
      if (batch?.isPurchased) {
        if (isParent) {
          text = "";
        } else {
          text = "Already purchased";
        }
      } else if (batchExpired(batch?.endDateTime)) {
        text = "CLOSED";
      } else {
        text = "SOLD OUT";
      }
    }
  }
  return { text, color };
};

const Batches = ({
  handleSelectedDate,
  selectedSlotDate,
  cartItem,
  purchaseHistory,
  selectedUsers = [],
  setSelectedUsers = () => {},
  isPackage = false,
  type = "Course",
  showChilds = false,
  enableEveryTime = false,
  isBuyNow = false,
  isCancelled = false,
  childArray,
  setChildArray,
}) => {
  const courseType = ["Individual", "Group"];
  const { loggedinUserId } = useSelector((state) => state.app);

  const formatCouponDate = (date) => {
    return moment(date).format("DD MMM YYYY");
  };

  const { pathname } = useLocation();
  const isMyCourses = pathname.includes("/my-courses");
  const navigate = useNavigate();
  const [activePopButton, setActivePopButton] = useState(
    cartItem?.course?.courseType?.[0] ||
      cartItem?.courseType?.[0] ||
      courseType[0]
  );

  const [confirmChilds, setConfirmChilds] = useState(null);
  const { convertPriceWithCurrency, displayCountryBasedCurrency } =
    PriceConversions();
  const { isParent } = useSelector((state) => state.app);

  const format = (date) => {
    const formatDate = moment.utc(date).local().format("DD MMM YYYY");
    return formatDate;
  };

  const returnBatchesArray = (each) => {
    const { individualDaily, individualWeekly, groupDaily, groupWeekly } =
      isEmpty(purchaseHistory) ? cartItem : purchaseHistory;

    const individual = [
      {
        title: "individualDaily",
        arrayOfBatches: individualDaily,
      },
      {
        title: "individualWeekly",
        arrayOfBatches: individualWeekly,
      },
    ];
    const group = [
      {
        title: "groupWeekly",
        arrayOfBatches: groupWeekly,
      },
      {
        title: "groupDaily",
        arrayOfBatches: groupDaily,
      },
    ];
    return activePopButton == "Individual" ? individual : group;
  };

  // const capitalizeEntireStr = (str) => {
  //   return str
  //     .split(" ")
  //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  //     .join(" ");
  // };

  const filterUser = (list, bool = false) => {
    const filterList = list?.filter((each) => each?.isPurchased == bool);
    return filterList || [];
  };

  const BatchCards = ({
    each,
    title,
    isPackage,
    handleSelectUser,
    handleSelectedDate,
    cartItem,
  }) => {
    const [showActiveDisc, setShowActiveDisc] = useState(null);
    const isDaily = title == "individualDaily" || title == "groupDaily";
    const isGroup = title == "groupDaily" || title == "groupWeekly";
    const filteredList = filterUser(each?.users);
    const purchasedList = filterUser(each?.users, true);
    const disableSelection = () => {
      if (isCancelled) return true;
      if (each?.isAvailable) {
        if (isGroup) {
          if (each?.availableSlots > 0) {
            return false;
          } else if (
            each?.allowWaitingList &&
            isBefore15mins(each?.startDateTime)
          ) {
            return false;
          }
          return true;
        }
        return false;
      }
      return true;
    };
    const currentDate = moment();
    const checkBatch = !isParent
      ? selectedUsers?.find((user) => user?.batchId == each?._id)
      : {};

    const returnUserList = (each) => {
      if (showChilds) {
        if (isParent && showChilds) {
          if (!each?.isAvailable) {
            return null;
          }
          return (
            <>
              <div
                style={{
                  display: isEmpty(filteredList) ? "none" : "flex",
                  flexDirection: "row",
                  gap: 8,
                  marginTop: 10,
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <AautiText
                  title={CartStrings?.PurchaseFor}
                  // weight={"bold"}
                  textAlign={"left"}
                  size={"normal"}
                  textStyle={{ width: "25%", color: "#1E1E1E" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 6,
                    flexWrap: "wrap",
                    width: "75%",
                  }}
                >
                  {filteredList?.map((user, index) => {
                    const dbKey = "_id";
                    const checkUser =
                      type == "Course"
                        ? selectedUsers?.find(
                            (element) =>
                              (element?.[dbKey] == user?.[dbKey] ||
                                element?.endUserId == user?._id) &&
                              element?.batchId == each?.[dbKey]
                          )
                        : selectedUsers?.find(
                            (each) => each?.[dbKey] == user?.[dbKey]
                          );
                    return (
                      <div
                        id={`purchase-for-${index + 1}`}
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectUser(user, checkUser, each, isGroup);
                          if (handleSelectedDate) {
                            handleSelectedDate(each, true);
                          }
                          if (isBuyNow && isParent) {
                            setChildArray(selectedUsers);
                          }
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip title={user?.displayName}>
                          <div
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <Avatar
                              disabled={true}
                              sx={{
                                bgcolor:
                                  !user?.profileImage &&
                                  returnColorbasedName(user?.displayName),
                                height: 32,
                                width: 32,
                              }}
                              // style={{
                              //   border: checkUser ? "2px solid" : "none",
                              // }}
                              key={index}
                              src={user?.profileImage}
                              alt={user?.displayName}
                            >
                              {!user?.profileImage &&
                                getFirstTwoChars(user?.displayName)}
                            </Avatar>

                            {checkUser && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "#222222",
                                  // backdropFilter: "blur(1px)",
                                  opacity: 0.8,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                }}
                              >
                                <FaCheck size={20} color="#598f00" />
                              </div>
                            )}
                          </div>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                style={{
                  display: isEmpty(purchasedList) ? "none" : "flex",
                  flexDirection: "row",
                  gap: 8,
                  marginTop: 10,
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <AautiText
                  title={CartStrings?.BoughtFor}
                  // weight={"bold"}
                  textAlign={"left"}
                  textStyle={{ width: "25%", color: "#1E1E1E" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 4,
                    flexWrap: "wrap",
                    width: "75%",
                  }}
                >
                  {purchasedList?.map((each, index) => {
                    return (
                      <div
                        id={`bought-for-${index + 1}`}
                        key={index}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={CapitalizeFirstLetter(each?.displayName)}
                        >
                          <Avatar
                            sx={{ height: 32, width: 32 }}
                            disabled={true}
                            key={index}
                            src={each?.profileImage}
                            alt={each?.displayName}
                          >
                            {getFirstTwoChars(each?.displayName)}
                          </Avatar>
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }
        // else if (each?.isPurchased) {
        //   return (
        //     <AautiText
        //       title={CartStrings?.AlreadyPurchased}
        //       textStyle={{ color: "red", marginTop: 5 }}
        //       weight={"medium"}
        //       textAlign={"left"}
        //     />
        //   );
        // }
      }
      return null;
    };

    const status = retrunSlotStatus(each, isGroup, isParent, isCancelled);

    let valueCheck =
      `${displayCountryBasedCurrency(
        cartItem?.currencyCode,
        isGroup ? each?.groupPrice : each?.individualPrice,
        isGroup ? each?.localGroupPrice : each?.localIndividualPrice
      )}` ==
      `${displayCountryBasedCurrency(
        cartItem?.currencyCode,
        !isGroup ? each?.individualDiscountPrice : each?.groupDiscountPrice,
        isGroup
          ? each?.localGroupDiscountPrice
          : each?.localIndividualDiscountPrice
      )}`;

    const checkDiscount =
      moment(each?.discountStartDate).isBefore(moment(new Date())) &&
      moment(each?.discountEndDate).isAfter(moment(new Date())) &&
      (each?.individualDiscountValue || each?.groupDiscountValue) &&
      (each?.groupDiscountPrice || each?.individualDiscountPrice);

    const returnDiscountType = (item) => {
      const value = !isGroup
        ? item?.individualDiscountValue
        : item?.groupDiscountValue;

      const localPrice = !isGroup
        ? item?.localIndividualDiscountValue
        : item?.localGroupDiscountValue;

      if (item?.discountType !== "Amount") {
        return value ? `(${value}%)` : null;
      }

      return (
        value &&
        `(-${displayCountryBasedCurrency(
          cartItem?.currencyCode,
          value,
          localPrice
        )})`
      );
    };

    const onSuccessDetails = (response) => {
      if (response?.data?.status === "Success") {
        const obj = response?.data?.result;
        if (response?.data?.result._id) {
          navigate(`/join_class`, { state: obj });
        } else {
          showToast("info", "No Sessions Available");
        }
      }
    };

    const onFailure = (error) => {
      console.log(error);
    };

    const formatDate = (dateStr, startTime, endTime) => {
      const date = moment(dateStr);
      const start = moment(startTime);
      const end = moment(endTime);
      return `${date.format("DD MMM YYYY")}, ${start.format(
        "h:mm A"
      )} - ${end.format("h:mm A")}`;
    };

    return (
      <button
        id={isGroup ? "group-batch-card" : "individual-batch-card"}
        disabled={enableEveryTime ? false : disableSelection()}
        onClick={(e) => {
          e.stopPropagation();
          const dbKey = PackageStrings?.id;
          const checkUser =
            type == PackageStrings?.courseCap
              ? selectedUsers?.find(
                  (element) =>
                    (element?.[dbKey] == filteredList[0]?.[dbKey] ||
                      element?.endUserId == filteredList[0]?._id) &&
                    element?.batchId == each?.[dbKey]
                )
              : selectedUsers?.find(
                  (each) => each?.[dbKey] == filteredList[0]?.[dbKey]
                );

          if (!isParent) {
            handleSelectedDate({ ...each, batchType: title }, cartItem?._id);
            handleSelectUser(filteredList[0], checkUser, each, isGroup);
          }

          if (isBuyNow && !isParent) {
            let update = [
              { endUserId: childArray?.[0]?.endUserId, batchId: each?._id },
            ];
            setChildArray(update);
          }

          const data = { courseId: cartItem?._id, batchId: each?._id };
          if (isMyCourses) {
            AuthService.getSessionsByCourseIdBatchId(
              data,
              onSuccessDetails,
              onFailure
            );
          }
        }}
        style={{
          borderColor: isParent
            ? selectedUsers?.find((item, index) => item?.batchId == each?._id)
              ? globalColors?.AAUTI_THEME_COLOR
              : globalColors?.BORDER_COLOR
            : each?._id == selectedSlotDate?._id
            ? globalColors?.AAUTI_THEME_COLOR
            : globalColors?.BORDER_COLOR,

          borderWidth: 1,
          borderRadius: 4,
          outline: "none",
          borderStyle: "solid",
          background: !disableSelection() ? "#fff" : "#ddd",
          width: "100%",
          cursor: "pointer",
          marginBottom: 10,
          padding: 0,
          boxShadow: "none",
        }}
        key={each?._id}
      >
        <div className="Waiting-con-detai">
          <AautiText
            title={capitalizeEntireStr(each?.batchName) || "NA"}
            weight={"bold"}
            textStyle={{
              color: "#333333",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              WebkitLineClamp: 2,
              textOverflow: "ellipsis",
              width: "72%",
            }}
            textAlign={"left"}
          />
          <AautiText
            title={status?.text}
            textAlign={"end"}
            textStyle={{
              color: status?.color,
              width: "26%",
            }}
          />
        </div>
        <div style={{ padding: 10 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={bookIcon}
              alt="Weekly"
              style={{ height: "15px", width: "15px" }}
            />
            <AautiText
              size={"semi"}
              title={
                isDaily
                  ? !isEmpty(each?.customDates) || !isEmpty(each?.excludedDates)
                    ? "Custom"
                    : "Daily"
                  : "Weekly"
              }
              textStyle={{
                color:
                  !disableSelection() &&
                  (isDaily
                    ? globalColors?.AAUTI_THEME_COLOR
                    : globalColors?.GREY_TEXT),
                marginLeft: 10,
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 10,
              alignItems: "center",
            }}
          >
            <MdOutlineCalendarMonth
              size={20}
              color={!disableSelection() && globalColors?.GREY_TEXT}
            />
            <AautiText
              size={"semi"}
              title={format(each?.startDate) + " - " + format(each?.endDate)}
              textStyle={{
                marginLeft: 6,
                color: !disableSelection() && globalColors?.GREY_TEXT,
              }}
            />
          </div>
          {isDaily ? (
            <div>
              <div
                style={{
                  display: "flex",
                  // marginBottom: 6,
                  marginTop: 10,
                  alignItems: "flex-end",
                }}
              >
                <MdOutlineAccessTime
                  size={20}
                  color={!disableSelection() && globalColors?.GREY_TEXT}
                />
                <AautiText
                  size={"small"}
                  title={
                    // returnTime(each?.startTime) +
                    // " - " +
                    // returnTime(each?.endTime)
                    formatStartandEndTime({
                      start: each?.startTime,
                      end: each?.endTime,
                    })
                  }
                  textStyle={{
                    marginLeft: 6,
                    color: !disableSelection() && globalColors?.GREY_TEXT,
                  }}
                />
              </div>
              {!isEmpty(each?.customDates) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: 8,
                  }}
                >
                  <AautiText
                    title={"Custom Dates:"}
                    textAlign={"left"}
                    size={"semi"}
                  />
                  <div>
                    {Object.entries(each?.customDates)?.map(
                      ([date, { startTime, endTime }]) => (
                        <div key={date}>
                          <AautiText
                            title={formatDate(date, startTime, endTime)}
                            size={"small"}
                            textStyle={{
                              // marginLeft: 6,
                              color:
                                !disableSelection() && globalColors?.GREY_TEXT,
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
              {!isEmpty(each?.excludedDates) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: 8,
                    width: "100%",
                  }}
                >
                  <AautiText
                    title={"Excluded Dates:"}
                    textAlign={"left"}
                    size={"semi"}
                  />
                  <div
                    style={{
                      width: "100%",
                      flexWrap: "wrap",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    {each?.excludedDates?.map((data, index) => (
                      <AautiText
                        title={`${format(data)}
                           ${
                             each?.excludedDates?.length !== index + 1
                               ? ","
                               : ""
                           }`}
                        // title={formatDate(date, startTime, endTime)}
                        size={"small"}
                        textStyle={{
                          // marginLeft: 6,
                          color: !disableSelection() && globalColors?.GREY_TEXT,
                        }}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 10,
                width: "fit-content",
              }}
            >
              {returnWeek(each?.recurringDays, disableSelection())}
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 8,
              marginTop: 10,
            }}
          >
            {virtualInPersonIcon(each)}
            {/* <img
              src={isGroup ? GroupIcon : IndividualIcon}
              alt={isGroup ? "group" : "individual"}
              style={{ height: "19px", width: "19px", zIndex: 100 }}
            /> */}
            <AautiText
              title={`${displayCountryBasedCurrency(
                cartItem?.currencyCode,
                isGroup ? each?.groupPrice : each?.individualPrice,
                isGroup ? each?.localGroupPrice : each?.localIndividualPrice
              )}`}
              size={"semi"}
              weight={!checkDiscount && "bold"}
              textStyle={{
                display: valueCheck && "none",
                color:
                  !disableSelection() &&
                  (!checkDiscount ? "#1E1E1E" : globalColors?.GREY_TEXT),
                textDecoration:
                  checkDiscount && returnDiscountType(each)
                    ? "line-through"
                    : "",
                fontFamily: "sans-serif",
              }}
            />
            <AautiText
              title={returnDiscountType(each)}
              size={"semi"}
              textStyle={{
                color: !disableSelection() && globalColors?.GREY_TEXT,
                display:
                  (!returnDiscountType(each) || !checkDiscount || valueCheck) &&
                  "none",
              }}
            />
            {returnDiscountType(each) &&
              returnDiscountType(each) != 0 &&
              (each?.individualDiscountValue || each?.groupDiscountValue) && (
                <AautiText
                  size={"semi"}
                  title={`${displayCountryBasedCurrency(
                    cartItem?.currencyCode,
                    !isGroup
                      ? each?.individualDiscountPrice
                      : each?.groupDiscountPrice,
                    isGroup
                      ? each?.localGroupDiscountPrice
                      : each?.localIndividualDiscountPrice
                  )}`}
                  weight={checkDiscount && "bold"}
                  textStyle={{
                    color: !disableSelection() && "#1E1E1E",
                    display: !checkDiscount && "none",
                    fontFamily: "sans-serif",
                  }}
                />
              )}
          </div>
          {(each?.individualDiscountValue || each?.groupDiscountValue) && (
            <div
              className="coupon-discount"
              style={{ display: valueCheck && "none" }}
            >
              <BiSolidDiscount
                size={20}
                color={!disableSelection() && globalColors?.GREY_TEXT}
              />
              <AautiText
                textStyle={{
                  color: !disableSelection() && globalColors?.GREY_TEXT,
                }}
                title={`${
                  !checkDiscount
                    ? `${
                        isGroup
                          ? each?.localGroupDiscountValue
                          : each?.localIndividualDiscountValue
                      }% off from`
                    : ""
                } (${formatCouponDate(
                  each?.discountStartDate
                )} - ${formatCouponDate(each?.discountEndDate)})`}
                size={"semi"}
              />
            </div>
          )}
          <div
            style={{ display: each?.allowMembers ? "flex" : "none" }}
            className="family-discount"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (showActiveDisc) {
                  setShowActiveDisc(null);
                } else {
                  setShowActiveDisc(each?._id);
                }
              }}
              className="family-btn"
            >
              <AautiText
                size={"semi"}
                title={"View discount for family members"}
              />
              {showActiveDisc ? (
                <IoIosArrowDown size={16} />
              ) : (
                <IoIosArrowForward size={16} />
              )}
            </button>
            <div className={`${showActiveDisc ? "expand" : "hide-expand"} `}>
              <AautiText
                title={
                  "If one person subscribes, the total price will be applied."
                }
                textAlign={"left"}
                size={"small"}
              />

              {each?.membersDiscount?.map((each, index) => {
                return (
                  <AautiText
                    key={index}
                    title={`For ${each?.noOfMembers} ${
                      each?.noOfMembers == 1 ? "member" : "members"
                    } ${each?.percentage}% discount will be applied.`}
                    textAlign={"left"}
                    size={"small"}
                  />
                );
              })}
            </div>
          </div>
          {!disableSelection() && returnUserList(each)}
        </div>
      </button>
    );
  };

  const handleSelectUser = (item, check, batch, isGroup) => {
    const dbKey = "_id";
    let list = [...(selectedUsers || [])];

    if (check) {
      // Remove user if check is true
      list = list.filter(
        (each) => each?.endUserId !== item?._id && each?._id !== item?._id
      );

      setSelectedUsers(list);
    } else {
      const userIndex = list.findIndex(
        (each) => (each?.endUserId ?? each?._id) === item?._id
      );

      if (userIndex > -1) {
        // Case when user is already in the list
        if (!isGroup && !batch?.allowMembers) {
          // If group is not allowed and members are restricted
          const filteredList = list.filter(
            (each) => each?.batchId !== batch?.[dbKey]
          );

          const updatedUserIndex = filteredList.findIndex(
            (user) => user.id === list[userIndex].id
          );

          if (updatedUserIndex !== -1) {
            filteredList[updatedUserIndex] = {
              ...filteredList[updatedUserIndex],
              batchId: batch?.[dbKey],
              selectedBatch: batch,
            };
          }

          if (isParent) {
            setConfirmChilds(filteredList);
          } else {
            list = filteredList;
          }
        } else {
          // Update child list only if batchId exists
          if (userIndex >= 0 && userIndex < list.length) {
            let newChildsList = [...list];
            newChildsList[userIndex] = {
              ...newChildsList[userIndex],
              batchId: batch?.[dbKey],
              selectedBatch: batch,
            };

            if (isParent) {
              if (!isGroup && batch?.allowMembers) {
                const selectedMembersFromBatch = list.filter(
                  (each) => each.batchId === batch?.[dbKey]
                );

                if (
                  selectedMembersFromBatch.length >=
                  batch?.membersDiscount?.length + 1
                ) {
                  showToast(
                    "info",
                    `You can select only up to ${batch?.membersDiscount?.length} members additionally`
                  );
                  return;
                } else {
                  let wishedList = list?.filter(
                    (each) => (each?.endUserId ?? each?._id) === item?._id
                  );

                  if (wishedList[0]?.batchId) {
                    setConfirmChilds(newChildsList);
                  } else {
                    // Add batchId to the user if it's missing and push it to the list
                    newChildsList[userIndex] = {
                      ...newChildsList[userIndex],
                      batchId: batch?.[dbKey],
                      selectedBatch: batch,
                    };
                    list = newChildsList;
                    setSelectedUsers(list);
                  }
                }
              } else {
                let wishedList = list?.filter(
                  (each) => (each?.endUserId ?? each?._id) === item?._id
                );

                if (wishedList[0]?.batchId) {
                  setConfirmChilds(newChildsList);
                } else {
                  // Add batchId to the user if it's missing and push it to the list
                  newChildsList[userIndex] = {
                    ...newChildsList[userIndex],
                    batchId: batch?.[dbKey],
                    selectedBatch: batch,
                  };
                  list = newChildsList;
                  setSelectedUsers(list);
                }
              }
            } else {
              list = newChildsList;
              setSelectedUsers(list);
            }
          }
        }
      } else {
        // Case when user is not in the list
        let obj = {
          _id: item?.[dbKey],
          batchId: batch?.[dbKey],
          selectedBatch: batch,
        };

        if (isGroup) {
          const selectedMembersFromBatch = list.filter(
            (each) => each.batchId === batch?.[dbKey]
          );

          if (
            selectedMembersFromBatch.length >= batch?.availableSlots &&
            !batch?.allowWaitingList
          ) {
            showToast("info", "This batch has no available slots");
          } else {
            list.push(obj);
            setSelectedUsers(list);
          }
        } else {
          if (!isGroup && batch?.allowMembers) {
            const selectedMembersFromBatch = list.filter(
              (each) => each.batchId === batch?.[dbKey]
            );

            if (
              selectedMembersFromBatch.length >=
              batch?.membersDiscount?.length + 1
            ) {
              showToast(
                "info",
                `You can select only up to ${batch?.membersDiscount?.length} members additionally`
              );
              return;
            }
          }

          if (!isGroup && !batch?.allowMembers) {
            list = list.filter((each) => each?.batchId !== batch?.[dbKey]);
          }

          list.push(obj);
          setSelectedUsers(list);
        }
      }
    }
  };

  const courseTypeArray =
    cartItem?.course?.courseType || cartItem?.courseType || courseType;

  return (
    <div>
      <div className="available-timings-details" style={{ marginTop: 10 }}>
        {sortClassType(courseTypeArray)
          // ?.sort((a, b) => b.localeCompare(a))
          ?.map((each, index) => {
            return (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setActivePopButton(each);
                }}
                key={index}
                style={{
                  backgroundColor: each === activePopButton && "#ffffff",
                  width: "50%",
                }}
                className="timing-type"
              >
                <AautiText title={each} weight={"bold"} size={"medium"} />
              </div>
            );
          })}
      </div>
      <div
        style={{
          width: "100%",
          overflow: "scroll",
          height: "77vh",
        }}
      >
        {returnBatchesArray()?.map((batcheItems, index) => {
          return (
            <div key={index}>
              {batcheItems?.arrayOfBatches?.map((each, index) => {
                return (
                  <BatchCards
                    key={index}
                    {...{
                      cartItem,
                      each,
                      title: batcheItems?.title,
                      handleSelectUser,
                      isPackage,
                      handleSelectedDate,
                    }}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      <ConfirmBacthDialog
        dialogTitle={"Confirm Batch Selection"}
        open={confirmChilds}
        onClose={() => {
          setConfirmChilds(null);
        }}
        onClick={() => {
          setSelectedUsers(confirmChilds);
          setConfirmChilds(null);
        }}
      />
    </div>
  );
};

export default Batches;
