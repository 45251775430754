import React, { useCallback, useRef, useState } from "react";
import { capitalizeWordFirstLetter } from "../../../utils/Helpers";
import moment from "moment";
import { AuthService } from "./Service";
import { useSelector } from "react-redux";
import { PackageStrings } from "../Constants";
import { showToast } from "../../globalComponents/Toast";
import { CapitalizeFirstLetter } from "../../CommonFunctions";

export const Container = ({ responseType, childId }) => {
  const { loggedinUserId, isUserLoggedIn, UserRole, userDetails } = useSelector(
    (state) => state.app
  );
  const [unReadMessages, setUnReadMessages] = useState([]);
  const [AllNot, setAlNot] = useState(null);
  const [readNotifications, setReadNotifications] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingNotifi, setLoadingNotifi] = useState(false);
  const [selected, setSelected] = useState(null);
  const [accepteState, setAccepteState] = useState(false);
  const [clearNotification, setClearNotification] = useState("");
  const [readOffset, setReadOffset] = useState(0);
  const [childNotifications, setChildNotifications] = useState(null);
  const [childOffset, setChildOffset] = useState(0);
  const [actionOffSet, setActionOffset] = useState(0);
  const [readLastItem, setReadLastItem] = useState(false);
  const [actionLastItem, setActionLastItem] = useState(false);
  const [childEndReach, setChildEndReach] = useState(false);
  const [actionIndex, setActionIndex] = useState(0);
  const [readIndex, setReadIndex] = useState(0);
  const [childIndex, setChildIndex] = useState(0);
  const [showReadScrollButton, setShowReadScrollButton] = useState(false);
  const [showActionScrollButton, setShowActionScrollButton] = useState(false);
  const [childScrollbtn, setChildScrollBtn] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [inCart] = useState(false);
  const readRefs = useRef([]);
  const actionRefs = useRef([]);
  const actionRef = useRef(null);
  const readRef = useRef(null);
  const childRef = useRef(null);
  const childRefs = useRef([]);
  const [showSelection, setShowSelection] = useState(false);

  const showCursor = (item) => {
    const validTypes = [
      "invite Course",
      "Adhoc starts in 15 Mins",
      "Course starts in 15 Mins",
      "Course Adhoc",
      "Session reschedule",
      "Course",
      "course",
      "Batch Transfer",
      "course purchase",
      "Adhoc purchase",
      "batchTransfer",
    ];

    return (
      validTypes.includes(item?.notificationType.trim()) || item?.status === "0"
    );
  };

  function formatTo12HourTime(isoString) {
    const date = new Date(isoString);
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    const formattedTime = `${hours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  }

  const notificationMessage = (item) => {
    if (
      item?.notificationType === "Merge" ||
      item?.notificationType === "session reschedule" ||
      item?.notificationType === "canceled session" ||
      item?.notificationType === "Adhoc reschedule"
    ) {
      const fromBatchDate = moment(item?.fromBatchDate).utc().format();
      const fromDate = moment(fromBatchDate).format("DD/MM/YYYY");
      const fromTime = moment(fromBatchDate).format("hh:mm A");
      const toBatchDate = moment(item?.toBatchDate).utc().format();
      const toDate = moment(toBatchDate).format("DD/MM/YYYY");
      const toTime = moment(toBatchDate).format("hh:mm A");
      switch (item?.notificationType) {
        case "Merge":
          return `Your session from ${item?.fromBatchName}, which was supposed to begin at ${fromTime} on ${fromDate}, has been merged with batch ${item?.toBatchName}, which will begin at ${toTime} on ${toDate}`;
        case "session reschedule":
          return `Your session from ${item?.fromBatchName}, which was supposed to begin at ${fromTime} on ${fromDate}, has been reschedule, ${item?.toBatchName}, which will begin at ${toTime} on ${toDate}`;
        case "canceled session":
          return `The scheduled session for the ${item?.fromBatchName} at ${toTime} on ${toDate} has been canceled`;
        case "Adhoc reschedule":
          return `Your ${item?.adhocName}, which was supposed to begin at ${fromTime} on ${fromDate},has been reschedule with adhoc ${item?.adhocName}, which will begin at ${toTime} on ${toDate}`;
        case "Session Reschedule Deleted":
          return `The session reschedule for the ${item?.batchName} for ${toTime} has been deleted`;
        case "Session Merge Deleted":
          return `The session merge for the ${item?.batchName} for ${toTime} has been deleted`;
        default:
          return CapitalizeFirstLetter(item?.message);
      }
    } else {
      return CapitalizeFirstLetter(item?.message);
    }
  };

  const onSuccessNot = (response) => {
    if (response?.status === "Success") {
      setUnReadMessages(response?.result?.unreadCount || 0);
      setLoadingNotifi(false);
      if (response?.result?.notifications?.length > 0) {
        if (responseType === "Child") {
          setChildNotifications([
            ...(childNotifications ?? []),
            ...response?.result?.notifications,
          ]);
          if (response?.result?.notifications?.length < 10) {
            setChildEndReach(true);
            return;
          }
        } else if (responseType === "Read") {
          setReadNotifications([
            ...(readNotifications ?? []),
            ...response?.result?.notifications,
          ]);
          if (response?.result?.notifications?.length < 10) {
            setReadLastItem(true);
            return;
          }
        } else {
          setAlNot([...(AllNot ?? []), ...response?.result?.notifications]);
          if (response?.result?.notifications?.length < 10) {
            setActionLastItem(true);
            return;
          }
        }
      } else {
        return null;
      }
    } else {
      setLoadingNotifi(false);
    }
  };

  const onFailure = (error) => {
    setLoadingNotifi(false);
    setOpen(false);
    showToast(PackageStrings?.error, error.message);
  };

  let countValue = 10;

  const params = {
    userId: responseType == "Child" ? childId : loggedinUserId,
    count: countValue,
  };
  switch (responseType) {
    case "Read":
      params.responseType = "read";
      params.offset = readOffset;
      params.offset = readOffset;
      break;
    case "Action":
      params.responseType = "action";
      params.offset = actionOffSet;
      break;
    case "Child":
      params.offset = childOffset;
      break;
    default:
      break;
  }

  let paramload =
    responseType == "Read"
      ? readOffset
      : responseType == "Child"
      ? childOffset
      : actionOffSet;

  const getNotificationData = () => {
    if (paramload == 0) {
      setLoadingNotifi(true);
    }
    AuthService.getAllNotificationsByUserId(params, onSuccessNot, onFailure);
  };

  // const handleSelectEachNotification = (notification) => {
  //   setSelectedNotifications((prevSelected) =>
  //     prevSelected.some((item) => item._id === notification._id)
  //       ? prevSelected.filter((each) => each._id !== notification._id)
  //       : [...prevSelected, notification]
  //   );
  // };

  const refreshNotifications = async () => {
    const paramstoPass = {
      userId: responseType === "Child" ? childId : loggedinUserId,
      count: 10,
    };
    
    switch (responseType) {
      case "Read":
        paramstoPass.responseType = "read";
        paramstoPass.offSet = 0;
        break;
      case "Action":
        paramstoPass.responseType = "action";
        paramstoPass.offSet = 0;
        break;
      case "Child":
        paramstoPass.offSet = 0;
        break;
      default:
        break;
    }
    
    await AuthService.getAllNotificationsByUserId(
      paramstoPass,
      (response) => {
        if (responseType === "Child") {
          setChildNotifications(response?.result?.notifications);
          if (response?.result?.notifications?.length < 10) {
            setChildEndReach(true);
          }
        } else if (responseType === "Read") {
          setReadNotifications(response?.result?.notifications);
          if (response?.result?.notifications?.length < 10) {
            setReadLastItem(true);
          }
        } else {
          setAlNot(response?.result?.notifications);
          if (response?.result?.notifications?.length < 10) {
            setActionLastItem(true);
          }
        }
      },
      (err) => {
        console.error("Error refreshing notifications:", err);
      }
    );
  };
  
  const deleteSelectedNotifications = async () => {
    AuthService.deleteMultipleNotifications(
      selectedNotifications,
      (res) => {
        if (res?.status == "Success") {
          setShowSelection(false);
          // setAlNot([]);
          // setReadNotifications([]);
          setActionIndex(0);
          setReadIndex(0);
          setChildIndex(0);
          refreshNotifications();
          setSelectedNotifications([]);
        }
      },
      (err) => {
        console.log(err, "----------error multiple delete");
      }
    );
  };

  const cancelSelection = () => {
    setShowSelection(false);
    setSelectedNotifications([]);
  };

  const handleSelectEachNotification = useCallback((notification) => {
    setSelectedNotifications((prevSelected) => {
      const selectedSet = new Set(prevSelected.map((item) => item._id));
      return selectedSet.has(notification._id)
        ? prevSelected.filter((each) => each._id !== notification._id)
        : [...prevSelected, notification];
    });
  }, []);
  // const handleSelectAll = (val, AllNotifications) => {
  //   if (val) {
  //     setSelectedNotifications(AllNotifications);
  //   } else {
  //     setSelectedNotifications([]);
  //   }
  // };

  const isAllSelected = () => {
    let check =
      (responseType === "Action" ? AllNot : readNotifications).length ==
      selectedNotifications?.length;

    return check;
  };

  const isNotificationSelected = (each) => {
    return selectedNotifications.some((item) => item._id === each._id);
  };

  return {
    isAllSelected,
    inCart,
    readRef,
    actionRef,
    actionRefs,
    readRefs,
    childRef,
    childRefs,
    showCursor,
    notificationMessage,
    formatTo12HourTime,
    unReadMessages,
    setUnReadMessages,
    AllNot,
    setAlNot,
    readNotifications,
    setReadNotifications,
    open,
    setOpen,
    loadingNotifi,
    setLoadingNotifi,
    selected,
    setSelected,
    accepteState,
    setAccepteState,
    clearNotification,
    setClearNotification,
    readOffset,
    setReadOffset,
    actionOffSet,
    setActionOffset,
    readLastItem,
    setReadLastItem,
    actionIndex,
    setActionIndex,
    childIndex,
    setChildIndex,
    actionLastItem,
    setActionLastItem,
    readIndex,
    setReadIndex,
    showReadScrollButton,
    setShowReadScrollButton,
    showActionScrollButton,
    setShowActionScrollButton,
    selectedNotifications,
    setSelectedNotifications,
    handleSelectEachNotification,
    // handleSelectAll,
    isNotificationSelected,
    showSelection,
    setShowSelection,
    deleteSelectedNotifications,
    getNotificationData,
    cancelSelection,
    childEndReach,
    setChildEndReach,
    childNotifications,
    setChildNotifications,
    childOffset,
    setChildOffset,
    childScrollbtn,
    setChildScrollBtn,
  };
};
