import React, { useEffect, useState } from "react";
import "./index.scss";
import AautiText from "../../globalComponents/AautiText";
import { Avatar, Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ShareIcon from "../../globalComponents/icons/ShareIcon";
import { AiFillStar } from "react-icons/ai";
import CourseDetailsStrings from "./CourseDeatilsStrings";
import { toggleSignInPop } from "../../../redux/reducer/appReducer";

import { showToast } from "../../globalComponents/Toast";
import { isEmpty } from "lodash";
import { AuthService } from "../../serviceProviderDetails/service";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import { CapitalizeFirstLetter } from "../../CommonFunctions";
import { FaAngleRight } from "react-icons/fa6";
import { checkNull } from "../../../utils/Helpers";

const UserCard = ({
  completeDetails,
  each,
  type,
  isPackage = false,
  isDashboard,
  isRatingScreen = false,
  isCourseRatingScreen = false,
  isGigRatingScreen = false,
  setOncloseRefresh
}) => {
  const { ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );
  const { loggedinUserId, isUserLoggedIn } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const path = window.origin;
  const dispatch = useDispatch();
  const sharePath = `${path}/sp-profile/${completeDetails?.serviceProviderId}`;
  const [cardItem, setCardItem] = useState(completeDetails);

  const onSuccessDeleteList = (response) => {
    if (response.status === "Success") {
      // showToast("success", "Unfollowed");
      setCardItem({ ...cardItem, isSpWishlisted: !cardItem?.isSpWishlisted });
    } else {
      // showToast("error", response?.message);
    }
  };
  const deleteListAPI = (each) => {
    const wishListData = {
      serviceProviderId: completeDetails?.serviceProviderId,
      name: completeDetails?.displayName,
      description: "Wishlist deleted",
      endUserId: loggedinUserId,
    };
    AuthService.deleteWishlistedServiceProvider(
      wishListData,
      onSuccessDeleteList,
      onFailure
    );
  };
  const onSuccessWishList = (response) => {
    if (response.status === "Success") {
      // showToast("success", "Following");
      setCardItem({ ...cardItem, isSpWishlisted: !cardItem?.isSpWishlisted });
    } else {
      showToast("error", response?.message);
    }
  };
  const wishListAPI = (each) => {
    const wishListData = {
      serviceProviderId: completeDetails?.serviceProviderId,
      name: completeDetails?.displayName,
      description: "wishList",
      endUserId: loggedinUserId,
    };
    AuthService.wishlistServiceProvider(
      wishListData,
      onSuccessWishList,
      onFailure
    );
  };
  const onFailure = (error) => {
    showToast("error", "somthing went wrong");
  };
  return (
    <div
      id="serviceprovider-card"
      className="userCard-div1"
      style={{
        marginBottom: isRatingScreen && 0,
        marginTop: isRatingScreen
          ? 0
          : mobileDevices || ipodDevices
            ? 10
            : !isPackage && 15,
      }}
    >
      {!isRatingScreen && !isDashboard && (
        <AautiText
          title={`A ${isPackage ? "Package" : type} by`}
          weight={"bold"}
          className={"userCard-text1"}
        />
      )}
      <div
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/sp-profile/${completeDetails?.serviceProviderId}`, {
            state: isDashboard ? { tabValue: 3 } : {},
          });
        }}
        className="profile3-user-card"
        style={{
          flexDirection: mobileDevices && "column",
          justifyContent: mobileDevices && "flex-start",
          alignItems: mobileDevices && "center",
          border: isRatingScreen ? "1px solid #E0E0E0" : "2px solid #f2f2f2",
        }}
      >
        <div className="userCard-div2">
          <Avatar
            alt={completeDetails?.displayName}
            sx={{ height: 80, width: 80 }}
            src={completeDetails?.profileImage}
            className="michale-im"
          />
          <div className={"userCard-div3"}>
            {(isRatingScreen ? completeDetails?.spAverageRating :
              (completeDetails?.spAverageRating || completeDetails?.averageRating)) ? (
              <div
                className="userCard-div4"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/sp-profile/${completeDetails?.serviceProviderId}`,
                    {
                      state: { tabValue: 3 },
                    }
                  );
                }}
              >
                <AiFillStar
                  size={mobileDevices ? 12 : 15}
                  className="userCard-icon1"
                />
                <AautiText
                  size={"small"}
                  weight={"normal"}
                  className={"userCard-text2"}
                  title={(isRatingScreen ? completeDetails?.spAverageRating :
                    (completeDetails?.spAverageRating ||
                      completeDetails?.averageRating)
                  )?.toFixed(1)}
                />
                <FaAngleRight color="#fff" size={mobileDevices ? 10 : 12} />
              </div>
            ) : (
              <div className="userCard-div4">
                <AiFillStar
                  size={mobileDevices ? 12 : 15}
                  className="userCard-icon1"
                />
                <AautiText
                  size={"small"}
                  weight={"normal"}
                  className={"userCard-text2"}
                  title={CourseDetailsStrings?.new}
                />
              </div>
            )}
          </div>
        </div>
        <div className="userCard-div5">
          <div
            className="userCard-div6"
            style={{
              flexDirection: isRatingScreen ? "row" : "column",
              alignItems:
                isRatingScreen || mobileDevices ? "center" : "flex-start",
              justifyContent: isRatingScreen && mobileDevices && "center",
            }}
          >
            {/* <Tooltip title={completeDetails?.displayName}> */}
            <div style={{ maxWidth: isRatingScreen ? "70%" : "100%" }}>
              <AautiText
                textStyle={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 1,
                  textOverflow: "ellipsis",
                }}
                size={"medium"}
                weight={"bold"}
                className={"userCard-text3"}
                title={CapitalizeFirstLetter(completeDetails?.displayName)}
              />
            </div>
            {/* </Tooltip> */}
            {completeDetails?.skillsetName ? (
              <Tooltip title={completeDetails?.skillsetName}>
                <div style={{ maxWidth: isRatingScreen ? "70%" : "100%" }}>
                  <AautiText
                    textStyle={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      WebkitLineClamp: 1,
                      textOverflow: "ellipsis",
                    }}
                    size={"small"}
                    title={
                      isRatingScreen
                        ? completeDetails?.skillsetName?.length > 30
                          ? `(${completeDetails?.skillsetName?.substring(
                            0,
                            30
                          )}...)`
                          : `(${completeDetails?.skillsetName})`
                        : `(${completeDetails?.skillsetName})`
                    }
                  />{" "}
                </div>
              </Tooltip>
            ) : null}
          </div>
          <div className="sp-detaisl">
            <div
              className="details-item-user"
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/sp-profile/${completeDetails?.serviceProviderId}`, {
                  state: { tabValue: type === "Gig" ? 2 : 1 },
                });
              }}
            >
              <AautiText
                title={
                  type === "Gig"
                    ? (completeDetails?.spGigCount
                      ? completeDetails?.spGigCount :
                      completeDetails?.gigsCount ?
                        completeDetails?.gigsCount :
                        isPackage
                          ? completeDetails?.gigsCount
                          : completeDetails?.spCourseCount) == 1
                      ? "Gig"
                      : CourseDetailsStrings?.gigs
                    : completeDetails?.spCourseCount == 1
                      ? "Course"
                      : CourseDetailsStrings?.courses
                }
                size={"semi"}
                className={"rightCont-div6-type"}
              />
              <AautiText
                title={
                  type === "Gig"
                    ? isPackage
                      ? completeDetails?.gigsCount
                      : completeDetails?.spCourseCount || completeDetails?.gigsCount || 0
                    : // completeDetails?.spCourseCount ||
                    // completeDetails?.coursesCount
                    completeDetails?.spCourseCount ||
                    completeDetails?.coursesCount ||
                    0
                }
                weight={"bold"}
                size={"semi"}
                className={"courseReviewsABuy-text2"}
              />
            </div>
            <div className="details-item5">
              <AautiText
                title={`${(completeDetails?.spStudentsCount || completeDetails?.subscribersCount ||
                  completeDetails?.studentsCount) == 1
                  ? "Student"
                  : "Students"
                  }`}
                size={"semi"}
                className={"rightCont-div6"}
              />
              <AautiText
                title={
                  completeDetails?.spStudentsCount ||
                  completeDetails?.studentsCount || completeDetails?.subscribersCount ||
                  0
                }
                weight={"bold"}
                size={"semi"}
                className={"courseReviewsABuy-text2"}
              />
            </div>
          </div>
          <div
            className="userCard-div7"
            style={{
              justifyContent:
                completeDetails?.tags?.length > 0
                  ? "space-between"
                  : "flex-end",
            }}
          >
            {!isDashboard && (
              <> {checkNull(completeDetails?.tags) ? null :
                <div className="spTagsDivUserCard">
                  {completeDetails?.tags?.map((each, index) =>
                    <div key={index} className="TPhighTextdiv">
                      <AautiText size={"semi"} title={each} />
                    </div>
                  )}
                </div>
              }
              </>
            )}
            {!isDashboard && (
              <div className="userCard-div8">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ShareIcon
                    shareUrl={sharePath}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                </div>
                {cardItem?.isSpWishlisted ? (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isUserLoggedIn) {
                        deleteListAPI(cardItem);
                        if (isCourseRatingScreen || isGigRatingScreen) {
                          setOncloseRefresh(true)
                        }
                      } else {
                        // dispatch(toggleSignInPop(true));
                        // showToast("Info", "Please login to continue....");
                        navigate("/login");
                      }
                    }}
                    className="TPFollowTextDiv-userCard"
                  >
                    <AautiText
                      size={mobileDevices ? "small" : "semi"}
                      title={AautiStrings?.followingString}
                      textStyle={{ color: "#fff" }}
                    />
                  </button>
                ) : (
                  <button
                    // disabled={
                    //   loggedinUserId === completeDetails?.serviceProviderId
                    // }
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isUserLoggedIn) {
                        wishListAPI(cardItem);
                        if (isCourseRatingScreen || isGigRatingScreen) {
                          setOncloseRefresh(true)
                        }
                      } else if (!isUserLoggedIn) {
                        navigate("/login");
                        // dispatch(toggleSignInPop(true));
                        // showToast("Info", "Please login to continue....");
                      }
                    }}
                    className="TPFollowTextDiv-userCard"
                  >
                    <AautiText
                      size={mobileDevices ? "small" : "semi"}
                      title={AautiStrings?.followString}
                      textStyle={{ color: "#fff" }}
                    />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
