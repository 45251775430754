import React, { createRef, useState } from "react";
import "./Style.scss";
import AautiUpload from "../../globalComponents/AautiUpload";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import Dialog from "@mui/material/Dialog";
import { MdAddCircleOutline, MdOutlineModeEditOutline } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";

import PostAddSharpIcon from "@mui/icons-material/PostAddSharp";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";
import PDFViewer from "pdf-viewer-reactjs";
import {
  ApiPaths,
  UploadBaseUrl,
  uploadUrl,
} from "../../../service/api-constants";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import AautiDialog from "../../globalComponents/AautiDialog";
import ImageCropper from "./ImageCropper";
import { isEmpty } from "lodash";
import { generateImagesUsingAi } from "./Services";
import NavButton from "../courseCreation/NavButton";
import { createCourseStrings, levelStrings } from "./Constants";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import HelperText from "../courseCreation/HelperText";
import BottomSheet from "../../globalComponents/BottomSheet";
import { Box } from "@mui/material";
import PreviewCard from "./PreviewCard";
import UploadPreview from "./UploadPreview";
import PreviewImage from "./PreviewImage";
import { showToast } from "../../globalComponents/Toast";
import { globalColors } from "../../../themes/GlobalColors";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const CreateCourse3 = (props) => {
  const {
    CreateCourseObj,
    changeCreateObj,
    handleClose,
    handleOpen,
    courseImageErrors,
    setCourseImageError,
  } = props;
  const { mobileDevices } = useSelector((state) => state.responsive);
  const { userDetails } = useSelector((state) => state.app);
  const [multipleUploads, setMultipleUploads] = useState([]);
  const [aiImageField, setAiImageField] = useState("");
  // console.log(CreateCourseObj, "CreateCourseObj");
  const [activeImageIndex, setActiveImageIndex] = useState([]);
  const [showMediaFile, setShowMediaFile] = useState("");
  const [showPrevObj, setShowPrevObj] = useState({
    bool: false,
    base: "",
    url: "",
  });

  // console.log(showPrevObj, "prev-obj");

  const [showCropPop, setCropPop] = useState(false);
  const [aiImagesList, setAiImagesList] = useState([]);
  //cropper states
  const [image, setImage] = useState("");

  const [uploadedFile, setUploadedFile] = useState(null);
  const cropperRef = createRef(null);
  const [cropObject, setCropObj] = useState(null);

  const fileUpload = async (file) => {
    handleOpen(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let responseJson = await response.json();
    return responseJson;
  };

  const multipleFileUpload = async (files) => {
    const formData = new FormData();
    files.map((each) => formData.append("files", each));
    formData.append("platform", UploadBaseUrl);
    const url = uploadUrl + ApiPaths.FILE_UPLOAD_MULTI;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });
    let responseJson = await response.json();
    return responseJson;
  };

  const cancelCrop = () => {
    setImage("");
    setUploadedFile(null);
    setMultipleUploads([]);

    setCropObj(null);
    setCropPop(false);
    cropperRef.current = null;
  };

  const uploadHere = async (file, feild) => {
    let responseJson =
      feild === "relatedDocuments"
        ? await multipleFileUpload([...file])
        : await fileUpload(file);
    if (responseJson?.result) {
      if (feild === "relatedImages") {
        if (!CreateCourseObj?.relatedImages?.includes(responseJson?.result)) {
          changeCreateObj(
            [...CreateCourseObj.relatedImages, responseJson?.result],
            feild
          );
          delete courseImageErrors?.[feild];

          cancelCrop();
        } else {
          cancelCrop();
        }
        handleOpen(false);
      } else if (feild === "relatedDocuments") {
        changeCreateObj(
          [...CreateCourseObj.relatedDocuments, ...responseJson.result],
          feild
        );
        cancelCrop();
        handleOpen(false);
      } else if (feild === "bookCoverPage") {
        changeCreateObj(
          [...CreateCourseObj.bookCoverPage, responseJson.result],
          feild
        );
        cancelCrop();
        handleOpen(false);
      } else {
        changeCreateObj(responseJson?.result, feild);
        delete courseImageErrors?.[feild];
        cancelCrop();
        handleOpen(false);
      }
      handleOpen(false);
    }
    handleOpen(false);
    handleClose();
  };

  const checkImageRatios = async (e, field, crop) => {
    e.preventDefault();
    const file = e.target.files[0];
    const { files } = e.target;
    let imagesList = [];
    if (files?.length > 1) {
      handleOpen();
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        // const formData = new FormData();
        // formData.append("file", file);
        // formData.append("platform", UploadBaseUrl);
        // const url = uploadUrl + ApiPaths.FILE_UPLOAD;
        // const response = await fetch(url, {
        //   method: "POST",

        //   body: formData,
        // });
        const json = await fileUpload(file);
        if (json.result) {
          imagesList?.push(json?.result);
        }
      }
      setMultipleUploads(imagesList);
      setCropPop(true);
      setImage(imagesList[0]);
      handleClose();
    } else {
      if (file) {
        if (!crop) {
          handleOpen();
          const img = new Image();
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const aspectRatio = img.width / img.height;
            if (field === "courseImage" || field === "bookCoverPage") {
              if (Math.abs(aspectRatio - 4 / 3) < 0.01) {
                uploadHere(e.target.files[0], field);
              } else {
                alert(
                  createCourseStrings?.selectedImageMustHaveA4IsTo3AspectRatio
                );
              }
            } else if (field === "relatedImages") {
              if (Math.abs(aspectRatio - 16 / 9) < 0.01) {
                uploadHere(e.target.files[0], field);
              } else {
                alert(
                  createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed16IsTo9
                );
              }
            }
          };
        } else {
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
            setUploadedFile(files[0]);
            setCropPop(true);
            const obj = {
              field,
              ratio:
                field === "courseImage" || field === "bookCoverPage"
                  ? "4 / 3"
                  : "16 / 9",
            };
            setCropObj(obj);
          };
          reader.readAsDataURL(files[0]);
        }
      }
    }
  };

  const removeRelatedImages = (positi) => {
    const a = CreateCourseObj?.relatedImages?.filter((each, index) => {
      return index !== positi;
    });
    changeCreateObj(a, "relatedImages");
  };
  const remveMediaFiles = (val) => {
    const a = CreateCourseObj?.relatedDocuments?.filter((each, index) => {
      return index !== val;
    });
    changeCreateObj(a, "relatedDocuments");
  };

  const removeCoverPage = (position) => {
    const a = CreateCourseObj?.bookCoverPage?.filter((each, index) => {
      return index !== position;
    });
    changeCreateObj(a, "bookCoverPage");
  };
  const handleUploadCroppedImage = async (file, field) => {
    handleOpen();
    if (showPrevObj?.url) {
      if (showPrevObj?.base == "courseImage") {
        uploadHere(file, "courseImage");
        setShowPrevObj((prev) => ({
          ...prev,
          bool: false,
          base: "",
          url: "",
        }));
        handleClose();
      } else if (showPrevObj?.base == "relatedImages") {
        let imagesList = [...CreateCourseObj.relatedImages] || [];
        imagesList = imagesList?.filter((each) => each !== showPrevObj?.url);
        let responseJson = await fileUpload(file);
        if (responseJson) {
          imagesList.push(responseJson?.result);
        }
        changeCreateObj(imagesList, "relatedImages");
        setShowPrevObj((prev) => ({
          ...prev,
          bool: false,
          base: "",
          url: "",
        }));
        handleClose();
      } else if (showPrevObj?.base === "bookCoverPage") {
        let responseJson = await fileUpload(file);
        changeCreateObj([responseJson?.result], "bookCoverPage");
        // uploadHere(file, "bookCoverPage");
        setShowPrevObj((prev) => ({
          ...prev,
          bool: false,
          base: "",
          url: "",
        }));
        handleClose();
      }
    } else {
      if (isEmpty(multipleUploads)) {
        await uploadHere(file, field);
      } else {
        if (CreateCourseObj?.relatedImages?.length < 8) {
          let imagesList = [...CreateCourseObj.relatedImages] || [];
          let responseJson = await fileUpload(file);
          if (responseJson) {
            imagesList.push(responseJson?.result);
          }
          changeCreateObj(imagesList, "relatedImages");
          handleClose();
        } else {
          handleClose();
        }
      }
    }
    handleClose();
  };

  const handleRemoveMultipleImages = (item) => {
    const list = multipleUploads?.filter((each) => each !== item);
    if (isEmpty(list)) {
      setImage("");
      setCropPop(false);
      cancelCrop();
    } else {
      setMultipleUploads(list);
      setImage(list[0]);
    }
  };

  // console.log(CreateCourseObj, "courseObject?...");

  const handleGenerateAiImages = (field) => {
    const isForRelatedImages = field === "relatedImages";
    const data = {
      prompt: `images for the category '${
        CreateCourseObj?.category || ""
      }' and subcategory '${CreateCourseObj?.subCategory || ""}'. 
      The aspect ratio should be ${
        isForRelatedImages ? "16:9" : "4:3"
      }, and the images must resemble natural scenes or real-world contexts, without any artificial or overly stylized design elements.`,
      numberOfImages: isForRelatedImages ? 4 : 2,
      model: isForRelatedImages ? "dall-e-2" : "dall-e-3",
      size: isForRelatedImages ? "256x256" : "1024x1024",
    };
    setAiImageField(field);
    handleOpen();
    generateImagesUsingAi(
      data,
      (response) => {
        handleClose();
        if (response?.result) {
          if (field == "courseImage") {
            const image = response?.result?.data[0];
            setCropPop(true);
            setImage("data:image/jpeg;base64," + image?.b64_json);
          } else {
            setAiImagesList(response?.result?.data);
          }
        } else {
          showToast("info", "Something went wrong");
        }
      },
      (error) => {
        console.log(error, "error?....");
        handleClose();
      }
    );
  };

  const returnDisabled =
    !CreateCourseObj?.category ||
    !CreateCourseObj?.subCategory ||
    isEmpty(CreateCourseObj?.courseLevel);

  const getFileType = (url) => {
    const extension = url.split(".").pop().toLowerCase();
    return extension;
  };
  const returnPopMedia = (media) => {
    const fileType = getFileType(media);
    // Render based on the file
    if (
      fileType === "jpg" ||
      fileType === "jpeg" ||
      fileType === "png" ||
      fileType === "gif"
    ) {
      // Render an image
      return (
        <img
          src={media}
          alt="img-media"
          style={{ width: "100%", height: "auto", marginTop: 10 }}
        />
      );
    } else if (
      fileType === "mp4" ||
      fileType === "webm" ||
      fileType === "ogg"
    ) {
      // Render a video
      return (
        <video
          controls
          style={{ width: "100%", height: "auto", marginTop: 10 }}
        >
          <source src={media} type={`video/${fileType}`} />
          Your browser does not support the video tag.
        </video>
      );
    } else if (fileType === "pdf") {
      // Render a PDF using iframe
      return (
        <PDFViewer
          document={{
            url: media,
          }}
          css="customViewer"
        />
      );
    } else {
      return <p>Unsupported file type: {fileType}</p>;
    }
  };

  return (
    <div className="CreateCourseTab">
      <div
        className="uploades"
        style={{
          flexDirection: mobileDevices ? "column" : "row",
          justifyContent: mobileDevices ? "center" : "space-between",
          alignItems: mobileDevices ? "center" : "flex-start",
          width: "100%",
          marginLeft: mobileDevices && "0px",
        }}
      >
        <div style={{ width: mobileDevices ? "98%" : "48%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              // marginTop: 15,
            }}
          >
            <AautiText
              title={AautiStrings?.CoverImageString}
              weight={"bold"}
              required
            />
            <Tooltip
              title={returnDisabled ? "category and levels are required" : null}
            >
              <button
                disabled={returnDisabled}
                onClick={() => {
                  handleGenerateAiImages("courseImage");
                }}
                id="cover-image-ai"
                style={{
                  background: "none",
                  border: "none",
                  padding: 0,
                  cursor: returnDisabled ? "help" : "pointer",
                  display: CreateCourseObj?.courseImage && "none",
                }}
              >
                <AautiText
                  size={"small"}
                  title={AautiStrings?.GenerateUsingAIString}
                  weight={"bold"}
                  textStyle={{
                    color: returnDisabled
                      ? "lightgrey"
                      : globalColors.AAUTI_THEME_COLOR,
                  }}
                />
              </button>
            </Tooltip>
          </div>

          {CreateCourseObj?.courseImage ? (
            <UploadPreview
              id="courseImage"
              index={"courseImage"}
              each={CreateCourseObj?.courseImage}
              {...{
                changeCreateObj,
                setShowPrevObj,
                // removeRelatedImages,
              }}
            />
          ) : (
            <div>
              <AautiUpload
                disabled={false}
                id="courseImage"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  checkImageRatios(e, "courseImage", true);
                }}
                iconName={createCourseStrings?.coverImage}
                important
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadCoverImageImp}
                    />
                  </div>
                }
              />
              <div className="labels">
                <HelperText
                  error={courseImageErrors?.courseImage}
                  title={
                    createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed4IsTo3
                  }
                />
                {/* <AautiText
                  size={"small"}
                  title={
                    
                  }
                  textStyle={{
                    fontFamily:
                      "AmazonEmber-Medium, Amazon Ember Medium, Amazon Ember, sans-serif",
                    color: "rgba(75, 75, 75, 0.9)",
                    margin: "2px",
                    marginBottom: "10px",
                  }}
                /> */}
              </div>
            </div>
          )}
        </div>

        <div style={{ width: mobileDevices ? "98%" : "48%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
              // marginTop: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <AautiText
                title={`${createCourseStrings?.relatedImages}`}
                weight={"bold"}
                required
              />
              <AautiText
                textStyle={{ color: "" }}
                title={`(2 or more)`}
                size={"small"}
              />
              {/* <HelperText title={"min 2 images required"} /> */}
            </div>
            {CreateCourseObj?.relatedImages?.length < 8 && (
              <Tooltip
                title={
                  returnDisabled ? "category and levels are required" : null
                }
              >
                <button
                  disabled={returnDisabled}
                  onClick={() => {
                    handleGenerateAiImages("relatedImages");
                  }}
                  id="related-images-ai"
                  style={{
                    background: "none",
                    border: "none",
                    cursor: returnDisabled ? "help" : "pointer",
                    padding: 0,
                    width: "fit-content",
                  }}
                >
                  <AautiText
                    size={"small"}
                    title={AautiStrings?.GenerateUsingAIString}
                    weight={"bold"}
                    textStyle={{
                      color: returnDisabled
                        ? "lightgrey"
                        : globalColors.AAUTI_THEME_COLOR,
                    }}
                  />
                </button>
              </Tooltip>
            )}
          </div>

          {CreateCourseObj?.relatedImages.length === 0 ? (
            <div>
              <AautiUpload
                multiple={true}
                id="upload-Related-Images"
                accept="image/jpeg, image/png"
                onChange={(e) => {
                  checkImageRatios(e, "relatedImages", true);
                }}
                iconName={createCourseStrings?.uploadMin2RelatedImages}
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <AddPhotoAlternateOutlinedIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadRelatedImgs}
                    />
                  </div>
                }
              />
              <div className="labels">
                <HelperText
                  title={`${createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed16IsTo9}`}
                  error={courseImageErrors?.relatedImages}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="relatedImage-mappi" style={{ gap: 4 }}>
                {CreateCourseObj?.relatedImages?.map((each, index) => {
                  return (
                    <UploadPreview
                      id={"relatedImages"}
                      {...{
                        index,
                        each,
                        removeRelatedImages,
                        setShowPrevObj,
                      }}
                    />
                  );
                })}
                <input
                  type="file"
                  multiple={true}
                  accept="image/*"
                  onChange={(e) => {
                    checkImageRatios(e, "relatedImages", true);
                  }}
                  id="upload-Related-Images"
                  style={{ display: "none" }}
                />
                <div style={{ alignItems: "center", display: "flex" }}>
                  <label htmlFor="upload-Related-Images">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {CreateCourseObj?.relatedImages?.length < 8 && (
                        <MdAddCircleOutline
                          size={45}
                          fill={"#1e98d7"}
                          style={{
                            fontSize: 24,
                            cursor: "pointer",
                            color: "#1e98d7",
                            fontWeight: 500,
                            // marginTop: 18,
                          }}
                          error={
                            CreateCourseObj?.relatedImages.length < 2
                              ? true
                              : false
                          }
                        />
                      )}
                    </div>
                  </label>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <div
        className="uploades"
        style={{
          marginTop: 15,
          flexDirection: mobileDevices ? "column" : "row",
          alignItems: mobileDevices ? "center" : "flex-start",
        }}
      >
        <div
          style={{
            width: mobileDevices ? "98%" : "48%",
            marginBottom: mobileDevices && 15,
          }}
        >
          {/* <AautiText
            size={"medium"}
            weight={"normal"}
            textStyle={{
              fontFamily:
                "AmazonEmber-Medium,Amazon Ember Medium,Amazon Ember,sans-serif",
              color: "#333333",
            }}
            title={"Media & Files:"}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // marginTop: 15,
            }}
          >
            <AautiText
              title={AautiStrings?.mediaAndFilesString}
              weight={"bold"}
            />
          </div>

          {CreateCourseObj?.relatedDocuments?.length === 0 ? (
            <div>
              <AautiUpload
                multiple={true}
                id="upload related documents"
                iconName={"Upload Media & Files"}
                accept="image/jpeg, image/png, application/pdf, video/mp4"
                onChange={(e) => {
                  handleOpen(true);
                  uploadHere(e.target.files, "relatedDocuments");
                }}
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <DescriptionIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadMediaFiles}
                    />
                  </div>
                }
              />
            </div>
          ) : (
            <div
              className="relatedImage-mappi"
              style={{
                flexDirection: "row",
                display: "flex",
                marginBottom: 10,
                gap: 4,
              }}
            >
              {CreateCourseObj?.relatedDocuments?.map((each, index) => {
                return (
                  // <BootstrapTooltip title="Preview" placement="bottom">
                  //   <div
                  //     className="each-images-course"
                  //     key={index}
                  //     style={{
                  //       width: mobileDevices ? "150px" : "160px",
                  //       border: "1px solid lightgrey",

                  //       backgroundImage:

                  //       padding: mobileDevices ? "8px" : 3,
                  //       marginBottom: mobileDevices && 10,
                  //       backgroundSize: "contain",
                  //       marginTop: 5,
                  //     }}
                  //     // resizeMode="stretch"

                  //   >
                  //     <CloseIcon
                  //       onClick={(e) => {
                  //         e.stopPropagation();
                  //         remveMediaFiles(index);
                  //       }}
                  //       fontSize="small"
                  //       sx={{
                  //         backgroundColor: "lightGrey",
                  //         borderRadius: "50%",
                  //         cursor: "pointer",
                  //       }}
                  //     />
                  //   </div>
                  // </BootstrapTooltip>

                  <Tooltip title="Preview" placement="bottom">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowMediaFile(each);
                      }}
                      style={{
                        border: "1px solid lightgrey",
                        borderRadius: 6,
                        padding: 5,
                        position: "relative",
                        height: 120,
                        width: 130,
                        cursor: "pointer",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          remveMediaFiles(index);
                        }}
                        sx={{
                          position: "absolute",
                          right: 1,
                          background: "#fff",
                          "&:hover": {
                            background: "#fff",
                          },
                        }}
                      >
                        <CloseIcon fontSize="small" sx={{ fontSize: 14 }} />
                      </IconButton>
                      <img
                        src={
                          each.split(".").pop() === "jpeg" ||
                          each.split(".").pop() === "jpg" ||
                          each.split(".").pop() === "png" ||
                          each.split(".").pop() === "jfif"
                            ? each
                            : each.split(".").pop() === "mp4" ||
                              each.split(".").pop() === "MP4"
                            ? "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/Video.png"
                            : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/640px-PDF_file_icon.svg.png"
                        }
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                        alt="uploaded-img"
                      />
                    </div>
                  </Tooltip>
                );
              })}
              <input
                type="file"
                multiple={true}
                accept="image/jpeg, image/png, application/pdf, video/mp4"
                onChange={(e) => {
                  handleOpen(true);
                  uploadHere(e.target.files, "relatedDocuments");
                }}
                id="RelatedImages2"
                style={{ display: "none" }}
              />
              <label
                htmlFor="RelatedImages2"
                style={{
                  display:
                    CreateCourseObj?.relatedDocuments?.length >= 10
                      ? "none"
                      : "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <MdAddCircleOutline
                    size={45}
                    fill={"#1e98d7"}
                    style={{
                      fontSize: 24,
                      cursor: "pointer",
                      color: "#1e98d7",
                      fontWeight: 500,
                      marginTop: 18,
                    }}
                    error={
                      CreateCourseObj?.relatedDocuments?.length < 2
                        ? true
                        : false
                    }
                  />
                </div>
              </label>
            </div>
          )}
        </div>

        <div style={{ width: mobileDevices ? "98%" : "48%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              // marginTop: 15,
            }}
          >
            <AautiText title={createCourseStrings?.coverPage} weight={"bold"} />
          </div>

          {CreateCourseObj?.bookCoverPage?.length > 0 ? (
            <div className="relatedImage-mappi">
              {CreateCourseObj?.bookCoverPage?.map((each, index) => {
                return (
                  <UploadPreview
                    id={"bookCoverPage"}
                    {...{
                      each,
                      index,
                      removeCoverPage,
                      setShowPrevObj,
                    }}
                  />
                );
              })}
              {CreateCourseObj?.bookCoverPage < 1 && (
                <>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      checkImageRatios(e, "bookCoverPage");
                    }}
                    id="RelatedImages1"
                    style={{ display: "none" }}
                  />
                  <label
                    htmlFor="RelatedImages1"
                    style={{ display: "flex", alignSelf: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <MdAddCircleOutline
                        size={45}
                        fill={"#1e98d7"}
                        style={{
                          fontSize: 24,
                          cursor: "pointer",
                          color: "#1e98d7",
                          fontWeight: 500,
                          marginTop: 18,
                        }}
                        // error={
                        //   CreateCourseObj?.bookCoverPage?.length < 2 ? true : false
                        // }
                      />
                    </div>
                  </label>
                </>
              )}
            </div>
          ) : (
            <div>
              <AautiUpload
                id={"CoverPage"}
                accept="image/jpeg, image/png"
                iconName={createCourseStrings?.uploadCoverPageOfTheBook}
                onChange={(e) => {
                  checkImageRatios(e, "bookCoverPage", true);
                }}
                customLabel={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <PostAddSharpIcon
                      sx={{ color: "#8687a7" }}
                      fontSize="large"
                    />
                    <AautiText
                      size={"semi"}
                      weight={"normal"}
                      textStyle={{ color: "#8687a7" }}
                      title={createCourseStrings?.uploadCoverPageOfTheBook}
                    />
                  </div>
                }
              />
              <div className="labels">
                <HelperText
                  title={
                    createCourseStrings?.onlyImagesWithTheFollowingRatioAreAllowed4IsTo3
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <AautiDialog
        open={showMediaFile}
        onClose={() => {
          setShowMediaFile("");
        }}
      >
        {returnPopMedia(showMediaFile)}
      </AautiDialog>

      <BottomSheet
        direction="bottom"
        open={showCropPop || !isEmpty(aiImagesList) || showPrevObj?.bool}
        onClose={() => {
          cancelCrop();
          setAiImagesList([]);
          setShowPrevObj((prev) => ({
            ...prev,
            bool: false,
            url: "",
            base: "",
          }));
        }}
        titlePosition="left"
        dialogTitle={!isEmpty(aiImagesList) && "Choose images"}
      >
        {showCropPop && (
          <div style={{ width: "100%" }}>
            <ImageCropper
              aiImages={aiImageField}
              {...{
                image,
                cropperRef,
                cropObject,
                handleUploadCroppedImage,
                uploadedFile,
                cancelCrop,
                multipleUploads,
                setImage,
                handleRemoveMultipleImages,
                removeWhenUploaded: true,
              }}
            />
          </div>
        )}
        {!isEmpty(aiImagesList) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                flexWrap: "wrap",
              }}
            >
              {aiImagesList?.map((img, index) => {
                const checkInclude = activeImageIndex?.some(
                  (each) => each.index == index
                );
                return (
                  <button
                    onClick={() => {
                      // setActiveImageIndex(img);
                      const object = {
                        image: "data:image/jpeg;base64," + img?.b64_json,
                        index,
                      };
                      let list = [...activeImageIndex];
                      if (aiImageField != "courseImage") {
                        if (checkInclude) {
                          const newList = list?.filter(
                            (each) => each?.index !== index
                          );
                          list = newList;
                        } else {
                          list.push(object);
                        }
                      } else {
                        list = [object];
                      }

                      setActiveImageIndex(list);
                    }}
                    key={index}
                    style={{
                      background: "none",
                      height: 200,
                      width: 310,
                      border: checkInclude ? "4px solid #2076E5" : "none",
                      padding: 0,
                      cursor: "pointer",
                      borderRadius: 4,
                    }}
                  >
                    <img
                      src={"data:image/jpeg;base64," + img?.b64_json}
                      alt="ai-images"
                      style={{ height: "100%", width: "100%", borderRadius: 3 }}
                    />
                  </button>
                );
              })}
            </div>
            {!isEmpty(activeImageIndex) && (
              <NavButton
                testId={"ai-img-next"}
                text={AautiStrings?.NextString}
                handleClick={() => {
                  setCropPop(true);
                  setImage(activeImageIndex?.[0]?.image);
                  if (aiImageField == "courseImage") {
                    setAiImagesList([]);
                    setActiveImageIndex([]);
                  } else if (aiImageField == "relatedImages") {
                    const multiList = activeImageIndex?.map(
                      (each) => each?.image
                    );
                    setMultipleUploads(multiList);
                    setAiImagesList([]);
                    setActiveImageIndex([]);
                  }
                }}
              />
            )}
          </div>
        )}
        {showPrevObj?.bool && (
          <PreviewImage
            url={showPrevObj?.url}
            handleClickOnPreviewEdit={() => {
              setImage(showPrevObj?.url);
              setCropPop(true);
              setShowPrevObj((prev) => ({
                ...prev,
                bool: false,
              }));
            }}
          />
        )}
      </BottomSheet>
    </div>
  );
};

export default CreateCourse3;
