import React, { useEffect, useState } from "react";
import AautiText from "../../globalComponents/AautiText";
import { getCourseHistory } from "./Services";
import { showToast } from "../../globalComponents/Toast";
import { useLocation, useParams } from "react-router-dom";
import AppContainer from "../../AppContainer";
import AautiFileHeader from "../../globalComponents/AautiFileHeader";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import NoDataFound from "../../globalComponents/NoDataFound";
import { Chrono } from "react-chrono";
import moment from "moment";
import { isEmpty } from "lodash";
import { MyCoursesConstants } from "./MyCoursesConstants";
import AccountTabs from "../../globalComponents/AccountTabs";

const CourseHistory = () => {
  const [historyOpen, setHistoryOpen] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  // const { courseId } = useParams();
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  let courseId = searchParams.get("id");
  const [formattedData, setFormattedData] = useState([]);

  const transformData = (data) => {
    return data.map((day) => ({
      title: moment(day?._id).format("DD MMM YYYY"),
    }));
  };

  useEffect(() => {
    getCourseHistory(
      { courseId },
      (response) => {
        if (response?.result) {
          setHistoryOpen(response?.result);
          const items = transformData(response?.result);

          setFormattedData(items);
        } else {
          showToast("info", response?.message);
        }
      },
      (error) => {
        console.log(error, "error");
      }
    );
  }, [courseId]);

  return (
    <AppContainer>
      {/* <AautiFileHeader activeTitle={MyCoursesConstants?.history} /> */}
      <AccountTabs
        tabObj={[
          {
            label: "My Courses",
            navigation: "/my-courses",
            active: false,
          },
          {
            label: "Course History",
            navigation: "/my-courses",
            active: true,
          },
        ]}
      />
      {/* <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "scroll",
          textAlign: "center",
        }}
      >
        {historyOpen?.map((activity, index) => {
          return (
            <div key={index} style={{ marginBottom: 4 }}>
              <AautiText
                title={activity?._id}
                weight={"bold"}
                textStyle={{ color: "#000" }}
              />
              <div>
                {activity?.activity?.map((each) => (
                  <AautiText
                    size={"semi"}
                    title={each?.message || each?.displayName}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div> */}

      {!isEmpty(formattedData) ? (
        <div
          className="chrono-timeline"
          style={{ height: "100%", overflow: "hidden" }}
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginBottom: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
                userSelect: "none",
              }}
            >
              <button
                style={{
                  display: activeIndex == 0 ? "none" : "flex",
                  background: "none",
                  border: "none",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveIndex(activeIndex - 1);
                }}
              >
                <FaChevronLeft />
              </button>
              <AautiText
                title={moment(formattedData[activeIndex]?.title).format(
                  "DD MMM YYYY"
                )}
              />
              <button
                style={{
                  display:
                    activeIndex == formattedData?.length - 1 ? "none" : "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setActiveIndex(activeIndex + 1);
                }}
              >
                <FaChevronRight />
              </button>
            </div>
          </div>
          <Chrono
            hideControls={true}
            activeItemIndex={activeIndex}
            items={formattedData}
            mode="VERTICAL_ALTERNATING"
            scrollable={{ scrollable: true }}
          >
            {historyOpen?.map((activity, index) => {
              return (
                <div key={index} style={{ marginBottom: 4, width: "100%" }}>
                  <div style={{ width: "100%" }}>
                    {activity?.activity?.map((each) => (
                      <div
                        style={{
                          background: "transparent",
                          border: "1px solid #f1f0f0",
                          padding: 6,
                          borderRadius: 2,
                          marginBottom: 5,
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <AautiText
                          size={"semi"}
                          weight={"bold"}
                          title={
                            each?.message ||
                            `${each?.displayName} purchased this ${
                              each?.batchName || "batch"
                            }`
                          }
                        />
                        <AautiText
                          title={moment(each?.createdAt).format(
                            "DD MMM YYYY hh:mm A"
                          )}
                          size={"small"}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </Chrono>
        </div>
      ) : (
        <NoDataFound NoDataFound={"No Records"} />
      )}
    </AppContainer>
  );
};

export default CourseHistory;
