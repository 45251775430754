import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputBase,
} from "@mui/material";
import { FaAngleDown } from "react-icons/fa";
import { RiSearchLine } from "react-icons/ri";
import constants from "../../constants.json";
import "./pageHeader.scss";
import { useContainer } from "./pageHeaderHooks";
// import { useLocation } from "react-router-dom";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { GlobeIcon, HeaderCartIcon } from "./pageHeaderUtils";
import { isEnterPressed } from "../../../../storage/constants";
import { TiThMenu } from "react-icons/ti";
import CountrySelect from "../../../header/CountrySelect";
import BottomSheet from "../../../globalComponents/BottomSheet";
import AautiDialog from "../../../globalComponents/AautiDialog";
import { HeaderStrings } from "../../../header/constants";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { CommonFunctions } from "../../../CommonFunctions";
import { useEffect, useState } from "react";
import userIcon from "../icons/user_icon.svg";
import AautiText from "../../../globalComponents/AautiText";
import { globalColors } from "../../../../themes/GlobalColors";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function PageHeader(props) {
  const { pathname } = useLocation();
  const hideSearch =
    pathname?.includes("/search-result") ||
    pathname?.includes("/trending-courses");

  const { toggleDrawer } = props;
  const { countriesArray, countryObject, cartsDataBeforeLogin } = useSelector(
    (state) => state.app
  );

  // const [filteredCountries, setFilteredCountries] = useState([]);

  // useEffect(() => {
  //   setFilteredCountries(countriesArray);
  // }, [countriesArray]);

  const {
    handleLogoClick,
    handleAllCoursesClick,
    handleTeachWithAautiClick,
    handleInstitutionsClick,
    handleSearchInputChange,
    handleSearchButtonClick,
    handleSignInClick,
    handleSignUpClick,
    // handleMenuButtonClick,
    searchInput,
    openCountrypop,
    closeCountryPop,
    filteredCountries,
    setFilteredCountries,
    handleOnChange,
  } = useContainer(props);

  const navigate = useNavigate();

  const location = useLocation();

  const hideContent =
    pathname !== "/register/select-profession" &&
    pathname !== "/register/select-subcategories" &&
    pathname !== "/register/select-intrests";
  return (
    <section id={constants.HOME_PAGE_HEADER}>
      <Box className="aauti_logo_div">
        <IconButton
          className="aauti_menu_button_icon"
          onClick={toggleDrawer(true)}
        >
          <TiThMenu />
        </IconButton>
        <img
          src={constants.aauti_logo}
          alt="aauti_logo"
          className="aauti_logo"
          onClick={hideContent ? handleLogoClick : () => {}}
          onMouseEnter={() => handleAllCoursesClick(false)}
        />
      </Box>

      {hideContent && (
        <Box className="aauti_buttons">
          <Box className="aauti_all_courses">
            <Box
              className="aauti_button_base"
              onMouseEnter={() => handleAllCoursesClick(true)}
            >
              <p className="aauti_label">{constants.aauti_all_courses}</p>
              <FaAngleDown className="aauti_label_icon" />
            </Box>
          </Box>
          <Box
            className="teach_with_aauti"
            onMouseEnter={() => handleAllCoursesClick(false)}
          >
            <p
              className="aauti_label"
              onClick={handleTeachWithAautiClick}
              style={{ color: pathname === "/teach-with-Aauti" && "#3083EF" }}
            >
              {constants.teach_with_aauti}
            </p>
          </Box>
          <Box className="institutions">
            <p
              className="aauti_label"
              style={{ color: pathname === "/institutes" && "#3083EF" }}
              onClick={handleInstitutionsClick}
            >
              {constants.institutions}
            </p>
          </Box>
        </Box>
      )}

      {hideContent && (
        <Box className="aauti_search_frame">
          {!hideSearch && (
            <Box className="aauti_search_input">
              <InputBase
                className="aauti_search_input_text"
                placeholder={constants.search_placeholder}
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyDown={(event) =>
                  isEnterPressed(event.key) && handleSearchButtonClick()
                }
              />
              <IconButton
                type="button"
                className="aauti_search_button_icon"
                aria-label="search"
                onClick={handleSearchButtonClick}
                id="SEARCH_ICON"
              >
                <RiSearchLine />
              </IconButton>
            </Box>
          )}
          {!hideSearch && (
            <IconButton
              type="button"
              className="aauti_search_button_icon_small_screen"
              aria-label="search"
              onClick={handleSearchButtonClick}
              id="SEARCH_ICON"
            >
              <RiSearchLine />
            </IconButton>
          )}
          {/* <IconButton onClick={handleSignInClick}>
          <GlobeIcon />
          <p className="aauti_label_country">₹ - INR</p>
        </IconButton> */}
          <IconButton onClick={() => closeCountryPop()} id="GLOBE_ICON">
            <GlobeIcon />
            <div
              style={{
                position: "absolute",
                top: 15,
                display: "flex",
                alignSelf: "center",
                background: "#fff",
                borderRadius: 10,
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ fontSize: 9 }}>{countryObject?.countryCurrency}</p>
            </div>
          </IconButton>
          {/* <IconButton onClick={handleSignInClick}>
          <HeaderCartIcon />
        </IconButton> */}

          {cartsDataBeforeLogin?.length ? (
            <IconButton onClick={() => navigate("/cart")} id="CART_ICON">
              <StyledBadge
                badgeContent={cartsDataBeforeLogin?.length || 0}
                color="primary"
              >
                <HeaderCartIcon />
              </StyledBadge>
            </IconButton>
          ) : (
            <IconButton onClick={() => navigate("/cart")} id="CART_ICON">
              <HeaderCartIcon />
            </IconButton>
          )}

          <Button
            variant="outlined"
            className="aauti_login_button"
            onClick={handleSignInClick}
            id="SIGN_IN"
          >
            {constants.sign_in_button}
          </Button>
          <Button
            variant="contained"
            className="aauti_signup_button"
            onClick={handleSignUpClick}
            id="GET_STARTED"
          >
            {constants.get_started_button}
          </Button>
        </Box>
      )}

      {hideContent && (
        <Box className="aauti_mobile_fame">
          {/* <IconButton onClick={handleSignInClick}>
          <HeaderCartIcon />
        </IconButton> */}
          <IconButton onClick={() => navigate("/cart")} id="CART_ICON">
            <HeaderCartIcon />
          </IconButton>
          <IconButton
            type="button"
            className="aauti_search_button_icon_small_screen"
            aria-label="search"
            onClick={handleSearchButtonClick}
            id="SEARCH_ICON"
          >
            <RiSearchLine />
          </IconButton>
          {/* <Button
            className="aauti_login_button_mobile"
            onClick={handleSignInClick}
          >
            Sign In
          </Button> */}
          <IconButton onClick={handleSignInClick} id="LOG_IN">
            <img src={userIcon} alt={"login"} className="sidebar-icon" />
          </IconButton>
        </Box>
      )}
      {/* <Box className="aauti_menu_button">
        <IconButton onClick={handleMenuButtonClick}>
          <MenuIcon />
        </IconButton>
      </Box> */}
      <>
        {/* {mobileDevices ? (
          <AautiDialog
            onClose={() => {
              closeCountryPop();
            }}
            open={toggleCurrency}
            dialogTitle={HeaderStrings?.SelectCurrency}
          >
            <CountrySelect
              {...{
                countryObject,
                countriesArray,
                // handleOnChange,
                filteredCountries,
                closeCountryPop,
              }}
            />
          </AautiDialog>
        ) : ( */}
        <BottomSheet
          onClose={() => {
            closeCountryPop();
          }}
          open={openCountrypop}
          dialogTitle={
            <AautiText
              weight={"bold"}
              title={HeaderStrings.SelectCurrency}
              textStyle={{ color: globalColors?.HEADING_COLOR }}
            />
          }
        >
          <CountrySelect
            {...{
              countryObject,
              handleOnChange,
              filteredCountries,
              closeCountryPop,
            }}
          />
        </BottomSheet>
        {/* )} */}
      </>
    </section>
  );
}
