import React from "react";
import AautiText from "./AautiText";

const AautiCheckbox = ({
  onChange,
  checked,
  inputLabel,
  inputId,
  disable = false,
  ...props
}) => {
  return (
    <div
      id="aauti_checkbox"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        gap: 4,
        marginBottom: 4,
        userSelect: "none",
      }}
    >
      <input
        disabled={disable}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        id={inputId}
        style={{
          height: "18px",
          width: "18px",
          cursor: "pointer",
        }}
        {...{ props }}
      />
      <label htmlFor={inputId}>
        <AautiText
          title={inputLabel}
          size={"semi"}
          textStyle={{ cursor: "pointer" }}
        />
      </label>
    </div>
  );
};

export default AautiCheckbox;
