const CourseDetailsStrings = {
  blackdeal: "Blockbuster Deal",
  location: "Location",
  lessonPlan: "Lesson Plan",
  whatWillLearn: "What You will Learn",
  cancelPolicy: "Cancellation Policy",
  addReview: "Add a Review",
  cancelUpto:
    "Cancel up to within 24 hours before the start time for a full refund.",
  reportCourse: "Report this Course",
  refundPolicy: "Refund Policy",
  noReviews: "No Reviews",
  otherCategories: "Similar Subcategories you may like",
  viewAll: "(View All)",
  sameCoursesbyOther: "Similar Courses by Other Service Providers",
  avgresponceTime: "Away. Avg.response time: 2Hrs",
  individualSlots: "Individual slots",
  daily: "Daily",
  weekly: "Weekly",
  groupSlots: "Group Slots",
  full: "Full",
  noSlots: "No Slots Available",
  review: "Reviews",
  createdBy: "Created By",
  experience: "Experience",
  courses: "Courses",
  students: "Students",
  overAllReviews: "Overall Reviews",
  noSubCatAvailable: "No SubCategories Available",
  nameUnavailabe: "Name unavailable",
  notAvalible: "N/A",
  expNotListed: "| Exp Not Listed",
  new: "New",
  notRegistered: "| Not Registered Yet!",
  from: "From",
  addedToWishList: "Added To WishList",
  removedFromWishlist: "Removed From WishList",
  notmentioned: "Not Mentioned",
  classType: "Class Type",
  lessons: "Lessons",
  buyNow: "Buy Now",
  addToCart: "Add to Cart",
  removeFromCart: "Remove from Cart",
  course: "course",
  mediaFiles: "Media & Files",
  courseType: "Course Type",
  yearsExp: "Years Exp",
  waitinglist: "Waiting List",
  viewBatch: "View Batch",
  available: "Available",
  gigs: "Gigs",
  highDemand: "High-demand",
  follow: "Follow",
  Full: `FULL`,
  courseReviews: "Course Reviews",
  allReviews: "All Reviews",
  RECENTLY_UPDATED: "Recently Updated",
};

export default CourseDetailsStrings;
