import React from "react";
import AautiText from "../globalComponents/AautiText";
import { Divider, TextField } from "@mui/material";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { CapitalizeFirstLetter } from "../CommonFunctions";
import { formatStartandEndTime } from "../../utils/Helpers";

const ConflictCard = ({ each }) => {
  return (
    <div
      style={{ width: "100%", border: "1px solid lightGrey", borderRadius: 5 }}
    >
      <AautiText
        title={each?.courseName}
        textStyle={{ color: "#3083EF", padding: "10px 15px" }}
      />
      <Divider
        color={"lightGrey"}
        sx={{
          width: "100%",
          //   margin: "5px 5px",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "10px 15px",
          gap: 10,
        }}
      >
        <AautiText
          title={
            each?.scheduleType == "course"
              ? each?.batchName
              : CapitalizeFirstLetter(each?.scheduleType)
          }
          // textStyle={{ padding: 10 }}
        />
        <AautiText
          size={"semi"}
          title={`${moment(each.startTime).format(
            "DD MMM YYYY"
          )}, ${formatStartandEndTime({
            start: each?.startTime,
            end: each?.endTime,
          })}`}
          // textStyle={{ padding: 10 }}
        />

        {/* <AautiText title={" - "} textStyle={{}} /> */}
        {/* <AautiText
          size={"semi"}
          title={moment(each?.endTime).format("LLL")}
          textStyle={{ marginLeft: 10 }}
        /> */}
      </div>
    </div>
  );
};

export default ConflictCard;
