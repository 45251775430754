import React, { useRef, useState } from "react";
import _, { isArray, isEmpty } from "lodash";
import Carousel from "react-grid-carousel";
import "./index.scss";
import VirtualIcon from "../../globalComponents/icons/VirtualIcon";
import InPerson from "../../globalComponents/icons/InPerson";
import { useSelector } from "react-redux";
import CourseDetailsStrings from "../courseDetails/CourseDeatilsStrings";
import AautiText from "../../globalComponents/AautiText";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { HiOutlineTrash } from "react-icons/hi2";
import { Button, IconButton } from "@mui/material";
import { LuClock4 } from "react-icons/lu";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { FiMonitor } from "react-icons/fi";
import { GoPerson } from "react-icons/go";
import VideoIcon from "../../../assets/images/video-818.svg";
import ImageIcon from "../../../assets/images/no-image.svg";
import {
  handleDocuments,
  PriceConversions,
  sortClassType,
} from "../../CommonFunctions";
import { BiSolidDiscount } from "react-icons/bi";
import { PartTypeView } from "aauti_lessonplan";
import AautiDialog from "../../globalComponents/AautiDialog";
import ReturnPolicy from "../../conditions/ReturnPolicy";
import {
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import { AautiStrings } from "../../globalComponents/AautiStrings";
import RefundSectionView from "../courseDetails/RefundSectionView";
import { levelStrings } from "../createCourse/Constants";
import { Position } from "@react-pdf-viewer/core";
import CustomBatchDetails from "./CustomBatchDetails";
import { showToast } from "../../globalComponents/Toast";
import PDFViewer from "pdf-viewer-reactjs";
import { globalColors } from "../../../themes/GlobalColors";
import { formatTime } from "../../../utils/Helpers";

const PreviewCourse = ({ object, setShowPreview }) => {
  //   const { each } = props;
  const [courseType, setCourseType] = useState(object?.courseType[0]);
  const [showActiveDisc, setShowActiveDisc] = useState(false);
  const [open, setOpen] = useState(false);
  const [courseDetials, setCourseDetails] = useState(object);
  const [refundPolicyState, setRefundPolicyState] = useState(false);
  const [docOpen, setDocOpen] = useState(false);
  const [type, setType] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const videoRef = useRef();
  const removeTopicByaccepting = (i, feild) => {
    let val = object;
    val?.[feild].splice(i, 1);
    setCourseDetails(val);
    setRefresh(!refresh);
  };
  let imagesList =
    [courseDetials?.courseImage, ...courseDetials?.relatedImages] || [];
  const { mobileDevices, ipodDevices, aboveLargeMobileDevices } = useSelector(
    (state) => state.responsive
  );
  const { convertToMillionBillions } = PriceConversions();
  let list = [
    { name: "Levels", code: "courseLevel" },
    { name: "Age Group", code: "targetAgeGroups" },
  ];
  if (object?.courseType?.includes("Individual")) {
    list = [...list, { name: "Languages-Individual", code: "languages" }];
  }
  if (object?.courseType?.includes("Group")) {
    list = [...list, { name: "Languages-Group", code: "groupLanguage" }];
  }
  const returnListing = (title, list) => {
    const returnSortedList = (arr) => {
      if (title == "Levels") {
        const sortedStrings = [...arr]?.sort();
        return sortedStrings;
      }
      return arr;
    };
    if (title === "Languages-Group" || title === "Languages-Individual") {
      const isArrayStrings = isArray(list);
      return (
        <div
          className="flexStyles"
          style={{ display: isEmpty(list) ? "none" : "flex" }}
        >
          <AautiText
            size={"semi"}
            title={title}
            textAlign={"flex-start"}
            className={"rightCont-text3"}
            weight={"bold"}
          />
          <AautiText
            size={"semi"}
            className={"rightCont-text4"}
            weight={"medium"}
            title={
              list
                ? isArrayStrings
                  ? list?.join(", ")
                  : list
                : CourseDetailsStrings?.notmentioned
            }
          />
        </div>
      );
    }
    return (
      <div className="flexStyles" style={{ display: isEmpty(list) && "none" }}>
        <AautiText
          size={"semi"}
          title={title}
          textAlign={"flex-start"}
          weight={"bold"}
          className={"rightCont-text3"}
        />
        {isEmpty(list) ? (
          <AautiText
            size={"semi"}
            className={"rightCont-text4"}
            title={CourseDetailsStrings?.notmentioned}
          />
        ) : (
          <div className="rightCont-div1">
            {returnSortedList(list)?.map((item, index) => (
              <AautiText
                size={"semi"}
                className={"rightCont-div6"}
                weight={"medium"}
                key={index}
                textStyle={{
                  color: globalColors.AAUTI_THEME_COLOR,
                  // cursor: "pointer",
                  display: "block",
                  textDecoration: "underline",
                }}
                title={`${item}${
                  index < returnSortedList(list)?.length - 1 ? "," : ""
                }`}
                // onClick={() => navigate("/search-result")}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const returnIcon = () => {
    if (courseDetials?.virtual && courseDetials?.inPerson) {
      return (
        <div className="rightCont-div2">
          <AautiText title={"Virtual, In-person"} size={"semi"} />
        </div>
      );
    } else if (courseDetials?.virtual) {
      return <AautiText title={"Virtual"} size={"semi"} />;
    } else if (courseDetials?.inPerson) {
      return <AautiText title={"In-person"} size={"semi"} />;
    }
  };

  const returnDate = (date, type) => {
    let formattedDate;
    if (type == "time") {
      formattedDate = formatTime({ time: date });
    } else {
      formattedDate = moment(date).format("DD MMM YYYY");
    }
    return formattedDate || "NA";
  };

  const SlotCard = (item, index, method, methodType) => {
    console.log(item, "item?...");
    const isGroup = methodType == "groupDaily" || methodType == "groupWeekly";
    const activeDiscountBatch = showActiveDisc === index;
    const checkDiscount =
      moment(item?.discountStartDate).isBefore(moment(new Date())) &&
      moment(item?.discountEndDate).isAfter(moment(new Date())) &&
      (item?.individualDiscountValue || item?.groupDiscountValue) &&
      (item?.groupDiscountPrice || item?.individualDiscountPrice);
    const currentDate = moment();
    const isFutureDate = moment(item?.discountStartDate).isAfter(currentDate);

    const returnDiscountType = (item) => {
      const value = !isGroup
        ? item?.localIndividualDiscountValue
        : item?.localGroupDiscountValue;

      if (item?.discountType !== "Amount") {
        return value ? `(${value}%)` : null;
      }
      return value ? `(-${convertToMillionBillions(value || 0)})` : "";
    };
    const returnWeek = (weeks) => {
      const nonEmptyElements = [];
      for (const key in weeks) {
        if (weeks[key].length > 0) {
          nonEmptyElements.push(
            <div
              key={key}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 4,
                marginBottom: 8,
              }}
            >
              <LuClock4 size={20} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <AautiText
                  title={`${returnDate(
                    weeks[key][0]?.startTime,
                    "time"
                  )}-${returnDate(weeks[key][0]?.endTime, "time")}`}
                />
                <div
                  style={{
                    background: "#066cb5",
                    padding: "2px 6px",
                    borderRadius: 4,
                  }}
                >
                  <AautiText
                    title={key.slice(0, 3)}
                    textStyle={{ color: "#ffffff" }}
                  />
                </div>
              </div>
            </div>
          );
          // categories();
        }
      }
      return nonEmptyElements;
    };

    return (
      <div
        style={{
          width: "100%",
          border: "1px solid lightgrey",
          borderRadius: 4,
          background: "none",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: 8,
            background: "#efefef",
          }}
        >
          <AautiText
            size={"semi"}
            weight={"bold"}
            textStyle={{
              color: "#1e1e1e",
              width: !isGroup ? "90%" : "75%",
            }}
            title={item?.batchName}
          />
          <div style={{ display: !isGroup && "none" }}>
            <AautiText
              title={`Available ${item?.maxNoOfStudents || 0}`}
              size={"semi"}
              weight={"bold"}
              textStyle={{ color: "green" }}
            />
          </div>
        </div>
        <div style={{ padding: 8 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              marginBottom: 8,
            }}
          >
            <CalendarMonthIcon
              style={{ width: "20px", height: "20px" }}
              size={20}
            />
            <AautiText title={method} size={"semi"} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
              marginBottom: 8,
            }}
          >
            <MdOutlineCalendarMonth size={20} />
            <AautiText
              title={`${returnDate(item?.startDate)}-${returnDate(
                item?.endDate
              )}`}
              size={"semi"}
            />
          </div>
          {item?.recurringDays ? (
            returnWeek(item?.recurringDays)
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 5,
                marginBottom: 8,
              }}
            >
              <LuClock4 size={20} />
              <AautiText
                size={"semi"}
                title={`${returnDate(item?.startTime, "time")}-${returnDate(
                  item?.endTime,
                  "time"
                )}`}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 10,
              marginTop: 8,
            }}
          >
            {item?.virtual ? (
              <FiMonitor fontSize="medium" />
            ) : (
              <GoPerson fontSize="medium" color="red" />
            )}

            <AautiText
              title={`${convertToMillionBillions(
                isGroup ? item?.localGroupPrice : item?.localIndividualPrice
              )}`}
              size={"semi"}
              weight={"bold"}
              textStyle={{
                color: "#3166ba",
                textDecoration:
                  checkDiscount && returnDiscountType(item)
                    ? "line-through"
                    : "",
              }}
            />
            <AautiText
              title={returnDiscountType(item)}
              size={"semi"}
              textStyle={{
                color: "#3166ba",
                display:
                  (!returnDiscountType(item) || !checkDiscount) && "none",
              }}
            />
            {returnDiscountType(item) && (
              <AautiText
                title={`${convertToMillionBillions(
                  (!isGroup
                    ? item?.localIndividualDiscountPrice
                    : item?.localGroupDiscountPrice) || 0
                )}`}
                weight={"bold"}
                textStyle={{
                  color: "#3166ba",
                  display: !checkDiscount && "none",
                }}
              />
            )}
          </div>
          <div
            style={{
              display:
                isFutureDate &&
                (item?.localIndividualDiscountValue ||
                  item?.localGroupDiscountValue)
                  ? "flex"
                  : "none",
              marginBottom: 6,
              marginTop: 6,
              alignItems: "center",
            }}
          >
            <BiSolidDiscount size={20} />
            <AautiText
              size={"small"}
              title={`(${
                returnDate(item?.discountStartDate) +
                " - " +
                returnDate(item?.discountEndDate)
              })`}
              textStyle={{ marginLeft: 10 }}
            />
          </div>

          <div
            style={{ display: item?.allowMembers ? "flex" : "none" }}
            className="family-discount"
          >
            <button
              onClick={() => {
                if (showActiveDisc !== "") {
                  setShowActiveDisc("");
                } else {
                  setShowActiveDisc(index);
                }
              }}
              className="family-btn"
            >
              <AautiText
                size={"semi"}
                title={"View discount for family members"}
              />
              {activeDiscountBatch ? (
                <IoIosArrowDown size={16} />
              ) : (
                <IoIosArrowForward size={16} />
              )}
            </button>
            <div
              className={`${activeDiscountBatch ? "expand" : "hide-expand"} `}
            >
              <AautiText
                title={
                  "If one person subscribes, the total price will be applied."
                }
                textAlign={"left"}
                size={"small"}
              />

              {item?.membersDiscount?.map((each, index) => {
                return (
                  <AautiText
                    key={index}
                    title={`For ${each?.noOfMembers} ${
                      each?.noOfMembers == 1 ? "member" : "members"
                    } ${each?.percentage}% discount will be applied.`}
                    textAlign={"left"}
                    size={"small"}
                  />
                );
              })}
            </div>
          </div>
          <CustomBatchDetails courseBatch={item} showRanges={false} />
        </div>
      </div>
    );
  };

  const returnSession = () => {
    const sessionMap = courseDetials?.lessonPlan?.lessons?.flatMap((session) =>
      session.topics.map((topic) => topic.topicName)
    );

    return sessionMap;
  };
  const sessionsMap = returnSession();

  const detailsDocuments = handleDocuments(courseDetials?.relatedDocuments);

  return (
    <div
      className="content-con1"
      style={{ padding: mobileDevices && 8, position: "relative" }}
    >
      {/* <IconButton
        onClick={() => setShowPreview(false)}
        sx={{
          width: "fit-content",
          cursor: "pointer",
          marginBottom: "10px",
          position: "absolute",
          right: 10,
          top: -10,
        }}
      >
        <IoIosCloseCircleOutline />
      </IconButton> */}
      {/* <AautiText
        textAlign={"right"}
        title={"Close"}
        textStyle={{
          color: "#00000099",
          marginRight: 10,
          alignSelf: "flex-end",
          width: "fit-content",
          cursor: "pointer",
          marginBottom: "10px",
          position: "absolute",
          right: 10,
          top: -10,
        }}
      /> */}
      <div
        className="content-con"
        style={{
          flexDirection:
            mobileDevices || ipodDevices || aboveLargeMobileDevices
              ? "column"
              : "row",
          width: mobileDevices ? "98%" : "96%",
          marginTop: 15,
        }}
      >
        <div
          className="detailsLeft-div14"
          style={{
            width:
              mobileDevices || ipodDevices || aboveLargeMobileDevices
                ? "100%"
                : "60%",
            background: "#fff",
            padding: 10,
            borderRadius: 4,
          }}
        >
          <div
            className="left-content"
            style={{
              marginTop: mobileDevices && 10,
              border: "none",
            }}
          >
            {isEmpty(imagesList) ? (
              <img
                src={courseDetials?.courseImage}
                alt="Course-img"
                className="detailsLeft-div1"
              />
            ) : (
              <div className="detailsLeft-div2">
                <Carousel
                  cols={1}
                  rows={1}
                  gap={10}
                  autoplay={3000}
                  loop={true}
                >
                  {imagesList?.map((each, index) => {
                    return (
                      <Carousel.Item key={index}>
                        <div
                          key={index}
                          className="detailsLeft-carousel1"
                          style={{
                            backgroundImage: `url(${each})`,
                          }}
                        >
                          <div className="detailsLeft-div3"></div>
                          <img
                            alt="related-imgs"
                            width="100%"
                            src={
                              each ||
                              "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
                            }
                            className="detailsLeft-img1"
                          />
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
            )}
          </div>

          <div className="overview-container3">
            <div style={{ width: mobileDevices ? "98%" : "94%", marginTop: 5 }}>
              <AautiText
                size={"normal"}
                weight={"bold"}
                className={"detailsLeft-text1"}
                title={courseDetials?.courseName}
              />
              <AautiText
                title={courseDetials?.summary}
                size={"semi"}
                className={"detailsLeft-text2"}
              />
            </div>
            {!isEmpty(object?.lessonPlan) && (
              <div style={{ width: "100%", marginTop: "10px" }}>
                <div className="detailsLeft-div11">
                  <AautiText
                    title={AautiStrings?.LessonPlanString}
                    weight={"bold"}
                    size={"extraMedium"}
                    textStyle={{ color: "#5C5B57" }}
                  />
                </div>
                <AautiText
                  title={
                    courseDetials?.lessonPlan?.contentName ||
                    courseDetials?.lessonPlan?.name
                  }
                  weight={"bold"}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <AautiText
                    title={
                      courseDetials?.lessonPlan.contentDescription ||
                      courseDetials?.lessonPlan.description
                    }
                    size={"semi"}
                    textStyle={{
                      color: "#626972",
                      margin: "6px 0px",
                      width: "80%",
                    }}
                  />

                  <div
                    style={{
                      display: isEmpty(sessionsMap) && "none",
                      marginBottom: 5,
                    }}
                  >
                    <AautiText
                      title={"Sessions"}
                      weight={"bold"}
                      className={"detailsLeft-text4"}
                    />
                    {sessionsMap?.map((each, index) => {
                      return (
                        <AautiText
                          key={index}
                          title={`${index + 1}. ${each}`}
                        />
                      );
                    })}
                  </div>

                  <Button
                    onClick={() => {
                      setOpen(true);
                    }}
                    style={{
                      width: "auto",
                      padding: 0,
                    }}
                  >
                    <AautiText
                      title={AautiStrings?.ViewLessonPlanString}
                      textStyle={{ textTransform: "capitalize" }}
                    />
                  </Button>
                </div>
              </div>
            )}
          </div>

          <div className="overview-container3">
            <AautiText
              title={CourseDetailsStrings?.whatWillLearn}
              textAlign={"left"}
              weight={"bold"}
              className={"detailsLeft-text4"}
            />
            <AautiText
              title={courseDetials?.courseDescription}
              textAlign={"left"}
              size={"semi"}
              className={"detailsLeft-text8"}
            />
            {/* <AautiText
              title={CourseDetailsStrings?.cancelPolicy}
              textAlign={"left"}
              weight={"bold"}
              className={"detailsLeft-text4"}
            />
            <AautiText
              title={CourseDetailsStrings.cancelUpto}
              textAlign={"left"}
              size={"semi"}
              className={"detailsLeft-text7"}
            />
            <div className="report-container2">
              <AautiText
                title={CourseDetailsStrings?.refundPolicy}
                size={"semi"}
                weight={"bold"}
                onClick={() => {
                  setRefundPolicyState(true);
                }}
                className={"detailsLeft-text9"}
              />
            </div> */}
          </div>
        </div>
        <div
          className="right-content"
          style={{
            width:
              mobileDevices || ipodDevices || aboveLargeMobileDevices
                ? "100%"
                : "38%",
            marginTop: mobileDevices || ipodDevices ? "20px" : "",
            height: "86vh",
            overflow: "scroll",
            padding: 15,
            background: "#fff",
            borderRadius: 4,
          }}
        >
          <div className="head-top-cards" style={{ width: "100%" }}>
            <div className="flexStyles">
              <AautiText
                title={"Category"}
                size={"semi"}
                weight={"bold"}
                textAlign={"left"}
                className={"rightCont-text2"}
              />
              <div className="flexItem">
                <AautiText
                  title={`${courseDetials?.category || ""}-${
                    courseDetials?.subCategory || ""
                  }`}
                  size={"semi"}
                />
              </div>
            </div>
            <div className="flexStyles">
              <AautiText
                title={CourseDetailsStrings?.courseType}
                size={"semi"}
                weight={"bold"}
                textAlign={"left"}
                className={"rightCont-text2"}
              />
              <div className="flexItem">
                {sortClassType(courseDetials?.courseType)?.map(
                  (each, index) => {
                    return (
                      <div key={index} className="rightCont-div4">
                        <label className="rightCont-div6">
                          <AautiText
                            title={`${each}${
                              index < courseDetials.courseType.length - 1
                                ? ","
                                : ""
                            }`}
                            size={"semi"}
                          />
                        </label>
                      </div>
                    );
                  }
                )}
              </div>
            </div>

            <div className="flexStyles">
              <AautiText
                title={CourseDetailsStrings?.classType}
                size={"semi"}
                weight={"bold"}
                className={"rightCont-text2"}
              />
              <AautiText
                size={"semi"}
                title={returnIcon()}
                className={"rightCont-text4"}
              />
            </div>
            <div className="rightCont-div7">
              {list?.map((item, index) => (
                <div key={index} className="rightCont-div7">
                  {returnListing(item.name, courseDetials[item?.code])}
                </div>
              ))}
            </div>

            {courseDetials?.relatedDocuments?.length > 0 && (
              <div
                className="overview-container3"
                style={{
                  display: detailsDocuments.flat().length <= 0 && "none",
                }}
              >
                <AautiText
                  title={CourseDetailsStrings?.mediaFiles}
                  weight={"bold"}
                  size={"extraMedium"}
                />
                <div className="container-realted-documents-map">
                  {detailsDocuments?.map((each, index) => {
                    return (
                      <>
                        {each?.length > 0 && (
                          <div
                            className="document-img-course"
                            style={{
                              cursor: "pointer",
                              minWidth: "82px",
                              maxWidth: "100px",
                              backgroundImage: `url(${
                                index == 1
                                  ? VideoIcon
                                  : index == 0
                                  ? ImageIcon
                                  : "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"
                              })`,
                            }}
                            onClick={() => {
                              setType(index);
                              setDocOpen(true);
                            }}
                          >
                            <AautiText
                              title={each?.length}
                              textAlign={"center"}
                              className={"rightCont-text1"}
                            />
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            )}
            <RefundSectionView
              completeDetails={courseDetials}
              onClickRefund={() => setRefundPolicyState(true)}
            />
          </div>

          <div
            style={{
              width: "100%",
              marginTop: "10px",
            }}
          >
            <div className="available-timings9">
              <AautiText
                title={AautiStrings?.BatchesString}
                weight={"bold"}
                size={"normal"}
                textStyle={{ color: "#1E1E1E" }}
              />
              <div className="available-timings2" style={{ marginTop: 10 }}>
                {sortClassType(courseDetials?.courseType)?.map(
                  (each, index) => {
                    return (
                      <div
                        onClick={() => setCourseType(each)}
                        key={index}
                        style={{
                          border:
                            each === courseType
                              ? "1px solid #3083EF"
                              : "1px solid lightgrey",
                          alignSelf: "center",
                          display: "flex",
                          justifyContent: "center",
                          width: "48%",
                          cursor: "pointer",
                          padding: "8px",
                          borderRadius: 4,
                        }}
                        // className="timing-type"
                      >
                        <AautiText
                          title={each}
                          weight={"medium"}
                          textStyle={{
                            color: each === courseType ? "#3083EF" : "grey",
                            alignSelf: "center",
                          }}
                          size={"medium"}
                        />
                      </div>
                    );
                  }
                )}
              </div>
              {courseType === "Individual" && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                >
                  {courseDetials?.individualDaily?.map((item, index) => {
                    return SlotCard(item, index, "Daily", "individualDaily");
                  })}
                  {courseDetials?.individualWeekly?.map((item, index) => {
                    return SlotCard(item, index, "Weekly", "individualWeekly");
                  })}
                </div>
              )}
              {courseType === "Group" && (
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 10 }}
                >
                  {courseDetials?.groupDaily?.map((item, index) => {
                    return SlotCard(item, index, "Daily", "groupDaily");
                  })}
                  {courseDetials?.groupWeekly?.map((item, index) => {
                    return SlotCard(item, index, "Weekly", "groupWeekly");
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AautiDialog open={open} onClose={() => setOpen(false)}>
        <PartTypeView PTData={courseDetials?.lessonPlan} />
      </AautiDialog>
      <AautiDialog open={docOpen} onClose={() => setDocOpen(false)}>
        <div className="detailsLeft-div2">
          <Carousel cols={1} rows={1} gap={10}>
            {handleDocuments(courseDetials?.relatedDocuments)[type]?.map(
              (each, index) => {
                return (
                  <Carousel.Item key={index}>
                    {type == 0 ? (
                      <div
                        key={index}
                        className="detailsLeft-carousel1"
                        style={{
                          backgroundImage: `url(${each})`,
                        }}
                      >
                        <div className="detailsLeft-div3"></div>
                        <img
                          alt="related-imgs"
                          width="100%"
                          src={
                            each ||
                            "https://svbk-lms.s3.ap-south-1.amazonaws.com/aautie/No_Image_Available.jpg"
                          }
                          className="detailsLeft-img1"
                        />
                      </div>
                    ) : type == 1 ? (
                      <div className="detailsLeft-carousel1">
                        <video
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                          autoPlay
                          ref={videoRef}
                          src={each}
                          muted={true}
                          controls={true}
                          preload="metadata"
                          loop
                        />
                      </div>
                    ) : (
                      <div style={{ height: "80vh" }}>
                        <PDFViewer
                          hideNavbar
                          document={{
                            url: each,
                          }}
                        />
                      </div>
                    )}
                  </Carousel.Item>
                );
              }
            )}
          </Carousel>
        </div>
      </AautiDialog> 
      <ReturnPolicy {...{
        open: refundPolicyState,
        setOpen: setRefundPolicyState,
        showFooter: false
      }} />
    </div>
  );
};

export default PreviewCourse;
