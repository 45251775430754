import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import './Style.scss'
import { globalColors } from '../../themes/GlobalColors';

const CommentTextField = ({
    value,
    onChange,
    onSubmit,
    onCancel,
    setEditID,
}) => {
    return (
        <div className="RRmarginNew">
            <TextField
                id="standard-basic"
                variant="standard"
                sx={{ width: '100%' }}
                size="small"
                value={value}
                onChange={onChange}
                onKeyDown={(event) => {
                    if (value?.length > 0 && event.key === 'Enter') {
                        onSubmit();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => {
                                    setEditID('');
                                    onCancel();
                                }}
                            >
                                <ClearIcon />
                            </IconButton>
                            {value?.length > 0 && (
                                <IconButton onClick={onSubmit}>
                                    <SendIcon sx={{ color: globalColors.AAUTI_THEME_COLOR }} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default CommentTextField;
