import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { formatStartandEndTime } from "../../../utils/Helpers";

const SlotFormats = ({ timeSlots }) => {
  const renderTimeSlots = () => {
    return Object.keys(timeSlots).map((day) => {
      const slots = timeSlots[day];
      return slots.map((slot, index) => {
        return (
          <div
            key={`${day}-${index}`}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 5,
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <AautiText title={`${day}:`} size={"semi"} weight={"bold"} />
            <AautiText
              title={formatStartandEndTime({
                start: slot.startTime,
                end: slot.endTime,
              })}
              size={"semi"}
            />
          </div>
        );
      });
    });
  };

  return <div>{renderTimeSlots()}</div>;
};

export default SlotFormats;
