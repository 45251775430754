import dayjs from "dayjs";

export const Grades = [
  "Pre School",
  "primary School",
  "Class 1",
  "Class 2",
  "Class 3",
  "Class 5",
  "Middle School",
  "School 6",
  "School 7",
  "Scholl 8",
  "Secondary School",
  "School 9",
  "School 10",
  "Senior Secondary School",
  "Class 11",
  "Class 12",
  "Undergraduate College",
  "Post Graduate College",
  "Research",
  "Professional Training",
  "B",
];
export const Relation = [
  "Child",
  "Brother",
  "Sister",
  "Cousin",
  "Nephew",
  "Buddy",
];
export const clearObj = {
  firstName: "",
  lastName: "",
  dateOfBirth: dayjs().subtract(3, "year"),
  email: "",
  displayName: "",
  password: "",
  grades: [],
  userName: "",
  relation: "",
  isIndependent: false,
  isEmailVerified: false,
  parentId: "",
};

export const MembersStrings = {
  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  DOBRequired: "Please select DOB",
  displayNameRequired: "Display name is required",
  relationRequired: "Please select Relation",
  emailRequired: "Email is required",
  userNameRequired: "Username is Required",
  pwdRequired: "Password is required",
  gradeRequired: "Please select grade",
  displayName: "Display Name",
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  userName: "User Name",
  pwd: "Password",
  independentMsg: "Automatically make this account independent after 18 years.",
  sendInvitation: "Send an Invitation ? ",
  inviteMember: "Invite Member",
  inviteMemberHeaderMsg:
    "We will send an invitation to connect their account with yours.",
  createAccount: "Create a new account ? ",
  addMember: "Add Member",
  yourMembers: "Your Members",
  yourInvitees: "Your Invitees",
  yourMembersHeaderMsg: "Select a member to view their progress.",
  notifications: "Notifications",
  ongoingCourses: "Ongoing Courses",
  viewAll: "(View All)",
  enrolledCourses: "Enrolled Courses",
  enterOTPEmail: "Please enter the OTP sent to your Email",
  enterOTPPhone: "Please enter the OTP sent to your Mobile",
  notReceivedOTP: "Didn't receive an OTP ?",
  resendOTP: "Resend OTP",
  assesments: "Assesments",
  lotteImage:
    "https://lottie.host/5b29736b-deb6-4f29-8d4a-d382cb9dfb36/50pXE0LtwG.json",
  success: "Success",
  courses: "Courses",
  attendance: "Attendance",
  batchName: "Batch name:",
  sessionCount: "Sessions count:",
  session: "Session",
  sessions: "Sessions",
  attended: ":  Attended",
  notAttended: ":  Not attended",
  sessionPercentage: "Session percentage:",
  noData: "No Data",
  right: "Right",
  onGoing: "Ongoing",
  enrolled: "Enrolled",
  allCourses: "All courses",
  mobile: "Mobile",
  passwordHelper: "(mix of letters,numbers and symbols in 8-14 characters)",
  altImage:
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
  errorMsg: "Something went wrong",
  noUserFound: "No users found",
  userAlreadyExists: "User already exists with given Email",
  updateMember: "Update member",
  fileSizeExeeds: "File size exceeds",
  altText: "NO",
  datePicker: "DatePicker",
  dateOfBirth: "DOB",
  relation: "Relation",
  selectGrade: "Select Grade",
  invite: "Invite",
  mobileString: "Mobile number length  should be 10",
  update: "Update",
  add: "Add",
  submit: "submit",
  submitCap: "Submit",
  selfInvitationNotAccepted: "Self invitation not accepted",
  inviteList: "Invite list",
  or: "Or",
  memberListEmpty: "Your members list is empty",
  inviteesListEmpty: "Your invitees list is empty",
  keepExploring: "Keep Exploring!",
  otp: "OTP",
};
