import React from "react";
import AautiText from "../../globalComponents/AautiText";
import { GoDotFill } from "react-icons/go";
import { FaArrowRight } from "react-icons/fa6";
import "./index.scss";
import { IconButton } from "@mui/material";

const Tab = ({
  each,
  activeField,
  setActiveField,
  CreateCourseObj,
  showContent,
  checkSlot,
  isGig,
  hasData = false,
}) => {
  const returnTag = (item) => {
    if (item?.id == 4 && isGig) {
      return "Gig Pricing";
    } else {
      return item.name;
    }
  };

  return (
    <div
      className="tab"
      style={{
        display: activeField === each.name ? "none" : "block",
        cursor: "pointer",
      }}
      onClick={() => {
        setActiveField(each.name);
      }}
    >
      <div className="text-icons">
        <AautiText
          size="big"
          weight={"bold"}
          title={returnTag(each)}
          textStyle={{ marginBottom: 1, width: "80%" }}
        />
        <div className="icons-flex">
          <GoDotFill
            color={
              showContent || checkSlot || hasData
                ? "green"
                : each?.name == "Lesson Plan"
                ? "#FFBF00"
                : "red"
            }
          />
          <IconButton
            onClick={() => {
              setActiveField(each.name);
            }}
          >
            <FaArrowRight size={20} />
          </IconButton>
        </div>
      </div>
      {!(each?.id == 4 && isGig) && (
        <AautiText
          textStyle={{ color: "#5C5B57" }}
          size={"small"}
          title={
            showContent
              ? CreateCourseObj?.courseName
              : isGig && each.name == "Title"
              ? "Creating the gig allows you the flexibility to choose from various categories, including Levels, Category, Type, Location, Age, Description, and Tags."
              : each?.text
          }
        />
      )}
    </div>
  );
};

export default Tab;
