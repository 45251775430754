import React from "react";
import AautiText from "../../../globalComponents/AautiText";
import { chatConstants } from "../../constants";
import { BsFileEarmarkText } from "react-icons/bs";
import Audio from "../messages/audio/audio";
import './styles.scss'

function FilePreview({id='file-preview', file }) {
  if (file.type.includes("image"))
    return (
      <img
        src={URL.createObjectURL(file)}
        alt={"alt"}
        loading="lazy"
        key={file?._id ?? file.name}
        className="full_dimensions"
      />
    );

  function renderFileByMimeType() {
    if (file.type.includes("audio")) return renderAudio();
    if (file.type.includes("video")) return renderVideo();
    return (
      <div className="no_preview_wrapper full_dimensions">
        <BsFileEarmarkText size={40} />
        <AautiText title={chatConstants.unableToPreviewFile} />
      </div>
    );
  }

  function renderVideo() {
    return (
      <video
        className="full_dimensions"
        src={URL.createObjectURL(file)}
        controls
        controlsList="nodownload"
      />
    );
  }

  function renderAudio() {
    return (
      <div className="no_preview_wrapper full_dimensions">
      <Audio
        {...{ id: `${id}-audio`, src: file.uri, type: file.type }}
      />
      </div>
    );
  }

  return (
    <div className="preview_wrapper">
      {renderFileByMimeType()}
    </div>
  );
}

export default FilePreview;
