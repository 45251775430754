import { ApiMethods, ApiPaths } from "../../service/api-constants";
import { ApiRequest } from "../../service/api-request";

/** login authentication
 * @param username, password
 */

function getAllCategories(onSuccess, onFailure) {
  ApiRequest({ url: ApiPaths.GET_ALL_CATEGORIES, method: ApiMethods.GET }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

const getSessionsBetweenDatesUrl = (role, id) => {
  if (role == "serviceprovider") {
    return `session/getServiceProviderSessionByStartDateAndEndDate?serviceProviderId=${id}`;
  } else {
    return `session/getEndUserSessionByStartDateAndEndDate?endUserId=${id}`;
  }
};

function getSessionApi(
  role,
  serviceProviderId,
  endDate,
  startDate,
  onSuccess,
  onFailure
) {
  ApiRequest({
    url: getSessionsBetweenDatesUrl(role, serviceProviderId),
    method: ApiMethods.GET,
    params: { endDate, startDate },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getToDoList(serviceProviderId, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TODO_LIST,
    method: ApiMethods.GET,
    params: { serviceProviderId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getTrendingCourse(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.TRENDING_COURSES,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getTopSeviceProviders(data, onSuccess, onFailure) {
  ApiRequest({
    url: "ratingsReviews/getTopServiceProvidersRatingsReviews",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getTopServiceProvidersBeforeLogin(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_TOPSERVICEPROVIDERS_BEFORE_LOGIN,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

// function getNewServiceProviders(count, onSuccess, onFailure) {
//   ApiRequest({
//     url: ApiPaths.GET_NEWSERVICEPROVIDERS + `?count=${count}`,
//     method: ApiMethods.GET,
//   }).then(
//     (response) => {
//       onSuccess(response?.data);
//     },
//     (error) => {
//       onFailure();
//     }
//   );
// }

function getRecentgigWithRecords(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_RECENT_GIGS,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getRecentAllGigs(onSuccess, onFailure) {
  ApiRequest({
    url: `gig/getAllGigs`,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}

function getCoursesNextEightHours(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_COURSES_NEXT_EIGHT_HOURS,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getBlockBusterDeals(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_BLOCK_BUSTER_DEALS,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getFreeDemoCourses(onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.BASE_URL + ApiPaths.GET_FREE_LIVE_CLASES,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getAllPackages(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_ALL_PACKAGES,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function getUpcomingCourses(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_UPCOMING_COURSES,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function DeleteCartById(id, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/deleteCartById",
    method: ApiMethods.DELETE,
    params: { id },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function wishlistServiceProvider(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_WISHLIST_WISHLIST_SERVICEPROVIDER,
    method: ApiMethods.POST,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
function deleteWishlistedServiceProvider(wishListData, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.POST_WISHLIST_DELETE_WISHLIST_SERVICEPROVIDER,
    method: ApiMethods.DELETE,
    data: wishListData,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getPackagesByServiceProviderId(data, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_PACKAGES_BY_SERVICEPROVIDER_ID,
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export function getPackageDetailsById(id, onSuccess, onFailure) {
  ApiRequest({
    url: ApiPaths.GET_PACKAGE_DETAILS_BY_ID + id,
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getUpcomingCoursesByUserId(userId, onSuccess, onFailure) {
  ApiRequest({
    url:
      ApiPaths.GET_UPCOMING_COURSES_BY_USER_ID +
      "?userId=" +
      userId +
      "&noOfDays=150&count=25&offset=0",
    method: ApiMethods.GET,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

function getMyGigsForServiceProvider(data, onSuccess, onFailure) {
  // alert("Hello");
  ApiRequest({
    url: "gig/getGigListByServiceProviderId",
    method: ApiMethods.GET,
    params: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}

export const AuthService = {
  getAllCategories,
  getPackageDetailsById,
  getTopSeviceProviders,
  // getNewServiceProviders,
  getUpcomingCourses,
  getAllPackages,
  getFreeDemoCourses,
  getBlockBusterDeals,
  getCoursesNextEightHours,
  getRecentgigWithRecords,
  deleteWishlistedServiceProvider,
  wishlistServiceProvider,
  getTopServiceProvidersBeforeLogin,
  getTrendingCourse,
  getRecentAllGigs,
  getPackagesByServiceProviderId,
  getSessionApi,
  getMyGigsForServiceProvider,
  getUpcomingCoursesByUserId,
  getToDoList,
};
