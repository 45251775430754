import {
  Backdrop,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { HighlightOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";

const AautiDialog = ({
  onClose,
  showCloseIcons,
  open,
  dialogTitle,
  children,
  minWidth = 500,
  maxWidth = 700,
  sxStyles,
  dialogWidth = "100%",
  titlePosition = "center",
  // maxWidth = 700,
  fullScreen,
  showHeader = true,
  backDropClose = true,
  overflow = "auto",
}) => {
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );

  function PaperComponent(props) {
    return (
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: { backdropFilter: "blur(4px)" },
      }}
      onClose={backDropClose ? onClose : () => {}}
      open={open}
      aria-labelledby="draggable-dialog-title"
      sx={{
        minWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
        // maxWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
        zIndex: 502,
        "& .MuiDialog-paper": {
          width: `${dialogWidth} !important`,
          minWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
          maxWidth: maxWidth,
          zIndex: 502,
          overflow: overflow,
          ".css-7stoaa-MuiDialogContent-root": {
            padding: "0px 30px 30px 30px !important",
          },
        },
        // "& .MuiDialogContent-root": {
        //   overflowY: "hidden",
        //   paddingLeft: { xs: "15px", sm: 0 },
        //   paddingRight: { xs: "15px", sm: 0 },
        // },
        ...sxStyles,
      }}
    >
      <DialogTitle
        sx={{ display: !showHeader ? "none" : "flex", alignItems: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            alignSelf: "center",
            justifyContent: "center",
            flexGrow: 1,
            width: "100%",
          }}
        >
          <Typography
            align={titlePosition}
            component="p"
            variant="p"
            sx={{
              fontWeight: 500,
              fontSize: mobileDevices ? "15px" : ipodDevices ? "15px" : "18px",
            }}
            id="alert-dialog-title"
          >
            {dialogTitle}
          </Typography>
        </Box>
        {!showCloseIcons && (
          <Box sx={{ position: "absolute", right: "5px", top: "10px" }}>
            <IconButton size="medium" onClick={onClose}>
              <HighlightOff />
            </IconButton>
          </Box>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          color: "text.secondary",
          fontSize: 14,
          p: mobileDevices ? "8px !important" : "30px !important",
          minWidth: ipodDevices ? "auto" : mobileDevices ? 200 : minWidth,
          ...sxStyles,
        }}
        id="alert-dialog-description"
      >
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default AautiDialog;
