import { ApiRequest } from "../../service";
import { ApiMethods, ApiPaths } from "../../service/api-constants";
export function getMembers(parentId, onSuccess, onFailure) {
  ApiRequest({
    url: "user/getMembersList",
    method: ApiMethods.GET,
    params: { parentId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function updateMembers(data, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/updateCart",
    method: ApiMethods.PUT,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function SaveOrderDetails(data, onSuccess, onFailure) {
  ApiRequest({
    url: "orderDetails/saveOrderDetails",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
export function DeleteCartById(data, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/deleteCart",
    method: ApiMethods.DELETE,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function getPurchaseHistory(courseId, endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: "myCourse/getPurchaseHistoryByCourseIdAndEndUserId?",
    method: ApiMethods.GET,
    params: { courseId, endUserId },
  }).then(
    (response) => {
      onSuccess(response.data);
    },
    (error) => {
      onFailure(error);
    }
  );
}
export function GetAllActiveCoupons(data, onSuccess, onFailure) {
  ApiRequest({
    url: "coupon/getAllActiveCoupons",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function GuestUserCarts(data, onSuccess, onFailure) {
  ApiRequest({
    url: "cart/getCartDetailsBeforeLogin",
    method: ApiMethods.POST,
    data: data,
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
export function ValidateCouponCode(params, onSuccess, onFailure) {
  ApiRequest({
    url: `coupon/validateCouponCode`,
    method: ApiMethods.GET,
    params,
  }).then(
    (response) => {
      if (response?.data) {
        onSuccess(response?.data);
      } else {
        onFailure(response?.data?.message);
      }
    },
    (error) => {
      onFailure("An error occurred, please try again.");
    }
  );
}

export function getTaxPercentages(endUserId, onSuccess, onFailure) {
  ApiRequest({
    url: `paymentRefund/getTotalCreditsByendUserId?`,
    method: ApiMethods.GET,
    params: { endUserId },
  }).then(
    (response) => {
      onSuccess(response?.data);
    },
    (error) => {
      onFailure();
    }
  );
}
