import { Skeleton } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux';

const ReviewsSkeleton = () => {
    const { mobileDevices } = useSelector((state) => state.responsive);
    return (
        <div>
            <Skeleton variant="rounded" sx={{ width: mobileDevices ? 100 : 150, height: mobileDevices ? 25 : 35, mb: mobileDevices ? 1 : 3 }} />
            <div style={{ width: "100%", justifyContent: "space-between", flexDirection: mobileDevices ? 'column' : 'row', marginBottom: mobileDevices ? 6 : 12, display: 'flex' }}>
                <Skeleton variant="rounded" sx={{ width: mobileDevices ? "100%" : "65%", height: mobileDevices ? 60 : 100, mb: mobileDevices ? 1 : 0 }} />
                <Skeleton variant="rounded" sx={{ width: mobileDevices ? "100%" : "32%", height: mobileDevices ? 60 : 100, }} />
            </div>
            <Skeleton variant="rounded" sx={{ width: "100%", height: mobileDevices ? 100 : 120, mb: mobileDevices ? 1 : 2 }} />
            <div style={{ width: "100%", justifyContent: "space-between", flexDirection: 'row', marginBottom: mobileDevices ? 6 : 12, display: 'flex' }}>
                <Skeleton variant="rounded" sx={{ width: mobileDevices ? 120 : 150, height: mobileDevices ? 25 : 35, }} />
                <Skeleton variant="rounded" sx={{ width: mobileDevices ? 80 : 100, height: mobileDevices ? 25 : 35, }} />
            </div>
            <Skeleton variant="rounded" sx={{ width: "100%", height: mobileDevices ? 80 : 100, mb: 2 }} />
        </div>
    )
}

export default ReviewsSkeleton
