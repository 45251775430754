import _, { isEmpty } from "lodash";
import { setBuyNowdata, setCartCount } from "../../redux/reducer/appReducer";
import { AuthService } from "../../service";
import { showToast } from "../globalComponents/Toast";
import { AuthServices } from "./Services";
import { useEffect, useRef, useState } from "react";
import {
  PriceConversions,
  setBuyNowDataAfterRefresh,
  setInLocalBeforeLogin,
} from "../CommonFunctions";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import gigStrings from "./gigStrings";
import { getMembers } from "../cart/Services";
import { CropLandscapeOutlined } from "@mui/icons-material";
import moment from "moment";
import AautiText from "../globalComponents/AautiText";

function PreviewContainer() {
  const params = useParams();
  const {
    mobileDevices,
    miniLapys,
    ipodDevices,
    lapy,
    aboveLargeMobileDevices,
  } = useSelector((state) => state.responsive);
  const {
    loggedinUserId,
    userDetails,
    cartCount,
    isUserLoggedIn,
    isParent,
    cartsDataBeforeLogin,
  } = useSelector((state) => state.app);
  const navigate = useNavigate();
  const { convertPriceWithCurrency, displayCountryBasedCurrency } =
    PriceConversions();
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const similarRef = useRef(null);
  const otherRef = useRef(null);
  const ratingRef = useRef(null);
  const [refresh, setRefresh] = useState(false);
  const [childs, setChilds] = useState([]);
  const [gigDetails, setGigDetails] = useState({});
  const [userGigs, setUsersGigs] = useState([]);
  const [orderID, setOrderID] = useState(gigDetails?.gig);
  const [childDetails, setChildDetails] = useState([]);
  const [ratingValue, setRatingValue] = useState(0);
  const [reviewComment, setReviewComment] = useState("");
  const [hoveredInd, setHoveredInd] = useState();
  const [gigReviews, setGigReviews] = useState({});
  const [similarSubCat, setSimilarSubCat] = useState([]);
  const [purchasedUsers, setPurChasedUsers] = useState([]);
  const [selectableUsers, setSelectableUers] = useState([]);
  const [showReportForm, setShowReportForm] = useState(false);
  const [similarGigs, setSimilarGigs] = useState(null);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [disabledRight, setDisableRight] = useState(false);
  const [disabledLeftButton, setDisableLeftButton] = useState(true);
  const [disabledRight2, setDisableRight2] = useState(false);
  const [disabledLeftButton2, setDisableLeftButton2] = useState(true);
  const [open, setOpen] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [defaultVideo, setDefaultVideo] = useState(0);
  const filteredUserGigs = userGigs?.filter((each, index) => {
    return each?._id !== params?.id;
  });

  const filteredSimilarGigs = similarGigs?.filter((each) => {
    return each?._id !== params?.id;
  });
  const catRef = useRef();
  let list = [];
  similarSubCat?.forEach((item) => {
    let temp = item.subCategory?.filter((each) => each?.count > 0);
    list = [...list, ...temp];
  });

  const [disabledLeftSubCat, setDisableLeftSubCat] = useState(true);
  const [disabledRightSubCat, setDisableRightSubCat] = useState(false);
  const handleBeforeChangeSubCat = (slide, nextSlide) => {
    if (nextSlide === 0) {
      setDisableLeftSubCat(true);
      setDisableRightSubCat(false);
    } else if (
      nextSlide === list - mobileDevices
        ? 1
        : ipodDevices
          ? 2
          : miniLapys || lapy
            ? 3
            : 4
    ) {
      setDisableRightSubCat(true);
      setDisableLeftSubCat(false);
    } else {
      setDisableRightSubCat(false);
      setDisableLeftSubCat(false);
    }
  };

  const subCatSettings = {
    dots: false,
    infinite: true,
    slidesToShow: mobileDevices
      ? 2
      : ipodDevices
        ? 4
        : miniLapys
          ? 4
          : lapy
            ? 5
            : 5,
    speed: 500,
    slidesToScroll: mobileDevices
      ? 1
      : miniLapys || ipodDevices
        ? 1
        : lapy
          ? 2
          : 3,
    arrows: false,
    swipable: true,
    beforeChange: handleBeforeChangeSubCat,
  };

  const handleSimilarArrows2 = (direc) => {
    if (direc === "right") {
      catRef?.current?.slickNext();
    } else {
      catRef?.current?.slickPrev();
    }
  };

  const handleBeforeChange = (slide, nextSlide) => {
    let itemToRem = mobileDevices
      ? 1
      : ipodDevices
        ? 2
        : miniLapys
          ? 2
          : lapy
            ? 2
            : 2;
    if (nextSlide === 0) {
      setDisableLeftButton(true);
      setDisableRight(false);
    } else if (nextSlide == filteredUserGigs?.length - itemToRem) {
      setDisableRight(true);
      setDisableLeftButton(false);
    } else {
      setDisableRight(false);
      setDisableLeftButton(false);
    }
  };

  const handleBeforeChange2 = (slide, nextSlide) => {
    let itemToRem = mobileDevices
      ? 1
      : ipodDevices
        ? 2
        : miniLapys
          ? 2
          : lapy
            ? 2
            : 2;
    if (nextSlide === 0) {
      setDisableLeftButton2(true);
      setDisableRight2(false);
    } else if (
      nextSlide == filteredSimilarGigs?.length - itemToRem ||
      nextSlide < filteredSimilarGigs?.length - itemToRem
    ) {
      setDisableRight2(true);
      setDisableLeftButton2(false);
    } else {
      setDisableRight2(false);
      setDisableLeftButton2(false);
    }
  };

  const slideSettings = {
    dots: false,
    infinite: true,
    slidesToShow: mobileDevices
      ? 1
      : ipodDevices
        ? 2
        : miniLapys
          ? 2
          : lapy
            ? 3
            : 3,
    speed: 500,
    slidesToScroll: mobileDevices
      ? 1
      : miniLapys || ipodDevices
        ? 1
        : lapy
          ? 1
          : 1,
    arrows: false,
    swipable: true,
    beforeChange: handleBeforeChange,
  };

  const slideSettings2 = {
    dots: false,
    infinite: true,
    slidesToShow: mobileDevices
      ? 1
      : ipodDevices
        ? 2
        : miniLapys
          ? 2
          : lapy
            ? 3
            : 3,
    speed: 500,
    slidesToScroll: mobileDevices
      ? 1
      : miniLapys || ipodDevices
        ? 1
        : lapy
          ? 1
          : 1,
    arrows: false,
    swipable: true,
    beforeChange: handleBeforeChange2,
  };
  const slideSettings3 = {
    dots: false,
    infinite: true,
    slidesToShow: mobileDevices
      ? 1
      : ipodDevices
        ? 2
        : miniLapys
          ? 2
          : lapy
            ? 3
            : 3,
    speed: 500,
    slidesToScroll: mobileDevices
      ? 1
      : miniLapys || ipodDevices
        ? 1
        : lapy
          ? 2
          : 3,
    arrows: false,
    swipable: true,
  };
  const [suggestionsList, setSuggestionsList] = useState([])
  const suggestionsArray = (arrayOne) => {
    if (suggestionsList?.includes(arrayOne)) {
      const a = suggestionsList?.filter((each, index) => {
        return each !== arrayOne;
      });
      setSuggestionsList(a);
    } else {
      setSuggestionsList([...suggestionsList, arrayOne]);
    }
  };
  const ratingAPI = (each) => {
    const ratingData = {
      endUserId: loggedinUserId,
      type: "gig",
      courseId: null,
      gigId: gigDetails?._id,
      serviceProviderId: gigDetails?.gig?.serviceProviderId,
      rating: ratingValue,
      comment: reviewComment,
      parentId: null,
      suggestions: suggestionsList,
    };
    AuthService.saveRatingsReviews(ratingData, onSuccessRatingPost, onFailure);
  };

  const onSuccessRatingPost = (response) => {
    if (response?.data?.status === "Success") {
      showToast("success", response?.data?.message);
      setRefresh(!refresh);
    } else {
      showToast("error", response?.data?.message);
    }
  };

  const buyNow = () => {
    const user = [
      {
        displayName: gigDetails?.gig?.serviceProviderDisplayName,
        firstName: gigDetails?.gig?.serviceProviderName,
        lastName: "",
        isAautiCertified: true,
      },
    ];
    const buyNowData = [
      {
        type: "gig",
        // serviceProvider: user,
        serviceProviderId: gigDetails?.gig?.serviceProviderId,
        endUserId: loggedinUserId,
        serviceProviderName: gigDetails?.gig?.serviceProviderDisplayName,
        transactionCurrencyCode:
          gigDetails?.gig?.transactionCurrencyCode ??
          gigDetails?.gig?.currencyCode,
        yearsOfExperience: gigDetails?.gig?.yearsOfExperience,
        subscribersCount:
          gigDetails?.gig?.endUserList?.length > 0
            ? gigDetails?.gig?.endUserList?.length
            : 0,
        averageRating: gigDetails?.gig?.averageRating,
        ratingCount: gigDetails?.gig?.ratingCount,
        gig: gigDetails?.gig,
        // averageRating: {
        //   averageRating: gigDetails?.gig?.averageRating,
        //   ratingCount: gigDetails?.gig?.ratingCount,
        // },
        childDetails: isEmpty(childDetails)
          ? [{ endUserId: loggedinUserId }]
          : childDetails.map((each, index) => {
            return { endUserId: each?._id };
          }),
      },
    ];
    dispatch(setBuyNowdata(buyNowData));
    setBuyNowDataAfterRefresh(buyNowData, dispatch, "gig");
    navigate(`/buyNow`, {
      state: "buyNow",
    });
  };

  const formatTime = ({ hour, minutes, seconds }) => {
    const duration = moment.duration({
      hours: hour,
      minutes: minutes,
      seconds: seconds,
    });

    // Conditionally format based on whether hours are greater than 0
    const formattedTime =
      // hour > 0
      // ?
      `${hour}:${minutes}:${seconds}`;
    // :
    // moment.utc(duration.asMilliseconds()).format("hh:mm:ss");

    const time = moment({ hour, minutes, seconds });
    return time.format("HH:mm:ss");
  };

  const getTimeDifference = (date) => {
    const now = moment();
    const targetDate = moment(date);

    // Calculate the difference in milliseconds
    const difference = targetDate.diff(now);

    // Check if the difference is less than 24 hours
    if (difference <= moment.duration(1, "days")) {
      const hoursLeft = targetDate.diff(now, "hours");
      return `${hoursLeft} ${hoursLeft == 1 ? "hr" : "hrs"}  left`;
    }

    // Check if the difference is less than 1 month
    const daysLeft = targetDate.diff(now, "days");
    if (daysLeft < 30) {
      return `${daysLeft} ${daysLeft == 1 ? "day" : "days"} left`;
    }

    // Calculate the difference in months
    const monthsLeft = targetDate.diff(now, "months");
    return `${monthsLeft} ${monthsLeft == 1 ? "month" : "months"} left`;
  };

  const returnGigPrice = () => {
    let today = moment().startOf("day");
    let isBetween =
      moment(gigDetails?.gig?.discountStartDate).isBefore(moment(new Date())) &&
      moment(gigDetails?.gig?.discountEndDate).isAfter(moment(new Date())) &&
      gigDetails?.gig?.discountPrice;
    // null,
    // "[]"

    // if (isBetween) {
    //   return [
    //     convertPriceWithCurrency(gigDetails?.gig?.discountPrice),
    //     isBetween,
    //   ];
    // } else {

    return [
      displayCountryBasedCurrency(
        gigDetails?.gig?.currencyCode,
        gigDetails?.gig?.gigPrice || 0,
        gigDetails?.gig?.localGigPrice || 0
      ),
      displayCountryBasedCurrency(
        gigDetails?.gig?.currencyCode,
        gigDetails?.gig?.discountPrice || 0,
        gigDetails?.gig?.localDiscountPrice || 0
      ),
      isBetween,
      getTimeDifference(gigDetails?.gig?.discountEndDate),
    ];
    // }
  };



  const gigData = [
    { leftContent: "Levels", rightData: gigDetails?.gig?.courseLevel },
    {
      leftContent: "Age Group",
      rightData: gigDetails?.gig?.targetAgeGroups,
    },
    {
      leftContent: "Languages",
      rightData: gigDetails?.gig?.languages,
    },
    {
      leftContent: "Price",
      rightData: returnGigPrice(),
    },
    {
      leftContent: "Purchase For",
      rightData: selectableUsers,
    },
    {
      leftContent: "Bought For",
      rightData: purchasedUsers,
    },
  ];
  useEffect(() => {
    getGigDetails();
    setDefaultVideo(0);
  }, [params?.id, refresh, userDetails]);
  const onSuccessOtherGigs = (response) => {
    setUsersGigs(response?.result);
  };
  const onFailOtherGigs = (error) => {
    console.log(error, gigStrings?.error);
  };
  const onSuccessReviews = (response) => {
    if (response.status === gigStrings?.success) {
      setGigReviews(response?.result);
    }
    // getGigDetails();
  };

  const onFailureReviews = (error) => {
    console.error(error, gigStrings?.errorGettingReviews);
  };

  const getServiceProviderGigs = (id) => {
    const data = isUserLoggedIn
      ? { serviceProviderId: id, endUserId: loggedinUserId }
      : { serviceProviderId: id };
    AuthServices.getAllGigsBySp(data, onSuccessOtherGigs, onFailOtherGigs);
  };

  console.log(gigDetails, "gigDetails");

  const getSimilarGigs = (id, cat, spId) => {
    const props = isUserLoggedIn
      ? {
        category: [cat],
        endUserId: loggedinUserId,
        count: 10,
        spIdList: [spId],
      }
      : {
        category: [gigDetails?.gig?.category],
        // endUserId: loggedinUserId,
        count: 10,
        spIdList: [gigDetails?.gig?.serviceProviderId],
      };
    AuthServices?.getGigsListByCategory(
      props,
      (res) => {
        if (res?.status === gigStrings?.success) {
          let val = res?.result?.filter(
            (item) => item?.serviceProviderId != spId
          );
          setSimilarGigs(val);
        } else {
          console.log("error");
        }
      },
      (err) => console.log(err)
    );
  };

  const onSuccess = (response) => {
    if (response?.status === gigStrings?.success) {
      setGigDetails(response?.result[0]);
      setOrderID(response?.result[0]?.gig);
      let users = [];
      let users2 = [];
      const parent = {
        displayName: userDetails?.displayName,
        email: userDetails?.email,
        isIndependent: userDetails?.isIndependent,
        profileImage: userDetails?.profileImage,
        userName: userDetails?.displayName,
        _id: userDetails?._id,
      };
      getMembers(
        loggedinUserId,
        (res) => {
          if (res.status == "Success" && res?.result) {
            setChilds([parent, ...res?.result]);
            users2 = [parent, ...res?.result];
            response?.result[0]?.gig?.endUserList?.map((each) => {
              users2 = users2?.filter((item2) => {
                return item2?._id != each?.userId;
              });
              // users = users?.filter((item2) => {
              //   return item2?._id == each?.userId;
              // });
              [parent, ...res?.result]?.map((item) => {
                if (each?.userId == item?._id) {
                  users = [...users, item];
                  return users;
                }
              });

              return users, users2;
            });
            const uniqueUsers = users.filter(
              (value, index, self) =>
                index === self.findIndex((t) => t._id === value._id)
            );
            setPurChasedUsers(uniqueUsers);
            setSelectableUers(users2);
          }
        },
        (err) => {
          console.log(err);
        }
      );

      // AuthService.getGigReviewsById(
      //   params?.id,
      //   onSuccessReviews,
      //   onFailureReviews
      // );
      getRating("topRating");
      getServiceProviderGigs(response?.result[0]?.gig?.serviceProviderId);
      AuthServices.getGigsCountByCategoryAndSubcategory(
        response?.result[0]?.gig?.category,
        response?.result[0]?.gig?.subCategory,
        (res) => {
          if (res?.status === gigStrings?.success) {
            setSimilarSubCat(res?.result);
          }
        },
        (err) => {
          console.log(err);
        }
      );
      const cat = [response?.result[0]?.gig?.category];
      const spList = [response?.result[0]?.gig?.serviceProviderId];
      const props = isUserLoggedIn
        ? {
          category: cat,
          endUserId: loggedinUserId,
          count: 10,
          spIdList: spList,
        }
        : {
          category: cat,
          // endUserId: loggedinUserId,
          count: 10,
          spIdList: spList,
        };
      AuthServices?.getGigsListByCategory(
        props,
        (res) => {
          if (res?.status === gigStrings?.success) {
            let val = res?.result?.filter(
              (item) =>
                item?.serviceProviderId !=
                response?.result[0]?.gig?.serviceProviderId
            );
            setSimilarGigs(val);
          } else {
            console.log("error");
          }
        },
        (err) => console.log(err)
      );

      // getSimilarGigs(
      //   response?.result[0]?.gig?._id,
      //   response?.result[0]?.gig?.category,
      //   response?.result[0]?.gig?.serviceProviderId
      // );
      setDetailsLoading(false);
    } else {
      setGigDetails({});
      setDetailsLoading(false);
    }
  };

  const getRating = (val) => {
    const data = {
      gigId: params?.id,
      sortBy: val,
    };
    if (isUserLoggedIn) {
      data.endUserId = loggedinUserId;
    }
    AuthService.getGigReviewsById(data, onSuccessReviews, onFailureReviews);
  };
  const onFailure = (error) => {
    console.log(error, gigStrings?.error);
  };

  const getGigDetails = () => {
    setDetailsLoading(true);
    if (loggedinUserId) {
      AuthService.getGigCompleteDetails(
        params?.id,
        loggedinUserId,
        onSuccess,
        onFailure
      );
    } else {
      AuthService.getGigCompleteDetailsBeforeLogin(
        params?.id,
        onSuccess,
        onFailure
      );
    }
  };

  const handleSimilarArrows = (direc) => {
    if (direc === gigStrings?.right) {
      similarRef?.current?.slickNext();
    } else {
      similarRef?.current?.slickPrev();
    }
  };

  const handleSimilarArrows3 = (direc) => {
    if (direc === gigStrings?.right) {
      otherRef?.current?.slickNext();
    } else {
      otherRef?.current?.slickPrev();
    }
  };

  const handleSimilarArrows4 = (direc) => {
    if (direc === gigStrings?.right) {
      ratingRef?.current?.slickNext();
    } else {
      ratingRef?.current?.slickPrev();
    }
  };

  const onSuccessDelete = (response) => {
    if (response?.status === gigStrings?.success) {
      dispatch(setCartCount(cartCount + 1));
      setGigDetails({
        ...gigDetails,
        addedToCart: false,
      });
      // showToast("success", response.message);
    }
  };

  const saveCart = (data, buynowitm) => {
    AuthService.saveCart(
      data,
      (response) => {
        if (response.status === gigStrings?.success) {
          if (buynowitm) {
            buyNow();
          } else {
            if (response.message === gigStrings?.alreadyPurchased) {
              // showToast("success", response.message);
            } else {
              // getGigDetails();
              setGigDetails({
                ...gigDetails,
                addedToCart: true,
              });
              // showToast("success", response.message);
            }
          }
          dispatch(setCartCount(cartCount + 1));
        } else {
          alert(response?.message);
        }
      },
      (error) => {
        console.log(error, gigStrings?.errorSaveCart);
      }
    );
  };

  const checkisInCart = () => {
    const isIdAvailable = _.some(cartsDataBeforeLogin, {
      gigId: gigDetails?.gig?._id,
    });
    return isIdAvailable;
  };

  const handleCart = (gig, buynowitm) => {
    const data = {
      type: "gig",
      endUserId: loggedinUserId,
      gigId: gig?.gig?._id,
      serviceProviderId: gig?.gig?.serviceProviderId,
      childDetails: isParent ? childDetails : [],
    };
    if (isUserLoggedIn) {
      if (buynowitm) {
        dispatch(setCartCount(cartCount + 1));
        data.parentId = userDetails?.parentId
          ? userDetails?.parentId
          : loggedinUserId;
        saveCart(data, buynowitm);
      } else {
        if (gig?.addedToCart) {
          AuthService.deleteCart(data, onSuccessDelete, onFailure);
        } else {
          data.parentId = userDetails?.parentId
            ? userDetails?.parentId
            : loggedinUserId;
          saveCart(data);
        }
      }
    } else {
      setInLocalBeforeLogin(
        {
          gigId: gig?.gig?._id,
          ratingCount: gig?.gig?.ratingCount || 0,
          subscribersCount: gig?.subscribersCount || 0,
        },
        dispatch,
        "gig"
      );
    }
  };

  const handleWishlist = (each) => {
    const obj = {
      type: gigStrings?.gig,
      courseId: null,
      gigId: gigDetails?.gig?._id,
      serviceProviderId: gigDetails?.gig?.serviceProviderId,
      name: gigDetails?.gig?.gigName,
      description: gigDetails?.gig?.gigDescription,
      endUserId: loggedinUserId,
      schedulerId: null,
      parentId: userDetails?.parentId ?? loggedinUserId,
    };

    if (gigDetails?.isWishlisted) {
      AuthService.deleteWishList(
        obj,
        (res) => {
          if (res?.status === gigStrings?.success) {
            // showToast("info", res?.message);
            setGigDetails({
              ...gigDetails,
              isWishlisted: false,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      AuthService.saveWishList(
        obj,
        (res) => {
          if (res?.status === gigStrings?.success) {
            // showToast("success", res?.message);
            setGigDetails({
              ...gigDetails,
              isWishlisted: true,
            });
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const completerDetails = {
    serviceProviderId: gigDetails?.gig?.serviceProviderId,
    profileImage: gigDetails?.gig?.profileImage,
    displayName: gigDetails?.gig?.serviceProviderDisplayName,
    skillsetName: gigDetails?.skillsetName,
    spCourseCount: gigDetails?.spGigCount,
    spStudentsCount: gigDetails?.subscribersCount,
    averageRating: gigDetails?.spAverageRating,
    isWishlisted: gigDetails?.isSpWishlisted,
    isSpWishlisted: gigDetails?.isSpWishlisted,
    tags: gigDetails?.tags,
  };

  const returnTags = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          flexWrap: "wrap",
          gap: 5,
          marginTop: 10,
        }}
      >
        {gigDetails?.gig?.searchTags?.map((each, index) => {
          return (
            <AautiText
              size={"semi"}
              title={
                "#" + each + (gigDetails?.tags?.length !== index + 1 && ",")
              }
              textStyle={{ color: "#1E1E1ECC" }}
            />
          );
        })}
      </div>
    );
  };
  return {
    returnTags,
    completerDetails,
    handleWishlist,
    handleCart,
    handleSimilarArrows,
    gigData,
    handleSimilarArrows2,
    handleSimilarArrows3,
    handleSimilarArrows4,
    otherRef,
    ratingRef,
    catRef,
    subCatSettings,
    buyNow,
    slideSettings,
    open,
    similarGigs,
    showReportForm,
    gigDetails,
    similarRef,
    videoRef,
    navigate,
    mobileDevices,
    miniLapys,
    ipodDevices,
    lapy,
    loggedinUserId,
    getServiceProviderGigs,
    userGigs,
    setOpen,
    similarSubCat,
    setShowReportForm,
    getGigDetails,
    gigId: params?.id,
    visible1,
    setVisible1,
    suggestionsArray,
    setSuggestionsList,
    suggestionsList,
    ratingAPI,
    orderID,
    setOrderID,
    setRatingValue,
    ratingValue,
    setReviewComment,
    reviewComment,
    gigReviews,
    detailsLoading,
    getRating,
    setDetailsLoading,
    childDetails,
    setChildDetails,
    childs,
    selectableUsers,
    getServiceProviderGigs,
    list,
    filteredUserGigs,
    disabledLeftButton,
    disabledRight,
    slideSettings2,
    disabledLeftButton2,
    getSimilarGigs,
    disabledRight2,
    filteredSimilarGigs,
    slideSettings3,
    setRefresh,
    disabledLeftSubCat,
    disabledRightSubCat,
    formatTime,
    aboveLargeMobileDevices,
    defaultVideo,
    setDefaultVideo,
    checkisInCart,
  };
}

export default PreviewContainer;
