import React from "react";
import { useSelector } from "react-redux";
import AautiText from "./AautiText";
import Dropdown from "react-multilevel-dropdown";
import { AautiStrings } from "./AautiStrings";
import JoinButton from "./JoinButton";
import { globalColors } from "../../themes/GlobalColors";

const CategoriesDropdown = ({
  categories,
  categorySubCat,
  setCategorySubCat,
  allCategories,
  setSubCategories,
  subCategories,
  refresh,
  setRefresh,
  setVisible2,
  CreateCourseObj,
  resetObject,
  handleSetObject,
  handleEmptyError,
  setShowConfirmation,
}) => {
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );

  const onChageDrodown = (e) => {
    setCategorySubCat((prev) => ({
      ...prev,
      category: e,
      subCategory: "",
    }));
    const newList = [];
    const b = allCategories?.filter((each, index) => {
      return e === each?.categoryName;
    });
    b[0]?.subCategories?.map((each, index) =>
      newList.push(each.subCategoryName)
    );
    setSubCategories(newList);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
        }}
      >
        <AautiText title={"Categories and SubCategories"} required />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#f2f2f2",
          width: mobileDevices && "auto",
          // height: "70vh",
          // overflow: "scroll",
        }}
      >
        <div
          style={{
            width: mobileDevices ? "45%" : "50%",
            height: mobileDevices ? "50vh" : ipodDevices ? "43vh" : "60vh",
            overflow: "scroll",
          }}
        >
          {categories?.map((each, index) => {
            return (
              <Dropdown.Item
                key={index}
                onClick={(e) => {
                  onChageDrodown(each);

                  // changeCreateObj("", "subCategory");
                  setCategorySubCat((prev) => ({
                    ...prev,
                    subCategory: "",
                  }));
                }}
                style={{
                  backgroundColor:
                    categorySubCat?.category === each ? "#f2f2f2" : "#ffffff",
                  borderTop: "0.2px solid lightgray",
                }}
              >
                <AautiText
                  title={each}
                  id={`${each}-category`}
                  textStyle={{
                    color:
                      categorySubCat?.category === each &&
                      globalColors.AAUTI_THEME_COLOR,
                  }}
                />
              </Dropdown.Item>
            );
          })}
        </div>
        <div
          style={{
            width: mobileDevices ? "59%" : "50%",
            backgroundColor: "#f2f2f2",
            height: mobileDevices ? "50vh" : ipodDevices ? "43vh" : "60vh",
            overflow: "scroll",
          }}
        >
          {subCategories.map((item) => {
            return (
              <Dropdown.Item
                onClick={(e) => {
                  // changeCreateObj(item, "subCategory");
                  setCategorySubCat((prev) => ({
                    ...prev,
                    subCategory: item,
                  }));
                  setRefresh(!refresh);
                }}
                style={{
                  backgroundColor:
                    categorySubCat?.subCategory === item ? "#f2f2f2" : "#fff",
                  border: "1px solid #dddddd",
                }}
              >
                <AautiText
                  title={item}
                  id={`${item}-subcategory`}
                  textStyle={{
                    color:
                      categorySubCat?.subCategory === item &&
                      globalColors.AAUTI_THEME_COLOR,
                  }}
                />
              </Dropdown.Item>
            );
          })}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "99%",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <button
          className="previewbutt"
          onClick={() => {
            setCategorySubCat({
              category: CreateCourseObj?.category,
              subCategory: CreateCourseObj?.subCategory,
            });
            setVisible2(false);
          }}
          style={{
            width: mobileDevices ? "20%" : "14%",
            height: "40px",
            marginLeft: "10px",
            marginTop: "8px",
            borderRadius: "5px",
            padding: mobileDevices ? "5px" : "10px",
          }}
        >
          <AautiText
            size={"semi"}
            title={AautiStrings?.cancelString}
            weight={"normal"}
            textStyle={{ color: "#000000" }}
          />
        </button>
        <JoinButton
          buttonStyle={{
            width: mobileDevices ? "20%" : "14%",
            height: "40px",
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "8px",
            alignSelf: "flex-end",
          }}
          title={AautiStrings?.DoneString}
          disabled={
            categorySubCat?.category === "" || categorySubCat.subCategory === ""
          }
          onClick={() => {
            if (
              CreateCourseObj.subCategory !== "" &&
              CreateCourseObj.subCategory !== categorySubCat?.subCategory
            ) {
              setShowConfirmation(true);
            } else {
              handleSetObject({
                category: categorySubCat?.category,
                subCategory: categorySubCat.subCategory,
                searchTags: [
                  categorySubCat?.category,
                  categorySubCat.subCategory,
                ],
              });
              handleEmptyError({ category: "", subCategory: "" });
              setVisible2(false);
            }
          }}
        />
      </div>
    </div>
  );
};

export default CategoriesDropdown;
