import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppContainer from "../../AppContainer";
import EmptyData from "../../globalComponents/EmptyData";
import { CircularProgress } from "@mui/material";
import { isEmpty } from "lodash";
import AautiText from "../../globalComponents/AautiText";
import { useSelector } from "react-redux";
import CourseFilters from "../../home/upcomming/CourseFilters";
import moment from "moment";
import { AuthService } from "../../../service";
import { FetchServices } from "./FetchServices";
import Coursecard from "../../appCards/Coursecard";
import GigCard from "../../appCards/GigCard";
import AdhocCard from "../../appCards/AdhocCard";
import TopProvidersCard from "../../home/topProviders/TopProvidersCard";
import { transformedObject } from "../../CommonFunctions";

const MoreResults = () => {
  const { state } = useLocation();
  const { mobileDevices, ipodDevices, lapy, miniLapys } = useSelector(
    (state) => state.responsive
  );

  const navigate = useNavigate();

  const { loggedinUserId } = useSelector((state) => state.app);
  const [searchValue, setSearchValue] = useState(state?.searchValue || "");
  const [apiLoading, setApiLoading] = useState(false);
  const [moreResults, setMoreResults] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [resultsFilters, setResultsFilters] = useState(
    state?.filtersData || {}
  );

  const onSuccess = (resp) => {
    setApiLoading(false);
    if (resp?.result) {
      setMoreResults(resp?.result);
    }
  };
  const onFailure = (error) => {
    setApiLoading(false);
    console.error(error, "error-getting more results");
  };

  const handleFetchApi = (data) => {
    switch (state?.activeType) {
      case "Courses":
        AuthService.getTimeslotsCourseSchedules(data, onSuccess, onFailure);
        break;
      case "Gigs":
        FetchServices.gigSMatchMySchedule(data, onSuccess, onFailure);
        break;
      case "Adhoc Sessions":
        FetchServices.adhocSessionsMatchMySchedule(data, onSuccess, onFailure);
        break;
      case "Service Providers":
        FetchServices.getSpMatchMySchedule(data, onSuccess, onFailure);
        break;
      default:
        break;
    }
  };

  const makeSearchApi = (value, filtersData = resultsFilters) => {
    setApiLoading(true);
    let cat = [];

    let data = {
      categories: cat,
      countries: [],
      dateSlots: {},
      endDate: moment(new Date()).add(3, "month").format("YYYY-MM-DDTHH:mm:00"),
      languages: [],
      searchKey: searchValue,
      startDate: moment(new Date()).format("YYYY-MM-DDTHH:mm:00"),
      endUserId: loggedinUserId ? loggedinUserId : null,
      timeSlots: [],
      weekSlots: {},
      subCategories: [],
      timeZone: moment().utcOffset(),
      ageGroup: [],
      levels: [],
      count: 100,
      type: state?.type == "toprated" ? "TopRated" : "Sponsored",
      ...state?.activeFilters?.activeFields,
    };
    if (value) {
      data.searchKey = value;
    }
    if (filtersData) {
      let filters = transformedObject(filtersData ? filtersData : {});
      data = { ...data, ...filters };
    }
    handleFetchApi(data);
  };

  useEffect(() => {
    makeSearchApi();
  }, [state?.type, state?.activeType]);

  const returnListing = (each, index) => {
    switch (state?.activeType) {
      case "Courses":
        return (
          <Coursecard
            key={index}
            title={"searchResults"}
            {...{ each }}
            cardWidth={mobileDevices ? "98%" : 269}
          />
        );
        break;
      case "Gigs":
        return (
          <GigCard
            {...{ each }}
            cardWidth="282px"
            getGigsList={() => {
              setRefresh(!refresh);
            }}
          />
        );
        break;
      case "Adhoc Sessions":
        return <AdhocCard {...{ each, index }} />;
        break;
      case "Service Providers":
        return (
          <TopProvidersCard
            key={index}
            {...{ each, index }}
            cardwidth={"278px"}
          />
        );
        break;
      default:
        break;
    }
  };

  const returnScreenName = () => {
    switch (state?.activeType) {
      case "Courses":
        return "topRatedInCourses";
        break;
      case "Gigs":
        return "topRatedInGigs";
        break;
      case "Adhoc Sessions":
        return "topRatedInAdhoc";
        break;
      case "Service Providers":
        return "serviceProviderCourses";
        break;
      default:
        break;
    }
  };

  return (
    <AppContainer>
      <div
        style={{
          width: "100%",
          paddingLeft: "1.5%",
          display: "flex",
          flexDirection: mobileDevices ? "column" : "row",
          marginTop: mobileDevices ? 2 : 15,
          gap: 15,
        }}
      >
        <div
          style={{
            width: mobileDevices ? "99%" : "20%",
            maxWidth: mobileDevices ? "99%" : "20%",
            minWidth: mobileDevices ? "99%" : "20%",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
            borderRadius: 4,
            padding: mobileDevices ? 0 : 10,
            background: mobileDevices && "transparent",
            height: "fit-content",
          }}
        >
          <CourseFilters
            actFilters={resultsFilters}
            handleClickApply={(data) => {
              makeSearchApi(null, data);
              setResultsFilters(data);
              navigate(".", { state: { ...state, filtersData: data } });
            }}
            searchValue={searchValue}
            onCloseSearch={() => {
              setSearchValue("");
            }}
            onChangeSearchValue={(e) => {
              const value = e.target.value.trimStart().replace(/\s+/g, " ");
              setSearchValue(value);
              makeSearchApi(value);
              navigate(".", { state: { ...state, searchValue: value } });
            }}
            screenName={returnScreenName()}
          />
        </div>
        <div style={{ width: mobileDevices ? "100%" : "78%" }}>
          <div
            style={{
              width: "100%",
              height: "80vh",
              overflow: "scroll",
            }}
          >
            <AautiText
              title={"Showing Results"}
              textStyle={{ marginBottom: 10 }}
            />

            {apiLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  height: "50vh",
                  width: "100% !important",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : isEmpty(moreResults) ? (
              <div>
                <EmptyData text="No Results" showExplore={false} />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  flexWrap: "wrap",
                  marginBottom: 15,
                  alignItems: "flex-start",
                }}
              >
                {moreResults?.map((each, index) => returnListing(each, index))}
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default MoreResults;
