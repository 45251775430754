import React from "react";
import AautiText from "../globalComponents/AautiText";
import JoinButton from "../globalComponents/JoinButton";
import emptyImage from "../../assets/images/empty.png";
import { useSelector } from "react-redux";
import { globalColors } from "../../themes/GlobalColors";
const EmptyTopic = ({
  topicName,
  message,
  buttonName,
  Image,
  extraText,
  onClick,
  id,
  showButton = true,
  height,
}) => {
  const { lapy, miniLapys, ipodDevices, mobileDevices } = useSelector(
    (state) => state.responsive
  );
  return (
    <div
      style={{
        marginTop: "10px",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        justifyContent: "center",
        padding: 16,
        height: height ? height : miniLapys ? "55vh" : "60vh",
        alignItems: "center",
      }}
    >
      <img
        src={Image ? Image : emptyImage}
        alt="empty-img"
        style={{
          resizeMode: "stretch",
          height: "250px",
          width: "300px",
          marginBottom: "15px",
        }}
      />
      <AautiText
        title={topicName}
        size={"normal"}
        weight={"bold"}
        textStyle={{
          color: "#000000",
          marginBottom: "10px",
          // fontFamily: "Amazon Ember V2 Bold, Amazon Ember V2, sans-serif",
        }}
      />

      <AautiText
        title={message}
        size={"normal"}
        weight={"normal"}
        textStyle={{
          color: "gray",
          textAlign: "center",

          // fontFamily: "Amazon Ember V2 Bold, Amazon Ember V2, sans-serif",
        }}
      />
      {/* {extraText && (
        <AautiText
          title={extraText}
          size={"normal"}
          weight={"normal"}
          textStyle={{
            color: "gray",
            textAlign: "center",
            marginBottom: "10px",
            width: "34%",
            fontFamily: "Amazon Ember V2 Bold, Amazon Ember V2, sans-serif",
          }}
        />
      )} */}
      {buttonName && (
        <JoinButton
          title={buttonName}
          id={buttonName ? buttonName : id}
          // minWidth="250px"
          outlined="true"
          buttonStyle={{
            marginTop: 5,
            borderColor: globalColors?.AAUTI_THEME_COLOR,
            borderRadius: "10px",
            minWidth: "250px",
            display: !showButton && "none",
            marginTop: "15px",
          }}
          textStyle={{
            color: globalColors?.AAUTI_THEME_COLOR,
            fontWeight: "600",
          }}
          onClick={onClick}
        />
      )}
    </div>
  );
};
export default EmptyTopic;
