import React, { useState, useMemo, useCallback, useEffect, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import SearchBar from "../components/searchBar";
import { FiPlus } from "react-icons/fi";
import { SlPencil } from "react-icons/sl";
import { CiSearch } from "react-icons/ci";
import { Close } from "@mui/icons-material";

import AautiText from "../../globalComponents/AautiText";
import AautiAvatar from "../components/AautiAvatar";
import ChatMenu from "../components/ChatMenu";
import { showToast } from "../../globalComponents/Toast";
import ChatAccessCard from "../components/ChatAccess";
import EmptyData from "../../globalComponents/EmptyData";
import SkeletonChatsList from "../components/skeletons/ChatsList";

import {
  addAdmin,
  addModerator,
  blockUser,
  getActiveChatRoom,
  getActiveChatRoomMembers,
  leaveRoom,
  removeAdmin,
  removeModerator,
  setActiveRoom,
  unblockUser,
} from "../../../redux/reducer/chatSlice";
import { chatConstants, conversationTypes, SocketEvents } from "../constants";
import "./styles.scss";


function ChatRoomInfo({
  id = "",
  loggedInUserId,
  onCloseRoomInfo,
  toggleAddUserDialog = () => {},
  toggleEditProfileDialog = () => {},
  toggleReportDialog = () => {},
  showBackIcon = true,
  showRoomInfoLoader = false,
  isInMobileView = false,
}) {
  const activeRoom = useSelector(getActiveChatRoom);
  const members = useSelector(getActiveChatRoomMembers);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchInput, setSearchInput] = useState(undefined);

  const {
    user,
    type: roomType,
    name = "",
    avatar = "",
    description,
    _id: roomId = "",
    access,
    isCancelled,
    moderators = [],
    admins = [],
    blockedUsers = [],
  } = activeRoom;

  const isGroupAdmin = admins?.includes(loggedInUserId);
  const userLeftChat = user?.exitDate ? true : false;
  const btnTitle = userLeftChat ? "Delete from chat" : "Exit group";
  const showFooterBtns = !isGroupAdmin || admins?.length > 1;

  const dispatch = useDispatch();

  const onChangeAccess = (access) => {
    dispatch({
      type: SocketEvents.room.updateInfo,
      payload: { access, roomId },
    });
  };

  useEffect(() => {
    dispatch({ type: SocketEvents.room.getMembers, payload: { roomId } });
  }, []);

  const exitGroup = () => {
    dispatch({ type: SocketEvents.room.leave, payload: { roomId } });
    dispatch(leaveRoom({ roomId, userId: loggedInUserId }));
  };

  const onPressExitOrDeleteGroup = () => {
    if (userLeftChat) {
      dispatch({
        type: SocketEvents.room.deleteConversation,
        payload: { roomId },
      });
      dispatch(setActiveRoom({ data: undefined }));
    } else {
      const isUserWantsToLeave = window.confirm(
        chatConstants.doYouWantToLeaveRoom
      );
      if (isUserWantsToLeave) {
        exitGroup();
      }
    }
  };

  function getUserMenuOptions({
    roomType,
    isAdmin,
    isModerator = false,
    isBlocked,
  }) {
    if (roomType === conversationTypes.GROUP) {
      if (isAdmin) return [chatConstants.removeAsAdmin];
      if (isBlocked) return [chatConstants.unblock, chatConstants.remove];
      return [
        chatConstants.makeAsAdmin,
        chatConstants.block,
        chatConstants.remove,
      ];
    }
    if (
      [
        conversationTypes.ASK,
        conversationTypes.COURSE,
        conversationTypes.COURSE_ADHOC,
        conversationTypes.ADHOC,
      ].includes(roomType)
    ) {
      if (isModerator) return [chatConstants.removeAsModerator];
      if (isBlocked) return [chatConstants.unblock];
      return [chatConstants.makeAsModerator, chatConstants.block];
    }
  }

  const onPressSearch = () => setShowSearchBar(true);
  const onChangeSearchInput = (event) => setSearchInput(event.target.value);

  const onPressBackOrClear = (action) => {
    if (!action) return;
    if (action === "BACK") {
      setShowSearchBar(!showSearchBar);
      setSearchInput(undefined);
    }
    if (action === "CLEAR") {
      setSearchInput(undefined);
    }
  };

  const handleRemovingAdmin = (userId) => {
    const availableAdmins = admins?.filter((eachAdmin) => eachAdmin !== userId);
    if (availableAdmins?.length === 0) {
      showToast("info", "Make anyone an admin to remove your access.");
    } else {
      dispatch(removeAdmin(userId));
      dispatch({
        type: SocketEvents.room.removeAdminAccess,
        payload: { userId, roomId },
      });
    }
  };

  const onPressMenuItem = (selectedItem, selectedUserId) => {
    let type;
    switch (selectedItem) {
      case chatConstants.removeAsAdmin:
        handleRemovingAdmin(selectedUserId);
        break;
      case chatConstants.block:
        type = SocketEvents.room.blockUser;
        dispatch(
          blockUser({ blockedBy: loggedInUserId, userId: selectedUserId })
        );
        break;
      case chatConstants.unblock:
        dispatch(unblockUser(selectedUserId));
        type = SocketEvents.room.unblockUser;
        break;
      case chatConstants.makeAsAdmin:
        dispatch(addAdmin(selectedUserId));
        type = SocketEvents.room.makeAdmin;
        break;
      case chatConstants.makeAsModerator:
        dispatch(addModerator(selectedUserId));
        type = SocketEvents.room.makeModerator;
        break;
      case chatConstants.removeAsModerator:
        dispatch(removeModerator(selectedUserId));
        type = SocketEvents.room.removeModerator;
        break;
      case chatConstants.remove:
        type = SocketEvents.room.removeUser;
        break;
      default:
        break;
    }
    if (type) dispatch({ type, payload: { userId: selectedUserId, roomId } });
  };

  const filteredMembers = useMemo(() => {
    if (showSearchBar && searchInput?.length >= 1) {
      return members.filter((member) =>
        member.displayName.toLowerCase().includes(searchInput?.toLowerCase())
      );
    }
    return members;
  }, [members, searchInput, showSearchBar]);

  const renderListHeader = () => {
    const value = `Participant${members?.length > 1 ? "s" : ""}`;
    return (
      <div
        style={{
          height: 40,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showSearchBar ? (
          <SearchBar
            {...{
              id: `${id}-search-bar`,
              value: searchInput,
              onChangeSearchInput,
              onPressBack: () => onPressBackOrClear("BACK"),
              onPressClear: () => onPressBackOrClear("CLEAR"),
              textInputProps: { autoFocus: true },
              showBackIcon :true
            }}
          />
        ) : (
          <>
            <AautiText
              title={`${members?.length ?? 0} ${value}`}
              size={"semi"}
              textStyle={{ color: "#333333" }}
            />
            <div
              style={{ display: "flex", flexDirection: "row", columnGap: 10 }}
            >
              {isGroupAdmin && !isCancelled && (
                <FiPlus
                  size={25}
                  color="#3083EF"
                  className="chat-header-icon"
                  onClick={() => toggleAddUserDialog(true)}
                />
              )}
              <CiSearch size={22}
                className="chat-header-icon"
                onClick={onPressSearch}
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderUser = useCallback(
    (value) => {
      let displayName = value?.displayName;
      const isMe = value?._id === loggedInUserId;
      const isAdmin = admins?.includes(value?._id);
      const isModerator = moderators?.includes(value?._id);
      const isBlocked = blockedUsers?.includes(value?._id);
      const menuOptions = isGroupAdmin
        ? (isAdmin && roomType === conversationTypes.COURSE) || isCancelled
          ? []
          : getUserMenuOptions({
              roomType,
              isAdmin,
              isModerator,
              isBlocked,
            })
        : [];
      displayName = isMe ? "You" : displayName;
      let endTags = [];
      if (isAdmin) endTags.push("Admin");
      if (isModerator) endTags.push("Moderator");
      if (isBlocked) endTags = ["Blocked"];
      displayName = `${displayName}${
        endTags.length ? ` (${endTags.join(", ")})` : ""
      }`;
      return (
        <ListItem
          key={value._id}
          secondaryAction={
            isGroupAdmin &&
            !isMe && (
              <ChatMenu
                {...{
                  id: "chat_room_info_user_item_menu",
                  options: menuOptions,
                  onPressMenuItem: (title) =>
                    onPressMenuItem(title, value?._id),
                }}
              />
            )
          }
          disablePadding
          disableRipple
          disableTouchRipple
          className={"list-item-add-group"}
          style={{ height: 60, columnGap: 10 }}
          sx={{
            p: 0,
            "& .css-518kzi-MuiListItemSecondaryAction-root": { right: 0 },
          }}
        >
          <AautiAvatar
            alt={value?.displayName}
            src={value?.profileImage}
            width={35}
            height={35}
          />
          <AautiText
            title={displayName}
            size={"semi"}
            textStyle={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            weight={"normal"}
          />
        </ListItem>
      );
    },
    [blockedUsers, admins, isGroupAdmin, moderators]
  );

  const renderEditIcon = () => (
    <IconButton key={"group-edit-icon"} onClick={toggleEditProfileDialog}>
      <SlPencil size={14} />
    </IconButton>
  );

  const wrapperInLineStyle = !isInMobileView
    ? { borderLeft: "solid 1px #d1e0e7" }
    : null;

  const showEditIcon = (isGroupAdmin && !isCancelled);

  return (
    <div className="chat-room-info-container" style={wrapperInLineStyle}>
      {!isInMobileView && (
        <div className="chat-room-info-md-header">
          {showEditIcon && renderEditIcon()}
          <IconButton onClick={onCloseRoomInfo}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      )}

      <div className="chat-room-info-body">
        <div className="chat-room-info-profile">
          <AautiAvatar
            {...{
              src: avatar,
              alt: name,
              height: isInMobileView ? 45 : 80,
              width: isInMobileView ? 45 : 80,
            }}
          />
          <div className="edit-icon-and-texts-wrapper">
            <div className="chat-room-info-texts-wrapper">
              <Tooltip title={name} arrow>
                <span className="overflow-text room-name">{name}</span>
              </Tooltip>
              <Tooltip title={description ?? "N/A"} arrow>
                <span className="overflow-text room-description">
                  {description ?? "N/A"}
                </span>
              </Tooltip>
            </div>
            {(showEditIcon && isInMobileView) && renderEditIcon()}
          </div>
        </div>
        {isGroupAdmin && !isCancelled && (
          <ChatAccessCard {...{ access, onChangeAccess }} />
        )}
        {renderListHeader()}
        <div className="members-list-wrapper">
          {showRoomInfoLoader && <SkeletonChatsList size={6} />}
          {!showRoomInfoLoader && !filteredMembers?.length && (
            <EmptyData
              {...{
                text: chatConstants.noResults,
                showExplore: false,
                height: "100%",
              }}
            />
          )}
          {!showRoomInfoLoader && !!filteredMembers?.length && (
            <List
              sx={{ width: "100%", bgcolor: "background.paper", margin: 0 }}
              style={{ paddingLeft: 16, paddingRight: 16 }}
            >
              {filteredMembers?.map(renderUser)}
            </List>
          )}
        </div>
      </div>
      <div className="chat-room-info-footer">
        {!userLeftChat && (
          <Button
            id={`${id}-${btnTitle}-btn1`}
            onClick={() => toggleReportDialog(true)}
            className="chat-room-info-action-btn"
          >
            {chatConstants.reportGroup}
          </Button>
        )}
        {showFooterBtns && (
          <Button
            id={`${id}-${btnTitle}-btn2`}
            onClick={onPressExitOrDeleteGroup}
            className="chat-room-info-action-btn"
          >
            {btnTitle}
          </Button>
        )}
      </div>
    </div>
  );
}

export default memo(ChatRoomInfo);
