import { useEffect, useState } from "react";
import {
  ChangeUserRole,
  setCartCount,
  setProfilePercentage,
  sideBarToggleName,
} from "../../redux/reducer/appReducer";
import "./Style.scss";
import { showToast } from "../globalComponents/Toast";
import { GetAllCountries } from "../availability/Services";
import { Service } from "./Service";
import { AuthService } from "../homePage/Service";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderStrings, TabsData, paths } from "./constants";
import TodaysEvents from "../home/TodaysEvents";
import upcomingSvg from "../../assets/images/upcoming_classes.svg";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
import { CommonFunctions } from "../CommonFunctions";
import todoSvg from "../home/main/assets/todo.svg";

const EventsTootip = styled(({ className, ...props }) => (
  <Tooltip
    disableFocusListener
    enterTouchDelay={1}
    enterDelay={0}
    arrow
    {...props}
    classes={{ popper: className }}
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -15],
            },
          },
        ],
      },
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 800,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    boxShadow: theme.shadows[1],
  },
}));

export function HeaderContainer(props) {
  const {
    isUserLoggedIn,
    FirstName,
    UserRole,
    userDetails,
    loggedinUserId,
    cartCount,
    countryObject,
    cartsDataBeforeLogin,
    relaodCart,
    profilePercentage,
  } = useSelector((state) => state.app);
  const { pathname } = useLocation();

  const tabVal =
    pathname === HeaderStrings?.institution
      ? 3
      : pathname === HeaderStrings?.whyAauti
      ? 1
      : pathname === HeaderStrings?.svk
      ? 2
      : 0;

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [toggleCurrency, setToggleCurrency] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [openTodayEvent, setOpenTodatEvents] = useState(false);
  const [userRole, setUserRole] = useState(
    UserRole?.includes(HeaderStrings?.serviceProvide2) &&
      userDetails?.approvalStatus !== "pending"
      ? HeaderStrings?.serviceProvider
      : HeaderStrings?.endUser
  );

  const { fetchUserDetails } = CommonFunctions();
  const navigation = useNavigate();
  const { mobileDevices, ipodDevices } = useSelector(
    (state) => state.responsive
  );
  const [loadingCountNot, setLoadingCountNotNotifi] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [allCountries, setAllCountries] = useState([]);
  const [filteredCountries, setFilteredCountries] = useState(allCountries);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElNot, setAnchorElNot] = useState(null);
  const openNot = Boolean(anchorElNot);
  // const [cartsCountBefore, setCartsCountBefore] = useState(0);
  // const [profilePercentage, setProfilePercentage] = useState(0);
  const menuOpen = Boolean(anchorEl);

  const getAllCarts = () => {
    AuthService.getAllCartsByUser(
      loggedinUserId,
      (response) => {
        dispatch(setCartCount(response?.result?.length));
      },
      () => {
        dispatch(setCartCount(0));
      }
    );
    Service?.getProfilePercentage(
      loggedinUserId,
      UserRole || "endUser",
      (response) => {
        // setProfilePercentage(response?.result?.percentage);
        dispatch(setProfilePercentage(response?.result?.percentage));
      },
      (err) => {
        dispatch(setProfilePercentage(0));
      }
    );
  };

  useEffect(() => {
    GetAllCountries(
      (response) => {
        if (response?.status === HeaderStrings?.success) {
          setAllCountries(response?.result);
          setFilteredCountries(response?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  useEffect(
    () => {
      setSelectedTab(tabVal);
      if (isUserLoggedIn && loggedinUserId) {
        setLoadingCountNotNotifi(true);
        getAllCarts();
        Service.getAllNotificationsByUserId(
          loggedinUserId,
          onSuccessNot,
          onFailure
        );
      } else {
        // let value = localStorage.getItem("CartDataBeforeLogin");
        // setCartsCountBefore(JSON.parse(value)?.length);
      }
    },
    isUserLoggedIn ? [loggedinUserId, UserRole, cartCount, openNot] : [pathname]
  );

  useEffect(() => {
    GetAllCountries(
      (response) => {
        if (response?.status === HeaderStrings?.success) {
          setAllCountries(response?.result);
          setFilteredCountries(response?.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  const onSuccessNot = (response) => {
    if (response?.status === HeaderStrings?.success) {
      setUnreadCount(response?.result?.unreadCount);
      setLoadingCountNotNotifi(false);
    } else {
      setLoadingCountNotNotifi(false);
    }
  };
  const onFailure = (error) => {
    setLoadingCountNotNotifi(false);
    showToast("error", error?.message);
  };
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
    dispatch(sideBarToggleName(!open));
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMobileClick = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const handleClickNot = (event) => {
    if (pathname !== "/notifications") {
      setAnchorElNot(event.currentTarget);
    }
  };
  const handleCloseNot = () => {
    setAnchorElNot(null);
  };

  const id = openNot ? HeaderStrings?.simplePopOver : undefined;
  const handleNavigation = () => {
    const replacePaths = paths.some((path) => pathname.includes(path));
    // console.log(pathname, "name");
    if (replacePaths) {
      navigation("/", { replace: true });
    }
  };

  const handleUserRole = () => {
    if (userRole === HeaderStrings?.serviceProvider) {
      setUserRole(HeaderStrings?.endUser);
      localStorage.setItem(HeaderStrings?.role, HeaderStrings?.endUser2);
      dispatch(ChangeUserRole(HeaderStrings?.endUser2));
      handleMenuClose();
      handleNavigation();
    } else {
      if (!userDetails?.role?.includes(HeaderStrings?.serviceProvide2)) {
        setConfirmation(HeaderStrings?.role);
      } else {
        setUserRole(HeaderStrings?.serviceProvider);
        dispatch(ChangeUserRole(HeaderStrings?.serviceProvide2));
        localStorage.setItem(
          HeaderStrings?.role,
          HeaderStrings?.serviceProvide2
        );
      }
      handleMenuClose();
      handleNavigation();
    }
  };

  const saveUserRole = () => {
    const data = {
      ...userDetails,
      role: [HeaderStrings?.serviceProvide2, HeaderStrings?.endUser2],
    };
    Service.updateUserDetails(data, onSuccessUpdate, onFailureUpdate);
    setConfirmation(null);
  };
  const onSuccessUpdate = async (response) => {
    if (response?.status === HeaderStrings?.success) {
      if (response.result?.approvalStatus !== "pending") {
        setUserRole(HeaderStrings?.serviceProvider);
        dispatch(ChangeUserRole(HeaderStrings?.serviceProvide2));
        localStorage.setItem(
          HeaderStrings?.role,
          `${HeaderStrings?.serviceProvide2},${HeaderStrings?.endUser2}`
        );
        setConfirmation(null);
      } else {
      }
      await fetchUserDetails();
      window.location.reload();
    } else {
    }
  };
  const onFailureUpdate = (error) => {};

  const payment = pathname.includes(HeaderStrings?.paymentGateway);
  const handleOnChange = (value) => {
    const countries = allCountries.filter((each) => {
      return each?.name?.toLowerCase()?.includes(value?.toLowerCase());
    });
    setFilteredCountries(countries);
  };
  const handleOpenConfirmation = (type) => {
    setConfirmation(type);
  };

  const closeCountryPop = () => {
    setToggleCurrency(false);
    setFilteredCountries(allCountries);
  };

  const eventsResponsiveHandle = () => {
    return ipodDevices || mobileDevices ? (
      <img
        onClick={() => {
          setOpenTodatEvents(true);
        }}
        src={todoSvg}
        alt="Left-arrow"
        style={{ height: 30, width: 30, cursor: "pointer" }}
      />
    ) : (
      <EventsTootip
        title={
          <div
            style={{
              width: "100%",
              maxHeight: "400px",
              overflow: "scroll",
              padding: 10,
            }}
          >
            <TodaysEvents />
          </div>
        }
      >
        <img
          src={upcomingSvg}
          alt="Left-arrow"
          style={{ height: 30, width: 30, cursor: "pointer" }}
        />
      </EventsTootip>
    );
  };

  return {
    handleOpenConfirmation,
    closeCountryPop,
    handleOnChange,
    payment,
    saveUserRole,
    id,
    handleCloseNot,
    handleUserRole,
    handleClickNot,
    handleMobileClick,
    TabsData,
    handleMenuClick,
    menuOpen,
    profilePercentage,
    filteredCountries,
    allCountries,
    unreadCount,
    loadingCountNot,
    navigation,
    mobileDevices,
    ipodDevices,
    pathname,
    isUserLoggedIn,
    FirstName,
    UserRole,
    userDetails,
    cartCount,
    countryObject,
    cartsDataBeforeLogin,
    dispatch,
    toggleCurrency,
    confirmation,
    setConfirmation,
    setAnchorElNot,
    setToggleCurrency,
    openNot,
    anchorElNot,
    userRole,
    handleMenuClose,
    setSelectedTab,
    selectedTab,
    handleDrawerOpen,
    relaodCart,
    eventsResponsiveHandle,
    openTodayEvent,
    setOpenTodatEvents,
  };
}
