import { Box, Rating } from "@mui/material";
import { truncateDescription } from "../../CommonFunctions";
import "./styles.scss";

export const ReviewCard = ({ index, slide, cardWidth }) => {
  return (
    <Box id="review_card_main" key={index} style={{ width: cardWidth }}>
      <Box className="slider_content_box_profile">
        <img src={slide?.image} alt={slide.title} className="slider_image" />
        <Box className="slider_content_box_profile_wrapper">
          <p className="slider_content_box_profile_name">
            {slide?.name || "N/A"}
          </p>
          <p className="slider_content_box_profile_title">
            {slide?.author || "N/A"}
          </p>
        </Box>
      </Box>
      <p className="slider_content_box_text">
        {truncateDescription(slide?.description)}
      </p>
      <Rating
        name="read-only"
        value={slide?.rating}
        readOnly
        style={{ marginTop: 6 }}
      />
    </Box>
  );
};
