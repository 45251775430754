import { Avatar, IconButton, Rating, Divider, } from '@mui/material';
import React from 'react'
import AautiText from '../globalComponents/AautiText';
import FormattedDate from './GetDateTimeDifference';
import CommentTextField from './CourseComment';
import "./Style.scss";
import "../serviceProviderDetails/Style.scss";
import { BiPencil } from "react-icons/bi";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MdDeleteOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getFirstTwoChars, returnColorbasedName } from '../CommonFunctions';
import { globalColors } from '../../themes/GlobalColors';

const ReviewsContainer = ({
    reviewData,
    ratingAPI,
    open,
    courseReviewData = [],
    handleClick,
    replayComment,
    replayRating,
    deleteAPI,
    setEditID,
    setEditRating,
    updateRating,
    setReviewComment,
    setReplayComment,
    setReplayRating,
    reviewComment,
    editID,
    editRating,
    eligibilty,
    anchorEl,
    open2,
    setVisibleReplay,
    visibleReplay,
    handleClose,
    comfirmDialog,
    review,
    setComfirmDialog,
    setOncloseRefresh,
    setCourseReview,
    setparentID,
    setOpen,
    screenName,
    setRatingValue,
    handleClose2,
    setDialogName,
    params
}) => {
    const navigate = useNavigate()
    function formatDate(inputDate) {
        const options = { year: "numeric", month: "long", day: "numeric" };
        return new Date(inputDate).toLocaleDateString("en-US", options);
    }
    const { mobileDevices, miniLapys, aboveLargeMobileDevices, ipodDevices } = useSelector((state) => state.responsive);
    const { loggedinUserId, userDetails, isUserLoggedIn } = useSelector(
        (state) => state.app
    );
    const spReviewsWidth = screenName === "spReviews" && !mobileDevices
    const avatarWidthDesktop = mobileDevices || aboveLargeMobileDevices ? 35 : spReviewsWidth ? (ipodDevices ? 50 : 60) : 40
    const avatarWidthMobile = mobileDevices || aboveLargeMobileDevices ? 30 : spReviewsWidth ? (ipodDevices ? 40 : 50) : 40
    return (
        <>
            <div className="reviews-column-div">
                {courseReviewData?.reviews?.map((each, index) => {
                    return (
                        <div key={index} className="course-review-card-new" style={{
                            borderBottom: index !== courseReviewData?.reviews.length - 1 ? `1px solid ${globalColors.Border_light_grey}` : 0
                        }}  >
                            <div className="course-review-reviewr">
                                {each?.profileImage ?
                                    <Avatar alt="Remy Sharp" sx={{
                                        height: avatarWidthDesktop,
                                        width: avatarWidthDesktop, mr: mobileDevices ? 1 : 1.5
                                    }} src={each?.profileImage} />
                                    :
                                    <Avatar sx={{
                                        height: avatarWidthDesktop,
                                        width: avatarWidthDesktop, mr: mobileDevices ? 1 : 1.5,
                                        bgcolor: returnColorbasedName(each?.displayName)
                                    }}   >
                                        {getFirstTwoChars(each?.displayName)}
                                    </Avatar>
                                }
                                <div className={spReviewsWidth ? "reviews-displayName-div" : "reviews-displayName-div2"}  >
                                    <div className='Rating-menu-div'>
                                        <div className='Rating-menu-div-2'>
                                            <AautiText
                                                weight={'bold'}
                                                title={each?.displayName}
                                                size={"semi"}
                                                className={"coment-dispaly-name"} />
                                            <div className="rating-show-div">
                                                <AautiText title={each?.rating?.toFixed(1)}
                                                    textStyle={{ color: globalColors.HEADING_COLOR }}
                                                    size={"semi"} />
                                                <Rating sx={{ color: globalColors.AAUTI_THEME_COLOR, fontSize: "1rem", }}
                                                    name="read-only"
                                                    value={each?.rating || 0}
                                                    readOnly
                                                    precision={0.2}
                                                    size="small"
                                                    color={globalColors.AAUTI_THEME_COLOR}
                                                />
                                            </div>
                                        </div>
                                        <div className="reviews-time-div" >
                                            <AautiText
                                                size="small"
                                                textStyle={{ color: globalColors.GREY_TEXT }}
                                                title={<FormattedDate dateTime={each?.createdAt} />}
                                            />
                                            {(screenName === "Orders" ||
                                                (screenName === "spReviews" && (eligibilty || loggedinUserId === params?.id)) ||
                                                reviewData?.isPurchased || each.endUserId === loggedinUserId) ?
                                                <IconButton sx={{ padding: 0 }}
                                                    aria-label="more"
                                                    id="long-button"
                                                    aria-controls={open ? "long-menu" : undefined}
                                                    aria-expanded={open ? "true" : undefined}
                                                    aria-haspopup="true"
                                                    onClick={(e) => handleClick(e, each)}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                : null}
                                        </div>
                                    </div>
                                    <AautiText
                                        title={each?.comment || "NA"}
                                        size={"semi"}
                                        weight={'normal'}
                                        className={"reviews.comment-div"}
                                        textStyle={{
                                            color: globalColors.HEADING_COLOR, maxWidth: '99%',
                                            overflowWrap: "anywhere"
                                        }}
                                    />
                                </div>
                            </div>
                            {each?.replies?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt))?.map((item, index) => (
                                <>
                                    <div className="SPreviewReplydiv3" style={{ width: "95%", alignSelf: "end" }}
                                        key={index} >
                                        {item?.profileImage ?
                                            <Avatar src={item?.profileImage}
                                                alt="Logo Header"
                                                sx={{
                                                    height: avatarWidthMobile,
                                                    width: avatarWidthMobile, mr: mobileDevices ? 1 : 1.5,
                                                }} /> :
                                            <Avatar sx={{
                                                height: avatarWidthMobile,
                                                width: avatarWidthMobile, mr: mobileDevices ? 1 : 1.5,
                                                bgcolor: returnColorbasedName(item?.displayName),
                                            }} >
                                                {getFirstTwoChars(item?.displayName)}
                                            </Avatar>
                                        }
                                        <div className="SPcommentdiv2" style={{
                                            width: spReviewsWidth && (ipodDevices ? "92.5%" : miniLapys ? '91.5%' : '93.5%')
                                        }}>
                                            <AautiText
                                                weight={"medium"}
                                                textStyle={{ color: globalColors.HEADING_COLOR }}
                                                size={"semi"}
                                                title={`${item?.displayName}`}
                                            />
                                            <AautiText
                                                textStyle={{ color: globalColors.GREY_TEXT, }}
                                                title={<FormattedDate dateTime={item?.createdAt} />}
                                                size={"small"}
                                                weight={"normal"}
                                            />
                                            {/* <div className="SPnewRow2" style={{ flexDirection: item.endUserId === loggedinUserId ? 'row' : 'column' }} > */}
                                            {editRating && item._id === editID ? (
                                                <CommentTextField
                                                    value={reviewComment}
                                                    onChange={(e) => setReviewComment(e.target.value.trimStart().replace(/\s+/g, " "))}
                                                    onSubmit={() => {
                                                        updateRating(item);
                                                        setEditRating(!editRating);
                                                        setEditID('');
                                                    }}
                                                    onCancel={() => {
                                                        setEditRating(!editRating);
                                                        setEditID('');
                                                    }}
                                                    setEditID={setEditID}
                                                    editID={editID}
                                                    itemID={item?._id}
                                                />
                                            ) : (
                                                <AautiText
                                                    title={item?.comment}
                                                    size={"semi"}
                                                    weight={'normal'}
                                                    className={"text-comment-div"}
                                                    textStyle={{ color: globalColors.HEADING_COLOR, maxWidth: "99%", overflowWrap: "anywhere" }}
                                                />
                                            )}
                                            {!editRating &&
                                                item.endUserId === loggedinUserId && (
                                                    <div className="SPnewRow1">
                                                        <BiPencil
                                                            size={mobileDevices || aboveLargeMobileDevices ? 12 : 15}
                                                            style={{ color: globalColors.GREY_TEXT, margin: "0px 10px 0px 0px", cursor: "pointer", }}
                                                            onClick={() => {
                                                                setReplayRating(false);
                                                                setEditID(item._id);
                                                                setReviewComment(item?.comment);
                                                                setEditRating(!editRating);
                                                            }}
                                                        />
                                                        <MdDeleteOutline
                                                            size={mobileDevices || aboveLargeMobileDevices ? 12 : 15}
                                                            style={{ color: globalColors.GREY_TEXT, cursor: "pointer" }}
                                                            onClick={() => {
                                                                deleteAPI(item?._id, "false");
                                                                setEditID("");
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            {((screenName === "Orders" || (screenName === "spReviews" && (eligibilty || loggedinUserId === params?.id)) || reviewData?.isPurchased) &&
                                                item.endUserId !== loggedinUserId) ? (
                                                <AautiText
                                                    title={"Reply"}
                                                    onClick={() => {
                                                        setReplayRating(true);
                                                        setReplayComment("");
                                                        setEditID(item?._id);
                                                        setEditRating(false);
                                                    }}
                                                    weight={"normal"}
                                                    textStyle={{ fontSize: mobileDevices ? 11 : 12 }}
                                                    className={"reviews-reply-text"}
                                                />
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* </div> */}
                                    {item?._id === editID && replayRating && (
                                        <div className="RRchield2div12"
                                            style={{ width: "95%" }} >
                                            {userDetails?.profileImage ?
                                                <Avatar sx={{
                                                    height: avatarWidthMobile,
                                                    width: avatarWidthMobile, mr: mobileDevices ? 1 : 1.5,
                                                }}
                                                    src={userDetails?.profileImage}
                                                    alt="student" />
                                                :
                                                <Avatar sx={{
                                                    height: avatarWidthMobile,
                                                    width: avatarWidthMobile, mr: mobileDevices ? 1 : 1.5,
                                                    bgcolor: returnColorbasedName(userDetails?.displayName)
                                                }}  >
                                                    {getFirstTwoChars(userDetails?.displayName)}
                                                </Avatar>
                                            }
                                            <div className="RRchield2commentdiv" style={{ width: spReviewsWidth && (ipodDevices ? "92.5%" : miniLapys ? '91.5%' : "93.5%") }}   >
                                                <div className="RRnewDisplayDiv">
                                                    <AautiText
                                                        textStyle={{ color: globalColors.HEADING_COLOR }}
                                                        weight={"medium"}
                                                        size={"semi"}
                                                        title={userDetails?.displayName}
                                                    />
                                                </div>
                                                <CommentTextField
                                                    value={replayComment}
                                                    onChange={(e) => setReplayComment(e.target.value.trimStart().replace(/\s+/g, " "))}
                                                    onSubmit={() => {
                                                        ratingAPI(each, true);
                                                        setReplayRating(false);
                                                        setEditID('');
                                                    }}
                                                    onCancel={() => {
                                                        setReplayRating(false);
                                                        setEditID('');
                                                    }}
                                                    setEditID={setEditID}
                                                    editID={editID}
                                                    itemID={each._id}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                            {editID === each?._id && replayRating && (
                                <div className="RRchield2div12"
                                    style={{
                                        width: "95%",
                                    }}  >{userDetails?.profileImage ?
                                        <Avatar
                                            sx={{
                                                height: avatarWidthMobile,
                                                width: avatarWidthMobile, mr: mobileDevices ? 1 : 1.5,
                                            }}
                                            src={userDetails?.profileImage}
                                            alt="student"
                                        /> :
                                        <Avatar sx={{
                                            height: avatarWidthMobile,
                                            width: avatarWidthMobile, mr: mobileDevices ? 1 : 1.5,
                                            bgcolor: returnColorbasedName(userDetails?.displayName)
                                        }}  >
                                            {getFirstTwoChars(userDetails?.displayName)}
                                        </Avatar>}
                                    <div className="RRchield2commentdiv"  >
                                        <div className="RRnewDisplayDiv">
                                            <AautiText
                                                textStyle={{ color: globalColors.HEADING_COLOR }}
                                                weight={"medium"}
                                                size={"semi"}
                                                title={userDetails?.displayName}
                                            />
                                        </div>
                                        <CommentTextField
                                            value={replayComment}
                                            onChange={(e) => setReplayComment(e.target.value.trimStart().replace(/\s+/g, " "))}
                                            onSubmit={() => {
                                                ratingAPI(each, true);
                                                setReplayRating(false);
                                                setEditID('');
                                            }}
                                            onCancel={() => { setReplayRating(false); setEditID(''); }}
                                            setEditID={setEditID}
                                            editID={editID}
                                            itemID={each._id}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    )
}

export default ReviewsContainer
