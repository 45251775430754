import { BsPersonVideo3 } from "react-icons/bs";
import { IoMdPerson } from "react-icons/io";
import { LuMonitor } from "react-icons/lu";
import { MdGroups } from "react-icons/md";

export const courseTypes = [
  {
    id: 1,
    icon: <IoMdPerson size={25} />,
    title: "Individual",
    text: "Selecting Individual allows for personalized training, with flexible scheduling and pacing to meet specific learning needs.",
    slotKeys: ["individualDaily", "individualWeekly"],
  },
  {
    id: 1,
    icon: <MdGroups size={25} />,
    title: "Group",
    text: "Selecting the Group option allows you to teach multiple participants at once.",
    slotKeys: ["groupDialy", "groupWeekly"],
  },
  {
    id: 1,
    icon: <BsPersonVideo3 size={25} />,
    title: "Gig",
    text: "Allows you to showcase your skills and services with engaging videos.",
  },
];

export const ClassTypes = [
  {
    id: 1,
    name: "Virtual",
    icon: <LuMonitor />,
  },
  { id: 2, name: "In Person", icon: <IoMdPerson /> },
];
