import React, { useState, useEffect } from "react";
import AautiText from "../globalComponents/AautiText";
import AautiDialog from "../globalComponents/AautiDialog";
import { updateMembersList } from "../../redux/reducer/appReducer";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import {
  getFirstTwoChars,
  logoutFunction,
  returnColorbasedName,
} from "../CommonFunctions";
import { useNavigate } from "react-router-dom";
import {
  getMembers,
  sendSms,
  getDeactivateReasons,
  deleteUser,
  loginAuth,
  VerifySms,
  reactivateUser,
} from "./service";
import { isEmpty } from "lodash";
import {
  Box,
  FormControl,
  Skeleton,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  Button,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import AppContainer from "../AppContainer";
import { showToast } from "../globalComponents/Toast";
import deletemember from "../../assets/images/deletemember.png";
import DeletionAlert from "../../assets/images/DeletionAlert.png";
import AccountTabs from "../globalComponents/AccountTabs";
import AautiTextInput from "../globalComponents/AautiTextInput";
import { AautiStrings } from "../globalComponents/AautiStrings";
import { courseConstant } from "../course/courseCreation/courseConstant";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import InfoIcon from "@mui/icons-material/Info";
import Spinner from "../globalComponents/Spinner";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const daysOptions = [
  { label: "Item 1", value: "10 Days" },
  { label: "Item 2", value: "1 Month" },
  { label: "Item 3", value: "2 Months" },
  { label: "Item 4", value: "Custom" },
  { label: "Item 5", value: "Not Sure" },
];

const getDate = (val, customDate) => {
  switch (val) {
    case daysOptions[0]?.value:
      return moment().add(10, "days");
    case daysOptions[1]?.value:
      return moment().add(1, "months");
    case daysOptions[2]?.value:
      return moment().add(2, "months");
    case daysOptions[3]?.value:
      return moment(customDate);
    default:
      return moment().add(12, "months");
  }
};

const DeleteOrDeactivate = () => {
  const { loggedinUserId, userDetails } = useSelector((state) => state.app);
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [yourMembers, setYourMembers] = useState(null);
  const { mobileDevices } = useSelector((state) => state.responsive);
  const [openPwdVerification, setOpenPwdVerification] = useState(false);
  const [openOtpVerification, setOpenOtpVerification] = useState(false);
  const [openDeactivationReasons, setOpenDeactivationReasons] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState("deActivate");
  const [password, setPassword] = useState("");
  const [deactivationReasons, setDeactivationReasons] = useState([]);
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [customReason, setCustomReason] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const [loadingVerification, setLoadingVerification] = useState(false);
  const [deactivationDays, setDeactivationDays] = useState(
    daysOptions[0].value
  );
  const [customDate, setCustomDate] = useState(dayjs().add(1, "day"));
  const [reactivateDate, setReactivateDate] = useState(dayjs);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(userDetails);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [resendEnabled, setResendEnabled] = useState(false);
  const [timer, setTimer] = useState(120);
  const dispatch = useDispatch();
  const navigation = useNavigate();

  useEffect(() => {
    setLoading(true);
    getMembers(
      loggedinUserId,
      (resp) => {
        const filteredMembers = resp?.result?.filter(
          (member) => member?.createdByParent
        );
        setYourMembers(filteredMembers);
        dispatch(updateMembersList(resp?.result || []));
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
    getDeactivateReasons(
      (resp) => {
        if (resp.status === "Success") {
          setDeactivationReasons(resp.result);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }, [loggedinUserId, dispatch, deleteClicked]);

  useEffect(() => {
    if (openOtpVerification) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          } else {
            clearInterval(countdown);
            setResendEnabled(true);
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [openOtpVerification, resendEnabled]);

  useEffect(() => {
    if (selectedValue === daysOptions[3].value) {
      setReactivateDate(getDate(deactivationDays, customDate));
    } else {
      setReactivateDate(getDate(deactivationDays));
    }
  }, [deactivationDays, customDate]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOpenPwdVerification = () => {
    setOpenPwdVerification(true);
  };

  const handleClosePwdVerification = () => {
    setOpenPwdVerification(false);
    setErrorMessage("");
  };

  const handleOpenOtpVerification = () => {
    setOpenOtpVerification(true);
    setTimer(120);
  };

  const handleCloseOtpVerification = () => {
    setOpenOtpVerification(false);
  };

  const handleOpenDeactivationReasons = () => {
    setOpenDeactivationReasons(true);
  };

  const handleCloseDeactivationReasons = () => {
    setOpenDeactivationReasons(false);
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setDeactivationDays(daysOptions[0].value);
    setOpenConfirmationDialog(false);
    setSelectedReasons([]);
    setCustomReason("");

    // setSelectedUser("");
  };

  const resetDeactivationData = () => {
    setOpenDeactivationReasons(false);
    setDeactivationDays(daysOptions[0].value);
    setCustomDate(dayjs().add(1, "day"));
    setSelectedReasons([]);
    setCustomReason("");
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
    setPassword("");
    setOtp("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleInfoClick = () => {
    setShowInfo((prevShowInfo) => !prevShowInfo);
  };

  const handleOtpChange = (e) => {
    setErrorMessage("");
    setOtp(e.target.value);
  };

  const handleCustomReasonChange = (e) => {
    if (e.target.value.length <= 300) {
      setCustomReason(e.target.value);
    }
  };

  const handleDeactivationDaysChange = (e) => {
    setDeactivationDays(e.target.value);
    if (e.target.value !== daysOptions[3].value) {
      setReactivateDate(getDate(e.target.value));
    }
  };

  const handleDateChange = (date) => {
    setCustomDate(date);
    setReactivateDate(getDate(deactivationDays, date));
  };

  const toggleReason = (reason) => {
    console.log(reason, "reason");
    setSelectedReasons((prev) =>
      prev.includes(reason)
        ? prev.filter((r) => r !== reason)
        : [...prev, reason]
    );
  };

  const onPressContinue = () => {
    // if (!selectedUser) {
    //   showToast("error", "Please select a user or member to continue.");
    //   return;
    // }
    handleOpenConfirmDialog();
  };

  useEffect(() => {
    setSelectedUser(userDetails);
  }, [userDetails]);

  const passwordVerificationScreen = () => (
    <div>
      <AautiDialog
        open={openPwdVerification}
        onClose={handleClosePwdVerification}
        dialogTitle={AautiStrings?.passwordVerificationString}
        titlePosition="left"
        dialogWidth="500px"
        sxStyles={{ padding: "5px 30px 30px 30px !important" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            src={
              !isEmpty(selectedUser?.profileImage)
                ? selectedUser?.profileImage
                : undefined
            }
            alt="profile"
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: isEmpty(selectedUser?.profileImage)
                ? returnColorbasedName(
                    selectedUser?.displayName || selectedUser?.userName
                  )
                : "transparent",
            }}
          >
            {isEmpty(selectedUser?.profileImage) &&
              getFirstTwoChars(
                selectedUser?.displayName || selectedUser?.userName
              )}
          </Avatar>
          <AautiText
            title={
              AautiStrings?.forYourSecurityPleaseReEnterYourPasswordOrOTPToContinueString
            }
          />
          <AautiText
            title={selectedUser?.email || selectedUser?.userName}
            weight={"bold"}
            style={{ textAlign: "center", marginTop: "10px" }}
          />
        </div>
        <AautiTextInput
          important
          text={AautiStrings?.passwordString}
          value={password}
          maxLength={14}
          onChange={(e) => {
            const valueWithoutSpaces = e.target.value.replace(/\s/g, '');
            handlePasswordChange({ ...e, target: { ...e.target, value: valueWithoutSpaces } });
            setErrorMessage("");
          }}
          type="password"
          style={{ width: "100%", marginTop: "10px" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            position: "relative",
          }}
        >
          {loadingVerification ? (
            <div
              style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
            >
              <Spinner />
            </div>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                setErrorMessage("");
                if (!password) {
                  showToast("error", "Please enter password.");
                  return;
                }
                setLoadingVerification(true);
                loginAuth(
                  {
                    username: selectedUser.email || selectedUser.userName,
                    password,
                  },
                  (response) => {
                    setLoadingVerification(false);
                    if (response.result) {
                      handleClosePwdVerification();
                      handleOpenDeactivationReasons();
                    } else {
                      showToast("error", "Invalid password. Please try again.");
                    }
                  },
                  (err) => {
                    setLoadingVerification(false);
                    console.log(err);
                    showToast("error", "An error occurred. Please try again.");
                  }
                );
              }}
              disabled={password.length < 8}
              style={{
                textTransform: "unset",
                color: "white",
                backgroundColor: "#3083EF",
              }}
            >
              Verify
            </Button>
          )}
        </div>
      </AautiDialog>
    </div>
  );

  const otpVerificationScreen = () => (
    <AautiDialog
      open={openOtpVerification}
      onClose={handleCloseOtpVerification}
      dialogTitle={AautiStrings?.OTPVerificationString}
      titlePosition="left"
      dialogWidth="500px"
      sxStyles={{ padding: "0px 30px 20px 30px !important" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          src={
            !isEmpty(selectedUser?.profileImage)
              ? selectedUser?.profileImage
              : undefined
          }
          alt="profile"
          style={{
            width: "70px",
            height: "70px",
            backgroundColor: isEmpty(selectedUser?.profileImage)
              ? returnColorbasedName(
                  selectedUser?.displayName || selectedUser?.userName
                )
              : "transparent",
          }}
        >
          {isEmpty(selectedUser?.profileImage) &&
            getFirstTwoChars(
              selectedUser?.displayName || selectedUser?.userName
            )}
        </Avatar>

        <AautiText
          title={selectedUser?.displayName}
          weight={"bold"}
          style={{ textAlign: "left", marginTop: "10px" }}
        />
        <AautiText
          title={AautiStrings?.pleaseEnterTheOTPSentToYourMobileNumberString}
          style={{ marginTop: "10px" }}
        />
      </div>
      <AautiTextInput
        important
        text={AautiStrings?.OTPString}
        value={otp}
        onChange={(e) => {
          const value = e.target.value;
          if (/^\d*$/.test(value) && value.length <= 6) {
            handleOtpChange(e);
            setErrorMessage("");
          }
        }}
        type="tel"
        maxLength={6}
        style={{ width: "100%", marginTop: "10px" }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "20px",
          position: "relative",
        }}
      >
      {errorMessage && (
        <Typography color="error" style={{ marginTop: "10px" }}>
          {errorMessage}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setErrorMessage("");
          VerifySms(
            selectedUser.mobile,
            otp,
            (response) => {
              if (response.status === "Success") {
                handleCloseOtpVerification();
                handleOpenDeactivationReasons();
              } else {
                showToast("error", "Invalid OTP. Please try again.");
              }
            },
            (err) => {
              console.log(err);
              showToast("error", "An error occurred. Please try again.");
            }
          );
        }}
        disabled={otp.length !== 6}
        style={{ textTransform: "unset" }}
      >
        Verify
      </Button>
      </div>
      <div style={{ marginTop: "10px", textAlign: "center" }}>
        <AautiText
          title={`Resend OTP ${resendEnabled ? "" : `in ${timer} seconds`}`}
          weight={"bold"}
          textStyle={{
            color: resendEnabled ? "#3166BA" : "grey",
            cursor: resendEnabled ? "pointer" : "default",
          }}
          onClick={() => {
            if (resendEnabled) {
              setOtp("");
              sendSms(
                selectedUser?.mobile,
                (response) => {
                  if (response.status === "Success") {
                    setResendEnabled(false);
                    setTimer(120);
                  }
                },
                (err) => {
                  console.log(err, "errr");
                }
              );
            }
          }}
        />
      </div>
    </AautiDialog>
  );

  const deactivationReasonsScreen = () => {
    const isCustomDateValid =
      customDate?.isAfter(dayjs()) &&
      customDate?.isBefore(dayjs().add(1, "year"));

    const isButtonEnabled =
      customReason.length >= 15 &&
      selectedReasons.length > 0 &&
      (deactivationDays !== daysOptions[3].value || isCustomDateValid);
    return (
      <Dialog
        open={openDeactivationReasons}
        onClose={resetDeactivationData}
        aria-labelledby="deactivation-reasons-title"
        sx={{
          "& .MuiDialog-paper": {
            width: "600px",
            maxWidth: "100%",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <DialogTitle id="deactivation-reasons-title">
          {selectedValue === "deActivate"
            ? "Deactivating your Aauti account"
            : "Deleting your Aauti account"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            {deactivationReasons.map((question) => (
              <Box
                key={question._id}
                onClick={() => toggleReason(question.name)}
                sx={{
                  padding: "8px",
                  marginBottom: "3px",
                  cursor: "pointer",
                  backgroundColor: selectedReasons.includes(question.name)
                    ? "#E3F2FD"
                    : "transparent",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="body1">{question.name}</Typography>
              </Box>
            ))}
            <AautiTextInput
              text="Reason"
              important
              value={customReason}
              onChange={handleCustomReasonChange}
              multiline
              rows={3}
              variant="outlined"
              style={{ width: "100%" }}
              margin="normal"
              helperText={`${customReason.length} / 300 (minimum 15 characters)`}
            />
          </FormControl>
          {selectedValue === "deActivate" && (
            <>
              <Button
                onClick={handleInfoClick}
                startIcon={<InfoIcon />}
                color="info"
                size="small"
                style={{
                  textTransform: "unset",
                  color: "#3083EF",
                }}
              >
                Info
              </Button>
              {showInfo && (
                <Typography>
                  <strong
                    style={{
                      fontSize: "14px",
                      display: "block",
                      marginTop: "15px",
                    }}
                  >
                    Reactivate your account
                  </strong>
                  <span style={{ fontSize: "14px" }}>
                    Aauti automatically allows users to reactivate their
                    accounts seamlessly, ensuring that you can return to
                    accessing courses and resources without any manual
                    intervention. Your account will be reactivated automatically
                    once the specified duration mentioned below has passed.
                  </span>
                </Typography>
              )}
            </>
          )}
          {selectedValue === "deActivate" && (
            <Box mt={2}>
              <FormControl fullWidth>
                <Select
                  value={deactivationDays}
                  onChange={handleDeactivationDaysChange}
                  fullWidth
                >
                  {daysOptions.map((day) => (
                    <MenuItem key={day.value} value={day.value}>
                      {day.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {deactivationDays === daysOptions[3].value && (
                <Box mt={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DemoItem label="Custom Date">
                        <DatePicker
                          value={customDate}
                          onChange={(date) => {
                            if (date && date.isAfter(dayjs().add(1, "year"))) {
                              setCustomDate(dayjs().add(1, "year"));
                            } else if (date && date.isBefore(dayjs())) {
                              setCustomDate(dayjs());
                            } else {
                              setCustomDate(date);
                            }
                            setReactivateDate(getDate(deactivationDays, date));
                          }}
                          minDate={dayjs()}
                          maxDate={dayjs().add(1, "year")}
                          format="DD/MM/YYYY"
                          placeholder="DD/MM/YYYY"
                          disablePast
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                </Box>
              )}

              <Typography>
                Automatically reactivate my account on:{" "}
                {deactivationDays === daysOptions[3].value
                  ? customDate.format("DD/MM/YYYY")
                  : reactivateDate.format("DD/MM/YYYY")}{" "}
              </Typography>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              resetDeactivationData();
            }}
            color="primary"
            style={{ textTransform: "unset" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleCloseDeactivationReasons();
              handleOpenConfirmationDialog();
            }}
            variant="contained"
            disabled={!isButtonEnabled}
            sx={{
              textTransform: "unset",
              color: "white",
              backgroundColor: isButtonEnabled ? "#3083EF" : "#D3D3D3",
              "&:disabled": {
                backgroundColor: "#D3D3D3",
              },
            }}
          >
            {selectedValue === "deActivate"
              ? "Deactivate Account"
              : "Delete Account"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const confirmationDialog = () => (
    <Dialog
      open={openConfirmationDialog}
      onClose={handleCloseConfirmationDialog}
      aria-labelledby="confirm-dialog-title"
      maxWidth="xs"
      sx={{
        "& .MuiDialog-paper": {
          width: mobileDevices ? "90%" : "50%",
          maxWidth: mobileDevices ? "90%" : "320px",
          maxHeight: mobileDevices ? "90%" : 435,
          padding: mobileDevices ? "20px" : "0px",
          borderRadius: mobileDevices ? "10px" : "8px",
        },
      }}
    >
      <DialogContent
        sx={{
          padding: mobileDevices ? "10px 15px" : "5px 10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={DeletionAlert}
          alt="Confirm"
          style={{
            height: mobileDevices ? "120px" : "200px",
            width: mobileDevices ? "120px" : "200px",
            alignSelf: "center",
          }}
        />
      </DialogContent>
      <DialogTitle
        id="confirm-dialog-title"
        sx={{
          fontSize: mobileDevices ? "16px" : "18px",
          textAlign: "center",
          padding: mobileDevices ? "10px 15px" : "5px 10px",
        }}
      >
        {selectedValue === "deActivate"
          ? "Are you sure you want to deactivate account?"
          : "Are you sure you want to delete account?"}
      </DialogTitle>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: mobileDevices ? "center" : "flex-end",
          alignItems: "center",
          gap: mobileDevices ? "10px" : "5px",
          padding: mobileDevices ? "10px" : "10px 48px 16px 0px;",
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCloseConfirmationDialog}
          sx={{
            width: mobileDevices ? "100%" : "auto",
            marginRight: !mobileDevices && "10px",
            textTransform: "unset",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleCloseConfirmationDialog();
            setLoadingVerification(true);
            let obj = {
              reasons: selectedReasons,
              reasonText: customReason,
              userId: selectedUser?._id ?? loggedinUserId,
              parentId: loggedinUserId,
              status: true,
              type: selectedValue === "deActivate" ? "deActivate" : "delete",
            };
            let finalObj =
              selectedValue === "deActivate"
                ? {
                    ...obj,
                    reactivateDate:
                      deactivationDays === daysOptions[3].value
                        ? customDate.toISOString()
                        : reactivateDate.toISOString(),
                  }
                : obj;
            setDeleteClicked(true);
            deleteUser(
              finalObj,
              (response) => {
                setLoadingVerification(false);
                showToast("success", response.message);
                if (selectedUser._id === finalObj.userId) {
                  setSelectedUser(userDetails);
                }

                if (selectedUser?._id === loggedinUserId) {
                  navigation("/");
                  logoutFunction();
                }
                dispatch(
                  updateMembersList(
                    yourMembers.filter(
                      (member) => member._id !== selectedUser._id
                    )
                  )
                );
              },
              (error) => {
                console.log("Account action failed", error);
                showToast("error", "An error occurred. Please try again.");
                setDeleteClicked(false);
                setLoadingVerification(false);
              }
            );
          }}
          sx={{
            width: mobileDevices ? "100%" : "auto",
            textTransform: "unset",
            color: "white",
            backgroundColor: "#3083EF",
          }}
        >
          {selectedValue === "deActivate" ? "Deactivate" : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );

  const confirmDialog = () => {
    return (
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: mobileDevices ? "90%" : "350px",
            maxWidth: mobileDevices ? "90%" : "380px",
            maxHeight: mobileDevices ? "90%" : 435,
            height: mobileDevices ? "45%" : 300,
            padding: mobileDevices ? "20px" : "30px",
            borderRadius: mobileDevices ? "10px" : "12px",
          },
        }}
        maxWidth="xs"
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: mobileDevices ? "10px" : "0px",
          }}
        >
          <img
            src={deletemember}
            alt="Confirm"
            style={{
              width: mobileDevices ? "80px" : "100px",
              height: mobileDevices ? "80px" : "100px",
            }}
          />
        </DialogTitle>
        <DialogContent
          sx={{
            padding: mobileDevices ? "10px 15px" : "5px 10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: mobileDevices ? "center" : "left",
          }}
        >
          <AautiText
            title={
              courseConstant?.allTheOngoingAndFutureSessionsWillBeCancelledDoYouWantToProceedString
            }
            textAlign="center"
            textStyle={{ fontSize: mobileDevices ? "14px" : "14px" }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: mobileDevices ? "10px" : "5px",
            padding: mobileDevices ? "10px" : "8px",
          }}
        >
          <Button
            onClick={() => {
              handleCloseConfirmDialog();
              if (selectedUser?.email || selectedUser?.userName) {
                handleOpenPwdVerification();
              } else if (selectedUser?.mobile !== null) {
                sendSms(
                  selectedUser?.mobile,
                  (response) => {
                    if (response.status === "Success") {
                      handleOpenOtpVerification();
                    }
                  },
                  (err) => {
                    console.log(err);
                  }
                );
              } else {
                showToast(
                  "error",
                  "The selected user/member does not have valid contact information."
                );
              }
            }}
            variant="outlined"
            color="primary"
            sx={{
              width: mobileDevices ? "auto" : "auto",
              textTransform: "unset",
            }}
          >
            Yes
          </Button>
          <Button
            onClick={handleCloseConfirmDialog}
            autoFocus
            variant="contained"
            sx={{
              width: mobileDevices ? "auto" : "auto",
              textTransform: "unset",
              color: "white",
              backgroundColor: "#3083EF",
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const returnHeaderTabs = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignSelf: "center",
        marginBottom: 10,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <AautiText
          weight={"bold"}
          title={courseConstant?.deactivationOrDeletion}
          textStyle={{ color: "#000000" }}
        />
      </div>
    </div>
  );

  const UserCard = () => (
    <div
      onClick={() => setSelectedUser(userDetails)}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        backgroundColor:
          selectedUser === userDetails ? "#E9F2FD" : "transparent",
        padding: "10px 0px 0px 10px",
        borderRadius: "4px",
        cursor: "pointer",
        paddingBottom: "0px",
      }}
    >
      <Radio
        checked={selectedUser === userDetails}
        onChange={() => setSelectedUser(userDetails)}
        value={userDetails?._id}
        name="user-selection"
        color="primary"
      />
      <Avatar
        src={
          !isEmpty(userDetails?.profileImage)
            ? userDetails?.profileImage
            : undefined
        }
        alt="profile"
        style={{
          width: "40px",
          height: "40px",
          margin: "0px 10px 10px 0px",
          backgroundColor: isEmpty(userDetails?.profileImage)
            ? returnColorbasedName(
                userDetails?.displayName || userDetails?.userName
              )
            : "transparent",
        }}
      >
        {isEmpty(userDetails?.profileImage) &&
          getFirstTwoChars(userDetails?.displayName || userDetails?.userName)}
      </Avatar>
      <div
        style={{
          flex: 1,
          borderBottom: "2px solid #E9E9E9",
          paddingBottom: "10px",
        }}
      >
        <AautiText
          className={"textStyle1"}
          title={userDetails?.displayName}
          weight={"bold"}
        />
        <AautiText
          className={"textStyle2"}
          title={"Myself"}
          weight={"normal"}
          size={"semi"}
          textStyle={{
            color: "#5C5B57",
          }}
        />
        <div
          style={{
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );

  const MemberCard = (each, index) => (
    <div
      key={each._id}
      onClick={() => setSelectedUser(each)}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        backgroundColor: selectedUser === each ? "#E9F2FD" : "transparent",
        padding: "10px 0px 0px 10px",
        borderRadius: "4px",
        cursor: "pointer",
        paddingBottom: "0px",
      }}
    >
      <Radio
        checked={selectedUser === each}
        onChange={() => setSelectedUser(each)}
        value={each._id}
        name="user-selection"
        color="primary"
      />
      <Avatar
        src={!isEmpty(each?.profileImage) ? each?.profileImage : undefined}
        alt="profile"
        style={{
          width: "40px",
          height: "40px",
          margin: "0px 10px 10px 0px",
          backgroundColor: isEmpty(each?.profileImage)
            ? returnColorbasedName(each?.displayName || each?.userName)
            : "transparent",
        }}
      >
        {isEmpty(each?.profileImage) &&
          getFirstTwoChars(each?.displayName || each?.userName)}
      </Avatar>
      <div
        style={{
          flex: 1,
          borderBottom: "2px solid #E9E9E9",
          paddingBottom: "10px",
        }}
      >
        <AautiText
          className={"textStyle1"}
          title={each?.displayName}
          weight={"bold"}
        />
        <AautiText
          className={"textStyle2"}
          title={each?.relation || "Unknown"}
          weight={"normal"}
          size={"semi"}
          textStyle={{
            color: "#5C5B57",
          }}
        />
        <div
          style={{
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );

  const RadioWithSubtitle = ({ title, subtitle, value }) => (
    <FormControlLabel
      value={value}
      control={<Radio />}
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "20px",
            marginTop: "6px",
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: "4px", maxWidth: "600px" }}
          >
            {subtitle}
          </Typography>
        </Box>
      }
      sx={{ alignItems: "flex-start", margin: "0" }}
    />
  );

  return (
    <AppContainer>
      <AccountTabs
        {...{
          tabObj: {
            label: "Deactivate or Delete",
            navigation: "/delete-deactivate",
            active: true,
          },
        }}
      />
      {loadingVerification ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div className="head">
          {returnHeaderTabs()}
          {loading ? (
            <Box
              className="TRloadingdiv1"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box className="TRloadingdiv2" sx={{ width: "25%" }}>
                <Skeleton variant="rounded" width={"100%"} height={40} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={mobileDevices ? 200 : 400}
                />
              </Box>
              <Box className="TRloadingdiv3" sx={{ width: "74%" }}>
                <Skeleton variant="rounded" width={"100%"} height={30} />
                <Skeleton
                  variant="rounded"
                  width={"100%"}
                  height={40}
                  sx={{ m: "8px 0px" }}
                />
                <Skeleton variant="rounded" width={"100%"} height={60} />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                borderRadius: 1.5,
                boxShadow:
                  "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
              }}
            >
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    width: mobileDevices ? "100%" : "30%",
                    borderRight: "1px #dedede solid",
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "80vh", md: "65vh" },
                      overflow: "scroll",
                      padding: 2,
                      width: "100%",
                    }}
                  >
                    <div>{UserCard()}</div>
                    {selectedUser?.displayName === userDetails?.displayName && (
                      <FormControl
                        component="fieldset"
                        sx={{ display: !mobileDevices && "none" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            width: "100%",
                          }}
                        >
                          <Button
                            variant={selectedValue === "deActivate"}
                            onClick={() => {
                              handleChange({ target: { value: "deActivate" } });
                              onPressContinue();
                            }}
                            fullWidth
                            sx={{ flex: 1, color: "#3083EF" }}
                          >
                            Deactivate
                          </Button>
                          <Button
                            variant={selectedValue === "delete"}
                            onClick={() => {
                              handleChange({ target: { value: "delete" } });
                              onPressContinue();
                            }}
                            fullWidth
                            sx={{ flex: 1, color: "#3083EF" }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </FormControl>
                    )}
                    {yourMembers && yourMembers.length > 0 && (
                      <>
                        <AautiText
                          weight={"bold"}
                          title={AautiStrings?.membersString}
                        />
                        <div>
                          {yourMembers?.map((each, index) => (
                            <>
                              {MemberCard(each, index)}

                              {selectedUser?._id === each?._id && (
                                <FormControl
                                  component="fieldset"
                                  sx={{ display: !mobileDevices && "none" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: 2,
                                      width: "100%",
                                    }}
                                  >
                                    <Button
                                      variant={selectedValue === "deActivate"}
                                      onClick={() => {
                                        handleChange({
                                          target: { value: "deActivate" },
                                        });
                                        onPressContinue();
                                      }}
                                      fullWidth
                                      sx={{ color: "#3083EF" }}
                                    >
                                      Deactivate
                                    </Button>
                                    <Button
                                      variant={selectedValue === "delete"}
                                      onClick={() => {
                                        handleChange({
                                          target: { value: "delete" },
                                        });
                                        onPressContinue();
                                      }}
                                      fullWidth
                                      sx={{ color: "#3083EF" }}
                                    >
                                      Delete
                                    </Button>
                                  </Box>
                                </FormControl>
                              )}
                            </>
                          ))}
                        </div>
                      </>
                    )}
                  </Box>
                </Box>
                <div
                  style={{
                    display: mobileDevices ? "none" : "flex",
                    width: "100%",
                    margin: "30px",
                  }}
                >
                  <div>
                    <AautiText
                      weight={"bold"}
                      title={
                        AautiStrings?.deactivationOrDeletionYourAautiAccountString
                      }
                    />
                    <AautiText
                      weight={"normal"}
                      size={"semi"}
                      title={
                        AautiStrings?.ifYouWantToTemporarilyCloseYourAccountYouCanDeactivateItIfYouWantToPermanentlyRemoveYourDataFromAautiYouCanDeleteYourAccountString
                      }
                      textStyle={{
                        marginTop: "8px",
                        maxWidth: "900px",
                        fontFamily: "Segoe UI",
                        fontSize: "16px",
                      }}
                    />
                    <FormControl
                      component="fieldset"
                      sx={{
                        "& .css-8q464b-MuiTypography-root": {
                          fontFamily: "Segoe UI",
                          fontSize: "16px",
                        },
                      }}
                    >
                      <RadioGroup value={selectedValue} onChange={handleChange}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            borderRadius: 1.5,
                            padding: "10px",
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                            marginTop: "10px",
                            minHeight: "85px",
                            border:
                              selectedValue === "deActivate"
                                ? "1px solid #3083EF"
                                : "2px solid transparent",
                          }}
                        >
                          <RadioWithSubtitle
                            title="Deactivate Account"
                            subtitle="Your Profile won't be shown on Aauti (There should not be any Active/Upcoming Sessions)"
                            value="deActivate"
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                            borderRadius: 1.5,
                            padding: "10px",
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
                            marginTop: "18px",
                            minHeight: "90px",
                            border:
                              selectedValue === "delete"
                                ? "1px solid #3083EF"
                                : "2px solid transparent",
                          }}
                        >
                          <RadioWithSubtitle
                            title={AautiStrings?.deleteAccountString}
                            subtitle={
                              AautiStrings?.deletingYourAccountIsPermanentAndIrreversibleOnceDeletedYouWontBeAbleToRetrieveYourAccountString
                            }
                            value="delete"
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </>
            </Box>
          )}
        </div>
      )}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          margin: "20px 0px",
        }}
      >
        <Button
          style={{
            display: mobileDevices || loading ? "none" : "block",
            textTransform: "unset",
            color: "white",
            backgroundColor: "#3083EF",
          }}
          onClick={onPressContinue}
          variant="contained"
          // disabled={!selectedUser}
        >
          Continue
        </Button>
      </div>
      {passwordVerificationScreen()}
      {otpVerificationScreen()}
      {deactivationReasonsScreen()}
      {confirmationDialog()}
      {confirmDialog()}
    </AppContainer>
  );
};

export default DeleteOrDeactivate;
